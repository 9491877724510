import { Dispatch } from 'react'
import { Action } from 'redux'
import { UserModel } from '../Models/user.model'
import service from '../Services/verify-code.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_VERIFY_IMPERSONATE_TOKEN = 'ACTION_VERIFY_IMPERSONATE_TOKEN'
export const ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS = 'ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS'
export const ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED = 'ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED'

export interface ActionVerifyImpersonateToken extends Action {
    type: typeof ACTION_VERIFY_IMPERSONATE_TOKEN
}

export interface ActionVerifyImpersonateTokenSuccess extends Action {
    type: typeof ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS
    payload: {
        user?: UserModel
    }
}

export interface ActionVerifyImpersonateTokenFailed extends Action {
    type: typeof ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED
    payload: {
        errorMessage: string
    }
}

export type VerifyImpersonateTokenActions = ActionVerifyImpersonateToken | ActionVerifyImpersonateTokenFailed | ActionVerifyImpersonateTokenSuccess

const dispatchVerifyImpersonateToken = (): ActionVerifyImpersonateToken => ({
    type: ACTION_VERIFY_IMPERSONATE_TOKEN,
})

const dispatchVerifyImpersonateTokenSuccess = (user?: UserModel): ActionVerifyImpersonateTokenSuccess => ({
    type: ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS,
    payload: {
        user,
    },
})

const dispatchVerifyImpersonateTokenFailed = (error: Error): ActionVerifyImpersonateTokenFailed => ({
    type: ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED,
    payload: { errorMessage: error.message },
})
const toaster = (code: number) => {
    switch (code) {
        case ResponseCodes.InvalidParams:
            toastService.error(I18n.t('auth_toast.invalid_code'))
            break
        case ResponseCodes.ExpiredCode:
            toastService.error(I18n.t('auth_toast.code_expired'))
            break
        case ResponseCodes.NotFound:
            toastService.error(I18n.t('auth_toast.user_not_exists'))
            break
        default:
            toastService.error(I18n.t('common.toast.something_went_wrong'))
            break
    }
}

export function actionVerifyImpersonateToken(jwtToken: string): (dispatch: Dispatch<VerifyImpersonateTokenActions>) => Promise<void> {
    return async (dispatch: Dispatch<VerifyImpersonateTokenActions>) => {
        dispatch(dispatchVerifyImpersonateToken())
        loaderStart(dispatch)
        try {
            const result = await service.verifyImpersonateToken(jwtToken)
            if (result && result.success) {
                dispatch(dispatchVerifyImpersonateTokenSuccess(result.user))
            } else {
                toaster(result?.code)
                dispatch(dispatchVerifyImpersonateTokenFailed(new Error(result?.message || 'Something went wrong.')))
            }
            loaderStop(dispatch)
        } catch (error: any) {
            dispatch(dispatchVerifyImpersonateTokenFailed(error))
            loaderStop(dispatch)
        }
    }
}
