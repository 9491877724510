import moment from 'moment'
export interface CytrioDateFormat {
  fullYear: string
  month: string
  date: string
  hour: string
  minute: string
  hourSuffix: string
  fullMonthName: string
  shortMonthName: string
  shortYear: string
  seconds: string
}

export interface DateRangeModel {
  startDate: number
  endDate: number
}

function buildCytrioDateFormat(someDate: Date): CytrioDateFormat {
  if (!someDate) {
    return
  }
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = someDate.getDate()
  const month = someDate.getMonth() + 1
  const fullYear = someDate.getFullYear().toString()
  const shortYear = someDate.getFullYear().toString().substr(-2)
  const fullMonthName = monthNames[someDate.getMonth()]
  const shortMonthName = shortMonthNames[someDate.getMonth()]
  let seconds = `${someDate.getSeconds()}`
  if (someDate.getSeconds() < 10) {
    seconds = `0${seconds}`
  }
  let minute = `${someDate.getMinutes()}`
  if (someDate.getMinutes() < 10) {
    minute = `0${minute}`
  }
  let normalizeHour
  const hours = someDate.getHours()
  if (hours >= 13) {
    normalizeHour = hours - 12
  } else {
    normalizeHour = hours
  }

  if (normalizeHour === 0) {
    normalizeHour = 12
  }

  return {
    fullYear,
    month: month < 10 ? `0${month}` : `${month}`,
    date: date < 10 ? `0${date}` : `${date}`,
    hour: `${normalizeHour}`,
    minute,
    hourSuffix: hours >= 12 ? 'PM' : 'AM',
    fullMonthName,
    shortMonthName,
    shortYear,
    seconds,
  }
}

export const formatDefaultDate = (someDate: Date): string => {
  const { shortMonthName, date, fullYear } = buildCytrioDateFormat(someDate)
  return `${shortMonthName} ${date}, ${fullYear}`
}

export const formatDefaultFullDate = (someDate: Date, isWithSeconds?: boolean, isShowTimeStamp?: boolean): string => {
  const { shortMonthName, date, fullYear, hour, hourSuffix, minute, seconds } = buildCytrioDateFormat(someDate)
  if (isShowTimeStamp) {
    if (isWithSeconds) {
      return `${shortMonthName} ${date}, ${fullYear} ${hour}:${minute}:${seconds} ${hourSuffix}`
    }
    return `${shortMonthName} ${date}, ${fullYear} ${hour}:${minute} ${hourSuffix}`
  }
  return `${shortMonthName} ${date}, ${fullYear}`
}

export const millisToMinutesAndSeconds = (millis: number) => {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
}

export const getCurrentYear = () => {
  return new Date().getFullYear()
}

export const checkDateIsEqualToToday = (someDate: Date): boolean => {
  // Get today's date
  var todaysDate = new Date()

  // call setHours to take the time out of the comparison
  if (someDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
    // Date equals today's date
    return true
  }
  return false
}

export function daysLeft(date: Date) {
  if (!date) {
    return 0
  }
  const maxDate = new Date()
  const daysLag = Math.ceil(Math.abs(maxDate.getTime() - date.getTime()) / (1000 * 3600 * 24))
  return 30 - daysLag
}

export function dueDaysLeft(dueDate: Date) {
  if (!dueDate) {
    return 0
  }
  const currentDate = new Date()
  return Math.ceil((dueDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24))
}

export function getStartAndEndDateBasedOnNumberOfDays(numberOfDays: number): DateRangeModel {
  const startDateRef = new Date()
  startDateRef.setHours(0, 0, 0, 0)
  startDateRef.setDate(startDateRef.getDate() - numberOfDays)
  const endDateRef = new Date()
  let startDate: number
  let endDate: number
  startDate = Math.trunc(startDateRef.getTime() / 1000)
  endDate = Math.trunc(endDateRef.getTime() / 1000)
  return {
    startDate,
    endDate,
  }
}

export function getStartAndEndDateBasedOnCustomDateForWeekly(customDate: string, isMonthly?: boolean): DateRangeModel {
  const startDateRef = new Date(customDate)
  startDateRef.setHours(0, 0, 0, 0)
  if (isMonthly) {
    startDateRef.setDate(startDateRef.getDate() - 30)
  } else {
    startDateRef.setDate(startDateRef.getDate() - 6)
  }
  const endDateRef = new Date(customDate)
  endDateRef.setHours(23, 59, 59, 999)
  let startDate: number
  let endDate: number
  startDate = Math.trunc(startDateRef.getTime() / 1000)
  endDate = Math.trunc(endDateRef.getTime() / 1000)
  return {
    startDate,
    endDate,
  }
}

export const getDateAndMonth = (someDate: Date): string => {
  if (!someDate) {
    return null
  }
  const { shortMonthName, date, fullYear } = buildCytrioDateFormat(someDate)
  return `${shortMonthName} ${date}, ${fullYear}`
}

export const avgResponseTimeConverter = (minutes: number): string => {
  if (!minutes || minutes === 0) {
    return '0m'
  }
  let days = Math.floor(minutes / 1440) // 60*24
  let hours = Math.floor((minutes - days * 1440) / 60)
  let minute = Math.round(minutes % 60)

  if (days > 0) {
    return days + 'd ' + hours + 'h'
  } else if (hours > 0) {
    return hours + 'h ' + minute + 'm'
  } else {
    return minute + 'm'
  }
}

export const getDateRangeEpochByMonth = (dateString: string): DateRangeModel => {
  const date = new Date(dateString),
    y = date.getFullYear(),
    m = date.getMonth()
  const startDateRef = new Date(y, m, 1)
  const endDateRef = new Date(y, m + 1, 0)

  let startDate: number
  let endDate: number
  startDate = Math.trunc(startDateRef.getTime() / 1000)
  endDate = Math.trunc(endDateRef.getTime() / 1000)
  return {
    startDate,
    endDate,
  }
}

export const getDateRangeEpochByWeekNumber = (dateString: string): DateRangeModel => {
  const [y, w] = dateString.split('-WK')
  const m = moment(y + '-W' + w)
  const dateFromStr = moment(m.startOf('week')).add(1, 'day')
  const dateToStr = moment(m.endOf('week')).add(1, 'day')
  const startDateRef = dateFromStr.toDate()
  startDateRef.setHours(0, 0, 0, 0)
  const endDateRef = dateToStr.toDate()

  let startDate: number
  let endDate: number
  startDate = Math.trunc(startDateRef.getTime() / 1000)
  endDate = Math.trunc(endDateRef.getTime() / 1000)
  return {
    startDate,
    endDate,
  }
}

export const getPrevioueDate = (days: number): number => {
  var today = new Date()
  var priorDate = new Date().setDate(today.getDate() - days)
  return priorDate
}
