import React, { lazy, useEffect, useMemo, useState } from 'react'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../Store/reducers'
import { actionSetUserData } from '../Auth/Actions/user-data.actions'
import styled from 'styled-components'
import Sidebar from './Sidebar'
import Header from './Header'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from './Layout.module.css'
import { getAllowedPages } from 'Modules/Auth/Utils'
import PrivateRoute from './PrivateRoute'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { dispatchLogout } from 'Modules/Auth/Actions/logout.actions'
import { UserModel } from 'Modules/Auth/Models/user.model'
import ConsentLogs from 'Modules/ConsentLogs'
import Domains from 'Modules/Domains'
import AddDomains from 'Modules/Domains/AddDomains'

declare global {
  interface Window {
    pendo: any
  }
}

const ProfileSettings = lazy(() => import('../ProfileSettings'))
const TenatManagement = lazy(() => import('../Tenant'))
const AddEditTenant = lazy(() => import('../Tenant/AddEditTenant'))
const RequestsManagement = lazy(() => import('../Requests'))
const Settings = lazy(() => import('../Settings'))
const Cookies = lazy(() => import('../Cookies'))
const Banners = lazy(() => import('../Banners'))
const UserManagement = lazy(() => import('../User'))
const Subscribe = lazy(() => import('../Subscribe'))
const Branding = lazy(() => import('Modules/Tenant/AddEditTenant/Branding'))
const Dashboard = lazy(() => import('Modules/Dashboard/ConsentDashboardV1'))
const Subscription = lazy(() => import('../Subscription'))
const Plans = lazy(() => import('../Plans'))

// Theme config default font and color
const Main = styled.main`
  font-family: ${props => props.theme.font};
  color: ${props => props.theme.fontColor};
  flex: 1;
  display: flex;
  position: relative;
`

const Layout = () => {
  const { userData, isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)

  const [isSidebarCollapsed, seIsSidebarCollapsed] = useState<boolean>(null)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const getUserDataParsed = () => {
    const userDataSessionStorage = SessionStorage.get(SessionStorageKeyNames.UserData)
    const newUserDataParsed = userDataSessionStorage && JSON.parse(userDataSessionStorage)
    return newUserDataParsed
  }

  const userDataParsed: UserModel = getUserDataParsed()

  const userModel = useSelector((state: AppStateType) => state.AuthReducer.userData)
  const allowedPages = useMemo(() => getAllowedPages(userDataParsed?.roles_and_permissions), [userDataParsed])
  let firstAllowedPage: string

  if (userModel?.tenantSource === 'direct' || userModel?.tenantSource === 'visitor') {
    if (userModel?.source_type === 'scanner_utility' && userModel?.latest_domain_id) {
      firstAllowedPage = '/consents/cookies?domain_id='+ userModel?.latest_domain_id
    } else {
      firstAllowedPage = '/admin/domains'
    }
  } else {
    firstAllowedPage = allowedPages[0] || '/login'
  }

  useEffect(() => {
    const newUserData = getUserDataParsed()
    if (!newUserData) {
      return
    }
    if (userData) {
      return
    }
    dispatch(actionSetUserData(userDataParsed))
  }, [userDataParsed, dispatch])

  if (userDataParsed) {
    // in your authentication promise handler or callback
    window.pendo?.initialize({
      visitor: {
        id: userDataParsed?.mcUserId,
        visitorId: userDataParsed?.mcUserId,
        email: userDataParsed?.email,
        firstName: userDataParsed?.firstName,
        lastName: userDataParsed?.lastName,
        roleName: userDataParsed?.roleDisplayName,
        tenantName: userDataParsed?.tenantName,
      },
      account: {
        id: userDataParsed?.tenantId,
      },
    })
  }
  useEffect(() => {
    if (!isAuthenticated && !userDataParsed && location.pathname.trim() != '/sp/impersonate') {
      dispatch(dispatchLogout())
      if (location.pathname != '/') {
        history.push(`/login?redirecturl=${location?.pathname + location.search}`, { from: location?.pathname })
      } else {
        history.push('/login', { from: location?.pathname })
      }
    }
  }, [isAuthenticated, location])

  const showSidebarAndHeader = !location?.pathname.includes('/plans')
  return (
    <>
      <Main>
        {showSidebarAndHeader ? <Sidebar seIsSidebarCollapsed={seIsSidebarCollapsed} /> : null}
        <div className={styles.appMainOuter}>
          {showSidebarAndHeader ? <Header isSidebarCollapsed={isSidebarCollapsed} /> : null}

          <Scrollbars autoHeightMax="" autoHide={false} style={{ height: 'calc(100% - 50px)' }}>
            <Switch>
              <PrivateRoute
                isAccessible={allowedPages?.includes('/dashboard')}
                path="/dashboard"
                component={Dashboard}
              />

              <PrivateRoute
                isAccessible={allowedPages?.includes('/subscribe')}
                path="/subscribe"
                component={Subscribe}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/subscription')}
                path="/subscription"
                component={Subscription}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/profile-settings')}
                path="/profile-settings"
                component={ProfileSettings}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/tenant-management')}
                path="/admin/tenant-management"
                component={TenatManagement}
                exact
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/tenant-management')}
                path="/admin/tenant-management/addedit/:id?"
                component={AddEditTenant}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/requests')}
                path="/requests"
                component={RequestsManagement}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/privacy-center/requests')}
                path="/privacy-center/requests"
                component={RequestsManagement}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/privacy-center/branding')}
                path="/privacy-center/branding"
                component={Branding}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/settings')}
                path="/admin/settings"
                component={Settings}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/usermanagement')}
                path="/admin/usermanagement"
                component={UserManagement}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/sp/usermanagement')}
                path="/admin/sp/usermanagement"
                component={UserManagement}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/domains/addDomains')}
                path="/admin/domains/addDomains"
                component={AddDomains}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/admin/domains')}
                path="/admin/domains"
                component={Domains}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/consents/cookies')}
                path="/consents/cookies"
                component={Cookies}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/consents/banners')}
                path="/consents/banners"
                component={Banners}
              />
              <PrivateRoute
                isAccessible={allowedPages?.includes('/consents/logs')}
                path="/consents/logs"
                component={ConsentLogs}
              />
              <PrivateRoute isAccessible={allowedPages?.includes('/plans')} path="/plans" component={Plans} />

              <Route exact path="/" render={() => <Redirect to={firstAllowedPage} />} />
            </Switch>
          </Scrollbars>
        </div>
      </Main>
    </>
  )
}

export default Layout
