export const profileSettings = {
  toast: {
    wrong_current_password: 'Invalid current password',
    change_password_success: 'Password updated successfully',
  },
  title: 'Profile Settings',
  profile_picture: 'Profile Picture',
  profile_details: 'Profile Details',
  change_your_password: 'Change Your Password',
  first_name: 'First Name',
  first_name_required: 'Please enter first name',
  last_name: 'Last Name',
  last_name_required: 'Please enter last name',
  email: 'Business Email',
  email_required: 'Please enter email',
  phone: 'Phone',
  phone_required: 'Please enter phone',
  current_password: 'Current Password',
  current_password_required: 'Please enter current password',
  new_password_cant_same: 'New password and current password cannot be same',
  new_password: 'New Password',
  new_password_required: 'Please enter a valid new password',
  confirm_password: 'Confirm Password',
  confirm_password_required: 'Confirm new password',
  confirm_password_not_match: 'Password does not match',
  change_photo: 'Change Photo',
  delete: 'Delete',
}
