import React, { useEffect, useState } from 'react'
import { useTable, useSortBy, usePagination, Column, Row, HeaderGroup, useExpanded } from 'react-table'
import Pagination from 'react-js-pagination'
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather'
import Scrollbars from 'react-custom-scrollbars'
import { formatDefaultFullDate } from 'Utils/dateAndTimeUtils'
import './Table.css'
import { useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import EmailTooltip from './EmailTooltip'

interface PageProps {
  pageIndex: number
  pageSize: number
  sortBy: any
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const CommonColumnCell = ({ value }) => {
  return (
    <div className="columnContainer">
      <div className="coloumnTitle" title={value}>
        {value}
      </div>
    </div>
  )
}

export const commonLastUpdated = (value, text, rowData) => {
  const updatedDateTime = value ? formatDefaultFullDate(new Date(value)) : '-'
  return (
    <div className="columnContainer">
      <div className="coloumnTitle" title={updatedDateTime}>
        {updatedDateTime}
      </div>
      <div className="columnLabel d-flex align-items-center" title={text}>
        {rowData.last_updated_email ? <EmailTooltip lightFont email={rowData.last_updated_email} text={text} /> : null}
      </div>
    </div>
  )
}

interface TableComponentProps {
  activePage: number
  setPageParams: (pageProps: PageProps) => void
  totalRecords: number
  data: Array<any>
  columns: Array<Column>
  noRecordsMsg?: string | JSX.Element
  noRecordsSubMsg?: string
  onRowClick?: (row: Row) => void
  defaultSort?: Array<any>
  tableRowIdPrefix?: string
  renderRowSubComponent?: any
  setIsRowExpanded?: (value: boolean) => void
}

const TableComponent = (props: TableComponentProps) => {
  const {
    noRecordsSubMsg,
    totalRecords,
    data,
    setPageParams,
    columns,
    onRowClick,
    noRecordsMsg,
    defaultSort,
    activePage,
    tableRowIdPrefix,
    renderRowSubComponent,
    setIsRowExpanded,
  } = props
  const [pageCount, setPageCount] = useState<number>(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    rows,
    visibleColumns,
    toggleRowExpanded,
    state: { pageIndex, pageSize, sortBy, expanded },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      manualPagination: true,
      pageCount: pageCount,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: defaultSort ? defaultSort : [],
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  const { isLoading } = useSelector((state: AppStateType) => state.LoaderReducer)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [oldExpanded, setOldExpanded] = useState<Array<string>>([])

  useEffect(() => {
    if (setIsRowExpanded) {
      toggleRowExpanded(oldExpanded, false)
      setOldExpanded(Object.keys(expanded))
      setIsRowExpanded(Object.keys(expanded)?.length > 0)
    }
  }, [expanded])

  useEffect(() => {
    if (totalRecords) {
      const pages = Math.ceil(totalRecords / pageSize)
      setPageCount(pages)
    }
  }, [totalRecords])

  const handlePageClick = (e: number) => {
    setPageParams({ pageIndex: e - 1, pageSize, sortBy })
  }

  useEffect(() => {
    setPageParams({ pageIndex, pageSize, sortBy })
  }, [sortBy, pageIndex, pageSize, setPageParams])

  if (rows.length === 0 && !isLoading) {
    return (
      <div className="no-records-container">
        <div className="no-records-heading">{noRecordsMsg}</div>
        <div className="no-records-sub-heading">{noRecordsSubMsg}</div>
      </div>
    )
  }

  const getColumnSortingIcons = (column: HeaderGroup<object>) => {
    if (column?.disableSortBy || column.id === 'expander') {
      return null
    }
    if (column?.isSorted) {
      if (column?.isSortedDesc) {
        return <ArrowDown className="cursorPointer" color="#333E46" size={16} />
      }
      return <ArrowUp className="cursorPointer" color="#333E46" size={16} />
    }
    return <ArrowUp className="cursorPointer" color="rgba(0, 0, 0, 0.2)" size={16} />
  }

  const pageCountShow = (pageSizeNum: number, activePageNum: number, totalRecordsNum: number) => {
    let currentCount = pageSizeNum * activePageNum
    if (currentCount > totalRecordsNum) {
      return totalRecords
    }
    return pageSizeNum * activePageNum
  }

  return (
    <>
      <div className="row p-0 m-0 custom-table-row flex-column">
        <table className="table table-striped custom-table mb-0 pb-0 flex-0" {...getTableProps()} style={{ flex: '0' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const style = { ...column.getHeaderProps().style, width: column?.width || 'auto' }
                  return (
                    <th
                      {...column.getHeaderProps({
                        className: column?.isSorted ? 'col-header-sorted' : '',
                        ...column.getSortByToggleProps({
                          title: column?.render('Header'),
                        }),
                      })}
                      style={style}
                    >
                      <div className="d-flex">
                        <div className="headerText"> {column.render('Header')}</div>
                        <span className="pl-1">{getColumnSortingIcons(column)}</span>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        </table>
        <Scrollbars
          style={{ flex: '1' }}
          autoHide={false}
          autoHeightMax={'100%'}
          renderView={props => <div className="hideHorizontalScroll" {...props} />}
          autoHeight
        >
          <table className="table table-striped custom-table mt-0 pt-0">
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)
                let className = onRowClick ? 'onRowClick' : ''
                if (row.isExpanded) {
                  className += ' onRowExpanded'
                }
                return (
                  <React.Fragment key={'table-row-key-' + rowIndex}>
                    <tr
                      onClick={() => onRowClick && onRowClick(row)}
                      id={
                        tableRowIdPrefix ? tableRowIdPrefix + rowIndex?.toString() : 'table-row-' + rowIndex?.toString()
                      }
                      {...row.getRowProps({ className })}
                    >
                      {row.cells.map(cell => {
                        const style = { ...cell?.column.getHeaderProps().style, width: cell?.column?.width || 'auto' }
                        return (
                          <td {...cell.getCellProps()} style={style}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td style={{ padding: '0' }} colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </Scrollbars>
      </div>
      {totalRecords > 10 ? (
        <div className="row m-0 mt-2 paginationContainer">
          <div className="col-md-6 p-0 m-0 paginationLabel">
            Showing {(activePage - 1) * pageSize + 1} to {pageCountShow(pageSize, activePage, totalRecords)} of{' '}
            {totalRecords} results{' '}
          </div>
          <div className="col-md-6 p-0 m-0 d-flex justify-content-end">
            <div className="mr-2">
              <Pagination
                prevPageText={<ChevronLeft color="#000000" size={18} />}
                firstPageText={<ChevronsLeft color="#000000" size={18} />}
                lastPageText={<ChevronsRight color="#000000" size={18} />}
                nextPageText={<ChevronRight color="#000000" size={18} />}
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={5}
                onChange={handlePageClick}
              />
            </div>
            <div>
              <select
                className="form-control form-custom-control"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 50].map(pageSizeOption => (
                  <option key={pageSizeOption} value={pageSizeOption}>
                    {pageSizeOption}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TableComponent
