import { Dispatch } from 'react'
import { Action } from 'redux'
import service, { VerifyEmailRequest } from '../Services/verifyemail.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_VERIFY_EMAIL = 'ACTION_VERIFY_EMAIL'
export const ACTION_VERIFY_EMAIL_CANCEL = 'ACTION_VERIFY_EMAIL_CANCEL'
export const ACTION_VERIFY_EMAIL_SUCCESS = 'ACTION_VERIFY_EMAIL_SUCCESS'
export const ACTION_VERIFY_EMAIL_FAILED = 'ACTION_VERIFY_EMAIL_FAILED'

export interface ActionVerifyEmail extends Action {
  type: typeof ACTION_VERIFY_EMAIL
}

export interface ActionVerifyEmailCancel extends Action {
  type: typeof ACTION_VERIFY_EMAIL_CANCEL
}

export interface ActionVerifyEmailSuccess extends Action {
  type: typeof ACTION_VERIFY_EMAIL_SUCCESS
  payload: {
    email: string,
    register_user: boolean
  }
}
export interface ActionVerifyEmailFailed extends Action {
  type: typeof ACTION_VERIFY_EMAIL_FAILED
  payload: {
    email: string,
    register_user: boolean
  }
}

export type VerifyEmailActions =
  | ActionVerifyEmail
  | ActionVerifyEmailFailed
  | ActionVerifyEmailCancel
  | ActionVerifyEmailSuccess

const dispatchEmailVerify = (): ActionVerifyEmail => ({
  type: ACTION_VERIFY_EMAIL,
})

const dispatchEmailVerifySuccess = (email: string, register_user: boolean): ActionVerifyEmailSuccess => ({
  type: ACTION_VERIFY_EMAIL_SUCCESS,
  payload: {
    email,
    register_user
  },
})

const dispatchEmailVeifyFailed = (email: string, register_user: boolean): ActionVerifyEmailFailed => ({
  type: ACTION_VERIFY_EMAIL_FAILED,
  payload: { email, register_user },
})

export const dispatchEmailVerifyCancel = (): ActionVerifyEmailCancel => ({
  type: ACTION_VERIFY_EMAIL_CANCEL,
})

export function actionEmailCheck(req: VerifyEmailRequest): (dispatch: Dispatch<VerifyEmailActions>) => Promise<void> {
  return async (dispatch: Dispatch<VerifyEmailActions>) => {
    dispatch(dispatchEmailVerify())
    loaderStart(dispatch)
    try {
      const result = await service.verifyemail(req)
      if (result && result.success) {
        dispatch(dispatchEmailVerifySuccess(req.email, false))
      } else {
        dispatch(dispatchEmailVeifyFailed(req.email, true))
      }
      loaderStop(dispatch)
    } catch (error) {
      //dispatch(dispatchEmailVeifyFailed(error))
      loaderStop(dispatch)
    }
  }
}
