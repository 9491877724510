import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import service from '../Service/get-user-permissions.service'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import toaster from 'Modules/Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { UserRole } from '../Models'

export const ACTION_GET_USER_PERMISSIONS = 'ACTION_GET_USER_PERMISSIONS'
export const ACTION_GET_USER_PERMISSIONS_SUCCESS = 'ACTION_GET_USER_PERMISSIONS_SUCCESS'
export const ACTION_GET_USER_PERMISSIONS_FAILURE = 'ACTION_GET_USER_PERMISSIONS_FAILURE'

export interface ActionGetUserPermissions extends Action {
  type: typeof ACTION_GET_USER_PERMISSIONS
  payload: {}
}

export interface ActionGetUserPermissionsSuccess extends Action {
  type: typeof ACTION_GET_USER_PERMISSIONS_SUCCESS
  payload: { data: Array<UserRole> }
}

export interface ActionGetUserPermissionsFailure extends Action {
  type: typeof ACTION_GET_USER_PERMISSIONS_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export type GetUserPermissionsActions =
  | ActionGetUserPermissions
  | ActionGetUserPermissionsSuccess
  | ActionGetUserPermissionsFailure

const dispatchGetUserPermissionsSuccess = (data: Array<UserRole>): ActionGetUserPermissionsSuccess => ({
  type: ACTION_GET_USER_PERMISSIONS_SUCCESS,
  payload: { data },
})

const dispatchGetUserPermissionsFailure = (err: Error, success: false): ActionGetUserPermissionsFailure => ({
  type: ACTION_GET_USER_PERMISSIONS_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

const dispatchGetUserPermissions = (): ActionGetUserPermissions => ({
  type: ACTION_GET_USER_PERMISSIONS,
  payload: {},
})

export function actionGetUserPermissions(): (dispatch: Dispatch<GetUserPermissionsActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetUserPermissionsActions>) => {
    dispatch(dispatchGetUserPermissions())
    try {
      loaderStart(dispatch)
      const result = await service.getUserPermissions()
      if (result.success) {
        dispatch(dispatchGetUserPermissionsSuccess(result.data))
      } else {
        toaster.error(I18n.t('addUserPermissions.errorWhileFetchingPermissions'))
        dispatch(dispatchGetUserPermissionsFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetUserPermissionsFailure(err, false))
      loaderStop(dispatch)
    }
  }
}
