import { DomainType } from 'Modules/Tenant/Models/tenantList.model'
import {
  ACTION_ADD_DOMAIN_SUCCESS,
  ACTION_ADD_DOMAIN_VALIDATED,
  ACTION_ADD_DOMAIN_WEBSITE_SUCCESS,
  ACTION_COOKIE_SCANNING_FAILURE,
  ACTION_COOKIE_SCANNING_SUCCESS,
  ACTION_EDIT_DOMAIN_SUCCESS,
  ACTION_GET_DOMAINS_LIST_SUCCESS,
  ACTION_GET_DOMAIN_BY_ID,
  ACTION_GET_JS_SCRIPT_RESET,
  ACTION_GET_JS_SCRIPT_SUCCESS,
  ACTION_REFETCH_DOMAINS_LIST,
  ACTION_REMOVE_DOMAIN_SUCCESS,
  ACTION_REMOVE_SUBDOMAIN_SUCCESS,
  ACTION_SET_VERIFIED_DOMAINS,
  ACTION_VERIFYING_DOMAIN,
  ACTION_VERIFY_JS_SCRIPT_LOADING,
  ACTION_VERIFY_JS_SCRIPT_SUCCESS,
  DomainsActions,
} from '../Actions/domainList.actions'

export interface DomainsReducerState {
  domainsList: DomainType[]
  domainToBeEdited: DomainType | null
  message: {
    success: boolean
    code: number
  }
  pageParams: {
    totalRecords: number
    activePage: number
  }
  scanStatus: {
    message: string
    success: null | boolean
  }
  scriptUrl: string
  scriptDeployStatus: string
  refreshDomainsList: { refetch: boolean }
  websiteDomain: { refetch_website_domain: boolean }
  domainsValidated: Array<string>
  verifyingDomain: boolean
}

export const initialState: DomainsReducerState = {
  domainsList: [],
  message: {
    success: null,
    code: null,
  },
  pageParams: {
    totalRecords: 0,
    activePage: 1,
  },
  domainToBeEdited: null,
  scanStatus: {
    message: '',
    success: null,
  },
  scriptUrl: '',
  scriptDeployStatus: '',
  refreshDomainsList: { refetch: false },
  websiteDomain: { refetch_website_domain: false },
  domainsValidated: [],
  verifyingDomain: false,
}

type Actions = DomainsActions

export const DomainsReducer = (state = initialState, action: Actions): DomainsReducerState => {
  switch (action.type) {
    case ACTION_GET_DOMAINS_LIST_SUCCESS:
      return {
        ...state,
        domainsList: action.payload.data,
        pageParams: {
          totalRecords: action.payload.totalRecords,
          activePage: action.payload.activePage,
        },
        refreshDomainsList: {
          refetch: false,
        },
      }

    case ACTION_EDIT_DOMAIN_SUCCESS:
      return {
        ...state,
        message: {
          code: action.payload.code,
          success: true,
        },
        refreshDomainsList: {
          refetch: true,
        },
      }

    case ACTION_REMOVE_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        message: {
          code: action.payload.code,
          success: true,
        },
        refreshDomainsList: {
          refetch: true,
        },
      }

    case ACTION_REMOVE_DOMAIN_SUCCESS:
      return {
        ...state,
        message: {
          code: action.payload.code,
          success: true,
        },
        refreshDomainsList: {
          refetch: true,
        },
      }

    case ACTION_GET_DOMAIN_BY_ID:
      return {
        ...state,
        domainToBeEdited: action.payload.domain,
      }
    case ACTION_ADD_DOMAIN_SUCCESS:
      return {
        ...state,
        message: {
          success: true,
          code: 2000,
        },
        refreshDomainsList: {
          refetch: true,
        },
      }

    case ACTION_ADD_DOMAIN_WEBSITE_SUCCESS:
      return {
        ...state,
        message: {
          success: true,
          code: 2000,
        },
        websiteDomain: {
          refetch_website_domain: true,

        },
      }
      
    case ACTION_COOKIE_SCANNING_SUCCESS:
      return {
        ...state,
        scanStatus: {
          success: true,
          message: action.payload.message,
        },
        refreshDomainsList: {
          refetch: true,
        },
      }

    case ACTION_COOKIE_SCANNING_FAILURE:
      return {
        ...state,
        scanStatus: {
          success: false,
          message: action.payload.message,
        },
      }

    case ACTION_GET_JS_SCRIPT_SUCCESS:
      return {
        ...state,
        scriptUrl: action.payload.scriptUrl,
      }

    case ACTION_VERIFY_JS_SCRIPT_SUCCESS:
      return {
        ...state,
        scriptDeployStatus: action.payload.status,
      }

    case ACTION_VERIFY_JS_SCRIPT_LOADING:
      return {
        ...state,
        scriptDeployStatus: action.payload.status,
      }

    case ACTION_GET_JS_SCRIPT_RESET:
      return {
        ...state,
        scriptUrl: '',
      }

    case ACTION_REFETCH_DOMAINS_LIST:
      return {
        ...state,
        refreshDomainsList: { refetch: true },
      }

    case ACTION_ADD_DOMAIN_VALIDATED:
      return {
        ...state,
        domainsValidated: [...state.domainsValidated, action.payload.domain],
      }

    case ACTION_VERIFYING_DOMAIN:
      return {
        ...state,
        verifyingDomain: action.payload.verifying,
      }

    case ACTION_SET_VERIFIED_DOMAINS:
      return {
        ...state,
        domainsValidated: [...action.payload.domainsVerified],
      }
    default:
      return state
  }
}
