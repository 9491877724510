import StatusBox from 'Modules/Common/Components/StatusBox'
import { I18n } from 'react-redux-i18n'
import CookiesDetails from '../CookiesDetails'
import { ConsentStatus, CookieDetails } from '../Models/consentLogs.model'

export const renderConsentStatus = (
  consentStatus: string,
  acceptedCookieDetails: CookieDetails,
  rejectedCookieDetails: CookieDetails
) => {
  if (consentStatus === ConsentStatus.Accepted) {
    return <StatusBox text={I18n.t('consentLogs.accepted')} theme={'success'} />
  } else if (consentStatus === ConsentStatus.PartiallyAccepted) {
    return (
      <div className="d-flex">
        <StatusBox className="mr-2" text={I18n.t('consentLogs.partially_accepted')} theme={'in_progress'} />
        <CookiesDetails acceptedCookieDetails={acceptedCookieDetails} rejectedCookieDetails={rejectedCookieDetails} />
      </div>
    )
  } else if (consentStatus === ConsentStatus.Rejected) {
    return <StatusBox text={I18n.t('consentLogs.rejected')} theme={'error'} />
  }
  return <></>
}
