import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight, Lock, Mail } from 'react-feather'
import ButtonWithIcon from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { AppStateType } from '../../../Store/reducers'
import { emailRegex } from 'Modules/Common/Constants'
import { actionLogin } from '../Actions/login.actions'

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const location = useLocation()
  let search = useLocation().search

  const dispatch = useDispatch()

  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)

  if (isAuthenticated) {
    const query = new URLSearchParams(search).get('redirecturl')
    if (query == null) {
      return (
        <Redirect
          to={{
            pathname: '/login/verifyCode',
          }}
        />
      )
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login/verifyCode',
            search: `?redirecturl=${query}`,
            state: { referrer: location?.pathname },
          }}
        />
      )
    }
  }

  const onSubmit = (data: any) => {
    dispatch(actionLogin(data))
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="row p-0 m-0 m-5">
        <div className="col-md-12">
          <p className={styles.createAccountText}>{I18n.t('login.sign_in')}</p>
          <p className={styles.signinCred}>{I18n.t('login.sign_in_cred')}</p>
        </div>
        <div className="row p-0 m-0 w-100">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('login.email')}</Form.Label>
              <Form.Control
                className={cx(styles.formInput, styles.loginFormInput)}
                x-automation-id="email"
                id="email"
                type="text"
                {...register('email', {
                  required: I18n.t('register.email_required'),
                  pattern: {
                    value: emailRegex,
                    message: I18n.t('register.email_required'),
                  },
                })}
                placeholder={I18n.t('login.email')}
                isInvalid={errors?.email}
              />
              <span className={styles.loginFormInputIcon}>
                <Mail color="#000000" size={20} />
              </span>
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row p-0 m-0 w-100 mt-2">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.password')}</Form.Label>
              <Form.Control
                className={cx(styles.formInput, styles.loginFormInput)}
                x-automation-id="password"
                id="password"
                type="password"
                {...register('password', {
                  required: I18n.t('register.password_required'),
                })}
                placeholder={I18n.t('register.password')}
                isInvalid={errors?.password}
              />
              <span className={styles.loginFormInputIcon}>
                <Lock color="#000000" size={20} />
              </span>
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="col-md-12 mb-2 d-flex justify-content-end">
          <Link
            to="/forgotpassword"
            x-automation-id="forgot-password-link"
            id="forgot-password-link"
            className={cx(styles.linkText, styles.forgotPasswordText)}
          >
            {I18n.t('login.forgot_password')}
          </Link>
        </div>
        <div className="col-md-12 mt-3">
          <ButtonWithIcon
            type="submit"
            text="Next"
            style={{ width: '100%' }}
            icon={<ArrowRight color="#FFFFFF" />}
            iconToRight={true}
            x-automation-id="next-btn"
            id="next-btn"
            className={styles.customButton}
          />
        </div>
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <p className={styles.alreadyRegisteredText}>{I18n.t('login.troubles')}</p>
        </div>
        <div className="col-md-12 d-flex justify-content-center">
          <a
            href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            x-automation-id="contact-admin"
            id="contact-admin"
            className={styles.linkText}
          >
            {I18n.t('login.contact_admin')}
          </a>
        </div>
      </div>
    </Form>
  )
}

export default LoginForm
