import { profileSettings } from 'Modules/Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { http } from 'Utils/AxiosWrapper'

export interface ChangePasswordRequestModal {
  current_password: string
  new_password: string
}

interface ChangePassword {
  changePassword(req: ChangePasswordRequestModal): Promise<ApiResponse<string>>
}

class ChangePasswordDefault implements ChangePassword {
  async changePassword(req: ChangePasswordRequestModal): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data }: any = await http.put(profileSettings.changePassword, req)
      result = {
        success: true,
        message: data.msg,
        statusCode: data.code,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }
}

export default new ChangePasswordDefault()
