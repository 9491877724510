export interface TenantModel {
  tenant_company_name: string
  tenant_company_url: string
  country: string
  state: string
  first_name: string
  last_name: string
  email: string
  phone: string
  privacy_contact_email: string
  department?: string
  phone_code?: string
  domains: Domain[]
}

export interface AddTenantResponse {
  statusCode: number
  message: string
  success: boolean
  id: string
}

export interface LicenseTier {
  license_name: string
  datasource_limit: number
  storage_limit: number
  subscription_id: number
}

export interface UpdateTenantSubscription {
  free_dsars: number
}
export interface TenantSubscription {
  configured_datasources: number
  datasource_limit: number
  disabled_licence: string
  license_name: string
  storage_used_bytes: number
  subscription_id: number
  tenant_id: number
  tenant_name: string
  tenant_status: string
  datastore_add_ons: number
  free_dsars: number
  default_free_dsars_limit: number
}

export interface AvailableLicenses {
  tenant_id: number
  tenant_name: string
  tenant_status: string
  available_licenses: number
  total_licenses: number
}

export interface BuyLicense {
  statusCode?: number
  message: string
  success: boolean
}
export interface FreeTrialResponse {
  statusCode?: number
  message: string
  success: boolean
  days_remaining: number
  license_plan: string
  domain_limit: number
  domains_used: number
  free_plan_edit_domain_limit: string
  website_scan_visitor : boolean,
  old_domain_id: number,
  new_domain_id: number,
  is_app_sumo_user: boolean
}

export interface TenantExtendFreeTrial {
  statusCode?: number
  message: string
  success: boolean
}

export enum BulkUploadResponseCodes {
  NoEntityDataFound = 4004,
  IncorrectExtension = 4013,
  MaxRecordsExceeded = 4014,
  IncorrectFileStructure = 4015,
  EntityAlreadyExits = 4009,
  InvalidFileData = 4022,
}

export interface BulkUploadModel {
  file?: File
}

export interface BulkUploadErrorResponse {
  missing_fields: number[]
  invalid_format: number[]
  invalid_field_names: string[]
  duplicate_rows: number[]
}

export interface BrandingModel {
  tenant_name?: string
  logo?: File
  privacy_policy?: string
  terms_of_use?: string
  contact_email?: string
  menu_style_color?: string
  banner_text_color?: string
  profile_icon_color?: string
  logo_url?: string
  support_email?: string
  do_not_sell_request?: boolean
}

export interface GeneralSetting extends BrandingModel {
  privacy_center_url: string
}

export interface SendEmailPayload {
  send_to: string[]
  subject: string
  message: string
}

export interface PCHTMLCodeModel {
  tenant_pc_html_code: string
  pc_url?: string
}

export interface ImpersonateResponse {
  code: number
  jwt_token: {
    exp_time_in_minutes: number
    token: string
  }
  message: string
}
export interface PCHTMLCodeModel {
  tenant_pc_html_code: string
  pc_url?: string
  is_script_installed?: boolean
  pc_url_status?: string
}

export interface NestedDomain {
  sub_domain_name: string
  is_consent_management_subcriptions_enable?: boolean
}
export interface Domain {
  domain_name: string
  is_consent_management_subcriptions_enable?: boolean
  is_privacy_center_subcriptions_enable?: boolean
  sub_domains: NestedDomain[]
}

export interface DefaultValues {
  domains: Domain[]
}
