import { Action } from 'redux'
import { BreadCrumbMenuItem } from '../../Models/index'

export const ACTION_UPDATE_BREADCRUMB = 'ACTION_UPDATE_BREADCRUMB'
export const ACTION_CLEAR_BREADCRUMB = 'ACTION_CLEAR_BREADCRUMB'

export interface ActionUpdateBreadCrumb extends Action {
  type: typeof ACTION_UPDATE_BREADCRUMB
  payload: { data: Array<BreadCrumbMenuItem> }
}

export interface ActionClearBreadCrumb extends Action {
  type: typeof ACTION_CLEAR_BREADCRUMB
}

export type BreadCrumbActions = ActionClearBreadCrumb | ActionUpdateBreadCrumb

export const dispatchUpdateBreadCrumb = (data: Array<BreadCrumbMenuItem>): ActionUpdateBreadCrumb => ({
  type: ACTION_UPDATE_BREADCRUMB,
  payload: { data },
})

export const dispatchClearBreadCrumb = (): ActionClearBreadCrumb => ({
  type: ACTION_CLEAR_BREADCRUMB,
})
