export const addUser = {
  inviteUsers: 'Invite User(s)',
  addUser: 'Add User',
  selectRole: 'Select Role',
  administrator: 'Administrator',
  sendInvite: 'Send Invite',
  users: 'Users',
  permissions: 'Permissions',
  selectUser: 'Select User',
  userRoleNote:
    'Selected role applies to all users added below. Max 3 users can be added simultaneously. For adding more, click on "Invite User" again.',
  emailAlready: 'User with this email already exists',
  inviteSuccess: 'Email invites sent to selected users',
  confirmSendingInvite: 'Confirm sending email invite to these users',
  usersConflict: 'Users "%{names}" have already been invited',
  userConflict: 'User "%{name}" has already been invited',
  overview: 'Overview',
  inValidDomain: 'Please enter email that matches with tenant domain',
  inValidSPDomain: 'Please enter email that matches with service provider domain',
  userLimitMessage: 'Only 3 users are  allowed to add',
  usersCreatedSuccess: 'User(s) invited successfully',
  somethingWentWrongMessage: 'Something went wrong',
  erroWhileCreatingUsers: 'Error while inviting users(s)',
  userName: 'User Name',
  email: 'Email',
  invitedOn: 'Invited On',
  status: 'Status',
  role: 'Role',
  search: 'Search',
  tenantAdmin: 'Tenant Admin',
  noData: 'No Users Exists',
  deleteUser: 'Delete User',
  deleteUserConfirmationMsg: 'Are you sure you want to delete this user account?',
  cannotDeleteUserDSAR: 'Cannot delete user as DSAR assigned to user is in progress',
  userDeleted: 'User account deleted',
  serviceProvider: 'Service Provider',
  entityExistsInAnotherTenant: 'This email already exists in the system for another company',
}

export const addUserPermissions = {
  errorWhileFetchingPermissions: 'Error while fetching permissions',
  noDataFound: 'No permissions found',
  requests: 'View / Close Requests',
  add_tenant_users: 'Add Tenant Users',
  delete_tenant_users: 'Delete Tenant Users',
  add_service_provider_users: 'Add Service Provider Users',
  delete_service_provider_users: 'Delete Service Provider Users',
  modify_setting: 'Modify Setting',
  add_tenant: 'Add Tenant',
  edit_tenant: 'Edit Tenant',
  delete_tenant: 'Delete Tenant',
}
