import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import { AlertCircle, AlertTriangle, X } from 'react-feather'
import styles from './Domains.module.css'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { actionRemoveDomain, actionRemoveSubDomain } from './Actions/domainList.actions'
import cx from 'classnames'
import Alert from 'react-bootstrap/esm/Alert'

const WebsiteEditModelSubscribe = ({
  showwebsiteSubsribeModel,
  setShowWebsiteSubscribeModel,
  title,
  message,
  message_2,
  onSubscriptionclick,
}) => {
  const dispatch = useDispatch()

  return (
    <div>
      <VerticallyCenteredModal
        dialogClassName={'websiteDeleteModelContainer'}
        show={showwebsiteSubsribeModel.showBanner}
        onHide={() =>
          setShowWebsiteSubscribeModel({
            showBanner: false,
          })
        }
        heading={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className={styles.cancelSubscriptionHeader}>
              <AlertTriangle style={{ verticalAlign: 'top' }} />
              &nbsp;&nbsp;{title}
            </span>
            <button
              type="button"
              role="closeIcon"
              id="TableFiltersSidebar-close-button"
              className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
              onClick={() => {
                setShowWebsiteSubscribeModel({
                  showBanner: false,
                })
              }}
            >
              <X />
            </button>
          </div>
        }
        bodyChild={
          <>
            <div className={cx('col-md-12 pl-0 w-100', 'deleteDomainMessage')}>
              {message} <br />
              {message_2}
            </div>
          </>
        }
        footerChild={
          <div className="row w-100">
            <div className="d-flex w-100 align-items-center justify-content-end">
              <CancelButtonWithIcon
                onClick={() => {
                  setShowWebsiteSubscribeModel({
                    showBanner: false,
                  })
                }}
                text={I18n.t('common.cancel')}
                x-automation-id="cancel"
                id="cancelBtn"
              />
              <ButtonWithIcon
                className="ml-2"
                text={I18n.t('domains.yesSubscribe')}
                x-automation-id="donebutton"
                id="doneBtn"
                onClick={onSubscriptionclick}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default WebsiteEditModelSubscribe
