import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { ConsentTemplateModel } from '../Models/banner.models'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/consentTemplate.service'

export const ACTION_GET_CONSENTTEMPLATE_SUCCESS = 'ACTION_GET_CONSENTTEMPLATE_SUCCESS'
export const ACTION_GET_CONSENTTEMPLATE_FAILURE = 'ACTION_GET_CONSENTTEMPLATE_FAILURE'
export const ACTION_GET_CONSENTTEMPLATE_CLEAR = 'ACTION_GET_CONSENTTEMPLATE_CLEAR'

export const ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS = 'ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS'
export const ACTION_WEBSITE_SCREEN_SHOT_SUCCESS = 'ACTION_WEBSITE_SCREEN_SHOT_SUCCESS'
export const ACTION_WEBSITE_SCREEN_SHOT_FAILURE = 'ACTION_WEBSITE_SCREEN_SHOT_FAILURE'
export const ACTION_WEBSITE_SCREEN_SHOT_LOADING = 'ACTION_WEBSITE_SCREEN_SHOT_LOADING'

export interface ActionGetConsentTemplateSuccess extends Action {
  type: typeof ACTION_GET_CONSENTTEMPLATE_SUCCESS
  payload: ConsentTemplateModel
}

export interface ActionGetConsentTemplateFailure extends Action {
  type: typeof ACTION_GET_CONSENTTEMPLATE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionGetConsentTemplateClear extends Action {
  type: typeof ACTION_GET_CONSENTTEMPLATE_CLEAR
  payload: {}
}

export interface ActionPublishBannerTemplate extends Action {
  type: typeof ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS
  payload: {
    success: boolean
    message: string
  }
}

export interface ActionWebsiteScreenShot extends Action {
  type: typeof ACTION_WEBSITE_SCREEN_SHOT_SUCCESS
  payload: {
    screenShot: string
  }
}

export interface ActionWebsiteScreenShotFailure extends Action {
  type: typeof ACTION_WEBSITE_SCREEN_SHOT_FAILURE
}

export interface ActionWebsiteScreenShotLoading extends Action {
  type: typeof ACTION_WEBSITE_SCREEN_SHOT_LOADING
  payload: {
    loading: boolean
    url: string
  }
}

export type ConsentTemplateActions =
  | ActionGetConsentTemplateSuccess
  | ActionGetConsentTemplateFailure
  | ActionGetConsentTemplateClear
  | ActionPublishBannerTemplate
  | ActionWebsiteScreenShot
  | ActionWebsiteScreenShotFailure
  | ActionWebsiteScreenShotLoading

const dispatchEditUsersSuccess = (data: ConsentTemplateModel): ActionGetConsentTemplateSuccess => ({
  type: ACTION_GET_CONSENTTEMPLATE_SUCCESS,
  payload: data,
})

const dispatchEditUsersFailure = (err: Error, success: boolean): ActionGetConsentTemplateFailure => ({
  type: ACTION_GET_CONSENTTEMPLATE_FAILURE,
  payload: { errorMessage: err.message, success },
})

export const dispatchEditConsentTemplateClear = (): ActionGetConsentTemplateClear => ({
  type: ACTION_GET_CONSENTTEMPLATE_CLEAR,
  payload: {},
})

export const dispatchPublicBannerTemplateSuceess = (
  success: boolean,
  message: string
): ActionPublishBannerTemplate => ({
  type: ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS,
  payload: { success, message },
})

export const dispatchWebsiteScreenShotSuceess = (screenShot: string): ActionWebsiteScreenShot => ({
  type: ACTION_WEBSITE_SCREEN_SHOT_SUCCESS,
  payload: { screenShot },
})
export const dispatchWebsiteScreenShotFailure = (): ActionWebsiteScreenShotFailure => ({
  type: ACTION_WEBSITE_SCREEN_SHOT_FAILURE,
})

export const dispatchWebsiteScreenLoading = (loading: boolean, url: string): ActionWebsiteScreenShotLoading => ({
  type: ACTION_WEBSITE_SCREEN_SHOT_LOADING,
  payload: {
    loading,
    url,
  },
})
export function actionGetConsentTemplate(
  domain_id: string,
  sub_domain_id: string
): (dispatch: Dispatch<ConsentTemplateActions>) => Promise<void> {
  return async (dispatch: Dispatch<ConsentTemplateActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.getConsentTemplate(domain_id, sub_domain_id)
      if (result.success) {
        dispatch(dispatchEditUsersSuccess(result.data))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(dispatchEditUsersFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchEditUsersFailure(err, false))
    }
  }
}

export function actionPublishBannerTemplate(
  tenant_id: string,
  payload: any,
  domain_id?: string,
  sub_domain_id?: string
): (dispatch: Dispatch<ConsentTemplateActions>) => Promise<void> {
  return async (dispatch: Dispatch<ConsentTemplateActions>) => {
    try {
      loaderStart(dispatch)

      const result = await service.publishConsentTemplate(tenant_id, payload, domain_id, sub_domain_id)
      if (result.success) {
        dispatch(dispatchPublicBannerTemplateSuceess(result?.success, result?.message))
        toaster.success(I18n.t('banners.consentUpdatedSuccessfully'))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionGetWebsiteScreenShot(
  domain: string
): (dispatch: Dispatch<ConsentTemplateActions>) => Promise<void> {
  return async (dispatch: Dispatch<ConsentTemplateActions>) => {
    try {
      loaderStart(dispatch)
      dispatch(dispatchWebsiteScreenLoading(true, domain))
      dispatch(dispatchWebsiteScreenShotSuceess(''))
      const result = await service.getWebsiteScreenShot(domain)
      if (result.success) {
        dispatch(dispatchWebsiteScreenShotSuceess(result?.data))
      } else {
        dispatch(dispatchWebsiteScreenShotFailure())
      }
      dispatch(dispatchWebsiteScreenLoading(false, domain))
      loaderStop(dispatch)
    } catch (err: any) {
      dispatch(dispatchWebsiteScreenLoading(false, domain))
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}
