import { http } from '../../../../Utils/AxiosWrapper/index'
import { ApiResponse, DropdownModel } from '../../Models'
import { common } from '../../../Common/Constants/ApiUrls'

interface DepartmentService {
  getDepartmentDropdown(): Promise<ApiResponse<DropdownModel[]>>
}

class DepartmentServiceDefault implements DepartmentService {
  async getDepartmentDropdown(): Promise<ApiResponse<DropdownModel[]>> {
    let response: ApiResponse<Array<DropdownModel>> = {
      success: false,
      message: '',
      data: [],
    }
    try {
      const apiData = await http.get(common.department)
      if (apiData && apiData?.data?.data && apiData?.data.data?.length > 0) {
        response.success = true
        response.message = 'Success'
        response.data = apiData.data.data
      } else {
        response = {
          success: false,
          message: '',
          data: [],
        }
      }
    } catch (error) {
      response = {
        success: false,
        message: '',
        data: [],
      }
    }
    return response
  }
}

export default new DepartmentServiceDefault()
