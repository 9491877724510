import { PermissionsMapping } from 'Modules/Common/Constants'
import { UserRole } from 'Modules/User/Models'

export const getUserPermissions = (roles_and_permissions: Array<UserRole>): Array<string> => {
  let permissions = []
  if (roles_and_permissions && roles_and_permissions.length > 0) {
    roles_and_permissions.forEach(roleAndPermission => {
      if (roleAndPermission && roleAndPermission.permissions && roleAndPermission.permissions.length > 0) {
        permissions = permissions.concat(roleAndPermission.permissions)
      }
    })
  }
  return permissions
}

export const getUserRoles = (roles_and_permissions: Array<UserRole>): Array<string> => {
  let roles = []
  if (roles_and_permissions && roles_and_permissions.length > 0) {
    roles_and_permissions.forEach(roleAndPermission => {
      roles = roles.concat(roleAndPermission.role_name)
    })
  }
  return roles
}

export const getAllowedPages = (roles_and_permissions: Array<UserRole>): Array<string> => {
  let permissions = getUserPermissions(roles_and_permissions)
  if (!permissions || permissions.length === 0) {
    return []
  }

  const allowedPages = []

  if (permissions.includes(PermissionsMapping.view_consent_dashboard)) {
    allowedPages.push('/dashboard')
  }
  if (permissions.includes(PermissionsMapping.view_dsar)) {
    allowedPages.push('/requests')
  }

  if (permissions.includes(PermissionsMapping.add_tenant) || permissions.includes(PermissionsMapping.edit_tenant)) {
    allowedPages.push('/admin/tenant-management')
  }

  if (permissions.includes(PermissionsMapping.add_service_provider_users)) {
    allowedPages.push('/admin/sp/usermanagement')
  }

  if (permissions.includes(PermissionsMapping.add_tenant_users)) {
    allowedPages.push('/admin/usermanagement')
  }

  if (permissions.includes(PermissionsMapping.modify_setting)) {
    allowedPages.push('/admin/settings')
  }
  if (permissions.includes(PermissionsMapping.view_domains)) {
    allowedPages.push('/admin/domains')
    allowedPages.push('/admin/domains/addDomains')
  }

  if(permissions.includes(PermissionsMapping.edit_website) || permissions.includes(PermissionsMapping.view_website)) {
    allowedPages.push('/admin/domains/addDomains')
  }

  if (permissions.includes(PermissionsMapping.policies)) {
    allowedPages.push('/admin/policies')
  }

  if (permissions.includes(PermissionsMapping.edit_cookies) || permissions.includes(PermissionsMapping.view_cookies)) {
    allowedPages.push('/consents/cookies')
  }

  if (permissions.includes(PermissionsMapping.view_banner) || permissions.includes(PermissionsMapping.edit_banner)) {
    allowedPages.push('/consents/banners')
  }

  if (permissions.includes(PermissionsMapping.view_consent_log)) {
    allowedPages.push('/consents/logs')
  }

  if (permissions.includes(PermissionsMapping.view_consent_dashboard)) {
    allowedPages.push('/dashboard')
  }

  if (
    permissions.includes(PermissionsMapping.view_privacy_center) ||
    permissions.includes(PermissionsMapping.edit_privacy_center)
  ) {
    allowedPages.push('/privacy-center')
    allowedPages.push('/privacy-center/branding')
    allowedPages.push('/privacy-center/requests')
  }

  allowedPages.push('/profile-settings')
  allowedPages.push('/subscribe')
  allowedPages.push('/subscription')
  allowedPages.push('/plans')

  return allowedPages
}
