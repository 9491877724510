import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Service/user-list.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { PageParams } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_USERS = 'ACTION_GET_USERS'
export const ACTION_GET_USERS_SUCCESS = 'ACTION_GET_USERS_SUCCESS'
export const ACTION_GET_USERS_FAILURE = 'ACTION_GET_USERS_FAILURE'

export interface ActionGetUsers extends Action {
  type: typeof ACTION_GET_USERS
  payload: PageParams
}

export interface ActionGetUsersSuccess extends Action {
  type: typeof ACTION_GET_USERS_SUCCESS
  payload: {
    data: Array<any>
    totalRecords: number
    activePage: number
  }
}

export interface ActionGetUsersFailure extends Action {
  type: typeof ACTION_GET_USERS_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetUsersActions = ActionGetUsers | ActionGetUsersFailure | ActionGetUsersSuccess

export const dispatchGetUsers = (pageParams: PageParams): ActionGetUsers => ({
  type: ACTION_GET_USERS,
  payload: pageParams,
})

const dispatchGetUsersSuccess = (
  data: Array<any>,
  totalRecords: number,
  activePage: number
): ActionGetUsersSuccess => ({
  type: ACTION_GET_USERS_SUCCESS,
  payload: {
    data,
    totalRecords,
    activePage,
  },
})

const dispatchGetUsersFailure = (error: Error): ActionGetUsersFailure => ({
  type: ACTION_GET_USERS_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetUsers(req: PageParams, isLoader: boolean = true): (dispatch: Dispatch<GetUsersActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetUsersActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getUsers(req)
      if (result && result.success) {
        dispatch(dispatchGetUsersSuccess(result.data, result.totalRecords, result.activePage))
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetUsersFailure(new Error(result?.message || I18n.t('common.toast.something_went_wrong'))))
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(error?.message || I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetUsersFailure(error))
      loaderStop(dispatch)
    }
  }
}
