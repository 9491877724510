import {
  ConsentTemplateActions,
  ACTION_GET_CONSENTTEMPLATE_CLEAR,
  ACTION_GET_CONSENTTEMPLATE_FAILURE,
  ACTION_GET_CONSENTTEMPLATE_SUCCESS,
  ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS,
  ACTION_WEBSITE_SCREEN_SHOT_SUCCESS,
  ACTION_WEBSITE_SCREEN_SHOT_FAILURE,
  ACTION_WEBSITE_SCREEN_SHOT_LOADING,
} from '../Actions/consentTemplate.actions'

import {
  DomainsActions,
  ACTION_GET_DOMAINS_CLEAR,
  ACTION_GET_DOMAINS_FAILURE,
  ACTION_GET_DOMAINS_SUCCESS,
} from '../Actions/domains.actions'

import { BasicDropdownModel } from 'Modules/Common/Models'
import { ConsentTemplateModel } from '../Models/banner.models'

export interface BannersReducerState {
  getConsentTemplate: {
    success: boolean
    data: ConsentTemplateModel
  }
  getDomainsList: {
    success: boolean
    data: Array<BasicDropdownModel>
  }
  publishConsentTemplate: {
    success: boolean
    message: string
  }
  screenShotBase64: string
  screenShotSuccess: boolean
  screenShotLoading: boolean
  urlLoading: string,
  customerLogo: string
}

export const initialState: BannersReducerState = {
  getConsentTemplate: {
    success: false,
    data: null,
  },
  getDomainsList: {
    success: false,
    data: [],
  },
  publishConsentTemplate: {
    success: false,
    message: '',
  },
  screenShotBase64: '',
  screenShotSuccess: false,
  screenShotLoading: false,
  urlLoading: '',
  customerLogo: '',
}

type Actions = ConsentTemplateActions | DomainsActions

export const BannersReducer = (
  state = initialState, // NOSONAR
  action: Actions
): BannersReducerState => {
  switch (action.type) {
    case ACTION_GET_CONSENTTEMPLATE_CLEAR:
    case ACTION_GET_CONSENTTEMPLATE_FAILURE:
      return {
        ...state,
        getConsentTemplate: { success: false, data: null },
      }
    case ACTION_GET_CONSENTTEMPLATE_SUCCESS:
      return {
        ...state,
        getConsentTemplate: { success: true, data: action.payload },
        customerLogo: action.payload?.content?.customer_logo,
      }
    case ACTION_GET_DOMAINS_CLEAR:
    case ACTION_GET_DOMAINS_FAILURE:
      return {
        ...state,
        getDomainsList: { success: false, data: [] },
      }
    case ACTION_GET_DOMAINS_SUCCESS:
      return {
        ...state,
        getDomainsList: { success: true, data: action.payload?.data },
      }

    case ACTION_PUBLISH_BANNER_TEMPLATE_SUCCESS:
      return {
        ...state,
        publishConsentTemplate: {
          success: true,
          message: action.payload.message,
        },
      }

    case ACTION_WEBSITE_SCREEN_SHOT_SUCCESS:
      return {
        ...state,
        screenShotBase64: action.payload.screenShot,
        screenShotSuccess: true,
      }

    case ACTION_WEBSITE_SCREEN_SHOT_FAILURE:
      return {
        ...state,
        screenShotBase64: '',
        screenShotSuccess: false,
      }

    case ACTION_WEBSITE_SCREEN_SHOT_LOADING:
      return {
        ...state,
        screenShotLoading: action.payload.loading,
        urlLoading: action.payload.url,
      }

    default:
      return state
  }
}
