import { http } from '../../../Utils/AxiosWrapper'
import { tenantManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'

interface PCURLDeploymentService {
  getPCURLDeploymentStatus(tenantId: string, domainId: string): Promise<ApiResponse<boolean>>
}
class PCURLDeploymentServiceDefault implements PCURLDeploymentService {
  async getPCURLDeploymentStatus(tenantId: string, domainId: string): Promise<ApiResponse<boolean>> {
    let result: ApiResponse<boolean>
    try {
      const { data } = await http.get(tenantManagement.PCURLDeployment(tenantId, domainId))
      result = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: data?.data?.is_pc_url_found,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }
}

export default new PCURLDeploymentServiceDefault()
