import { domains } from 'Modules/Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { Dispatch, SetStateAction } from 'react'

const checkValidDomain = async (
  url: string,
  index: number,
  setShowLoader: Dispatch<
    SetStateAction<{
      loading: boolean
      index: number
      isSubdomain: boolean
    }>
  >,
  isSubdomain: boolean = false
) => {
  let result
  setShowLoader({
    loading: true,
    index,
    isSubdomain: isSubdomain,
  })
  try {
    await http.post(domains.checkValidDomain, {
      domains: url,
    })
    result = 'success'
  } catch (e) {
    result = 'error'
  }
  setShowLoader({
    loading: false,
    index,
    isSubdomain: isSubdomain,
  })
  return result
}

export default checkValidDomain
