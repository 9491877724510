import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { CookieModel } from '../Models/cookies.model'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/cookie.service'

export const ACTION_EDIT_COOKIE_SUCCESS = 'ACTION_EDIT_COOKIE_SUCCESS'
export const ACTION_EDIT_COOKIE_FAILURE = 'ACTION_EDIT_COOKIE_FAILURE'
export const ACTION_EDIT_COOKIE_CLEAR = 'ACTION_EDIT_COOKIE_CLEAR'
export const ACTION_REFETCH_COOKIE_LIST = 'ACTION_REFETCH_COOKIE_LIST'

export interface ActionEditCookieSuccess extends Action {
  type: typeof ACTION_EDIT_COOKIE_SUCCESS
}

export interface ActionRefetchCookieList extends Action {
  type: typeof ACTION_REFETCH_COOKIE_LIST
}

export interface ActionEditCookieFailure extends Action {
  type: typeof ACTION_EDIT_COOKIE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionEditCookieClear extends Action {
  type: typeof ACTION_EDIT_COOKIE_CLEAR
  payload: {}
}

export type CookieActions =
  | ActionEditCookieSuccess
  | ActionEditCookieFailure
  | ActionEditCookieClear
  | ActionRefetchCookieList

const dispatchEditUsersSuccess = (): ActionEditCookieSuccess => ({
  type: ACTION_EDIT_COOKIE_SUCCESS,
})

export const dispatchRefetchCookieList = (): ActionRefetchCookieList => ({
  type: ACTION_REFETCH_COOKIE_LIST,
})

const dispatchEditUsersFailure = (err: Error, success: boolean): ActionEditCookieFailure => ({
  type: ACTION_EDIT_COOKIE_FAILURE,
  payload: { errorMessage: err.message, success },
})

export const dispatchEditCookieClear = (): ActionEditCookieClear => ({
  type: ACTION_EDIT_COOKIE_CLEAR,
  payload: {},
})

export function actionEditCookie(editCookie: CookieModel): (dispatch: Dispatch<CookieActions>) => Promise<void> {
  return async (dispatch: Dispatch<CookieActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.editCookie(editCookie)
      if (result.success) {
        dispatch(dispatchEditUsersSuccess())

        toaster.success(I18n.t('cookies.cookieUpdateSuccessMsg'))
        dispatch(dispatchRefetchCookieList())
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(dispatchEditUsersFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchEditUsersFailure(err, false))
    }
  }
}
