export const addTenant = {
  downloadSampleTemplate: 'Download Sample Template',
  dragAndDrop: 'Drag and drop .CSV file here',
  or: 'OR',
  uploadCSV: 'Upload CSV',
  browse: 'Browse…',
  fileValidationErrorText: 'You have uploaded an invalid file format.',
  uploadAgain: 'Upload Again',
  fileUploadSuccessMessage: 'File Uploaded Successfully',
  noEntityDataFound: 'No records found for tenant creation',
  bulkUploadSuccessMSG: 'Tenants created successfully',
  MaxRecordsExceeded: 'Max records exceeded',
  IncorrectExtension: 'Incorrect file extension',
  incorrectFileStructure: 'Incorrect file structure',
  entityAlreadyExits: 'Tenant already exists',
  invalidFileData: 'Invalid Data',
  inProgressValidation: 'File Validation Inprogress...',
  errorInstructionMsg: 'Please correct and upload the template again.',
  manually: 'Manually',
  companyName: 'Company Name',
  companyURL: 'Company URL',
  businessContact: 'Business Contact',
  bussinessEmail: 'Business Email',
  privacyContact: 'Privacy Contact',
  privacyContactEmail: 'Email',
  privacyContactEmailRequired: 'Email is required',
  phoneNumber: 'Phone Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  tenantManagement: 'Tenants',
  addNewTanent: 'Add Tenant',
  comapnyNameRequired: 'Company Name is required',
  comapnyURLRequired: 'Company URL is required',
  companySubDomainRequired: 'Company sub-domain is required',
  companyDomainRequired: 'Company domain is required',
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Last Name is required',
  businessEmailRequired: 'Business Email is required',
  phoneRequired: 'Phone Number is required',
  phoneNumberInvalid: 'Invalid Phone Number',
  tenantAddedSuccess: 'Primary tenant admin account for %{name} created for company %{company}',
  addTenantSuccess: 'Tenant account added',
  tenantEmailAlready: 'Tenant user record with this company URL already exists',
  companyURLEmailAlreadyExists: 'Record already exists for this tenant company URL/user email',
  licence: 'License (Tiers)',
  addon: 'Add-on Usage',
  nofDatasources: 'No. of Datasources',
  autoLogoReport: 'Audit Log/Report Storage',
  renewDate: 'Renew Date',
  licenseTiers: 'License Tiers',
  tier: 'Tier',
  datasource: 'Datasource',
  storage: 'Storage',
  onlyDigit: 'Please enter only digits',
  datasourceRequired: 'Datasource is required',
  storageRequired: 'Storage is required',
  renewDateRequired: 'Renew Date is required',
  tiers: 'Tiers',
  confirmText: 'Are you sure you want to apply the subscription changes for this tenant?',
  subscriptionSucesss: 'Tenant updated',
  detailsUpdated: 'Details updated',
  freeDSARS: 'Free DSARs',
  buyLicenseinfo: 'Please buy additional license to onboard new tenants.',
  buyLicenseText: 'Buy License',
  totalLicenses: 'Total Licenses',
  availableLicenses: 'Available Licenses',
  licenserequestsucess: 'License request has been sent successfully!',
  noOfLicense: 'No. of license',
  privacyPolicyInfoText: 'This email will be displayed on the Privacy Policy page of your website',
  privacyManagement: 'Privacy Management',
  consentManagement: 'Consent Management',
  addDomain: 'Add Domain',
  addSubDomain: '+ Add Sub Domain',
  domainName: 'Domain Name',
  subscription: 'Subscription',
  invalidDomain: 'Invalid domain entered',
  duplicateDomain: 'Duplicate domain name entered',
  invalidSubDomain: 'Invalid sub-domain entered',
  duplicateSubDomain: 'Duplicate sub-domain entered',
  addFirstDomain: 'Add Your First Domain',
}

export const tenant = {
  addTenant,
  copy: 'Copy',
  copyHTMLCode: 'Copy HTML Code',
  companyInfo: 'Company Info',
  companyName: 'Company Name',
  addNewTenant: 'Add Tenant',
  updateTenant: 'Update Tenant',
  noTenantsMsg: 'No Tenant Exists',
  noTenantsSubMsg: 'Add new tenant to proceed',
  areYouSureToExtendFreeTrial: 'Are you sure you want to extend free trial for this tenant by %{days} days?',
  confirmFreeTrialExtension: 'Confirm Free Trial Extension',
  freeTrialPeriodExtended: 'Free trial period extended by %{xx} days',
  extendFreeTrial: 'Extend Free Trial',
  trialAlreadyExtendedOnce: 'Trial has already been extended once',
  organizationName: 'Organization Name',
  subscription: 'Subscription',
  privacyCenter: 'Privacy Center',
  requests: 'Requests',
  primaryAdmin: 'Primary Admin',
  creationDate: 'Creation Date',
  privacyCenterStatus: 'Privacy Center Status',
  userStatus: 'User Status',
  pendingActivation: 'Pending Deployment',
  online: 'Online',
  error: 'Error',
  deployed: 'Deployed',
  overdueDsarCount: '%{count} DSAR(s) are overdue',
  copyURL: 'Copy URL',
  copyCode: 'Copy Code',
  copyCodeHeading: 'How to Manage Your Account Information and Your Privacy Rights',
  copyCodeBodyLine1:
    '%{tenantCompanyName} believes in putting you in control of your personal information. ' +
    'Upon request, we will provide you with information about whether we hold any of your personal information and how it is used.',
  copyCodeBodyLine2:
    'You may submit a privacy request to access, correct, or remove your personal information by visiting your user account at ',
  copyCodeBodyLine3: ' or by contacting us at ',
  invited: 'Invited',
  accepted: 'Accepted',
  contactInfo: 'Contact Info',
  privacyCenterBranding: 'Privacy Center Branding',
  general: 'General',
  branding: 'Branding',
  PCUrl: 'Privacy Center URL',
  PCCode: 'Privacy Center Code',
  OrgName: 'Organization Name',
  IdVerification: 'Identity Verification',
  RequestType: 'Request Type',
  EmailIdVerification: 'Email Identity Verification',
  ExtIdVerification: 'External Identity Verification',
  rtAccess: 'Right To Access',
  rtDelete: 'Right To Delete',
  PCUrlValidation: 'Please enter Privacy Center URL',
  orgNameValidation: 'Please enter Organization Name ',
  logo: 'Company Logo',
  notes: 'Note',
  uploadUrLogo: 'Upload Your Logo',
  allowedFileType: 'Allowed file types:',
  fileTypes: 'JPG, JPEG, PNG',
  maximumSize: 'Maximum file size:',
  fileSize: '4 MB',
  bcLogo: 'We recommend using transparent background logo',
  color: 'Colors',
  colorBannerText: 'Top/Side Banner',
  textBannerText: 'Banner Text',
  changeLogo: 'Change Logo',
  uploadlogoandCrop: 'Upload Logo and Crop here',
  uploadLogo: 'Upload Logo',
  crop: 'Crop',
  publishChanges: 'Are you sure to publish your changes on Privacy Center?',
  brandingupdateMgs: 'Branding changes published successfully',
  brandingupdateMsgNew: 'Branding changes published on Privacy Center',
  brandingErr: 'Error while updatinng Privacy Center Branding',
  logoSizeErr: 'Logo size must be less than 4 MB',
  incorrectFile: 'Incorrect file extension. You can use only .png, .jpg or .jpeg',
  login: 'Login',
  requestsScreen: 'Request Screen',
  pcUrlCopied: 'Privacy center URL copied to clipboard',
  copyUrl: 'Copy URL',
  preview: 'Preview',
  failedLogo: 'Failed to upload logo',
  generciError: 'Error while saving/publishing branding changes',
  pvcPolicy: 'Privacy Policy',
  tmsUse: 'Terms of Use',
  cntEmail: 'Contact Email',
  wlcmsg: 'Welcome Message',
  pvcPolicyRequired: 'Please enter valid Privacy Policy URL',
  supportContactExample: 'e.g. support@cytrio.com',
  pvcPolicyExample: 'e.g. cytrio.com/legal/privacypolicy',
  tmsUseExample: 'e.g. cytrio.com/legal/termsofservice',
  supportContact: 'Support Contact',
  tmsUseRequired: 'Please enter valid Terms of Use URL',
  cntEmailRequired: 'Please enter valid  privacy contact email',
  supportEmailRequired: 'Please enter valid support contact email',
  cntEmailValidationError: 'Please enter valid Contact Email',
  loginContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  profileIconText: 'Profile Icon',
  english: 'English',
  tenantRemovedSuccess: 'Tenant removed successfully',
  confirmRemoveTenantBodyText:
    'Deleting the tenant account shall delete all the associated DSAR and user data. Are you sure you want to proceed?',
  pcUrlInfoText:
    "Add this URL to your company's Privacy Policy to allow your customers to exercise their privacy rights by signing up on Privacy Center",
  open: 'Open',
  close: 'Closed',
  consoleLogin: 'Console Login',
  console: 'Console',
  confirm_deployment: 'Confirm Deployment',
  pcurlDeployMessage:
    'Are you sure you have embedded Privacy Center URL in the Privacy Policy of this tenants company website?',
  jsplugin: ' JS Code',
  copyjscode: 'Copy JS Code',
  downloadjscode: 'Download',
  customizePolicyText: 'Customize Policy Text',
  deployCYTRIOScript: 'Deploy CYTRIO Script',
  CYTRIOJavascript: 'CYTRIO Javascript',
  installScript: 'Install script in website',
  copyAndPasteThisCodeText:
    'Copy and paste this code immediately after the opening <head> tag of your sites HTML source code',
  emailCode: 'Email Code',
  instructions: 'Instructions',
  PCHTMLCodeResetSuccess: 'Privacy policy text has been reset',
  updatePCHTMLCodeSuccess: 'Privacy policy text has been updated',
  customizePolicyTextInstructions:
    'Invoke renderPrivacyCenterWithText function passing in the HTML element ID where the URL needs to be rendered. You can customize the text that needs to be rendered on Privacy Policy by editing it below.',
  deployCYTRIOScriptInstructions:
    'Invoke renderPrivacyCenter function passing in the HTML element ID where the URL needs to be rendered.',
  renderPCURL: 'Render PC URL',
  renderPCURLWithText: 'Render PC URL with text',
  deploy: 'Deploy',
  pleaseAddTenantDetails: 'Please add tenant details',
  installScriptInWebsite: 'Install script',
  updatePrivacyPolicyText: 'Update Privacy Policy',
  verify: 'Verify',
  copyInPrivacyPolicyPageOfTheWebsite: 'Please copy the below text and add to the Privacy Policy page of your website',
  copyInPrivacyPolicyPageOfTheWebsitelink: 'Please copy the below link and add to the Privacy Policy page of your website',
  privacyCenterDeploymentText: 'Following links will be displayed on the sign-in screen of your Privacy Center',
  deploymentSuccesfullMsg: 'Deployment Successful',
  scriptNotInstalledMsg: 'Script not installed',
  scriptNotInstalledSubMsg: 'Please ensure step 1 is completed',
  iframeLoadFailedMsg:
    'Please ensure step 1 is completed and refresh the Privacy Policy page of your website and verify again',
  pcUrlMsg: 'Privacy Center URL not detected',
  pcUrlSubMsg: '',
  privacyPolicyLinkMissingError:
    'Please add the Privacy Policy page URL of website on which the Privacy Center needs to be deployed.',
  clickToAddPrivacyPolicyURL: 'Click here to add Privacy Policy page URL',
  domains: 'Domains',
  deleteTenant: 'Delete Tenant',
  editTenant: 'Edit Tenant',
  cookieBanner: 'Cookie Banner',
  consentBannerStatus: 'Consent Banner Status',
  active: 'Active',
  pendingDeployment: 'Pending Deployment',
  scanInProgress: 'Scan In Progress…',
  scanFailed: 'Scan Failed',
  scanNow: 'Scan Now',
  privacyPolicyPageNotReachable: 'Privacy Policy page not reachable',
  selectDomain: 'Domain',
  selectTenant: 'Tenant',
  deployConsentBanner: 'Deploy Consent Banner',
  verifying: 'Verifying...',
  verifyingDomain: 'Verifying domain..',
  scriptNotInstalledTooltipMsg: 'Consent banner script not installed on website',
  addDoNotSellLink: 'Add Do Not Sell Link',
  addDoNotSellLinkToFooter: 'Add Do Not Sell Link to the footer of your website',
  customizePrivacyCenter: 'Customize Privacy Center',
  privacyContact: 'Privacy Contact',
  links: 'Links',
  contacts: 'Contacts',
  doNotSellLink: 'Do Not Sell Link',
  doNotSellLinkMessage: 'Add Do Not Sell link to the footer of your website',
  enableDoNotSellRequest: 'Enable Do Not Sell Request',
  doNotSellInfoMessage:
    "If you are selling or sharing your consumer's PI data, then enable this option to allow your consumers to submit a Do Not Sell request through your Privacy Center.",
}

export const shareCodeModal = {
  shareTo: 'To',
  subject: 'Subject',
  message: 'Message',
  sendEmail: 'Send Email',
  send: 'Send',
  sendEmailSuccessMsg: 'Email sent',
  inValidDomain: 'Please enter email that match with your company domain',
}
