import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/getCookiesConsentByRatio.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { CookieConsentRationModel } from '../Models/ConsentDashboard.model'

export const ACTION_GET_COOKIE_CONSENT_TYPE = 'ACTION_GET_COOKIE_CONSENT_TYPE'
export const ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS = 'ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS'
export const ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE = 'ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE'

export interface ActionGetCookieConsentType extends Action {
  type: typeof ACTION_GET_COOKIE_CONSENT_TYPE
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionGetCookieConsentSuccess extends Action {
  type: typeof ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS
  payload: CookieConsentRationModel
}

export interface ActionGetCookieConsentFailure extends Action {
  type: typeof ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetCookieConsentActions =
  | ActionGetCookieConsentType
  | ActionGetCookieConsentFailure
  | ActionGetCookieConsentSuccess

export const dispatchGetRequestByType = (startDate: string, endDate: string): ActionGetCookieConsentType => ({
  type: ACTION_GET_COOKIE_CONSENT_TYPE,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetRequestByTypeSuccess = (data: CookieConsentRationModel): ActionGetCookieConsentSuccess => ({
  type: ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS,
  payload: data,
})

const dispatchGetRequestByTypeFailure = (error: Error, code?: number): ActionGetCookieConsentFailure => ({
  type: ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetCookiesByConsentRation(
  startDate: number,
  endDate: number,
  selectTenant: any,
  selectDomain: any,
  selectSubDomain: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetCookieConsentActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetCookieConsentActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getCookieConsentType(startDate, endDate, selectTenant, selectDomain, selectSubDomain)
      if (result && result.success) {
        dispatch(dispatchGetRequestByTypeSuccess(result.data))
      } else {
        toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(
          dispatchGetRequestByTypeFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetRequestByTypeFailure(error))
      loaderStop(dispatch)
    }
  }
}
