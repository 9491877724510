import { Action } from 'redux'

export const ACTION_TOGGLE_ROW = 'ACTION_TOGGLE_ROW'
export const ACTION_TOGGLE_SELECT_ALL = 'ACTION_TOGGLE_SELECT_ALL'

export interface ActionToggleRow extends Action {
    type: typeof ACTION_TOGGLE_ROW
    recordId: number
}

export interface ActionToggleSelectAll extends Action {
    type: typeof ACTION_TOGGLE_SELECT_ALL
}

export type SelectReuqestActions = ActionToggleRow | ActionToggleSelectAll

export const dispatchToggleRow = (recordId: number): ActionToggleRow => ({
    type: ACTION_TOGGLE_ROW,
    recordId
})

export const dispatchToggleSelectAll = (): ActionToggleSelectAll => ({
    type: ACTION_TOGGLE_SELECT_ALL,
})