import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/dsarRequestBySubject.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { DSARSubjectModel } from '../Models/ConsentDashboard.model'

export const ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START = 'ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START'
export const ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS = 'ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS'
export const ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE = 'ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE'

export interface ActionGetDSARByRequestSubject extends Action {
  type: typeof ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionGetDSARByRequestSubjectSuccess extends Action {
  type: typeof ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS
  payload: DSARSubjectModel
}

export interface ActionGetDSARByRequestSubjectFailure extends Action {
  type: typeof ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetDSARByRequestSubjectActions =
  | ActionGetDSARByRequestSubject
  | ActionGetDSARByRequestSubjectFailure
  | ActionGetDSARByRequestSubjectSuccess

export const dispatchGetDSARByRequestSubject = (startDate: string, endDate: string): ActionGetDSARByRequestSubject => ({
  type: ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetDSARByRequestSubjectSuccess = (data: DSARSubjectModel): ActionGetDSARByRequestSubjectSuccess => ({
  type: ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS,
  payload: data,
})

const dispatchGetDSARByRequestSubjectFailure = (error: Error, code?: number): ActionGetDSARByRequestSubjectFailure => ({
  type: ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetDSARByRequestSubject(
  startDate: number,
  endDate: number,
  selectedRegulation: string,
  isLoader: boolean = true
): (dispatch: Dispatch<GetDSARByRequestSubjectActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetDSARByRequestSubjectActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getRequestByChart(startDate, endDate, selectedRegulation)
      if (result && result.success) {
        dispatch(dispatchGetDSARByRequestSubjectSuccess({ ...result.data }))
      } else {
        toastService.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(
          dispatchGetDSARByRequestSubjectFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetDSARByRequestSubjectFailure(error))
      loaderStop(dispatch)
    }
  }
}
