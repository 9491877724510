import { ErrorMessage } from '@hookform/error-message'
import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import HTMLTemplateEditor from 'Modules/Common/Components/HTMLTemplateEditor'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import React, { KeyboardEventHandler, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { X } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { I18n } from 'react-redux-i18n'
import CreatableSelect from 'react-select/creatable'
import { validateEmailForSpecificDomain } from 'Utils/stringUtils'
import { SendEmailPayload } from './Models/tenant.models'
import toaster from 'Modules/Common/Services/Toaster/index'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { actionSendEmail } from './Actions/send-email.actions'

interface ShareCodeModalProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
  pcURL: string
  code: string
  onCancelClick: () => void
  tenantCompanyName: string
}
function ShareCodeModal(props: ShareCodeModalProps) {
  const { isShowPopup, setIsShowPopup, pcURL, code, onCancelClick, tenantCompanyName } = props
  const [inputValue, setInputValue] = React.useState('')
  const userModel = useSelector((state: AppStateType) => state.AuthReducer?.userData)
  let domain = userModel?.email?.substring(userModel?.email?.indexOf('@') + 1, userModel?.email?.indexOf('.com')) // Service provider domain
  const dispatch = useDispatch()

  const components = {
    DropdownIndicator: null,
  }

  const createOption = (label: string) => ({
    label,
    value: label,
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const subjectText = `Privacy Center URL & code for ${tenantCompanyName}`
    setValue('subject', subjectText)

    const codeToPlainText = code.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    let defaultBody = `
    <div>
      <p>Hi,</p>
      <p>Below is the URL for Privacy Center for ${tenantCompanyName} that can be copied in the Privacy Policy:</p>
      <p style="font-size:13px;">
          <a href="${pcURL}" target="_blank">${pcURL}</a>
      </p>
      <p>Alternatively, you can also use the below HTML code snippet to be embedded in the Privacy Policy:</p>

      <code>  ${codeToPlainText} </code>
      <p>
      <span>Best regards,</span> <br/>
      <span> The ${userModel?.tenantName} team </span>
      </p>
    </div>
    `
    setValue('message', defaultBody)

    setValue('sendTo', [])
  }, [pcURL, code, tenantCompanyName, isShowPopup])

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const oldValues = getValues('sendTo') || []
        const upldatedValue = [...oldValues, createOption(inputValue)]
        setValue('sendTo', upldatedValue)
        setInputValue('')
        event.preventDefault()
    }
  }

  const onSubmit = (data: any) => {
    const sendToList = getValues('sendTo') || []
    const sendEmailPayload: SendEmailPayload = {
      send_to: sendToList.map(({ label }) => label),
      message: data.message,
      subject: data.subject,
    }

    if (validateEmailDomain(sendEmailPayload.send_to)) {
      dispatch(actionSendEmail(sendEmailPayload))
    }
  }

  const onSubmitByButton = () => {
    let ele = document.getElementById('sendEmailForm')
    if (ele) {
      ele.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const validateEmailDomain = (recipientsList: string[]) => {
    if (recipientsList.length > 0) {
      let hasInvalidEmail = true
      let invalidEmailsSet = []
      recipientsList.forEach(recipient => {
        let isValid = validateEmailForSpecificDomain(recipient, domain)
        if (!isValid) {
          invalidEmailsSet.push(recipient)
        }
        if (hasInvalidEmail) {
          hasInvalidEmail = isValid
        }
      })
      if (!hasInvalidEmail) {
        toaster.error(I18n.t('shareCodeModal.inValidDomain'))
        return false
      }
      return true
    }
    return false
  }

  let bodyChild: JSX.Element = (
    <div className="row p-0 m-0">
      <div className="col-md-12 mb-1">
        <form x-automation-id="sendEmailForm" id="sendEmailForm" onSubmit={handleSubmit(onSubmit)}>
          <Form.Row className="d-flex p-0 m-0">
            <Form.Group className="required-astric d-flex align-items-center w-100">
              <Form.Label className="form-input-label mr-2 mb-0 shareCodeFormLabel">
                {I18n.t('shareCodeModal.shareTo')}
              </Form.Label>
              <Controller
                name="sendTo"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => {
                  return (
                    <CreatableSelect
                      {...field}
                      components={components}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={newValue => setValue('sendTo', newValue)}
                      onInputChange={newValue => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Type something and press enter..."
                      value={getValues('sendTo')}
                      className={`custom-form-control w-100 flex-fill`}
                      x-automation-id="sendTo"
                      id="sendTo"
                    />
                  )
                }}
              />
            </Form.Group>
            <Form.Group className="required-astric d-flex align-items-center w-100">
              <Form.Label className="form-input-label mr-2 mb-0 shareCodeFormLabel">
                {I18n.t('shareCodeModal.subject')}
              </Form.Label>
              <Form.Control
                className="custom-form-control w-100 flex-fill"
                maxLength={64}
                x-automation-id="subject"
                id="subject"
                {...register('subject', {
                  required: I18n.t('shareCodeModal.subject'),
                })}
                isInvalid={errors?.subject}
              />
              <ErrorMessage className="invalid-feedback" name="first_name" as="div" errors={errors} />
            </Form.Group>
            <Form.Group className="required-astric d-flex w-100">
              <Form.Label className="form-input-label mr-2 mb-0 shareCodeFormLabel">
                {I18n.t('shareCodeModal.message')}
              </Form.Label>
              <div className={`w-100 flex-fill`}>
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <HTMLTemplateEditor
                        placeholders={[]}
                        content={getValues('message')}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setValue('message', data)
                        }}
                      />
                    )
                  }}
                />
              </div>
            </Form.Group>
          </Form.Row>
        </form>
      </div>
    </div>
  )
  return (
    <VerticallyCenteredModal
      heading={
        <div className="d-flex justify-content-between align-items-center w-100">
          <span>{I18n.t('shareCodeModal.sendEmail')}</span>
          <button
            type="button"
            role="closeIcon"
            id="TableFiltersSidebar-close-button"
            className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
            onClick={onCancelClick}
          >
            <X />
          </button>
        </div>
      }
      footerChild={
        <div className="row w-100">
          <div className="d-flex w-100 align-items-center justify-content-end">
            <CancelButtonWithIcon
              onClick={onCancelClick}
              text={I18n.t('common.cancel')}
              x-automation-id="cancel"
              id="cancelBtn"
            />
            <ButtonWithIcon
              className="ml-2"
              text={I18n.t('shareCodeModal.send')}
              x-automation-id="confirm"
              id="sendEmailBtn"
              onClick={onSubmitByButton}
            />
          </div>
        </div>
      }
      show={isShowPopup}
      dialogClassName="shareCodeModal"
      x-automation-id="send-model-popup"
      bodyChild={bodyChild}
      onHide={() => setIsShowPopup(false)}
    />
  )
}

export default React.memo(ShareCodeModal)
