import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { http } from 'Utils/AxiosWrapper'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { tenantManagement } from '../../Common/Constants/ApiUrls'

export interface VerifyCodeRequest {
  code: string
}

export interface VerifyCodeResponse {
  success: boolean
  message: string
  code: number
}

export interface VerifyUserLicenseService {
  verifyUserLicense(request: VerifyCodeRequest): Promise<VerifyCodeResponse>
}

class VerifyUserLicenseServiceDefault implements VerifyUserLicenseService {
  async verifyUserLicense(request: VerifyCodeRequest): Promise<VerifyCodeResponse> {
    const bodyJSONData = {
      auth_code: request.code,
    }
    let result: VerifyCodeResponse
    try {
      const { data } = await http.post(tenantManagement.verifyuserlicense, bodyJSONData)
      result = VerifyUserLicenseServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): VerifyCodeResponse {
    if (!data) {
      return {
        success: false,
        message: 'Error no data in response',
        code: 4000,
      }
    }
    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new VerifyUserLicenseServiceDefault()
