import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/domains.service'
import cookiescan from '../../CookieScanning/Services/index'
import { DomainType } from 'Modules/Tenant/Models/tenantList.model'
import { AddDomainPayload, WebsiteAddDomainPayload } from '../Models/domains.model'
import { ActionRefreshTenantList, dispatchRefreshTenantList } from 'Modules/Tenant/Actions/tenant-list.actions'

export const ACTION_GET_DOMAINS_LIST_SUCCESS = 'ACTION_GET_DOMAINS_LIST_SUCCESS'
export const ACTION_GET_DOMAINS_LIST_FAILURE = 'ACTION_GET_DOMAINS_LIST_FAILURE'

export const ACTION_ADD_DOMAIN_SUCCESS = 'ACTION_ADD_DOMAIN_SUCCESS'
export const ACTION_ADD_DOMAIN_FAILURE = 'ACTION_ADD_DOMAIN_FAILURE'
export const ACTION_ADD_DOMAIN_WEBSITE_SUCCESS = 'ACTION_ADD_DOMAIN_WEBSITE_SUCCESS'
export const ACTION_ADD_DOMAIN_WEBSITE_FAILURE = 'ACTION_ADD_DOMAIN_WEBSITE_FAILURE'
export const ACTION_GET_DOMAIN_BY_ID = 'ACTION_GET_DOMAIN_BY_ID'
export const ACTION_EDIT_DOMAIN_SUCCESS = 'ACTION_EDIT_DOMAIN_SUCCESS'
export const ACTION_REMOVE_SUBDOMAIN_SUCCESS = 'ACTION_REMOVE_SUBDOMAIN_SUCCESS'
export const ACTION_COOKIE_SCANNING_SUCCESS = 'ACTION_COOKIE_SCANNING_SUCCESS'
export const ACTION_COOKIE_SCANNING_FAILURE = 'ACTION_COOKIE_SCANNING_FAILURE'
export const ACTION_GET_JS_SCRIPT_SUCCESS = 'ACTION_GET_JS_SCRIPT_SUCCESS'
export const ACTION_VERIFY_JS_SCRIPT_SUCCESS = 'ACTION_VERIFY_JS_SCRIPT_SUCCESS'
export const ACTION_VERIFY_JS_SCRIPT_LOADING = 'ACTION_VERIFY_JS_SCRIPT_LOADING'
export const ACTION_GET_JS_SCRIPT_RESET = 'ACTION_GET_JS_SCRIPT_RESET'
export const ACTION_REFETCH_DOMAINS_LIST = 'ACTION_REFETCH_DOMAINS_LIST'
export const ACTION_ADD_DOMAIN_VALIDATED = 'ACTION_ADD_DOMAIN_VALIDATED'
export const ACTION_VERIFYING_DOMAIN = 'ACTION_VERIFYING_DOMAIN'
export const ACTION_SET_VERIFIED_DOMAINS = 'ACTION_SET_VERIFIED_DOMAINS'
export const ACTION_REMOVE_DOMAIN_SUCCESS = 'ACTION_REMOVE_DOMAIN_SUCCESS'

export interface ActionGetDomainsListSuccess extends Action {
  type: typeof ACTION_GET_DOMAINS_LIST_SUCCESS
  payload: {
    data: DomainType[]
    totalRecords: number
    activePage: number
  }
}

export interface ActionGetDomainsListFailure extends Action {
  type: typeof ACTION_GET_DOMAINS_LIST_FAILURE
  payload: {
    errorMessage: string
    success: boolean
  }
}

export interface ActionAddDomainSuccess extends Action {
  type: typeof ACTION_ADD_DOMAIN_SUCCESS
  payload: {
    message: string
  }
}

export interface ActionAddDomainWebsiteSuccess extends Action {
  type: typeof ACTION_ADD_DOMAIN_WEBSITE_SUCCESS
  payload: {
    message: string
  }
}

export interface ActionAddDomainWebsiteFailure extends Action { 
  type: typeof ACTION_ADD_DOMAIN_WEBSITE_FAILURE
  payload: {
    errorMessage: string
    success: boolean
  }
}
export interface ActionAddDomainFailure extends Action {
  type: typeof ACTION_ADD_DOMAIN_FAILURE
  payload: {
    errorMessage: string
    success: boolean
  }
}

export interface ActionGetDomainByIdSuccess extends Action {
  type: typeof ACTION_GET_DOMAIN_BY_ID
  payload: {
    domain: DomainType
  }
}

export interface ActionEditDomainSucess extends Action {
  type: typeof ACTION_EDIT_DOMAIN_SUCCESS
  payload: {
    code: number
    message: string
  }
}

export interface ActionRemoveSubDomainSuccess extends Action {
  type: typeof ACTION_REMOVE_SUBDOMAIN_SUCCESS
  payload: {
    code: number
    message: string
  }
}

export interface ActionCookieScanningSuccess extends Action {
  type: typeof ACTION_COOKIE_SCANNING_SUCCESS
  payload: {
    success: boolean
    message: string
  }
}

export interface ActionCookieScanningFailure extends Action {
  type: typeof ACTION_COOKIE_SCANNING_FAILURE
  payload: {
    success: boolean
    message: string
  }
}

export interface ActionGetJsScriptSuccess extends Action {
  type: typeof ACTION_GET_JS_SCRIPT_SUCCESS
  payload: {
    scriptUrl: string
  }
}

export interface ActionVerifyJsScriptSuccess extends Action {
  type: typeof ACTION_VERIFY_JS_SCRIPT_SUCCESS
  payload: {
    status: string
  }
}

export interface ActionVerifyScriptLoading extends Action {
  type: typeof ACTION_VERIFY_JS_SCRIPT_LOADING
  payload: {
    status: string
  }
}

export interface ActionAddDomainValidated extends Action {
  type: typeof ACTION_ADD_DOMAIN_VALIDATED
  payload: {
    domain: string
  }
}

export interface ActionGetJsScriptReset extends Action {
  type: typeof ACTION_GET_JS_SCRIPT_RESET
}

export interface ActionRefetchDomainsList extends Action {
  type: typeof ACTION_REFETCH_DOMAINS_LIST
}

export interface ActionVerifyingDomain extends Action {
  type: typeof ACTION_VERIFYING_DOMAIN
  payload: {
    verifying: boolean
  }
}

export interface ActionSetVerifiedDomains extends Action {
  type: typeof ACTION_SET_VERIFIED_DOMAINS
  payload: {
    domainsVerified: Array<string>
  }
}

export interface ActionRemoveDomainSuccess extends Action {
  type: typeof ACTION_REMOVE_DOMAIN_SUCCESS
  payload: {
    code: number
    message: string
  }
}

export type DomainsActions =
  | ActionGetDomainsListSuccess
  | ActionGetDomainsListFailure
  | ActionAddDomainFailure
  | ActionAddDomainSuccess
  | ActionGetDomainByIdSuccess
  | ActionEditDomainSucess
  | ActionRemoveSubDomainSuccess
  | ActionCookieScanningSuccess
  | ActionCookieScanningFailure
  | ActionGetJsScriptSuccess
  | ActionVerifyJsScriptSuccess
  | ActionVerifyScriptLoading
  | ActionGetJsScriptReset
  | ActionRefetchDomainsList
  | ActionAddDomainValidated
  | ActionVerifyingDomain
  | ActionSetVerifiedDomains
  | ActionRemoveDomainSuccess
  | ActionRefreshTenantList
  | ActionAddDomainWebsiteSuccess
  | ActionAddDomainWebsiteFailure
interface GetDomainListSuccess {
  data: DomainType[]
  totalRecords: number
  activePage: number
}
const dispatchGetDomainListSuccess = ({
  data,
  totalRecords,
  activePage,
}: GetDomainListSuccess): ActionGetDomainsListSuccess => ({
  type: ACTION_GET_DOMAINS_LIST_SUCCESS,
  payload: {
    data: data,
    totalRecords: totalRecords,
    activePage: activePage,
  },
})
const dispatchGetDomainListFailure = (err: Error, success: boolean): ActionGetDomainsListFailure => ({
  type: ACTION_GET_DOMAINS_LIST_FAILURE,
  payload: { errorMessage: err.message, success },
})

const dispatchAddDomainSuccess = (message: string): ActionAddDomainSuccess => ({
  type: ACTION_ADD_DOMAIN_SUCCESS,
  payload: {
    message,
  },
})

const dispatchAddDomainFailure = (err: Error, success: boolean): ActionAddDomainFailure => ({
  type: ACTION_ADD_DOMAIN_FAILURE,
  payload: { errorMessage: err.message, success },
})


const dispatchAddDomainWebsiteSuccess = (message: string): ActionAddDomainWebsiteSuccess => ({
  type: ACTION_ADD_DOMAIN_WEBSITE_SUCCESS,
  payload: {
    message,
  },
})  

const dispatchAddDomainWebsiteFailure = (err: Error, success: boolean): ActionAddDomainWebsiteFailure => ({
  type: ACTION_ADD_DOMAIN_WEBSITE_FAILURE,
  payload: { errorMessage: err.message, success },
})



export const dispatchGetDomainByIdSuccess = (domain: DomainType): ActionGetDomainByIdSuccess => ({
  type: ACTION_GET_DOMAIN_BY_ID,
  payload: { domain },
})

export const dispatchEditDomainSuccess = (code: number, message: string): ActionEditDomainSucess => ({
  type: ACTION_EDIT_DOMAIN_SUCCESS,
  payload: { code, message },
})

export const dispatchRemoveSubDomainSuccess = (code: number, message: string): ActionRemoveSubDomainSuccess => ({
  type: ACTION_REMOVE_SUBDOMAIN_SUCCESS,
  payload: { code, message },
})

export const dispatchCookieScanningSuccess = (success: boolean, message: string): ActionCookieScanningSuccess => ({
  type: ACTION_COOKIE_SCANNING_SUCCESS,
  payload: { success, message },
})

export const dispatchCookieScanningFailure = (success: boolean, message: string): ActionCookieScanningFailure => ({
  type: ACTION_COOKIE_SCANNING_FAILURE,
  payload: { success, message },
})

export const dispatchGetJsScriptSuccess = (scriptUrl: string): ActionGetJsScriptSuccess => ({
  type: ACTION_GET_JS_SCRIPT_SUCCESS,
  payload: { scriptUrl },
})

export const dispatchVerifyJsScriptLoading = (): ActionVerifyScriptLoading => ({
  type: ACTION_VERIFY_JS_SCRIPT_LOADING,
  payload: { status: 'in_progress' },
})

export const dispatchVerifyJsScriptSuccess = (status: string): ActionVerifyJsScriptSuccess => ({
  type: ACTION_VERIFY_JS_SCRIPT_SUCCESS,
  payload: { status },
})

export const dispatchGetJsScriptReset = (): ActionGetJsScriptReset => ({
  type: ACTION_GET_JS_SCRIPT_RESET,
})

export const dispatchResetSuccessful = (): ActionGetJsScriptReset => ({
  type: ACTION_GET_JS_SCRIPT_RESET,
})

export const dispatchRefetchDomainsList = (): ActionRefetchDomainsList => ({
  type: ACTION_REFETCH_DOMAINS_LIST,
})

export const dispatchAddDomainValidated = (domain: string): ActionAddDomainValidated => ({
  type: ACTION_ADD_DOMAIN_VALIDATED,
  payload: { domain },
})

export const dispatchVerifyingDomain = (verifying: boolean): ActionVerifyingDomain => ({
  type: ACTION_VERIFYING_DOMAIN,
  payload: {
    verifying,
  },
})

export const dispatchSetVerifiedDomains = (domainsVerified: Array<string>): ActionSetVerifiedDomains => ({
  type: ACTION_SET_VERIFIED_DOMAINS,
  payload: {
    domainsVerified: domainsVerified,
  },
})

export const dispatchRemoveDomainSuccess = (code: number, message: string): ActionRemoveDomainSuccess => ({
  type: ACTION_REMOVE_DOMAIN_SUCCESS,
  payload: { code, message },
})

export function actionGetAllDomainsList(params: any): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.getDomainsList(params)
      if (result.success) {
        dispatch(
          dispatchGetDomainListSuccess({
            data: result.data,
            totalRecords: result.totalRecords,
            activePage: result.activePage || 1,
          })
        )
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(dispatchGetDomainListFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetDomainListFailure(err, false))
    }
  }
}

export function actionAddDomain(payload: AddDomainPayload): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.addDomain(payload)

      if (result.success) {
        dispatch(dispatchAddDomainSuccess(result?.message))
        toaster.success(I18n.t('domains.successMessage'))
      } else {
        toaster.error(result?.message)
        dispatch(dispatchAddDomainFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchAddDomainFailure(err, false))
    }
  }
}

export function actionGetDomainById(id: number): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.getDomainById(id)
      if (result.success) {
        dispatch(dispatchGetDomainByIdSuccess(result?.data))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionEditDomain(
  payload: AddDomainPayload,
  id: number,
  is_sp: boolean
): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.editDomain(payload, id, is_sp)
      if (result.success) {
        dispatch(dispatchEditDomainSuccess(result?.code, result?.data))
        toaster.success(I18n.t('domains.updateMessage'))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionRemoveSubDomain(id: number): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.removeSubDomain(id)
      if (result.success) {
        dispatch(dispatchRemoveSubDomainSuccess(result?.code, result?.data))
        dispatch(dispatchRefreshTenantList())
        toaster.success(I18n.t('domains.removedSubdomain'))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionRemoveDomain(id: number, flag: string): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.removeDomain(id, flag)
      if (result.success) {
        dispatch(dispatchRemoveDomainSuccess(result?.code, result?.data))
        dispatch(dispatchRefreshTenantList())
        toaster.success(I18n.t('domains.removedDomain'))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionGetJsScript(
  tenantId: number,
  domainId: number,
  subDomainId?: number
): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      dispatch(dispatchVerifyJsScriptLoading())
      dispatch(dispatchGetJsScriptReset())
      const result = await service.getJsScript(tenantId, domainId, subDomainId)
      if (result.success) {
        dispatch(dispatchGetJsScriptSuccess(result?.data))
      } else {
        dispatch(dispatchGetJsScriptSuccess(''))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionVerifyJsScript(
  tenantId: number,
  domainId: number,
  subDomainId?: number
): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      dispatch(dispatchVerifyJsScriptLoading())
      const result = await service.verifyJsScript(tenantId, domainId, subDomainId)
      if (result.success) {
        dispatch(dispatchVerifyJsScriptSuccess(result?.data))
      } else {
        dispatch(dispatchVerifyJsScriptSuccess(''))
      }
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionAddDomainWebsite(payload: WebsiteAddDomainPayload): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.addWebsiteDomain(payload)
      if (result.success) {
        dispatch(dispatchRefetchDomainsList())
        dispatch(dispatchAddDomainWebsiteSuccess(result?.message))
      } else {
        dispatch(dispatchAddDomainWebsiteFailure(new Error(result.message), true))
        dispatch(dispatchRefetchDomainsList())
        loaderStop(dispatch)
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchAddDomainWebsiteFailure(err, false))
    }
  }
}


export function actionRemoveWebsiteDomain(id: number, flag: string): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.removeDomain(id, flag)
      if (result.success) {
        dispatch(dispatchRemoveDomainSuccess(result?.code, result?.data))
        dispatch(dispatchRefreshTenantList())
      } else {
        //toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionScanWebsiteDomain(domain_id: number): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.scanwebsitedomain(domain_id)
      if (result.success) {
        dispatch(dispatchCookieScanningSuccess(true, result?.message))
        dispatch(dispatchRefreshTenantList())
      } else {
        dispatch(dispatchCookieScanningFailure(false, result?.message))
        //toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}

export function actionScanTenantDomain(tenant_email: string): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      //loaderStart(dispatch)
      const result = await service.scanAppSumodomain(tenant_email)
      //dispatch(dispatchRefetchDomainsList())
      if (result.success) {
        dispatch(dispatchCookieScanningSuccess(true, result?.message))
        dispatch(dispatchRefreshTenantList())
      } else {
        dispatch(dispatchCookieScanningFailure(false, result?.message))
        //toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      //toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}