export const consentLogs = {
  consentLogs: 'Consent Logs',
  export: 'Export',
  country: 'Country',
  consentStatus: 'Consent Status',
  noDataMsg: 'No consent logs exist',
  selectConsentStatus: 'Select consent status',
  website: 'Website',
  privacyCenter: 'Privacy Center',
  accepted: 'Accepted',
  partially_accepted: 'Partially Accepted',
  rejected: 'Rejected',
  ipAddress: 'IP Address',
  dateTime: 'Date/Time',
  cookies: 'Cookies',
  search: 'Search',
  cookie: 'Cookie',
  category: 'Category',
}
