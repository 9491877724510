export const urlEmailRegx = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/,
}

export const phoneNumber = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const URLValidation = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export const departmentList = [{ value: '1', label: 'IT' }]

export const ValidIpAddressRegex = new RegExp(
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$'
)
export const ValidateFTP_HTTP = new RegExp(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/)

export const numberRegex = /^[0-9]+$/

export const DefaultSort = {
  columnName: 'created_at',
  altColumnName: 'updated_at',
  sortOrder: ['desc', 'asc'],
  pageNo: 1,
}

export const DatasourceStatus = {
  NotConfigured: 'Not Configured',
  Configured: 'Configured',
}

export const CookieTypes = {
  first_party: 'first_party',
  third_party: 'third_party',
}

export const CookieConsentTypes = {
  accepted: 'accepted',
  rejected: 'rejected',
  partially_accepted: 'partially_accepted',
}


export const AccessTypes = {
  rt_access: 'rt_access',
  rt_delete: 'rt_delete',
  access: 'access',
  delete: 'delete',
}

export const DataSubjects = {
  customer: 'customer',
  employee: 'employee',
}

export const RequestDistributionTypes = {
  by_subject: 'by_subject',
  by_type: 'by_type',
}

export const RequestLocationTypes = {
  by_stateview: 'by_stateview',
  by_countryview: 'by_countryview',
}

export const Role = {
  admin: 'admin',
  dataowner: 'data_owner',
  reviwer: 'reviewer',
}

export const IdVerificationStatus = {
  pending: '0',
  completed: '1',
}

export const DSARRequestStatus = {
  overdue: 'overdue',
  id_validation: 'id_validation',
  dsar_submit: 'dsar_submit',
  auto_discovery: 'auto_discovery',
  auto_assign: 'auto_assign',
  review_approve: 'review_approve',
  failed: 'failed',
  completed: 'completed',
  inprogress: 'in_progress',
  awaiting_confirmation: 'awaiting_confirmation',
  pending: 'pending',
  generating_report: 'generating_report',
  notify_requestor: 'notify_requestor',
  validate_delete: 'delete_validation',
  validate_delete_failed: 'validate_delete_failed',
  waiting_on_initial_scan: 'waiting_on_initial_scan',
  pending_licence_activation: 'pending_license_activation',
  pending_id_validation: 'pending_id_validation',
  ontrack_count: 'ontrack_count',
  overdue_count: 'overdue_count',
  overdue_1day_count: 'overdue_1day_count',
  failed_count: 'failed_count',
  open_requests_count: 'open_requests_count',
  admin_count: 'admin_count',
  data_owner_count: 'data_owner_count',
  reviewer_count: 'reviewer_count',
  awaiting_data_source_configuration: 'awaiting_data_source_configuration',
  awaiting_scanner_configuration: 'awaiting_scanner_configuration',
  closed: 'closed',
}
export const TaskStatus = {
  confirmed: 'confirmed',
  denied: 'denied',
  pending: 'pending',
  inProgress: 'in_progress',
  completed: 'completed',
}

export const DSARDashboardViewFilterDays = [30, 60, 90]

export const DSARDashboardCardTitles = {
  totalRequests: 'totalRequests',
  allStatus: 'all_status',
  inProgress: 'in_progress',
  completed: 'completed',
  totalOnTime: 'totalOnTime',
  failed: 'failed',
  totalOverdue: 'overdue',
  overduein1day: 'overdue_in_1_day',
  pending: 'pending',
  ontrack: 'on_track',
  avgTimeToResponse: 'avgTimeToResponse',
  dsarFailedOpen: 'failed_open',
}

export const DSARDashboardMapCountry = 'USA'

export const chartFontFamily = 'Roboto'
export const chartFontColor = '#000000'
export const chartDeleteColor = '#F45757'
export const chartAccessColor = '#20C997'
export const chartEmployeeColor = '#20C997'
export const chartCustomerColor = '#E1DA2B'
export const chartOnTrackColor = '#20C997'
export const chartOverDueColor = '#FD7E14'
export const openRequestOverDueIn1DayColor = '#FD7E14'
export const openRequestOverDueColor = '#E90000'
export const chartOverIn1DayColor = '#E1DA2B'
export const chartFailedColor = '#E90000'
export const regulationChartCCPAColor = '#F58FC0'
export const regulationChartGDPRColor = '#3EB5F8'
export const chartFirstparty = '#00b050'
export const chartThirdparty = '#eeac00'
export const chartAccepted = '#00b050'
export const chartRejected = '#ff0202'
export const chartPartiallyAccepted = '#eeac00'
export const progressbarColor = '#3cc4ff'
export const progressbarBGColor = '#20C997'

export const bigNumberPrefixes = [
  { number: 1e3, suffix: 'k' },
  { number: 1e6, suffix: 'M' },
  { number: 1e9, suffix: 'G' },
  { number: 1e12, suffix: 'T' },
  { number: 1e15, suffix: 'P' },
  { number: 1e18, suffix: 'E' },
  { number: 1e21, suffix: 'Z' },
  { number: 1e24, suffix: 'Y' },
]

export const statusMapping = {
  pending: 'Pending',
  in_progress: 'In Progress',
  completed: 'Completed',
}

export const licenseUpgradeEmail = 'sales@cytrio.com'
export const supportEmail = 'support@cytrio.com'
export const complianceMicrosoft = 'https://compliance.microsoft.com'
export const cytrioPrivacyPolicyURL = 'https://cytrio.com/legal/privacypolicy/'
export const cytrioTermsofServiceURL = 'https://www.cytrio.com/legal/TermsofService/'
export const cytrioAboutUsURL = 'https://cytrio.com/company/'
export const cytrioHelpURL = 'https://cytrio.com'

export const PercenatgeValues = {
  increase: 'increase',
  decrease: 'decrease',
}

export const PlanNames = {
  free_trial: 'Free Trial',
  starter: 'Starter',
  growth: 'Growth',
  enterprise: 'Enterprise',
}

export const PermissionsMapping = {
  edit_tracking_scripts: 'edit_tracking_scripts',
  view_tracking_scripts: 'view_tracking_scripts',
  view_privacy_center: 'view_privacy_center',
  edit_privacy_center: 'edit_privacy_center',
  view_banner: 'view_banner',
  edit_banner: 'edit_banner',
  edit_website: 'edit_website',
  view_website: 'view_website',
  edit_cookies: 'edit_cookies',
  view_cookies: 'view_cookies',
  view_consent_log: 'view_consent_log',
  view_consent_dashboard: 'view_consent_dashboard',
  add_tenant: 'add_tenant',
  edit_tenant: 'edit_tenant',
  add_tenant_users: 'add_tenant_users',
  modify_setting: 'modify_setting',
  policies: 'policies',
  consents: 'consents',
  view_dsar: 'view_dsar',
  view_and_exports_requests_reports: 'view_and_exports_requests_reports',
  add_service_provider_users: 'add_service_provider_users',
  delete_service_provider_users: 'delete_service_provider_users',
  view_domains: 'view_domains',
}

export const FeaturePermissionsMapping = {
  privacy_management: 'privacy_management',
  consent_management: 'consent_management',
}

export const DatastoreScanStatus = {
  scanInProgress: 'scan_in_progress',
  scanFailed: 'scan_failed',
  scanPaused: 'scan_paused',
  disconnected: 'disconnected',
}

export const testAuthTypes = {
  userNamePassword: 'usernameAndPassword',
  customerDetailsWithUserNamePassword: 'customerDetailsWithUserNamePassword',
  apiKey: 'apiKey',
  iamRole: 'iam',
}

export const dataStoreType = {
  SaaSApp: 'saas_app',
  rdbms: 'rdbms',
  data_warehouse: 'data_warehouse',
}

export const ObjectsRelations = [
  {
    label: '1-to-1',
    value: 'ONE_TO_ONE',
  },
  {
    label: 'Many-to-1',
    value: ' MANY_TO_ONE',
  },
  {
    label: '1-to-many',
    value: 'ONE_TO_MANY',
  },
  {
    label: 'Many-to-many',
    value: 'MANY_TO_MANY',
  },
]

export const levelTypeMap = {
  datasource: 'datasource',
  database: 'database',
  table: 'table',
  column: 'column',
}

export const selectionTypeMap = {
  selectedDB: 'selectedDB',
  selectedColumn: 'selectedColumn',
  selectedAttribute: 'selectedAttribute',
  selectedTable: 'selectedTable',
  selectedObject: 'selectedObject',
  selectedDatasource: 'selectedDatasource',
}

export const DatasourceTypes = {
  RDBMS: 'rdbms',
  SAAS_APP: 'saas_app',
  DATA_WAREHOUSE: 'data_warehouse',
}

export const showIntervalLoader = false

export const reportFrequencies = {
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  yearly: 'yearly',
}

export const auditLog = {
  info: 'INFO',
  error: 'ERROR',
}

export const dataSourceFilter = [
  {
    label: 'All Status',
    value: 'all',
  },
  {
    label: 'Connected',
    value: 'connected',
  },
  {
    label: 'Not Configured',
    value: 'not_configured',
  },
]

export const defaultreportSelected = [
  {
    label: 'Record of Processing Activities - RoPA',
    value: 'roPA',
  },
  {
    label: 'Cross Border Data Transfer',
    value: 'crossBorderTransfer',
  },
]

export const oAuthType = {
  oAuth2: 'oauth2',
  oAuth: 'oauth',
}

export const TenantStatus = {
  active: 'Active',
  invited: 'Invited',
}

export const SaaSFieldsTooltips = {
  tenantid: 'Tenant ID',
}
