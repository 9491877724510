import { goToURLIcon } from 'Assets/Images'
import StatusBox from 'Modules/Common/Components/StatusBox'
import { PrivacyCenterStatus } from 'Modules/Common/Enums/Common'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { AppStateType } from 'Store/reducers'
import CopyCodeModal from './CopyCodeModal'
import ShareCodeModal from './ShareCodeModal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  cell?: any
  value: any
  data?: any
  tenantId?: string
}

const CopyPrivacyCenter = ({ cell, value, data, tenantId }: Props) => {
  const { sendEmail } = useSelector((state: AppStateType) => state.TenantReducer)

  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const [isShowSendEmailPopup, setIsShowSendEmailPopup] = useState<boolean>(false)
  const [modifiedCode, setModifiedCode] = useState<string>('')
  const rowData: any = cell ? cell.row.original : data
  const pc_url_status = rowData?.pc_url_status
  const privacy_center_url = value
  let theme: any = ''
  if (pc_url_status === PrivacyCenterStatus.Error) {
    theme = 'error'
  }
  if (pc_url_status === PrivacyCenterStatus.Online) {
    theme = 'success'
  }
  const pcStatusValues = {
    [PrivacyCenterStatus.Error]: I18n.t('tenant.error'),
    [PrivacyCenterStatus.Online]: I18n.t('tenant.deployed'),
    [PrivacyCenterStatus.PendingActivation]: I18n.t('tenant.pendingActivation'),
  }

  const onCancelClick = () => {
    setIsShowSendEmailPopup(false)
    setIsShowPopup(false)
  }

  const onSendEmailClick = () => {
    setIsShowSendEmailPopup(true)
    setIsShowPopup(false)
  }

  useEffect(() => {
    onCancelClick()
  }, [sendEmail?.success])

  const pcUrl = process.env.REACT_APP_PRIVACY_CENTER_URL + '/' + privacy_center_url
  let tenantsPrivacyEmail = rowData?.privacy_contact_email
  const code = `
            <div>
            <b style="margin-bottom:10px;">${I18n.t('tenant.copyCodeHeading')}</b>
            <p style="font-size:13px;margin-bottom:10px;">
            ${I18n.t('tenant.copyCodeBodyLine1', { tenantCompanyName: rowData?.tenant_name })}
            </p>
            <p style="font-size:13px;">
            ${I18n.t('tenant.copyCodeBodyLine2')} <a href="${pcUrl}" target="_blank">Privacy Center</a>
            ${I18n.t('tenant.copyCodeBodyLine3')} <a href="mailto:${tenantsPrivacyEmail}">${tenantsPrivacyEmail}</a>.
            </p>
            <p></p>
            <p style="text-align:center;font-size:12px;color:#797979;margin-bottom: 5px;">Powered by 
            <img style="width: 75px;" width="100" src="${window?.location?.origin + '/Cytrio_Logo.png'}" /></p></div>
          `

  useEffect(() => {
    setModifiedCode(code)
  }, [])

  return (
    <div className="columnContainer">
      {pc_url_status ? (
        <div className="coloumnTitle">
          <div className="d-flex align-items-center">
            {pc_url_status === PrivacyCenterStatus.Error ? (
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip title={value} className="custom-tooltip" id="tooltipTopUserEmail">
                    {pcUrl + ' does not exist on ' + rowData?.privacy_policy_page}
                  </Tooltip>
                }
              >
                <StatusBox text={pcStatusValues[pc_url_status]} theme={theme} />
              </OverlayTrigger>
            ) : (
              <StatusBox text={pcStatusValues[pc_url_status]} theme={theme} />
            )}
          </div>
          <div className="d-flex align-items-center mt-2">
            <a className="copyTextIcon mr-2" href={pcUrl} title={pcUrl} target="_blank">
              <img src={goToURLIcon} width={16} className="mr-1 iconColorGreen" />
              {I18n.t('tenant.URL')}
            </a>

            {pc_url_status !== PrivacyCenterStatus.Online ? (
              <button
                id={'copyTextIcon-' + cell?.row?.index}
                className="copyTextIcon mr-2"
                type="button"
                onClick={() => {
                  setIsShowPopup(true)
                }}
              >
                {I18n.t('tenant.deploy')}
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
      {isShowPopup ? (
        <CopyCodeModal
          isShowPopup={isShowPopup}
          setIsShowPopup={setIsShowPopup}
          pcURL={pcUrl}
          tenantId={tenantId}
          onSendEmailClick={onSendEmailClick}
          setModifiedCode={setModifiedCode}
          code={code}
          rowData={rowData}
        />
      ) : null}
      <ShareCodeModal
        code={modifiedCode}
        pcURL={pcUrl}
        isShowPopup={isShowSendEmailPopup}
        setIsShowPopup={setIsShowSendEmailPopup}
        tenantCompanyName={rowData?.tenant_name}
        onCancelClick={onCancelClick}
      />
    </div>
  )
}

export default React.memo(CopyPrivacyCenter)
