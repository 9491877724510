import { auth } from 'Modules/Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { http } from 'Utils/AxiosWrapper'
import { ResetPasswordModel } from '../Models/user.model'

interface ForgotPassword {
  sendForgotPasswordLink(email: string): Promise<ApiResponse<string>>
  verifyPasswordLink(UUID: string): Promise<ApiResponse<string>>
  resetPassword(resetPass: ResetPasswordModel): Promise<ApiResponse<string>>
}

class ForgotPasswordDefault implements ForgotPassword {
  async sendForgotPasswordLink(email: string): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'post',
        url: auth.forgotPassword,
        data: { email },
      })
      result = {
        success: true,
        statusCode: data?.code,
        message: data?.msg,
        data: data?.msg,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
        data: error?.response?.data?.message,
      }
    }
    return result
  }
  async verifyPasswordLink(UUID: string): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'get',
        url: auth.verifyForgotPasswordLink,
        params: { reset_link_uuid: UUID },
      })
      result = {
        success: true,
        statusCode: data?.code,
        message: data?.msg,
        data: data?.msg,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
        data: error?.response?.data?.message,
      }
    }
    return result
  }
  async resetPassword(resetPass: ResetPasswordModel): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'post',
        url: auth.resetPassword,
        data: resetPass,
      })
      result = {
        success: true,
        statusCode: data?.code,
        message: data?.msg,
        data: data?.msg,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
        data: error?.response?.data?.message,
      }
    }
    return result
  }
}

export default new ForgotPasswordDefault()
