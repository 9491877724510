export type ConsentLogsType = {
  domain_id: { label: string; value: string; isSubdomain?: boolean; domain?: string }
  selectedConsentStatus: { label: string; value: ConsentStatus }
  consent_category: ConsentLogTab
}

export type WebsiteConsentLog = {
  consent_log_id: number
  consent_category: 'website'
  ip_address: string
  country: string
  consent_status: 'accepted' | 'rejected' | 'partially_accepted'
  accepted_cookies_category: string[]
  rejected_cookies_category: string[]
  created_at: string
  domain_id_fk: number
  tenant_id_fk: number
}

export interface ConsentLogsExportPayload {
  export: 'csv' | 'excel'
  export_type: 'All'
  consent_status: any
  search: string
  domain_id: any
}

export type PrivacyCenterConcentLog = {
  consent_log_id: number
  consent_category: 'privacy_center'
  first_name: string
  last_name: string
  email: string
  country: string
  consent_status: 'accepted' | 'rejected' | 'partially_accepted'
  accepted_cookies_category: string[]
  rejected_cookies_category: string[]
  created_at: string
  domain_id_fk: number
  tenant_id_fk: number
}

export enum ConsentStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  PartiallyAccepted = 'partially_accepted',
  All = 'all',
}

export enum ConsentLogTab {
  Website = 'website',
  PrivacyCenter = 'privacy_center',
}

export type CookieDetails = {
  [key: string]: string[]
}
