import { userManagement } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse, PageParams } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { buildParams } from 'Utils/queryFormat'

export interface GetUserService {
  getUsers(request: PageParams): Promise<ApiResponse<any>>
}

class GetUserServiceDefault implements GetUserService {
  async getUsers(request: PageParams): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'get',
        url: userManagement.getUserList,
        params: buildParams(request),
      })
      result = GetUserServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ApiResponse<any> {
    if (!data || data.code !== ResponseCodes.Success) {
      return {
        success: false,
        code: ResponseCodes.NotFound,
        message: 'Empty data in response',
      }
    }

    return {
      success: true,
      code: data.code,
      message: data.message || '',
      data: data.data || [],
      totalRecords: data.total_records,
      activePage: parseInt(data.page),
    }
  }
}

export default new GetUserServiceDefault()
