import './Accordion.css'
import { ChevronRight } from 'react-feather'
import { Accordion, Container, Card, Row, Col } from 'react-bootstrap'

type props = {
  children: any
  title: string
  arrowPosition: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  forceDefaultHide?: boolean
  CustomHeader?: React.ElementType
}

export default function AccordionDisplay({
  children,
  title,
  arrowPosition,
  forceDefaultHide = false,
  CustomHeader = null,
}: props) {
  return (
    <Accordion defaultActiveKey="0" className="w-100">
      <Card>
        <Accordion.Collapse eventKey={forceDefaultHide ? '1' : '0'}>
          <Container className="px-0">
            <Card.Body>
              <div>{children}</div>
            </Card.Body>
          </Container>
        </Accordion.Collapse>
        <Accordion.Toggle as={Card.Header} eventKey={forceDefaultHide ? '1' : '0'}>
          {CustomHeader ? (
            <>
              <CustomHeader />
            </>
          ) : (
            <Container className="px-0">
              <div className="d-flex justify-content-between align-items-center form-input-label">
                <div className="d-flex align-items-center card-header-text" style={{ height: '100%' }}>
                  {title}
                </div>
                <div className="accordion-arrow" style={{ textAlign: arrowPosition ? arrowPosition : 'left' }}>
                  <ChevronRight className="form-input-label" size={14} />
                </div>
              </div>
            </Container>
          )}
        </Accordion.Toggle>
      </Card>
    </Accordion>
  )
}
