import {
  ACTION_GET_SUBSCRIPTION,
  ActionGetSubscription,
  ACTION_LOADING,
  ActionLoading,
  ACTION_CANCEL_FREE_TRIAL_OR_PLAN,
  ACTION_CANCEL_ENTERPRISE_PLAN,
  ACTION_CANCEL_PROFESSIONAL_PLAN,
  ActionCancelFreeTrailorPlan,
  ActionCancelProfessionalPlan,
  ActionCancelEnterprisePlan,
} from '../Actions/index'

export interface SubscriptionScreenReducerState {
  data: any
  message: string
  loading: boolean
  activePage: number
  pageParams: null
  totalRecords: number
  cancelFreetrialOrPlanSuccess: boolean
  cancelPaidPlan: boolean
}

export const initialState: SubscriptionScreenReducerState = {
  data: [],
  loading: false,
  message: '',
  activePage: 1,
  pageParams: null,
  totalRecords: 0,
  cancelFreetrialOrPlanSuccess: false,
  cancelPaidPlan: false
}

export const SubscriptionScreenReducer = (
  state = initialState,
  action: ActionGetSubscription | ActionCancelFreeTrailorPlan | ActionLoading | ActionCancelProfessionalPlan | ActionCancelEnterprisePlan
): SubscriptionScreenReducerState => {
  switch (action.type) {
    case ACTION_GET_SUBSCRIPTION:
      if (action.payload.data) {
        return {
          ...state,
          data: [action.payload.data],
          cancelPaidPlan: false
        }
      } else {
        return {
          ...state,
          data: [],
          cancelPaidPlan: false
        }
      }

    case ACTION_CANCEL_FREE_TRIAL_OR_PLAN:
      return {
        ...state,
        cancelFreetrialOrPlanSuccess: true,
        message: action.payload.message,
      }

    case ACTION_CANCEL_PROFESSIONAL_PLAN:
      return {
        ...state,
        cancelPaidPlan: true,
        message: action.payload.message,
      }

    case ACTION_CANCEL_ENTERPRISE_PLAN:
      return {
        ...state,
        cancelPaidPlan: true,
        message: action.payload.message,
      }
    case ACTION_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}
