import styled from 'styled-components'
import { FormControl } from 'react-bootstrap'
import { Icon, Search, X } from 'react-feather'
import { ReactElement } from 'react'

interface InputProps {
  text?: string
  icon?: ReactElement<Icon>
  iconToRight?: boolean
  iconClassName?: string
  iconContianer?: string
  closeContainer?: string
  automationEleId: string
  handleClear?: () => void
}

const StyledInput = styled(FormControl)`
  padding: 0px 25px;
  font-size: 13px;
`

const Span = styled.span`
  position: absolute;
  top: 3px;
  left: 5px;
`

const SpanClose = styled.span`
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
`

const Div = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const SearchInput = ({
  text,
  icon,
  iconToRight,
  automationEleId,
  iconContianer,
  closeContainer,
  iconClassName,
  handleClear,
  ...rest
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Div x-automation-id={automationEleId}>
      <StyledInput {...rest} x-automation-id={`${automationEleId}_input`} />
      <Span className={iconContianer} x-automation-id={`${automationEleId}_span`}>
        <Search className={iconClassName} size={18} color="rgba(0, 0, 0, 0.5)" />
      </Span>
      {rest.value && (
        <SpanClose
          className={closeContainer}
          x-automation-id={`${rest.id}-clear-span`}
          id={`${rest.id}-clear-span`}
          onClick={handleClear}
        >
          <X size={18} color="rgba(0, 0, 0, 0.5)" />
        </SpanClose>
      )}
    </Div>
  )
}

export default SearchInput
