import { Dispatch } from 'react';
import { Action } from 'redux';
import { DropdownModel } from '../../Models/index';
import service from './department.service';
export const ACTION_GET_DEPARTMENT = 'ACTION_GET_DEPARTMENT';
export const ACTION_GET_DEPARTMENT_SUCCESS = 'ACTION_GET_DEPARTMENT_SUCCESS';
export const ACTION_GET_DEPARTMENT_FAILURE = 'ACTION_GET_DEPARTMENT_FAILURE';

export interface ActionGetDepartment extends Action {
  type: typeof ACTION_GET_DEPARTMENT;
  payload: {};
}

export interface ActionGetDepartmentSuccess extends Action {
  type: typeof ACTION_GET_DEPARTMENT_SUCCESS;
  payload: {
    success: boolean;
    data: Array<DropdownModel>;
  };
}

export interface ActionGetDepartmentFailure extends Action {
  type: typeof ACTION_GET_DEPARTMENT_FAILURE;
  payload: {
    success: boolean;
    errorMessage: string;
  };
}

export type GetDeparmentActions =
  | ActionGetDepartment
  | ActionGetDepartmentFailure
  | ActionGetDepartmentSuccess;

const dispatchGetDepartmentSuccess = (
  success: boolean,
  data: Array<DropdownModel>
): ActionGetDepartmentSuccess => ({
  type: ACTION_GET_DEPARTMENT_SUCCESS,
  payload: {
    success,
    data,
  },
});

const dispatchGetDepartmentFailure = (
  error: Error,
  success: boolean
): ActionGetDepartmentFailure => ({
  type: ACTION_GET_DEPARTMENT_FAILURE,
  payload: { errorMessage: error.message, success },
});

export function getDepartmentDropdownAction(): (
  dispatch: Dispatch<GetDeparmentActions>
) => Promise<void> {
  return async (dispatch: Dispatch<GetDeparmentActions>) => {
    try {
      const result = await service.getDepartmentDropdown();
      if (result && result.success) {
        let data: Array<DropdownModel> | undefined = result.data;
        if (data) {
          dispatch(dispatchGetDepartmentSuccess(true, data));
        } else {
          dispatch(
            dispatchGetDepartmentFailure(
              new Error('Error while getting Department'),
              false
            )
          );
        }
      } else {
        dispatch(dispatchGetDepartmentFailure(new Error(result.message), false));
      }
    } catch (error: any) {
      dispatch(dispatchGetDepartmentFailure(error, false));
    }
  };
}
