import { http } from '../../../Utils/AxiosWrapper'
import {
  TenantModel,
  AddTenantResponse,
  AvailableLicenses,
  TenantExtendFreeTrial,
  BulkUploadModel,
  BuyLicense,
  SendEmailPayload,
} from '../Models/tenant.models'
import { tenantManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { AxiosResponse } from 'axios'
import { handleDownloadFile } from 'Utils/downloadFileUtils'

interface TanentMangement {
  addTenant(tenant: TenantModel): Promise<AddTenantResponse>
  updateTenant(tenantId: number, data: TenantModel): Promise<ApiResponse<string>>
  getTenant(tenantId: number): Promise<ApiResponse<TenantModel>>
  tenantBulkUpload(inputData: BulkUploadModel): Promise<AddTenantResponse>
  deleteTenant(tenantId: string): Promise<ApiResponse<string>>
  sendEmail(sendEmailPayload: SendEmailPayload): Promise<ApiResponse<string>>
  updateTenantPCDeployment(tenantId: number): Promise<ApiResponse<string>>
}

class TenantService implements TanentMangement {
  async addTenant(tenant: TenantModel): Promise<AddTenantResponse> {
    let response: AddTenantResponse
    try {
      const { data }: any = await http.post(tenantManagement.addTenant, tenant)
      response = {
        success: true,
        message: data?.message,
        statusCode: data?.code,
        id: data?.tenant_id,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error.response.data?.message,
        statusCode: error.response.data?.code,
        id: '',
      }
    }
    return response
  }

  async updateTenant(tenantId: number, updatedData: TenantModel): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'put',
        url: tenantManagement.subscription(tenantId),
        data: updatedData,
      })
      if (data && data.code === ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: 'Something went wrong',
          statusCode: 400,
        }
      }
    }
    return response
  }

  async updateTenantDomains(tenantId: number, updatedData: TenantModel): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'put',
        url: tenantManagement.addnewTenantDomain(tenantId),
        data: updatedData,
      })
      if (data && data.code === ResponseCodes.CreatedSuccessfully) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: 'Something went wrong',
          statusCode: 400,
        }
      }
    }
    return response
  }


  async updateTenantPCDeployment(tenantId: number): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'put',
        url: tenantManagement.tenantPCDeployment(tenantId),
      })
      if (data && data.code === ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: 'Something went wrong',
          statusCode: 400,
        }
      }
    }
    return response
  }

  async getTenant(tenantId: number): Promise<ApiResponse<TenantModel>> {
    let response: ApiResponse<TenantModel>
    try {
      const { data } = await http.request({
        method: 'get',
        url: tenantManagement.subscription(tenantId),
      })

      if (data && data.code === ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
          data: data.data,
        }
      } else {
        response = {
          success: false,
          message: 'Empty response.',
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: '',
          statusCode: 5000,
        }
      }
    }
    return response
  }

  async deleteTenant(tenantId: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data } = await http.delete(tenantManagement.deleteTenant(tenantId))
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async extendFreeTrial(tenantId: number): Promise<ApiResponse<TenantExtendFreeTrial>> {
    let response: ApiResponse<TenantExtendFreeTrial>
    try {
      const { data } = await http.request({
        method: 'put',
        url: tenantManagement.extendFreeTrial(tenantId),
      })
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async buyLicenses(requestedNumberOfLicenses: number): Promise<ApiResponse<BuyLicense>> {
    let response: ApiResponse<BuyLicense>
    try {
      const { data }: any = await http.post(tenantManagement.buyLicenses, {
        requested_number_of_license: requestedNumberOfLicenses,
      })
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async getAvailableLicenses(): Promise<ApiResponse<AvailableLicenses>> {
    let response: ApiResponse<AvailableLicenses>
    try {
      const { data } = await http.request({
        method: 'get',
        url: tenantManagement.availableLicenses,
      })

      if (data && data.code === ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
          data: data.data,
        }
      } else {
        response = {
          success: false,
          message: 'Empty response.',
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: '',
          statusCode: 5000,
        }
      }
    }
    return response
  }

  async tenantBulkUpload(inputData: BulkUploadModel): Promise<AddTenantResponse> {
    let response: AddTenantResponse
    try {
      const { data }: any = await http.post(tenantManagement.tenantBulkUpload, TenantService.buildRequest(inputData))
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
        id: '',
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
          id: '',
        }
      } else {
        response = {
          success: false,
          message: '',
          statusCode: 400,
          id: '',
        }
      }
    }
    return response
  }

  async downloadSampleTemplate(): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const response: AxiosResponse = await http.get(tenantManagement.downloadSampleTemplateForBulkTenantCreation, {
        responseType: 'blob',
      })
      result = handleDownloadFile(response)
    } catch (error: any) {
      const data = await error?.response?.data?.text()
      result = {
        success: false,
        message: JSON.parse(data)?.message,
        statusCode: JSON.parse(data)?.code,
      }
    }
    return result
  }

  async sendEmail(sendEmailPayload: SendEmailPayload): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.post(tenantManagement.sendEmail, sendEmailPayload)
      if (data && data.code === ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
        }
      } else {
        response = {
          success: false,
          message: 'Something went wrong',
          statusCode: 400,
        }
      }
    }
    return response
  }

  async freeTrialDetails(
    tenantId: string
  ): Promise<
    ApiResponse<{
      is_app_sumo_user: boolean
      days_remaining: number
      license_plan: string
      domain_limit: number
      domains_used: number
      website_scan_visitor: boolean
      free_plan_edit_domain_limit: string
      old_domain_id: number
      new_domain_id: number
    }>
  > {
    let response: ApiResponse<{
      days_remaining: number
      license_plan: string
      domain_limit: number
      domains_used: number
      website_scan_visitor: boolean
      free_plan_edit_domain_limit: string
      old_domain_id: number
      new_domain_id: number
      is_app_sumo_user: boolean
    }>
    try {
      const { data } = await http.get(tenantManagement.freeTrialDetails(tenantId))
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
        data: {
          days_remaining: data.data.days_remaining,
          license_plan: data.data.license_plan,
          domain_limit: data.data.domain_limit,
          domains_used: data.data.domains_used,
          website_scan_visitor: data.data.website_scan_visitor,
          free_plan_edit_domain_limit: data.data.free_plan_edit_domain_limit,
          old_domain_id: data.data.old_domain_id,
          new_domain_id: data.data.new_domain_id,
          is_app_sumo_user: data.data.is_app_sumo_user,
        },
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async sendSubscriptionToSalesTeam(tenantId: string): Promise<ApiResponse<{}>> {
    let response: ApiResponse<{}>
    try {
      const { data } = await http.post(tenantManagement.sendSubscriptionToSalesTeam(tenantId))
      response = {
        success: true,
        message: data.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async getStripeCheckoutUrl(
    tenantId: string,
    stripeCheckoutpayload: {
      plan: string
      recurrence: string
    }
  ): Promise<
    ApiResponse<{
      checkoutUrl: string
      sessionId: string
      subscription_status: string
    }>
  > {
    let response: ApiResponse<{
      checkoutUrl: string
      sessionId: string
      subscription_status: string
    }>
    try {
      const { data }: any = await http.post(tenantManagement.getStripeCheckoutUrl(tenantId), stripeCheckoutpayload)
      response = {
        success: true,
        message: data.message,
        data: {
          checkoutUrl: data.checkout_url,
          sessionId: data.sessionId,
          subscription_status: data.subscription_status,
        },
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async getStripePaymentDetails(): Promise<
    ApiResponse<{
      data: any
    }>
  > {
    let response: ApiResponse<{
      data: any
    }>
    try {
      const { data }: any = await http.get(tenantManagement.getStripePricing)
      response = {
        success: true,
        message: data.message,
        data: data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  static buildRequest(request: BulkUploadModel): FormData {
    const { file } = request

    const formData = new FormData()
    if (file) {
      formData.append('csv', file, file.name)
    }
    return formData
  }
}

export default new TenantService()
