export const requests = {
  requests: 'Requests',
  requestID: 'Request ID',
  regulation: 'Regulation',
  status: 'Status',
  submittedOn: 'Submitted On',
  dataSubject: 'Data Subject',
  subjectType: 'Subject Type',
  requestType: 'Request Type',
  residency: 'Residency',
  rightToAccess: 'Access',
  rightToDelete: 'Delete',
  rightToDoNotSell: 'Do Not Sell',
  ok: 'OK',
  confirm: 'Confirm',
  active: 'Active',
  overdue: 'Overdue',
  dueOn: 'Due on',
  dueInDay: 'Due in %{day} day(s)',
  daysOverdue: '%{days} Day(s) Overdue',
  overdueToday: 'Overdue Today',
  dueIn1Day: 'Due in 1 day',
  customer: 'Customer',
  employee: 'Employee',
  CCPA: 'CCPA',
  GDPR: 'GDPR',
  country: 'Country',
  noDataMsg: 'No Data Subject Access Requests Exist',
  closeRequest: 'Close Request',
  uploadReport: 'Upload Report',
  open: 'Open',
  close: 'Closed',
  closeRequestSuccessMsg: 'DSAR Request closed successfully',
  export: 'Export',
  selectAll: 'Select All',
  selected: 'Selected',
  exportdsar: 'DSAR Requests exported successfully!',
  dueToday: 'Due today',
  dueIn: 'Due in',
  overdueText: 'overdue',
  onTrack: 'On Track',
  dsarprocessingguideTextone: 'Refer to our ',
  dsarprocessingguideTexttwo: 'DSAR processing guide ',
  dsarprocessingguideTextthree: 'on how to generate a report',
  fileuploadtextOne: 'Only PDF/CSV/XLS/XLSX/Zip allowed',
  fileuploadtextTwo: 'Max. size 5MB',
  uploadFilesHeader: 'Upload File(s)',
  confirmMssageone:
    'Are you sure you want to close this request? Once the request is closed, data subject will be notified and DSAR report will be available in their Privacy Center account.',
  warning_message: 'Please upload the final DSAR report in order to close this request.',
  warning_messageOne: 'Refer our ',
  warning_messageTwo: 'for additional information.',
  fileUploadSuccessMessage: 'DSAR report uploaded successfully',
  fileremoveSuccessMessage: 'DSAR report removed sucessfully',
  IncorrectExtension: 'Incorrect file extension',
  notifyuser: 'How to process and fulfill a data subject rights request? Refer ',
  notifyuserTwo: 'DSAR processing user guide.',
  logoSizeErr: 'File size must be less than 5 MB',
  noRecordSelected: 'No records were selected',
  dsarReport: 'DSAR Report',
  select: 'Select',
}
