import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { GeneralSetting } from '../Models/tenant.models'
import { ApiResponse } from 'Modules/Common/Models'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/privacyCenter.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_PC_BRANDING_START = 'ACTION_GET_PC_BRANDING_START'
export const ACTION_GET_PC_BRANDING_SUCCESS = 'ACTION_GET_PC_BRANDING_SUCCESS'
export const ACTION_GET_PC_BRANDING_FAILURE = 'ACTION_GET_PC_BRANDING_FAILURE'
export const ACTION_GET_PC_BRANDING_CLEAR = 'ACTION_GET_PC_BRANDING_CLEAR'

export interface ActionGetPCBrandingStart extends Action {
  type: typeof ACTION_GET_PC_BRANDING_START
  payload: {}
}

export interface ActionGetPCBrandingSuccess extends Action {
  type: typeof ACTION_GET_PC_BRANDING_SUCCESS
  payload: { data: ApiResponse<GeneralSetting> }
}

export interface ActionGetPCBrandingFailure extends Action {
  type: typeof ACTION_GET_PC_BRANDING_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionGetPCBrandingClear extends Action {
  type: typeof ACTION_GET_PC_BRANDING_CLEAR
  payload: {}
}

export type GetPCBrandingActions =
  | ActionGetPCBrandingStart
  | ActionGetPCBrandingSuccess
  | ActionGetPCBrandingFailure
  | ActionGetPCBrandingClear

const dispatchGetPCBrandingSuccess = (data: ApiResponse<GeneralSetting>): ActionGetPCBrandingSuccess => ({
  type: ACTION_GET_PC_BRANDING_SUCCESS,
  payload: { data },
})

const dispatchGetPCBrandingFailure = (err: Error, success: boolean): ActionGetPCBrandingFailure => ({
  type: ACTION_GET_PC_BRANDING_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

export const dispatchGetPCBrandingClear = (): ActionGetPCBrandingClear => ({
  type: ACTION_GET_PC_BRANDING_CLEAR,
  payload: {},
})

export function actionGetPCBranding(
  tenantId: string | number,
  domainId: string,
  showLoader: boolean = true,
  subDomainId?: string
): (dispatch: Dispatch<GetPCBrandingActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetPCBrandingActions>) => {
    try {
      showLoader && loaderStart(dispatch)
      const result = await service.getPCBranding(tenantId, domainId, subDomainId)
      if (result.statusCode === ResponseCodes.Success) {
        dispatch(dispatchGetPCBrandingSuccess(result))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchGetPCBrandingFailure(new Error(result.message), false))
      }
      showLoader && loaderStop(dispatch)
    } catch (err: any) {
      showLoader && loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetPCBrandingFailure(err, false))
    }
  }
}
