import {
  EmailTemplateActions,
  ACTION_GET_EMAIL_TEMPLATE,
  ACTION_GET_EMAIL_TEMPLATE_FAILURE,
  ACTION_GET_EMAIL_TEMPLATE_SUCCESS,
  ACTION_UPDATE_EMAIL_TEMPLATE,
  ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE,
  ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS,
} from '../Actions/emailTemplate.actions'

import {
  ACTION_GET_TENANT_BRANDING_FAILURE,
  ACTION_GET_TENANT_BRANDING_START,
  ACTION_GET_TENANT_BRANDING_SUCCESS,
  GetTenantBrandingActions,
} from '../Actions/getTenantBranding.actions'

import {
  ACTION_UPDATE_TENANT_BRANDING_CLEAR,
  ACTION_UPDATE_TENANT_BRANDING_FAILURE,
  ACTION_UPDATE_TENANT_BRANDING_START,
  ACTION_UPDATE_TENANT_BRANDING_SUCCESS,
  UpdateTenantBrandingActions,
} from '../Actions/updateTenantBranding.actions'

import { EmailTemplateModel, BrandingModel } from '../Models'

export interface SettingsReducerState {
  getEmailTemplate: {
    success: boolean
    data: EmailTemplateModel
  }
  updateEmailTemplate: { success: boolean }
  branding: {
    success: boolean
    data: BrandingModel
  }
  updateBranding: { success: boolean }
}

export const initialState: SettingsReducerState = {
  getEmailTemplate: {
    success: false,
    data: null,
  },
  updateEmailTemplate: { success: false },
  branding: {
    success: false,
    data: null,
  },
  updateBranding: { success: false },
}

export const SettingsReducer = (
  state = initialState, // NOSONAR
  action: EmailTemplateActions | GetTenantBrandingActions | UpdateTenantBrandingActions
): SettingsReducerState => {
  switch (action.type) {
    case ACTION_GET_EMAIL_TEMPLATE:
      return {
        ...state,
        getEmailTemplate: {
          success: false,
          data: null,
        },
        updateEmailTemplate: {
          success: false,
        },
      }
    case ACTION_GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        getEmailTemplate: {
          success: false,
          data: null,
        },
      }
    case ACTION_GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getEmailTemplate: {
          success: true,
          data: action.payload,
        },
      }
    case ACTION_UPDATE_EMAIL_TEMPLATE:
    case ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        updateEmailTemplate: { success: false },
      }
    case ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        updateEmailTemplate: { success: true },
      }

    case ACTION_GET_TENANT_BRANDING_START:
      return {
        ...state,
        branding: {
          success: false,
          data: null,
        },
      }
    case ACTION_GET_TENANT_BRANDING_SUCCESS:
      return {
        ...state,
        branding: { success: true, data: action.payload?.data?.data },
      }
    case ACTION_GET_TENANT_BRANDING_FAILURE:
      return {
        ...state,
        branding: {
          success: false,
          data: null,
        },
      }

    case ACTION_UPDATE_TENANT_BRANDING_START:
      return {
        ...state,
        updateBranding: {
          success: false,
        },
      }
    case ACTION_UPDATE_TENANT_BRANDING_SUCCESS:
      return {
        ...state,
        updateBranding: { ...action.payload.data },
      }
    case ACTION_UPDATE_TENANT_BRANDING_FAILURE:
      return {
        ...state,
        updateBranding: {
          success: false,
        },
      }
    case ACTION_UPDATE_TENANT_BRANDING_CLEAR:
      return {
        ...state,
        updateBranding: {
          success: false,
        },
      }
    default:
      return state
  }
}
