import { http } from '../../../../Utils/AxiosWrapper/index'
import { ApiResponse, LocationDropdownModel } from '../../Models'
import { common } from '../../../Common/Constants/ApiUrls'

interface LocationService {
  getlocationDropdown(): Promise<ApiResponse<LocationDropdownModel[]>>
}

class LocationServiceDefault implements LocationService {
  async getlocationDropdown(): Promise<ApiResponse<LocationDropdownModel[]>> {
    let response: ApiResponse<Array<LocationDropdownModel>> = {
      success: false,
      message: '',
      data: [],
    }
    try {
      const apiData = await http.get(common.location)
      if (apiData && apiData.data.locations && apiData.data.locations.length > 0) {
        apiData.data.locations.forEach((loc: any) => {
          let location: LocationDropdownModel = {
            value: loc.iso3,
            iso: loc.iso,
            iso3: loc.iso3,
            country_id: loc?.country_id,
            countryName: loc.country_name,
            label: loc.country_name,
            countryMobileCode: '+' + loc.country_mobile_code,
            options: loc.states.map(stateItem => {
              return {
                value: stateItem?.code,
                label: stateItem?.state_name, 
                countryMobileCode: '+' + loc.country_mobile_code,
                country: loc.country_name,
                countryISO: loc.iso,
                countryISO3: loc.iso3,
              }
            })
          }
          response.data?.push(location)
        })
        response.success = true
        response.message = 'Success'
      } else {
        response = {
          success: false,
          message: '',
          data: [],
        }
      }
    } catch (error) {
      response = {
        success: false,
        message: '',
        data: [],
      }
    }
    return response
  }
}

export default new LocationServiceDefault()
