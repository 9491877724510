import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import service from '../Services/PCURLDeployment.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { PCURLDeploymentStatus } from '../Reducers/tenant.reducer'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_PC_URL_DEPLOYMENT_STATUS = 'ACTION_GET_PC_URL_DEPLOYMENT_STATUS'
export const ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS = 'ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS'
export const ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE = 'ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE'

export interface ActionGetPCURLDeploymentStatus extends Action {
  type: typeof ACTION_GET_PC_URL_DEPLOYMENT_STATUS
}

export interface ActionGetPCURLDeploymentStatusSuccess extends Action {
  type: typeof ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS
  payload: { data: boolean }
}

export interface ActionGetPCURLDeploymentStatusFailure extends Action {
  type: typeof ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE
  payload: { success: boolean; errorMessage: string; status?: PCURLDeploymentStatus }
}

export type PCURLDeploymentActions =
  | ActionGetPCURLDeploymentStatus
  | ActionGetPCURLDeploymentStatusFailure
  | ActionGetPCURLDeploymentStatusSuccess

export const dispatchGetPCURLDeploymentStatus = (): ActionGetPCURLDeploymentStatus => ({
  type: ACTION_GET_PC_URL_DEPLOYMENT_STATUS,
})

const dispatchGetPCURLDeploymentStatusSuccess = (data: boolean): ActionGetPCURLDeploymentStatusSuccess => ({
  type: ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS,
  payload: { data },
})

const dispatchGetPCURLDeploymentStatusFailure = (
  error: Error,
  success: boolean,
  status?: PCURLDeploymentStatus
): ActionGetPCURLDeploymentStatusFailure => ({
  type: ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE,
  payload: { errorMessage: error.message, success, status },
})

export function actionGetPCURLDeploymentStatus(
  tenantId: string,
  domainId: string
): (dispatch: Dispatch<PCURLDeploymentActions>) => Promise<void> {
  return async (dispatch: Dispatch<PCURLDeploymentActions>) => {
    dispatch(dispatchGetPCURLDeploymentStatus())
    loaderStart(dispatch)
    try {
      const result = await service.getPCURLDeploymentStatus(tenantId, domainId)
      if (result && result.success) {
        dispatch(dispatchGetPCURLDeploymentStatusSuccess(result?.data))
      } else {
        if (result.code !== ResponseCodes.NotFound) {
          toaster.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(
          dispatchGetPCURLDeploymentStatusFailure(
            new Error(result?.message || 'Something went wrong.'),
            false,
            result.code === ResponseCodes.NotFound ? 'not_found' : 'failed'
          )
        )
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetPCURLDeploymentStatusFailure(error, false))
      loaderStop(dispatch)
    }
  }
}
