import { PageParams } from 'Modules/Common/Models'
import { UserByRole, UserRole } from '../Models'

import {
  AddUsersActions,
  ACTION_ADDUSERS_FAILURE,
  ACTION_ADDUSERS_SUCCESS,
  ACTION_ADDUSERS,
  ACTION_ADDUSERS_RESET,
  ACTION_ADDUSERS_CONFLICT,
} from '../Actions/user.actions'

import {
  ACTION_GET_USER_PERMISSIONS,
  ACTION_GET_USER_PERMISSIONS_FAILURE,
  ACTION_GET_USER_PERMISSIONS_SUCCESS,
  GetUserPermissionsActions,
} from '../Actions/get-user-permissions.actions'

import {
  ACTION_GET_USERS,
  ACTION_GET_USERS_FAILURE,
  ACTION_GET_USERS_SUCCESS,
  GetUsersActions,
} from '../Actions/user-list.actions'

import {
  ACTION_DELETE_USER,
  ACTION_DELETE_USER_FAILURE,
  ACTION_DELETE_USER_SUCCESS,
  ACTION_DELETE_USER_SHOW_CONFIRM_MODEL,
  DeleteUserActions,
} from '../Actions/delete-user.actions'

export interface UserReducerState {
  addUsers: {
    success?: boolean
    message?: string
    statusCode?: number
    addUsersLoader?: boolean
    errorMessage?: string
    emails?: Array<string>
    addUserConflict?: boolean
  }
  deleteUser: {
    success: boolean
    showDeleteModal: boolean
  }
  usersList: Array<any>
  totalRecords: number
  pageParams: PageParams
  activePage: number
  rolesAndPermissions: Array<UserRole>
  usersByRole: Array<UserByRole>
  isUserListRefresh?: boolean
}

export const initialState: UserReducerState = {
  addUsers: {
    success: false,
  },
  activePage: 1,
  usersList: [],
  pageParams: null,
  totalRecords: 0,
  rolesAndPermissions: [],
  usersByRole: [],
  deleteUser: {
    success: false,
    showDeleteModal: false,
  },
}

export const UserReducer = (
  state = initialState, // NOSONAR
  action: AddUsersActions | GetUserPermissionsActions | GetUsersActions | DeleteUserActions
): UserReducerState => {
  switch (action.type) {
    case ACTION_ADDUSERS_RESET:
      return {
        ...state,
        addUsers: {
          success: false,
          addUserConflict: false,
        },
      }
    case ACTION_ADDUSERS:
      return {
        ...state,
        addUsers: {
          addUsersLoader: true,
          errorMessage: '',
          addUserConflict: false,
        },
      }
    case ACTION_ADDUSERS_CONFLICT:
      return {
        ...state,
        addUsers: {
          addUsersLoader: false,
          errorMessage: '',
          emails: action.payload.emails,
          addUserConflict: true,
        },
      }
    case ACTION_ADDUSERS_SUCCESS:
      return {
        ...state,
        addUsers: {
          ...action.payload.data,
          addUsersLoader: false,
          errorMessage: '',
        },
      }
    case ACTION_ADDUSERS_FAILURE:
      return {
        ...state,
        addUsers: {
          ...state.addUsers,
          errorMessage: action.payload.errorMessage,
          addUsersLoader: false,
          success: false,
        },
      }


    case ACTION_GET_USER_PERMISSIONS:
    case ACTION_GET_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        rolesAndPermissions: [],
      }

    case ACTION_GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolesAndPermissions: action.payload.data,
      }

    case ACTION_GET_USERS:
      return {
        ...state,
        pageParams: action.payload,
      }
    case ACTION_GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload.data,
        totalRecords: action.payload.totalRecords,
        activePage: action.payload.activePage,
        addUsers: {
          success: false,
        },
      }
    case ACTION_GET_USERS_FAILURE:
      return {
        ...state,
        usersList: [],
        totalRecords: 0,
        activePage: 1,
        addUsers: {
          success: false,
        },
      }

    case ACTION_DELETE_USER_FAILURE:
    case ACTION_DELETE_USER:
      return {
        ...state,
        isUserListRefresh: false,
        addUsers: {
          success: false,
        },
        deleteUser: {
          success: false,
          showDeleteModal: false,
        },
      }
    case ACTION_DELETE_USER_SUCCESS:
      return {
        ...state,
        isUserListRefresh: true,
        deleteUser: {
          success: true,
          showDeleteModal: false,
        },
      }
    case ACTION_DELETE_USER_SHOW_CONFIRM_MODEL:
      return {
        ...state,
        isUserListRefresh: true,
        deleteUser: {
          success: false,
          showDeleteModal: true,
        },
      }
    default:
      return state
  }
}
