import { tenantManagement } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { PageParams } from 'Modules/Common/Models'

export interface GetTenantResponse {
  success: boolean
  code: number
  message: string
  data?: any
  totalRecords?: number
  activePage?: number
  extendedFreeTrialDays?: number
}

export interface GetTenantService {
  getTenants(request: PageParams, additionalParams: any): Promise<GetTenantResponse>
}

const buildParams = (p: PageParams, additionalParams: any) => {
  const params: any = {
    ...additionalParams,
  }
  params.page_no = p.page_no
  params.page_size = p.page_size
  if (p.search && p.search != '') {
    params.search = p.search
  }
  if (p.sort_column && p.sort_column != '') {
    params.sort_column = p.sort_column
  }
  if (p.sort_direction && p.sort_direction != '') {
    params.sort_direction = p.sort_direction
  }
  return params
}

class GetTenantServiceDefault implements GetTenantService {
  async getTenants(request: PageParams, additionalParams: any): Promise<GetTenantResponse> {
    let result: GetTenantResponse
    try {
      const { data } = await http.request({
        method: 'get',
        url: tenantManagement.addTenant,
        params: buildParams(request, additionalParams),
      })
      result = GetTenantServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): GetTenantResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty data in response',
      }
    }

    if (data.code === 4004) {
      return {
        success: false,
        code: data.code,
        message: data.message,
      }
    }

    return {
      success: true,
      code: data.code,
      message: data.message || '',
      data: data.data,
      extendedFreeTrialDays: data.extended_free_trial_days,
      totalRecords: data.total_records,
      activePage: parseInt(data.page),
    }
  }
}

export default new GetTenantServiceDefault()
