import React from 'react'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'

const Div = styled.div`
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: 24px;
  text-transform: uppercase;
  margin-top:-8px;
`

const Logo_LoginPage = () => {
  return <Div>{I18n.t('sidebar.privacy_manager')}</Div>
}

export default Logo_LoginPage

