import { http } from '../../../Utils/AxiosWrapper'
import { consentManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse, BasicDropdownModel } from 'Modules/Common/Models'

interface DomainsServiceProps {
  getDomains(tenant_id: string): Promise<ApiResponse<Array<BasicDropdownModel>>>
}

class DomainsService implements DomainsServiceProps {
  async getDomains(tenant_id: string): Promise<ApiResponse<Array<BasicDropdownModel>>> {
    let result: ApiResponse<Array<BasicDropdownModel>>
    try {
      const { data } = await http.get(consentManagement.getDomains, {
        params: {
          tenant_id,
        },
      })
      let domains = []
      if (data?.data?.length > 0) {
        domains = data?.data?.map(e => ({
          label: e?.domain_name,
          value: e?.domain_id,
          privacyCenterUrl: e?.privacy_center_url,
          bannerStatus: e?.banner_status,
          subDomains: e?.sub_domain?.map(e => ({
            label: e?.sub_domain_name,
            value: e?.sub_domain_id,
          })),
        }))
      }
      result = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: domains,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }
}

export default new DomainsService()
