import React from 'react'
import { I18n } from 'react-redux-i18n'

interface PasswordCheckContentProps {
  containsLL: boolean
  containsUL: boolean
  contains8: boolean
  containsN: boolean
}

const PasswordCheckContent = ({ containsLL, containsUL, containsN, contains8 }: PasswordCheckContentProps) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <p className="m-0">{I18n.t('register.pwd_must_meet')}</p>
          <p>{I18n.t('register.following_requirements')}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ul>
            <li style={{ color: containsLL ? 'green' : 'red' }}>{I18n.t('register.one_letter')}</li>
            <li style={{ color: containsUL ? 'green' : 'red' }}>{I18n.t('register.one_capital')}</li>
            <li style={{ color: containsN ? 'green' : 'red' }}>{I18n.t('register.one_number')}</li>
            <li style={{ color: contains8 ? 'green' : 'red' }}>{I18n.t('register.eight')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PasswordCheckContent
