import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import service from '../Services/index'
import { AddSubscriptionPayload } from '../Model'

export const ACTION_ADD_SUBSCRIPTION = 'ACTION_ADD_SUBSCRIPTION'
export const ACTION_LOADING = 'ACTION_LOADING'

export interface ActionAddSubscription extends Action {
  type: typeof ACTION_ADD_SUBSCRIPTION
  payload: {
    message: string
  }
}

export interface ActionLoading extends Action {
  type: typeof ACTION_LOADING
  payload: {
    loading: boolean
  }
}

export const dispatchActionAddSubscription = (data: string): ActionAddSubscription => ({
  type: ACTION_ADD_SUBSCRIPTION,
  payload: {
    message: data,
  },
})

export const dispatchActionLoading = (loading: boolean): ActionLoading => ({
  type: ACTION_LOADING,
  payload: {
    loading,
  },
})

const toasterHandler = (code: number, message?: string) => {
  switch (code) {
    case ResponseCodes.Success:
      toaster.success(message)
      break
    default:
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionAddSubscription(
  tenantId: string,
  payload: AddSubscriptionPayload
): (dispatch: Dispatch<ActionAddSubscription | ActionLoading>) => Promise<void> {
  return async (dispatch: Dispatch<ActionAddSubscription | ActionLoading>) => {
    dispatch(dispatchActionLoading(true))
    try {
      const result = await service.addSubsription(tenantId, payload)
      dispatch(dispatchActionAddSubscription(result.message))
      dispatch(dispatchActionLoading(false))
      toasterHandler(result.code, result.message)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchActionLoading(false))
    }
  }
}
