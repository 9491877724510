import { http } from '../../../Utils/AxiosWrapper'
import { CookieModel } from '../Models/cookies.model'
import { consentManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'

interface CookieServiceProps {
  editCookie(Cookie: CookieModel): Promise<ApiResponse<string>>
}

class CookieService implements CookieServiceProps {
  public async editCookie(Cookie: CookieModel): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.put(consentManagement.cookies, Cookie)
      response = {
        success: true,
        message: data?.message,
        code: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new CookieService()
