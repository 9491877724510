import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor'
import { settings } from 'Modules/Common/Constants/ApiUrls'
import { useEffect, useState } from 'react'

export type HTMLTemplateEditorPlaceHolder = {
  name: string
  code: string
}

type HTMLTemplateEditorProps = {
  content: string
  onChange: (event, editor) => void
  placeholders: Array<HTMLTemplateEditorPlaceHolder>
  items?: string[]
}
const HTMLTemplateEditor = ({ content, onChange, placeholders, items }: HTMLTemplateEditorProps) => {
  const defaultItems = [
    'sourceEditing',
    'heading',
    '|',
    'undo',
    'redo',
    '|',
    'fontSize',
    'fontFamily',
    '|',
    'fontColor',
    'fontBackgroundColor',
    'highlight:yellowMarker',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'selectAll',
    'removeFormat',
    'alignment',
    '|',
    'numberedList',
    'bulletedList',
    'outdent',
    'indent',
    '|',
    'link',
    'specialCharacters',
    'horizontalLine',
    'pageBreak',
    'blockQuote',
    'insertImage',
    'insertTable',
    '|',
    'image',
    'placeholder',
  ]

  const [showEditor, setshowEditor] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setshowEditor(true)
    }, 0)
  }, [])

  return (
    <>
      {showEditor && (
        <CKEditor
          editor={ClassicEditor}
          data={content || ''}
          onChange={onChange}
          config={{
            toolbar: {
              htmlSupport: {
                allow: [
                  {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true,
                  },
                ],
              },
              shouldNotGroupWhenFull: true,
              items: items ? items : defaultItems,
            },
            simpleUpload: {
              uploadUrl: process.env.REACT_APP_API_URL + settings.emailTemplateImage,
              withCredentials: true,
            },
            image: {
              resizeOptions: [
                {
                  name: 'resizeImage:original',
                  value: null,
                  icon: 'original',
                },
                {
                  name: 'resizeImage:50',
                  value: '50',
                  icon: 'medium',
                },
                {
                  name: 'resizeImage:75',
                  value: '75',
                  icon: 'large',
                },
              ],
              styles: ['alignLeft', 'alignCenter', 'alignRight'],
              toolbar: [
                'resizeImage',
                'imageStyle:alignLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignRight',
                '|',
                'imageTextAlternative',
                '|',
                'linkImage',
              ],
            },
            language: 'en',
            licenseKey: '',
            placeholderConfig: { types: placeholders },
          }}
        />
      )}
    </>
  )
}

export default HTMLTemplateEditor
