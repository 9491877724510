import Domains from 'Modules/Tenant/AddEditTenant/Domains'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import queryString from 'query-string'

import { useHistory, useLocation } from 'react-router-dom'
import {
  actionAddDomain,
  actionEditDomain,
  actionGetDomainById,
  dispatchSetVerifiedDomains,
} from './Actions/domainList.actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  dispatchUpdateBreadCrumb,
  dispatchClearBreadCrumb,
} from 'Modules/Common/Services/BreadCrumb/BreadCrumb.actions'
import PageTitle from 'Modules/Common/Components/PageTitle'
import styles from './Domains.module.css'
import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import FixedBottomRow from 'Modules/Common/Components/FixedBottomRow'
import { AppStateType } from 'Store/reducers'
import '../Tenant/AddEditTenant/Domains/Domains.module.css'
import { cx } from '@emotion/css'

export default function AddDomains() {
  const { domainToBeEdited, refreshDomainsList } = useSelector((state: AppStateType) => state.DomainsReducer)
  const domainLimit = useSelector((state: AppStateType) => state.TenantReducer.domainLimit)
  const domainsUsed = useSelector((state: AppStateType) => state.TenantReducer.domainsUsed)
  const role_name = useSelector((state: AppStateType) => state?.AuthReducer?.userData?.roles_and_permissions?.[0]?.role_name)
  const role = useSelector((state: AppStateType) => state.AuthReducer.userData?.roleDisplayName)

  const [showLoader, setShowLoader] = useState({
    loading: false,
    index: 0,
    isSubdomain: false,
  })

  const isAddDomainDisabled = role !== 'Service Provider' && domainLimit !== 0 && domainsUsed >= domainLimit

  const domainsDefaultValues = {
    domains: [
      {
        domain_name: '',
        sub_domains: [],
      },
    ],
  }

  const { control, register, formState, getValues, trigger, reset, setValue } = useForm({
    defaultValues: domainsDefaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const values = getValues()

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { domainId } = queryString.parse(location?.search)

  const domainRecord = domainId && domainToBeEdited

  const getDomainVerifiedList = () => {
    return [domainRecord[0]?.domain_name, ...domainRecord[0].sub_domains.map(subdomain => subdomain?.subdomain_name)]
  }

  useEffect(() => {
    if (isAddDomainDisabled && !domainId) {
      history.push('/admin/domains')
      return
    }
    if (domainId) {
      dispatch(actionGetDomainById(domainId))
    }
  }, [dispatch, domainId, history, isAddDomainDisabled])

  useEffect(() => {
    dispatch(
      dispatchUpdateBreadCrumb([
        {
          link: '/admin/domains',
          text: I18n.t('domains.pageTitle'),
        },
        { text: I18n.t(domainId ? 'domains.updateDomain' : 'domains.addDomain') },
      ])
    )
    return () => {
      dispatch(dispatchClearBreadCrumb())
    }
  }, [dispatch, domainId])

  useEffect(() => {
    if (domainRecord) {
      reset({
        domains: [
          {
            domain_name: domainRecord[0].domain_name,
            sub_domains: domainRecord[0].sub_domains.map(subDomain => ({
              sub_domain_name: subDomain.subdomain_name,
              sub_domain_id: subDomain.subdomain_id,
            })),
          },
        ],
      })

      dispatch(dispatchSetVerifiedDomains(getDomainVerifiedList()))
    }
  }, [domainRecord, reset])

  useEffect(() => {
    if (refreshDomainsList?.refetch && role_name === 'tenant_admin') {
        history.push('/admin/domains')
    }
  }, [dispatch, history, refreshDomainsList?.refetch, role_name])

  const getDomainPayload = () => {
    const formValues = {
      domains: values.domains.map(value => ({
        domain_name: value.domain_name,
        sub_domains: value.sub_domains.map(({ sub_domain_name, sub_domain_id }) => {
          let subDomainObj = {
            sub_domain_name: '',
          }
          subDomainObj['sub_domain_name'] = sub_domain_name

          if (sub_domain_id !== '') {
            subDomainObj['sub_domain_id'] = sub_domain_id
          }
          return subDomainObj
        }),
      })),
    }

    return formValues
  }

  const handleSubmit = () => {
    if (domainId) {
      if(role_name === 'service_provider'){
        dispatch(actionEditDomain(getDomainPayload(), domainId, true))
      }else{
        dispatch(actionEditDomain(getDomainPayload(), domainId, false))
      }
    } else {
      dispatch(actionAddDomain(getDomainPayload()))
    }
  }
  const handleDomainValue = value => {}
  
  return (
    <div className={styles.addDomainsContainer}>
      <div className={cx(styles.pageTitle, 'mb-1')}>
        <PageTitle title={I18n.t(domainId ? 'domains.updateDomain' : 'domains.addDomain')} />
      </div>
      <Domains
        control={control}
        register={register}
        formState={formState}
        getValues={getValues}
        trigger={trigger}
        showAddDomainButton={false}
        isEditable={true}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        setValue={setValue}
        onDomainBooleanValueChange={handleDomainValue}
      />
      <FixedBottomRow>
        <Form.Group className="col-md-12 required-astric d-flex justify-content-end">
          <div className="d-flex align-items-cennter">
            <CancelButtonWithIcon
              text="Cancel"
              x-automation-id="vModel_cancel"
              onClick={() => history.push('/admin/domains')}
            />
            <ButtonWithIcon
              text={I18n.t(domainId ? 'common.save' : 'common.add')}
              type="button"
              x-automation-id="vModel_submit"
              className="ml-2"
              onClick={handleSubmit}
              disabled={!formState.isValid || showLoader.loading}
            />
          </div>
        </Form.Group>
      </FixedBottomRow>
    </div>
  )
}
