import React from 'react'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import { CytrioLogo, AppSumoLogo } from 'Assets/Images'

const Div = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  font-size: 12px;
  margin: 0px 0px 5px 0px;
`
const Span = styled.span`
  margin: 0px 2px -2px 0px;
`
const PoweredByCytrioPartner = () => {
  return (
    <Div>
      {I18n.t('sidebar.powered_by')}
      <a href="https://www.cytrio.com" target="_blank" rel="noreferrer">
        <img className={'ml-2'} width={60} src={CytrioLogo} />
      </a>  
      &nbsp;&nbsp;<Span>with</Span>&nbsp;
      <a href="https://appsumo.com" target="_blank" rel="noreferrer">
        <img className={'ml-0'} width={70} src={AppSumoLogo} />
      </a>
    </Div>
  )
}

export default PoweredByCytrioPartner
