import React, { useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import { Container, OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './ConsentLogs.module.css'
import { cookieConsentIcon } from 'Assets/Images'
import { ConsentStatus, CookieDetails } from './Models/consentLogs.model'
import AccordionDisplay from 'Modules/Common/Components/Accordian/Accordian'
import { ChevronRight } from 'react-feather'
import cx from 'classnames'
import { renderConsentStatus } from './Utils/common'
import BootstrapTable from 'Modules/Common/Components/BootstrapTable'

type props = {
  acceptedCookieDetails: CookieDetails
  rejectedCookieDetails: CookieDetails
}
const CookiesDetails = ({ acceptedCookieDetails, rejectedCookieDetails }: props) => {
  const customHeader = (title, consentStatus) => {
    return (
      <Container className="px-0 w-100">
        <div className="d-flex align-items-center ">
          <div className="d-flex align-items-center " style={{ height: '100%', width: '53%' }}>
            <div className="accordion-arrow mr-2 ">
              <ChevronRight size={14} />
            </div>
            <div title={title} className="text-truncate pr-2">
              {title}
            </div>
          </div>
          <div className="d-flex"> {renderConsentStatus(consentStatus, null, null)}</div>
        </div>
      </Container>
    )
  }

  const cookieCategotyAccordian = (categoryName, cookieList, consentStatus) => {
    return (
      <div className="row d-flex m-0 w-100">
        <AccordionDisplay
          title={categoryName}
          arrowPosition="left"
          forceDefaultHide={true}
          CustomHeader={() => customHeader(categoryName, consentStatus)}
        >
          <table className={cx('table custom-table mt-0 pt-0', styles.innerTable)}>
            <thead>
              <tr className={styles.popoverTableHeading}>
                <th>{I18n.t('consentLogs.cookie')}</th>
                <th>{I18n.t('consentLogs.consentStatus')}</th>
              </tr>
            </thead>
            <tbody>
              {cookieList.map((cookie, index) => (
                <tr key={index}>
                  <td>{cookie}</td>
                  <td>
                    <div className="d-flex"> {renderConsentStatus(consentStatus, null, null)} </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AccordionDisplay>
      </div>
    )
  }

  return (
    <div>
      <OverlayTrigger
        trigger={['click', 'focus']}
        placement="right"
        rootClose={true}
        x-automation-id="cookies-details"
        overlay={overlayProps => (
          <Popover {...overlayProps} id="cookies-details-popover" className={cx(styles.customPopover, 'cookiePopover')}>
            <Popover.Content>
              <div className="row d-flex m-0 mb-2">
                <span className="popoverTitle pl-1 coloumnTitle">{I18n.t('consentLogs.cookies')}</span>
              </div>

              <BootstrapTable
                className={cx(styles.cookieDetailsBootstrapTable, 'border mt-0')}
                striped
                bordered={false}
                responsive
                hover={false}
                isScrollable={true}
                theadChild={
                  <tr className={styles.popoverTableHeading}>
                    <th style={{ width: '50%' }}>{I18n.t('consentLogs.category')}</th>
                    <th>{I18n.t('consentLogs.consentStatus')}</th>
                  </tr>
                }
                trChild={
                  <>
                    {acceptedCookieDetails && Object.keys(acceptedCookieDetails)?.map((categoryName, index) => (
                      <tr key={index}>
                        <td colSpan={2} className="p-0">
                          {cookieCategotyAccordian(
                            categoryName,
                            acceptedCookieDetails[categoryName],
                            ConsentStatus.Accepted
                          )}
                        </td>
                      </tr>
                    ))}
                    {acceptedCookieDetails && Object.keys(rejectedCookieDetails)?.map((categoryName, index) => (
                      <tr key={index}>
                        <td colSpan={2} className="p-0">
                          {cookieCategotyAccordian(
                            categoryName,
                            rejectedCookieDetails[categoryName],
                            ConsentStatus.Rejected
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </Popover.Content>
          </Popover>
        )}
      >
        <img className="cursor-pointer" src={cookieConsentIcon} alt="consents" width={14} height={16} />
      </OverlayTrigger>
    </div>
  )
}

export default CookiesDetails
