import React, { useEffect } from 'react'
import styles from '../Auth.module.css'
import cx from 'classnames'
import logoC from '../../../Assets/Images/CytrioLogo/c.png'
import redCircle from '../../../Assets/Images/CytrioLogo/red_circle.png'
import { SessionStorageKeyNames, LogoutStatus, Pages } from '../../Common/Enums/Common'
import VerifyCode from '../Register/VerifyCode'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import queryString from 'querystring'
import toastService from '../../Common/Services/Toaster'
import LoginForm from './VisitorLoginForm'
import SendCode from './VisitorSendCode'
import { Lock, LogIn, Mail } from 'react-feather'
import { I18n } from 'react-redux-i18n'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import ForgotPassword from '../ForgotPassword'
import ResetPassword from '../ResetPassword'
import Logo from 'Modules/Common/Components/Logo'
import Logo_LoginPage from 'Modules/Common/Components/Logo_Login'
import PoweredByCytrio from 'Modules/Common/Components/PoweredByCytrio'
import { cytrioPrivacyPolicyURL, cytrioTermsofServiceURL } from 'Modules/Common/Constants'
import VisitorLoginForm from './VisitorLoginForm'
import VisitorLoginPasswordForm from './VisitorLoginPassword'
import VisitorVerifyCode from './VisitorSendCode'
import VisitorRegisterForm from './VisitorRegisterForm'

const leftDiv = (page: Pages) => (
  <div className="row d-flex h-100 p-2 m-0">
    <div className="col-md-12">
      <div className="mt-5">
        <Logo_LoginPage />
      </div>
      {page !== Pages.ForgotPassword && page !== Pages.ResetPassword && (
        <div className={cx(styles.loginText, 'mt-5')}>
          <p className={page == Pages.VisitorLogin ? styles.activeLoginText : styles.inActiveLoginText}>
            <span>
              <Mail color="#FFFFFF" />
            </span>
            {I18n.t('register.vemail')}
          </p>
          <p
            className={
              page == Pages.VerifyCodePage || page == Pages.SendCodePage
                ? styles.activeLoginText
                : styles.inActiveLoginText
            }
          >
            <span>
              <LogIn color="#FFFFFF" />
            </span>
            {I18n.t('register.vsignin')}
          </p>
          <p
            className={
              page == Pages.VerifyCodePage || page == Pages.SendCodePage
                ? styles.activeLoginText
                : styles.inActiveLoginText
            }
          >
            <span>
              <Lock color="#FFFFFF" />
            </span>
            {I18n.t('register.verification_code')}
          </p>
        </div>
      )}
    </div>
    <div className="row d-flex align-items-end p-0 m-0 mb-3">
      <div className="col-md-12 d-flex align-items-end">
        <div>
          <p className={cx(styles.desc, 'pb-3')}>{I18n.t('login.data_captured')}</p>
          <PoweredByCytrio />
          <div className={cx(styles.footerDiv, 'col-md-12 p-0 d-flex')}>
            <a href={cytrioPrivacyPolicyURL} target="_blank" className="pr-2" rel="noreferrer">
              {I18n.t('register.privacy_policy')}
            </a>
            <div className={styles.verticle}></div>
            <a href={cytrioTermsofServiceURL} target="_blank" rel="noreferrer">
              {I18n.t('sidebar.terms_of_service')}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

interface LoginProps {
  page: Pages
}

const VisitorLogin = ({ page }: LoginProps) => {
  const userDataSessionStorage = SessionStorage.get(SessionStorageKeyNames.UserData)
  const userDataParsed = userDataSessionStorage && JSON.parse(userDataSessionStorage)
  const location = useLocation()
  const history = useHistory()
  let search = useLocation().search

  useEffect(() => {
    const { status } = queryString.parse(location.search.substr(1))
    if (
      status &&
      ((Array.isArray(status) && status[0] && status[0].toLowerCase() === LogoutStatus.status.toLowerCase()) ||
        (!Array.isArray(status) && status.toLowerCase() === LogoutStatus.status.toLowerCase()))
    ) {
      toastService.warn(I18n.t('common.toast.jwtTokenExpired'))
      history.push('/login')
    }
  }, [location.search])

  if (userDataParsed) {
    const query = new URLSearchParams(search).get('redirecturl')
    if (query != null) {
      history.push(`${query}`)
    } else {
      return <Redirect to="/" />
    }
  }
  let form
  if (page === Pages.VisitorLogin) {
    form = <VisitorLoginForm />
  }
  if (page == Pages.AuthenticateUser) {
    form = <VisitorRegisterForm hashCode={'a'} setHashCode={'setHashCode'} />
  }
  if (page == Pages.Passwordpage) {
    form = <VisitorLoginPasswordForm />
  }
  if (page == Pages.VerifyMFA) {
    form = <VisitorVerifyCode page={Pages.VisitorLogin} />
  }

  return (
    <div className={styles.outerDiv}>
      <div className={styles.mainDiv}>
        <div className={cx(styles.innerDiv_VisitorForm)}>
        <div className={`${(page === Pages.AuthenticateUser) ? styles.leftDiv_VisitorForm_register : styles.leftDiv_VisitorForm_login
              }`}>
            <img className={cx(styles.image, styles.imageLogin)} src={logoC} />
            <img className={cx(styles.image2, styles.image2Login)} src={redCircle} />
            {leftDiv(page)}
          </div>
          <div className={`${(page === Pages.AuthenticateUser) ? styles.rightDiv_VisitorForm_register : styles.rightDiv_VisitorForm_login
              }`}>{form}</div>
        </div>
      </div>
    </div>
  )
}

export default VisitorLogin
