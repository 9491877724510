import { register, verifyCode, sendCode, login, authToast, forgetPass, signupMessage } from './Auth/en'
import { banners } from './Banners/en'
import { common } from './Common/en'
import { consentLogs } from './ConsentLogs/en'
import { cookies } from './Cookies/en'
import { domains } from './Domains/en'
import { profileSettings } from './ProfileSettings/en'
import { requests } from './Requests/en'
import { settings } from './Settings/en'
import { subscribe } from './Subscribe/en'
import { subscription } from './Subscription/en'
import { tenant, shareCodeModal } from './Tenant/en'
import { addUser, addUserPermissions } from './User/en'
import { consntDashboard } from './Dashboard/en'
import { plans } from './Plans/en'

export const en = {
  header: {
    logout: 'Logout',
    settings: 'Profile Settings',
    subscription: 'Subscription',
  },
  sidebar: {
    trackingScripts: 'Tracking Scripts',
    dashboard: 'Dashboard',
    webistes: 'Websites',
    cookies: 'Cookies',
    banner: 'Banner',
    logs: 'Consent Logs',
    powered_by: 'Powered by',
    privacy_manager: 'Data Privacy Platform',
    privacy_center: 'Privacy Center',
    tenants: 'Tenants',
    consents: 'Consent',
    policies: 'Policies',
    users: 'Users',
    settings: 'Settings',
    requests: 'Requests',
    scanners: 'Scanners',
    system_settings: 'System Settings',
    about: 'About',
    help: 'Help',
    privacy_policy: 'Privacy Policy',
    t_and_c: 'Terms of Use',
    terms_of_service: 'Terms of Service',
    all_rights_reserved: 'CYTRIO, Inc. All Rights Reserved',
    cytrioInc: 'CYTRIO, Inc.',
    allRightsReserved: 'All Rights Reserved',
    collapse: 'Collapse',
    expand: 'Expand',
    branding: 'Branding',
    domains: 'Domains',
  },
  search_by: {
    tenant: 'Search by Organization Name',
    user: 'Search by First/Last Name, Email',
    datasource: 'Search by Type, Data Source Name',
    scanner: 'Search by Scanner Name, Deployed By',
    requests: 'Search by Keyword',
    idValidationReviewer: 'Search by Name',
    userdetails: 'Search User Details',
    auditLogsMainConsole: 'Search User Details',
    entity: 'Search by Keyword',
    businessPurpose: 'Search by Name',
    processingActivities: 'Search by Name',
    processingActivitiesDSEntity: 'Search by Keyword',
    risklisting: 'Search by Keyword',
  },
  auth_toast: authToast,
  login: login,
  send_code: sendCode,
  verify_code: verifyCode,
  register: register,
  common: common,
  tenant: tenant,
  profile_settings: profileSettings,
  forgetPass: forgetPass,
  requests: requests,
  settings: settings,
  user: { addUser },
  addUserPermissions: addUserPermissions,
  shareCodeModal: shareCodeModal,
  cookies: cookies,
  banners: banners,
  subscription: subscription,
  consentLogs: consentLogs,
  domains: domains,
  consentDashboard: consntDashboard,
  subscribe: subscribe,
  plans: plans,
  signupMessage: signupMessage,
}
