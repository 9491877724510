import { BreadCrumbMenuItem } from 'Modules/Common/Models'
import { BreadCrumbActions, ACTION_CLEAR_BREADCRUMB, ACTION_UPDATE_BREADCRUMB } from './BreadCrumb.actions'

export interface BreadCrumbReducerState {
  data: Array<BreadCrumbMenuItem>
}

export const initialState: BreadCrumbReducerState = { data: null }

export const BreadCrumbReducer = (
  state = initialState, // NOSONAR
  action: BreadCrumbActions
): BreadCrumbReducerState => {
  switch (action.type) {
    case ACTION_CLEAR_BREADCRUMB:
      return { data: null }
    case ACTION_UPDATE_BREADCRUMB:
      return { data: action.payload.data }
    default:
      return state
  }
}
