import React, { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { cx } from '@emotion/css'
import NestedDomains from './nested-domains'
import styles from './Domains.module.css'
import { ErrorMessage } from '@hookform/error-message'
import { I18n } from 'react-redux-i18n'
import { X } from 'react-feather'
import { fromUrl, parseDomain, ParseResultType } from 'parse-domain'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { InputGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import checkValidDomain from './checkValidDomain'
import { dispatchAddDomainValidated, dispatchVerifyingDomain } from 'Modules/Domains/Actions/domainList.actions'

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    {I18n.t('domains.upgradeAdditionalDomainText')}
  </Tooltip>
)

export default function Domains({
  control,
  register,
  formState,
  getValues,
  setValue,
  trigger,
  showAddDomainButton = true,
  isEditable = false,
  setShowLoader,
  showLoader,
  onDomainBooleanValueChange
}) {
  const domainLimit = useSelector((state: AppStateType) => state.TenantReducer.domainLimit)
  const domainsUsed = useSelector((state: AppStateType) => state.TenantReducer.domainsUsed)
  const licensePlan = useSelector((state: AppStateType) => state.TenantReducer.licensePlan)
  const domainsValidated = useSelector((state: AppStateType) => state.DomainsReducer.domainsValidated)
  const role = useSelector((state: AppStateType) => state.AuthReducer.userData?.roleDisplayName)
  const { selectedTenantDetails } = useSelector((state: AppStateType) => state.TenantReducer)
  const dispatch = useDispatch()

  const [showAddNewDomainTab, setShowAddNewDomainTab] = useState<boolean>(false)
  

  const { append, fields, remove } = useFieldArray({
    name: 'domains',
    control,
  })

  useEffect(() => {
    if (selectedTenantDetails && selectedTenantDetails?.tenant_company_url) {
      setShowAddNewDomainTab(false)
      fields.length > 0 && remove(fields.length - 1)
    } else {
      setShowAddNewDomainTab(true)
    }
  }, [selectedTenantDetails])


  function domainHasError(index: number) {
    return formState?.errors?.domains?.[index]?.domain_name
  }

  const hasNestedValues = (index: number): boolean => {
    return getValues().domains[index]?.sub_domains?.length >= 1
  }

  const AddDomainHeader = () => {
    return (
      <div className={cx('d-flex', styles.header)}>
        <div className={styles.domainName}>{I18n.t('tenant.addTenant.domainName')}</div>
        {/* <div> {I18n.t('tenant.addTenant.subscription')}</div> */}
      </div>
    )
  }

  const isValidSubdomain = (index: number) => {
    const parsedDomain = parseDomain(fromUrl(getValues().domains?.[index].domain_name))
    if (
      parsedDomain.type === ParseResultType.Invalid ||
      parsedDomain.type === ParseResultType.NotListed ||
      parsedDomain.type === ParseResultType.Reserved
    ) {
      return false
    }

    return true
  }

  const isDuplicateDomain = (value: string, index: number): boolean => {
    const domains = getValues().domains
    for (let i = 0; i < domains.length; i++) {
      if (fromUrl(domains[i].domain_name) === fromUrl(value) && i !== index) {
        return true
      }
    }

    return false
  }

  const revalidateDomain = async (value: string, index: number) => {
    const parsedDomain = parseDomain(fromUrl(value))
    let validateDomain
    dispatch(dispatchVerifyingDomain(true))
    validateDomain = await checkValidDomain(parsedDomain.hostname as string, index, setShowLoader)
    dispatch(dispatchVerifyingDomain(false))
    if (validateDomain === 'error') {
      domainHasError(index)
      onDomainBooleanValueChange(false);
        return I18n.t('Domain does not exist')
    } else {
      onDomainBooleanValueChange(true);
      dispatch(dispatchAddDomainValidated(value))
    }
    return undefined
  }

  const validate = async (value: string, index: number) => {

    const parsedDomain = parseDomain(fromUrl(value))
    if (!isValidSubdomain(index)) {
      return I18n.t('tenant.addTenant.invalidDomain')
    }

    if (isDuplicateDomain(value, index)) {
      return I18n.t('tenant.addTenant.duplicateDomain')
    }

    let validateDomain
    if (!domainsValidated.includes(value)) {
      dispatch(dispatchVerifyingDomain(true))

      validateDomain = await checkValidDomain(parsedDomain.hostname as string, index, setShowLoader)

      dispatch(dispatchVerifyingDomain(false))

      if (validateDomain === 'error') {
        onDomainBooleanValueChange(false);
          return I18n.t('Domain does not exist')
      } else {
        onDomainBooleanValueChange(true);
        dispatch(dispatchAddDomainValidated(value))
      }
    }

    return undefined
  }

  const isAddDomainDisabled = role !== 'Service Provider' && domainLimit !== 0 && domainsUsed >= domainLimit
  const isFreePlan = licensePlan === 'free_plan'

  const showDefaultDomain = () => {
    return fields.map((field: Record<string, string>, index: number) => (
      <div key={field.id} className={styles.addDomainContainer}>
        <AddDomainHeader />
        <div className="row m-0 p-0">
          <div className={cx(styles.inputContainer, styles.minHeightBox, 'd-flex')}>
            <InputGroup.Text id="basic-addon3" className={cx('form-control-InputGroup-text', styles.inputGroup)}>
              https://
            </InputGroup.Text>
            <div className="d-flex flex-column w-100">
              <input
                x-automation-id="domain_name"
                key={field.id}
                placeholder="e.g. cytrio.com "
                defaultValue={field.domain_name}
                disabled={isFreePlan}
                className={`${styles.input} form-control ${domainHasError(index) ? 'is-invalid' : ''}`}
                {...register(`domains.${index}.domain_name`, {
                  required: I18n.t('tenant.addTenant.companyDomainRequired'),
                  validate: (value: string) => {
                    return validate(value, index)
                  },
                  setValueAs: v => v?.replace(/(^\w+:|^)\/\//, '')?.replace(/^www\./, ''),
                })}
                onBlur={() => {
                  trigger()
                  return revalidateDomain(getValues().domains?.[index].domain_name, index)
                }}
              />
              <ErrorMessage
                className={cx('invalid-feedback', hasNestedValues(index) && `${styles.subdomainError}`)}
                name={`domains[${index}].domain_name`}
                as="div"
                errors={formState?.errors}
              />
            </div>
            <input
              x-automation-id="sub_domain_id"
              defaultValue={field.sub_domain_name}
              key={field.id}
              className={styles.subdomainId}
              {...register(`domains.${index}.domain_id`)}
            />
          </div>

          {fields.length > 1 ? (
            <div className={styles.removeIconContainer} onClick={() => remove(index)}>
              <X className={cx(styles.removeIcon, styles.removeIconDomain)}></X>
            </div>
          ) : null}

          <div className={styles.verifyingDiv}>
            <div>
              {!showLoader?.isSubdomain && showLoader?.loading && showLoader?.index === index ? (
                <div className={cx('d-flex', 'align-item-center', styles.verifyingDivLoader)}>
                  <Spinner animation={'border'} role="status" size="sm" className="mr-2" style={{ color: '#c69400' }} />
                  <div className={styles.verifyingText}>{I18n.t('tenant.verifyingDomain')}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <NestedDomains
          nestIndex={index}
          {...{ control, register }}
          formState={formState}
          getValues={getValues}
          isEditable={isEditable}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          trigger={trigger}
        />
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <div className={cx('d-flex justify-content-end', styles.addDomainButton)}>
        {showAddDomainButton &&
          (isAddDomainDisabled && domainLimit <= 0 ? (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <button
                onClick={e => {
                  e.preventDefault()
                  append({
                    domain_name: '',
                    is_consent_management_subcriptions_enable: true,
                    is_privacy_center_subcriptions_enable: false,
                    sub_domains: [],
                  })
                }}
                disabled={isAddDomainDisabled}
              >
                {I18n.t('tenant.addTenant.addDomain')}
              </button>
            </OverlayTrigger>
          ) : (
            <button
              onClick={e => {
                e.preventDefault()
                append({
                  domain_name: '',
                  is_consent_management_subcriptions_enable: true,
                  is_privacy_center_subcriptions_enable: false,
                  sub_domains: [],
                })
                setShowAddNewDomainTab(true)
                // fields.length > 1 && remove(fields.length - 1)
              }}
              disabled={isAddDomainDisabled}
            >
              {I18n.t('tenant.addTenant.addDomain')}
            </button>
          ))}
      </div>

      {selectedTenantDetails && selectedTenantDetails?.tenant_company_url ? (
        <div className={styles.addDomainContainer}>
          <AddDomainHeader />
          <div className="row m-0 p-0">
            <div className={cx(styles.inputContainer, styles.minHeightBox, 'd-flex')}>
              <InputGroup.Text id="basic-addon3" className={cx('form-control-InputGroup-text', styles.inputGroup)}>
                https://
              </InputGroup.Text>
              <div className="d-flex flex-column w-100">
                <input
                  x-automation-id="domain_name"
                  placeholder="e.g. cytrio.com "
                  defaultValue={selectedTenantDetails?.tenant_company_url}
                  disabled={true}
                  className={(styles.input, 'form-control')}
                  onBlur={() => {
                    trigger()
                  }}
                  {...register(`domain_name_old`, {
                    validate: (value: string) => {
                      //return validate(value, 0)
                    },
                    setValueAs: v => v?.replace(/(^\w+:|^)\/\//, '')?.replace(/^www\./, ''),
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showAddNewDomainTab && <>{showDefaultDomain()}</>}
    </div>
  )
}
