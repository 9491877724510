import { http } from '../../../Utils/AxiosWrapper'
import { requests } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { handleDownloadFile } from 'Utils/downloadFileUtils'
import { AxiosResponse } from 'axios'
import { RequestExportPayload, ReportUpload, ReportResponse, TenantDsarReports } from '../Models/request.model'

interface RequestMangement {
  closeRequest(requestId: number): Promise<ApiResponse<string>>
  exportRequest(payload: RequestExportPayload): Promise<ApiResponse<string>>
}

class RequestService implements RequestMangement {
  async closeRequest(requestId: number): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.put(requests.closeRequest(requestId), {})
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }

  async exportRequest(payload: RequestExportPayload): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const response: AxiosResponse = await http.post(requests.exportRequests, payload, {
        responseType: 'blob',
      })
      result = handleDownloadFile(response)
    } catch (error: any) {
      const data = await error?.response?.data?.text()
      result = {
        success: false,
        message: JSON.parse(data)?.message,
        statusCode: JSON.parse(data)?.code,
      }
    }
    return result
  }


  async getDSARReports(tenantId: string, dsar_id: number): Promise<ApiResponse<any>> {
    let response: ApiResponse<any>
    try {
      const { data }: any = await http.get(requests.getDSARReports(tenantId, dsar_id))
      response = {
        success: true,
        message: data.message,
        code: data.code,
        data: data.data.dsar_reports,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  async updateDsarReports(
    request: TenantDsarReports,
    ProcessingActivityId: number,
    tenantId: string,
    isDelete: boolean,
  ): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.request({
        method: isDelete ? 'DELETE' : 'POST',
        url: requests.updateDSARReport(ProcessingActivityId, tenantId),
        data: request,
      })
      response = {
        success: true,
        message: data.message,
        code: data.code,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }


  async uploadReport(inputData: any, tenantId:string, dsar_id:number): Promise<ReportResponse> {
    let response: ReportResponse
    try {
      const { data }: any = await http.post(
        requests.uploadReport(
          tenantId,
          dsar_id,
        ),
        inputData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
        id: '',
      }
    } catch (error: any) {
      if (error && error.response) {
        response = {
          success: false,
          message: error.response.data?.message,
          statusCode: error.response.data?.code,
          id: '',
        }
      } else {
        response = {
          success: false,
          message: '',
          statusCode: 400,
          id: '',
        }
      }
    }
    return response
  }


}

export default new RequestService()
