import { SessionStorage } from '../SessionStorageWrapper'
import { BroadcastChannelNames, SessionStorageKeyNames, BroadcastChannelMessages } from '../../Modules/Common/Enums'
import { BroadcastChannel } from 'broadcast-channel'

class LogOutAllTabs {
  logOutChannel: BroadcastChannel<string>
  constructor() {
    this.logOutChannel = new BroadcastChannel(BroadcastChannelNames.Logout)
  }

  public postMessage(message: BroadcastChannelMessages) {
    this.logOutChannel.postMessage(message)
  }

  public logOutAllTabs() {
    this.logOutChannel.onmessage = (evt: any) => {
      SessionStorage.removeKey(SessionStorageKeyNames.UserData)
      SessionStorage.removeKey(SessionStorageKeyNames.TempToken)
      SessionStorage.removeKey(SessionStorageKeyNames.TempTokenExpiry)
      window.location.href = window.location.origin + '/login'
      window.location.reload()
    }
  }
}

export default new LogOutAllTabs()
