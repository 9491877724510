import React, { useState, useRef, useEffect } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import styles from '../Layout.module.css'
import cx from 'classnames'
import { MenuItem } from './NavItems'

type Props = {
  isShown: boolean
  children: any
  pages: Array<MenuItem>
  activePath: string
  history: any
}

function MenuPopover(props: Props) {
  const { activePath, pages, children, isShown, history } = props

  const component = pages?.map(({ label, route }, index) => (
    <div className={styles.menuContainer}>
      <div
        onClick={() => {
          history.push(route)
        }}
        id={'submenu-item-' + index}
        className={cx(activePath.includes(route) ? styles.activeMenuItem : '', styles.menuItem)}
      >
        {label}
      </div>
    </div>
  ))

  const [showPopover, setShowPopover] = useState(false)
  const childNode = useRef(null)
  let setTimeoutConst = null

  useEffect(() => {
    return () => {
      if (setTimeoutConst) {
        clearTimeout(setTimeoutConst)
      }
    }
  })

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true)
    }, 0)
  }

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst)
    setShowPopover(false)
  }

  const displayChild = React.Children.map(children, child =>
    React.cloneElement(child, {
      onMouseOver: handleMouseEnter,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      ref: node => {
        childNode.current = node
        const { ref } = child
        if (typeof ref === 'function') {
          ref(node)
        }
      },
    })
  )[0]

  if (!isShown) {
    return displayChild
  }

  return (
    <>
      {displayChild}
      <Overlay show={showPopover} placement="right-start" target={childNode}>
        <Popover
          onMouseEnter={() => {
            setShowPopover(true)
          }}
          onMouseLeave={handleMouseLeave}
          id="popover"
          className="sidebar-menu-popover"
        >
          {component}
        </Popover>
      </Overlay>
    </>
  )
}

export default MenuPopover
