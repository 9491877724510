import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight, Mail } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppStateType } from '../../../Store/reducers'
import { emailRegex } from 'Modules/Common/Constants'
import { actionForgotPasswordInfo, dispatchForgotPassword } from '../Actions/forget-password.actions'
import SentPopup from './SentPopup'
import ErrorBoundary from 'Modules/Common/Components/ErrorBoundary'

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)

  const forgotPassword = useSelector((state: AppStateType) => state.AuthReducer.forgotPassword)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isShowPopup, setIsShowPopup] = useState(false)
  useEffect(() => {
    if (forgotPassword?.success) {
      setIsShowPopup(true)
      dispatch(dispatchForgotPassword())
    }
  }, [forgotPassword])
  if (isAuthenticated) {
    history.push('/')
  }
  const onSubmit = (data: any) => {
    dispatch(actionForgotPasswordInfo(data.email))
  }

  const onBackToLogin = () => {
    history.push('/login')
  }
  const onPopupClose = () => {
    setIsShowPopup(false)
    setValue('email', '')
  }

  return (
    <ErrorBoundary>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="row p-0 m-0 m-5">
          <div className="col-md-12">
            <p className={styles.createAccountText}>{I18n.t('forgetPass.forgetPass')}</p>
          </div>
          <div className="col-md-12">
            <p className={styles.formDescription}>{I18n.t('forgetPass.forgetPassDescription')}</p>
          </div>
          <div className="row p-0 m-0 w-100">
            <div className="col-md-12">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('login.email')}</Form.Label>
                <Form.Control
                  className={cx(styles.formInput, styles.loginFormInput)}
                  x-automation-id="email"
                  id="email"
                  type="text"
                  {...register('email', {
                    required: I18n.t('register.email_required'),
                    pattern: {
                      value: emailRegex,
                      message: I18n.t('register.email_required'),
                    },
                  })}
                  placeholder={I18n.t('login.email')}
                  isInvalid={errors?.email}
                />
                <span className={styles.loginFormInputIcon}>
                  <Mail color="#000000" size={20} />
                </span>
                <Form.Control.Feedback type="invalid" className="m-0">
                  {errors?.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end pr-1 mt-3">
            <CancelButtonWithIcon
              type="button"
              text={I18n.t('forgetPass.backToLogin')}
              style={{ width: '100%' }}
              x-automation-id="back-btn"
              id="back-btn"
              onClick={onBackToLogin}
              className={styles.customButton}
            />
          </div>
          <div className="col-md-6 pl-1 mt-3">
            <ButtonWithIcon
              type="submit"
              text={I18n.t('common.send')}
              style={{ width: '100%' }}
              icon={<ArrowRight color="#FFFFFF" />}
              iconToRight={true}
              x-automation-id="next-btn"
              id="next-btn"
              className={styles.customButton}
            />
          </div>
        </div>
        <SentPopup isShowPopup={isShowPopup} setIsShowPopup={onPopupClose} />
      </Form>
    </ErrorBoundary>
  )
}

export default React.memo(ForgotPassword)
