export const subscription = {
  subscription: 'Subscription',
  noSubscriptionExits: 'No subscription exits',
  cancelSubscription: 'Cancel Subscription',
  keepSubscription: 'No, keep subscription',
  subscribe: 'Subscribe',
  cancelFreeTrialMessage: 'Are you sure you would like to cancel your free trial?',
  cancelFreePlanMessage: 'Are you sure you would like to cancel your free plan?',
  cancelProfessionalPlanMessage: 'Are you sure you would like to cancel your professional plan?',
  cancelEnterprisePlanMessage: 'Are you sure you would like to cancel your enterprise plan?',
  cancelFreePlansSubMessage:
    ' Clicking on Confirm below shall remove your account and the associated data from the system.',
  cancelPaidPlansSubMessage:
    ' Clicking on Confirm below shall cancel your current subscription.  Your account and the associated data from the system shall be removed after 15 days.',
  freeTrialTextAreaLabel: 'Please let us know the reason for cancelling your free trial and any suggestions for us.',
  freePlanTextAreaLabel: 'Please let us know the reason for cancelling your free plan and any suggestions for us.',
  professionalPlanTextAreaLabel:
    'Please let us know the reason for cancelling your professional plan and any suggestions for us.',
  enterprisePlanTextAreaLabel:
    'Please let us know the reason for cancelling your enterprise plan and any suggestions for us.',
  reason: 'Reason',
  cancelFreeTrial: 'Cancel Free Trial',
  cancelFreePlan: 'Cancel Free Plan',
  cancelProfessionalPlan: 'Cancel Professional Plan',
  cancelEnterprisePlan: 'Cancel Enterprise Plan',
  daysLeft: 'days left',
  freeTrialEnded: 'Free trial ended',
  upgrade: 'Upgrade',
  downgrade: 'Downgrade',
  changePlan: 'Change Plan',
  paymentSuccessMessage: 'You are now subscribed to the Professional plan',
}
