import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import service from '../Services/request.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'

export const ACTION_CLOSE_REQUEST = 'ACTION_CLOSE_REQUEST'
export const ACTION_CLOSE_REQUEST_SUCCESS = 'ACTION_CLOSE_REQUEST_SUCCESS'
export const ACTION_CLOSE_REQUEST_FAILURE = 'ACTION_CLOSE_REQUEST_FAILURE'
export const ACTION_CLOSE_REQUEST_CLEAR = 'ACTION_CLOSE_REQUEST_CLEAR'

export interface ActionCloseRequest extends Action {
  type: typeof ACTION_CLOSE_REQUEST
}

export interface ActionCloseRequestClear extends Action {
  type: typeof ACTION_CLOSE_REQUEST_CLEAR
}

export interface ActionCloseRequestSuccess extends Action {
  type: typeof ACTION_CLOSE_REQUEST_SUCCESS
  payload: {
    data: string
  }
}

export interface ActionCloseRequestFailure extends Action {
  type: typeof ACTION_CLOSE_REQUEST_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export type CloseRequestActions =
  | ActionCloseRequest
  | ActionCloseRequestFailure
  | ActionCloseRequestSuccess
  | ActionCloseRequestClear

export const dispatchCloseRequestClear = (): ActionCloseRequestClear => ({
  type: ACTION_CLOSE_REQUEST_CLEAR,
})

const dispatchCloseRequestSuccess = (data: string): ActionCloseRequestSuccess => ({
  type: ACTION_CLOSE_REQUEST_SUCCESS,
  payload: {
    data,
  },
})

const dispatchCloseRequestFailure = (
  error: Error,
  success: boolean
): ActionCloseRequestFailure => ({
  type: ACTION_CLOSE_REQUEST_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionCloseRequest(
  requestId: number,
): (dispatch: Dispatch<CloseRequestActions>) => Promise<void> {
  return async (dispatch: Dispatch<CloseRequestActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.closeRequest(requestId)
      if (result && result.success) {
        toaster.success(I18n.t('requests.closeRequestSuccessMsg'))
        dispatch(dispatchCloseRequestSuccess(result?.message))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchCloseRequestFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchCloseRequestFailure(error, false))
      loaderStop(dispatch)
    }
  }
}
