import { PageParams } from 'Modules/Common/Models'
import {
  ACTION_GET_CONSENT_LOGS,
  ACTION_GET_CONSENT_LOGS_FAILURE,
  ACTION_GET_CONSENT_LOGS_SUCCESS,
  GetConsentLogsActions,
} from '../Actions/getConsentLogs.actions'
// import {
//   ACTION_EXPORT_CONSENTLOGS,
//   ACTION_EXPORT_CONSENTLOGS_FAILURE,
//   ACTION_EXPORT_CONSENTLOGS_SUCCESS,
//   ExportConsentLogsActions,
// } from '../Actions/export-ConsentLogs.actions'
import { PrivacyCenterConcentLog, WebsiteConsentLog } from '../Models/consentLogs.model'

export interface ConsentLogsReducerState {
  website: {
    dataList: Array<WebsiteConsentLog>
    totalRecords: number
    pageParams: PageParams
    activePage: number
  }
  privacy_center: {
    dataList: Array<PrivacyCenterConcentLog>
    totalRecords: number
    pageParams: PageParams
    activePage: number
  }
}

export const initialState: ConsentLogsReducerState = {
  website: {
    dataList: [],
    activePage: 1,
    pageParams: null,
    totalRecords: 0,
  },
  privacy_center: {
    dataList: [],
    activePage: 1,
    pageParams: null,
    totalRecords: 0,
  },
}

type Actions = GetConsentLogsActions

export const ConsentLogsReducer = (
  state = initialState, // NOSONAR
  action: Actions
): ConsentLogsReducerState => {
  switch (action.type) {
    case ACTION_GET_CONSENT_LOGS:
      return {
        ...state,
        [action.payload.consentCategory]: {
          ...state.website,
          pageParams: action.payload.pageParams,
        },
      }
    case ACTION_GET_CONSENT_LOGS_SUCCESS:
      return {
        ...state,
        [action.payload.consentCategory]: {
          ...state.website,
          dataList: action.payload.data,
          totalRecords: action.payload.totalRecords,
          activePage: action.payload.activePage,
        },
      }
    case ACTION_GET_CONSENT_LOGS_FAILURE:
      return {
        ...state,
        [action.payload.consentCategory]: {
          ...state.website,
          dataList: [],
          totalRecords: 0,
          activePage: 1,
        },
      }

    default:
      return state
  }
}
