import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/consentLogs.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { PageParams } from 'Modules/Common/Models'
import { ConsentLogsType, ConsentLogTab } from '../Models/consentLogs.model'

export const ACTION_GET_CONSENT_LOGS = 'ACTION_GET_CONSENT_LOGS'
export const ACTION_GET_CONSENT_LOGS_SUCCESS = 'ACTION_GET_CONSENT_LOGS_SUCCESS'
export const ACTION_GET_CONSENT_LOGS_FAILURE = 'ACTION_GET_CONSENT_LOGS_FAILURE'

export interface ActionGetConsentLogs extends Action {
  type: typeof ACTION_GET_CONSENT_LOGS
  payload: { pageParams: PageParams; consentCategory: ConsentLogTab }
}

export interface ActionGetConsentLogsSuccess extends Action {
  type: typeof ACTION_GET_CONSENT_LOGS_SUCCESS
  payload: { data: Array<any>; totalRecords: number; activePage: number; consentCategory: ConsentLogTab }
}

export interface ActionGetConsentLogsFailure extends Action {
  type: typeof ACTION_GET_CONSENT_LOGS_FAILURE
  payload: { errorMessage: string; consentCategory: ConsentLogTab }
}

export type GetConsentLogsActions = ActionGetConsentLogs | ActionGetConsentLogsFailure | ActionGetConsentLogsSuccess

export const dispatchGetConsentLogs = (
  pageParams: PageParams,
  consentCategory: ConsentLogTab
): ActionGetConsentLogs => ({
  type: ACTION_GET_CONSENT_LOGS,
  payload: { pageParams, consentCategory },
})

const dispatchGetConsentLogsSuccess = (
  data: Array<any>,
  totalRecords: number,
  activePage: number,
  consentCategory: ConsentLogTab
): ActionGetConsentLogsSuccess => ({
  type: ACTION_GET_CONSENT_LOGS_SUCCESS,
  payload: { data, totalRecords, activePage, consentCategory },
})

const dispatchGetConsentLogsFailure = (error: Error, consentCategory: ConsentLogTab): ActionGetConsentLogsFailure => ({
  type: ACTION_GET_CONSENT_LOGS_FAILURE,
  payload: { errorMessage: error.message, consentCategory },
})

export function actionGetConsentLogs(
  req: PageParams,
  additionalParams: ConsentLogsType,
  isLoader: boolean = true
): (dispatch: Dispatch<GetConsentLogsActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetConsentLogsActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getConsentLogs(req, additionalParams)
      if (result && result.success) {
        dispatch(
          dispatchGetConsentLogsSuccess(
            result.data,
            result.totalRecords,
            result.activePage,
            additionalParams.consent_category
          )
        )
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        }
        dispatch(
          dispatchGetConsentLogsFailure(
            new Error(result?.message || 'Something went wrong.'),
            additionalParams.consent_category
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetConsentLogsFailure(error, additionalParams.consent_category))
      loaderStop(dispatch)
    }
  }
}
