import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { MoreVertical } from 'react-feather'
import styles from './ActionDropdown.module.css'
import cx from 'classnames'

type ActionDropdownProps = {
  menuItems: Array<{
    label: string
    onClick: (event?: any) => void
    icon: JSX.Element
    id: string
    isDisabled?: boolean
    customClassName?: string
  }>
  indexKey: string
}

const CustomActionToggle = React.forwardRef(({ onClick, id }: any, ref: any) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    id={id}
  >
    <span className="cursorPointer actionItem actionItemMedium">
      <MoreVertical color="#222222" size="18px" />
    </span>
  </a>
))

const ActionDropdown = (props: ActionDropdownProps) => {
  const { indexKey, menuItems } = props
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomActionToggle} id={"action-dropdown-" + indexKey} />
      <Dropdown.Menu className={styles.actionMenu}>
        <span className={styles.caret}></span>
        {menuItems.map((item, index) => (
          <Dropdown.Item
            disabled={item?.isDisabled}
            key={index}
            eventKey={index?.toString()}
            id={item.id + indexKey}
            onClick={item.onClick}
            className={styles.actionMenuItem}
          >
            <div className={cx(styles.actionMenuItemDivBorder, styles.actionMenuItemDiv, item.customClassName)}>
              <span className={cx(styles.settingIconCircel, 'mr-2')}>{item.icon}</span>
              {item.label}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionDropdown
