import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../Auth/Login'
import VisitorLogin from '../Auth/VisitorLogin'
import Register from '../Auth/Register'
import Layout from '../Layout'
import SignupPartner from 'Modules/Auth/SignupPartner'
import { Pages } from '../Common/Enums/Common'
import Impersonate from 'Modules/Auth/Impersonate'

const Home = () => {
  return (
    <div className="App" x-automation-id="homeApp">
      <Switch>
        <Route path="/visitorlogin" exact>
          <VisitorLogin page={Pages.VisitorLogin} />
        </Route>
        <Route path="/visitorlogin/authenticateUser" exact>
          <VisitorLogin page={Pages.AuthenticateUser} />
        </Route>
        <Route path="/visitorlogin/password" exact>
          <VisitorLogin page={Pages.Passwordpage} />
        </Route>
        <Route path="/visitorlogin/verifyVisitorCode" exact>
          <VisitorLogin page={Pages.VerifyMFA} />
        </Route>
        <Route path="/login" exact>
          <Login page={Pages.LoginPage} />
        </Route>
        <Route path="/login/sendCode">
          <Login page={Pages.SendCodePage} />
        </Route>
        <Route path="/login/verifyCode">
          <Login page={Pages.VerifyCodePage} />
        </Route>
        <Route path="/forgotpassword">
          <Login page={Pages.ForgotPassword} />
        </Route>
        <Route path="/resetpassword">
          <Login page={Pages.ResetPassword} />
        </Route>
        <Route path="/register" exact>
          <Register page={Pages.RegisterPage} />
        </Route>
        <Route path="/register/verifyCode">
          <Register page={Pages.VerifyCodePage} />
        </Route>
        <Route path="/signup" exact>
          <SignupPartner page={Pages.SignupPage} />
        </Route>
        <Route path="/signup/verifyCode" exact>
          <SignupPartner page={Pages.VerifyCodePage} />
        </Route>
        <Route path="/sp/impersonate">
          <Impersonate />
        </Route>
        <Route path="/">
          <Layout />
        </Route>
      </Switch>
    </div>
  )
}

export default Home
