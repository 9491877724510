import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import { AddUserList } from '../Models'
import { ApiResponse } from 'Modules/Common/Models'
import service from '../Service/user.service'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import toaster from 'Modules/Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_ADDUSERS = 'ACTION_ADDUSERS'
export const ACTION_ADDUSERS_SUCCESS = 'ACTION_ADDUSERS_SUCCESS'
export const ACTION_ADDUSERS_FAILURE = 'ACTION_ADDUSERS_FAILURE'
export const ACTION_ADDUSERS_RESET = 'ACTION_ADDUSERS_RESET'
export const ACTION_ADDUSERS_CONFLICT = 'ACTION_ADDUSERS_CONFLICT'

export interface ActionAddUsers extends Action {
  type: typeof ACTION_ADDUSERS
  payload: {}
}

export interface ActionAddUsersConflict extends Action {
  type: typeof ACTION_ADDUSERS_CONFLICT
  payload: { emails: Array<string> }
}

export interface ActionAddUsersSuccess extends Action {
  type: typeof ACTION_ADDUSERS_SUCCESS
  payload: { data: ApiResponse<string[]> }
}

export interface ActionAddUsersFailure extends Action {
  type: typeof ACTION_ADDUSERS_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionAddUsersReset extends Action {
  type: typeof ACTION_ADDUSERS_RESET
  payload: { success: boolean }
}

export type AddUsersActions =
  | ActionAddUsers
  | ActionAddUsersSuccess
  | ActionAddUsersFailure
  | ActionAddUsersReset
  | ActionAddUsersConflict

const dispatchAddUsersSuccess = (data: ApiResponse<string[]>): ActionAddUsersSuccess => ({
  type: ACTION_ADDUSERS_SUCCESS,
  payload: { data },
})

const dispatchAddUsersFailure = (err: Error, success: false): ActionAddUsersFailure => ({
  type: ACTION_ADDUSERS_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

const dispatchAddUsersConflict = (emails: Array<string>): ActionAddUsersConflict => ({
  type: ACTION_ADDUSERS_CONFLICT,
  payload: { emails },
})

const dispatchAddUsers = (): ActionAddUsers => ({
  type: ACTION_ADDUSERS,
  payload: {},
})

export const dispatchAddUsersReset = (): ActionAddUsersReset => ({
  type: ACTION_ADDUSERS_RESET,
  payload: {
    success: false,
  },
})

export function actionAddusers(users: AddUserList): (dispatch: Dispatch<AddUsersActions>) => Promise<void> {
  return async (dispatch: Dispatch<AddUsersActions>) => {
    dispatch(dispatchAddUsers())
    try {
      loaderStart(dispatch)
      const result = await service.addUsers(users)
      if (result.success) {
        dispatch(dispatchAddUsersSuccess(result))
        toaster.success(I18n.t('user.addUser.usersCreatedSuccess'))
      } else {
        if (result.statusCode == ResponseCodes.Conflicts) {
          toaster.error(I18n.t('user.addUser.emailAlready'))
          dispatch(dispatchAddUsersConflict(result.data))
        } else {
          toaster.error(I18n.t('user.addUser.erroWhileCreatingUsers'))
          dispatch(dispatchAddUsersFailure(new Error(result.message), false))
        }
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchAddUsersFailure(err, false))
      loaderStop(dispatch)
    }
  }
}
