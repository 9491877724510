import React, { useState, useEffect } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight } from 'react-feather'
import ButtonWithIcon from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actionSignupProcess } from '../Actions/register.actions'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'querystring'
import convertToString from '../../../Utils/queryFormat'
import { AppStateType } from '../../../Store/reducers'
import { URLValidation, cytrioTermsofServiceURL, emailRegex } from 'Modules/Common/Constants'
import { components, SingleValueProps } from 'react-select'
import { LocationDropdownModel } from 'Modules/Common/Models'
import { getLocationDropdownAction } from 'Modules/Common/Services/Location/location.actions'
import * as Icons from 'Assets/Images/Icons/Flags'
import ReCAPTCHA from 'react-google-recaptcha'
import PasswordInput, { validatePasswordInput } from 'Modules/Common/Components/PasswordInput'
import Select from 'react-select'
import { actionScanTenantDomain } from 'Modules/Domains/Actions/domainList.actions'

const SingleValue = ({ children, ...props }: SingleValueProps<LocationDropdownModel>) => {
  return (
    <components.SingleValue {...props}>
      {children}
      {Icons && Icons[props?.data?.iso] && (
        <img
          style={{
            width: '18px',
            height: '18px',
            marginLeft: '10px',
          }}
          src={Icons[props?.data?.iso]}
        />
      )}
    </components.SingleValue>
  )
}
const SignupForm = ({ setHashCode, hashCode }) => {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { userData, verifyEmail } = useSelector((state: AppStateType) => state.AuthReducer)
  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)
  const getLocations = useSelector(
    (state: AppStateType) => ({ ...state.LocationReducer.locationDropdown }),
    shallowEqual
  )

  const [locationsDDL, setLocationsDDL] = useState<Array<LocationDropdownModel>>([])
  const [countryMobileCodeList, setCountryMobileCodeList] = useState<Array<LocationDropdownModel>>([])
  const [isSubmitted, setIsSubmitted] = useState(false) // added to fix jerk issue on submit
  const [url, setUrl] = useState('https://');

  const handleChange = (e) => {
    setUrl(e.target.value);
};

const handleClick = () => {
    if (!url.startsWith('https://')) {
        setUrl('https://');
    }
};

  useEffect(() => {
    const { code: auth_code } = queryString.parse(location?.search?.substr(1))
    setHashCode(convertToString(auth_code))
    setValue('auth_code', auth_code)
  }, [location?.search, setHashCode, history])

  
  useEffect(() => {
    let countryItem = locationsDDL.find(item => item?.value === 'USA')
    setValue('country', countryItem)

  }, [locationsDDL])

  useEffect(() => {
    if (getLocations.data) {
      let countryList = getLocations.data.map(countryItem => {
        return {
          value: countryItem?.value,
          label: countryItem?.label,
          countryMobileCode: countryItem?.countryMobileCode,
          iso: countryItem?.iso,
        }
      })

      let countryMobileCodeList = getLocations.data.map(countryItem => {
        return {
          value: countryItem?.countryMobileCode,
          label: countryItem?.countryMobileCode,
          iso: countryItem?.iso,
        }
      })
      setCountryMobileCodeList(countryMobileCodeList)
      setLocationsDDL(countryList)
    } else {
      dispatch(getLocationDropdownAction())
    }
  }, [getLocations])


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionScanTenantDomain(verifyEmail))
      history.push('/signup/verifyCode')
    }
  }, [isAuthenticated, history, verifyEmail, dispatch])


  const onSubmit = (data: any) => {
    setIsSubmitted(true)
    dispatch(
      actionSignupProcess({
        ...data,
        country: data && data.country && data.country.value ? data.country.value : '',
      })
    )
  }
  const onCancelClick = () => {
    history.push('/login')
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('auth_code')} />
      <div className="row p-0 m-0 mt-3 pl-2 pr-2">
        <div className="row p-0 m-0 w-100">
          <div className="col-md-6 d-flex align-items-center">
            <p className={cx(styles.createAccountText, 'mb-1')}>{I18n.t('signupMessage.signup')}</p>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            {/* <p className={styles.alreadyRegisteredText}>
              {I18n.t('register.already_registered')}{' '}
              <Link x-automation-id="sign-in-link" id="sign-in-link" to="/login" className={styles.linkText}>
                {I18n.t('register.sign_in')}
              </Link>
            </p> */}
          </div>
        </div>
        <div className="row p-0 m-0 w-100">
          <div className="col-md-6">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('register.company_name')}
              </Form.Label>
              <Form.Control
                className={styles.formInput}
                type="text"
                x-automation-id="company-name"
                id="company-name"
                {...register('companyName', { required: I18n.t('register.company_name_required') })}
                placeholder={I18n.t('register.company_name')}
                isInvalid={errors?.companyName}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.companyName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="col-md-6 pl-0">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('register.companyHeadquarters')}
              </Form.Label>
              <Controller
                name="country"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      placeholder={I18n.t('register.companyHeadquarters')}
                      x-automation-id="ddlLocation"
                      id="ddlLocation"
                      classNamePrefix="react-select"
                      className={`${styles.companySelect} react-select-phone_code p-0 ${
                        errors?.country ? 'invalid-ddl' : ''
                      }`}
                      {...field}
                      isClearable={false}
                      styles={{
                        control: p => ({
                          ...p,
                          border: errors?.country ? '1px solid red !important' : '',
                        }),
                      }}
                      options={locationsDDL}
                    />
                  )
                }}
                rules={{
                  required: true,
                }}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.country && I18n.t('register.phone_required')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <div className="row p-0 m-0 w-100">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.cpmpanyurl')}</Form.Label>
              <Form.Control
                className={styles.formInput}
                type="text"
                x-automation-id="companyurl"
                id="companyurl"
                {...register('companyurl', {
                  required: I18n.t('register.company_url_required'),
                  pattern: {
                    value: URLValidation,
                    message: I18n.t('register.company_url_validation'),
                  },
                })}
                placeholder={('https://www.yourdomain.com')}
                isInvalid={errors?.companyurl}
                value={url}
                onChange={handleChange}
                onClick={handleClick}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.companyurl?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <div className="row p-0 m-0 w-100">
          <div className="col-md-6">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('register.first_name')}
              </Form.Label>
              <Form.Control
                className={styles.formInput}
                type="text"
                x-automation-id="first-name"
                id="first-name"
                {...register('firstName', { required: I18n.t('register.first_name_required') })}
                placeholder={I18n.t('register.first_name')}
                isInvalid={errors?.firstName}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-md-6 pl-0">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.last_name')}</Form.Label>
              <Form.Control
                className={styles.formInput}
                type="text"
                x-automation-id="last-name"
                id="last-name"
                {...register('lastName', { required: I18n.t('register.last_name_required') })}
                placeholder={I18n.t('register.last_name')}
                isInvalid={errors?.lastName}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row p-0 m-0 w-100">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.email')}</Form.Label>
              <Form.Control
                className={styles.formInput}
                type="text"
                x-automation-id="email"
                id="email"
                {...register('email', {
                  required: I18n.t('register.email_required'),
                  pattern: {
                    value: emailRegex,
                    message: I18n.t('register.email_required'),
                  },
                })}
                placeholder={I18n.t('register.email')}
                isInvalid={errors?.email}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* <div className="col-md-6 pl-0">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.phone')}</Form.Label>
              <Form.Row className="p-0 m-0">
                <Controller
                  name="phone_code"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <Select
                        x-automation-id="ddlCountryLocationCode"
                        components={{ SingleValue }}
                        classNamePrefix="react-select"
                        className={`${styles.mobileCodeSelect} react-select-phone_code p-0 ${
                          errors.phone || errors.countryMobileCode ? 'invalid-ddl' : ''
                        }`}
                        {...field}
                        isClearable={false}
                        styles={{
                          control: p => ({
                            ...p,
                            border: errors.phone ? '1px solid red;' : '',
                          }),
                        }}
                        options={countryMobileCodeList}
                      />
                    )
                  }}
                />
                <Form.Control
                  className={cx(styles.formInput, styles.phoneNumberInput)}
                  type="text"
                  x-automation-id="phone"
                  id="phone"
                  {...register('phone', { required: I18n.t('register.phone_required') })}
                  placeholder={I18n.t('register.phone')}
                  isInvalid={errors?.phone || errors?.phone_code}
                />{' '}
                <Form.Control.Feedback type="invalid" className="m-0">
                  {(errors?.phone || errors?.phone_code) && I18n.t('register.phone_required')}
                </Form.Control.Feedback>
              </Form.Row>
            </Form.Group>
          </div> */}
        </div>
        <div className="row p-0 m-0 w-100">
          <div className="col-md-6">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.password')}</Form.Label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: I18n.t('register.password_required'),
                  validate: {
                    valid: () =>
                      Object.values(validatePasswordInput(getValues('password')))?.every(e => e) ||
                      I18n.t('register.password_required'),
                  },
                }}
                render={({ field }) => {
                  return (
                    <PasswordInput
                      className={styles.formInput}
                      x-automation-id="password"
                      id="password"
                      {...field}
                      value={field?.value || ''}
                      placeholder={I18n.t('register.password')}
                      isInvalid={errors.password}
                      getValues={() => getValues('password')}
                    />
                  )
                }}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.password?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-md-6 pl-0">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('register.confirm_password')}
              </Form.Label>
              <Form.Control
                className={styles.formInput}
                type="password"
                x-automation-id="confirm-password"
                id="confirm-password"
                {...register('confirmPassword', {
                  required: I18n.t('register.confirm_password_required'),
                  validate: {
                    positive: v => {
                      const p = getValues('password')
                      return p == v || I18n.t('register.confirm_password_not_match')
                    },
                  },
                })}
                placeholder={I18n.t('register.confirm_password')}
                isInvalid={errors?.confirmPassword}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.confirmPassword?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <div className="row p-0 m-0 mt-2 mb-2 w-100">
          <div className={cx(styles.columnAlignCenter, 'col-lg-6 col-md-12 mt-2')}>
            <div className="row w-100 m-0 p-0 d-flex align-items-center ml-2">
              <input
                type="checkbox"
                x-automation-id="terms-agree-checkbox"
                id="terms-agree-checkbox"
                className={styles.checkbox}
                {...register('agree', {
                  required: I18n.t('register.terms_required'),
                })}
              />
              <label className={cx(styles.alreadyRegisteredText, 'ml-1')}>
                {I18n.t('register.agree')}{' '}
                <a
                  x-automation-id="terms-agree-link"
                  id="terms-agree-link"
                  href={cytrioTermsofServiceURL}
                  target="_blank"
                  className={styles.linkText}
                  rel="noreferrer"
                >
                  {I18n.t('register.terms')}
                </a>
              </label>
            </div>
            <div className="row w-100 m-0 p-0 ml-2">
              {errors.agree?.message && (
                <span className="invalid-feedback" style={{ display: 'block' }}>
                  {errors.agree?.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 recaptcha-wrapper pl-0">
            <Controller
              name="captchaCode"
              control={control}
              rules={
                process.env.REACT_APP_DISABLE_MC_CAPTCHA === 'true' ? {} : { required: 'register.captcha_required' }
              }
              render={({ field }) => {
                return (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHAV2_SITEKEY}
                    onChange={value => {
                      field.onChange(value)
                    }}
                    onExpired={() => {
                      field.onChange('')
                    }}
                  />
                )
              }}
            />
            <Form.Control.Feedback type="invalid" className="m-0 d-block">
              {I18n.t(errors?.captchaCode?.message || '')}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="row p-0 m-0 mt-3 w-100 d-flex justify-content-center">
          <div className="col-md-4 pl-0">
            <ButtonWithIcon
              type="submit"
              text={I18n.t('common.saveAndNext')}
              style={{ width: '100%' }}
              x-automation-id="next-btn"
              id="next-btn"
              icon={<ArrowRight color="#FFFFFF" size={20} />}
              iconToRight={true}
              className={styles.customButton}
            />
          </div>
        </div>
        <div className="row w-100 mt-3 mb-2 d-flex justify-content-center">
          <p className={styles.alreadyRegisteredText}>
            {I18n.t('register.trouble_sign_up')}{' '}
            <a
              href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
              x-automation-id="contact-admin"
              id="contact-admin"
              className={styles.linkText}
            >
              {I18n.t('register.contact_admin')}
            </a>
          </p>
        </div>
      </div>
    </Form>
  )
}

export default SignupForm
