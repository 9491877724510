import { http } from '../../../Utils/AxiosWrapper'
import { PCHTMLCodeModel } from '../Models/tenant.models'
import { tenantManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'

interface PCHTMLCodeService {
  getPCHTMLCode(tenantId: string, domainId: string): Promise<ApiResponse<PCHTMLCodeModel>>
  updatePCHTMLCode(data: PCHTMLCodeModel, tenantId: string, domainId:string, isReset: boolean): Promise<ApiResponse<string>>
}
class PCHTMLCodeServiceDefault implements PCHTMLCodeService {
  async getPCHTMLCode(tenantId: string | number, domainId: string | number,): Promise<ApiResponse<PCHTMLCodeModel>> {
    let result: ApiResponse<PCHTMLCodeModel>
    try {
      const { data } = await http.get(tenantManagement.PCHTMLCode(tenantId, domainId))
      result = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: data?.data,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async updatePCHTMLCode(inputData: PCHTMLCodeModel, tenantId: string, domainId: string, isReset: boolean): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const params = {
        reset: isReset ? '1' : '0',
      }
      const { data }: any = await http.put(tenantManagement.PCHTMLCode(tenantId, domainId), inputData, { params })
      result = {
        success: true,
        message: data?.message,
        statusCode: data?.code,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }
}

export default new PCHTMLCodeServiceDefault()
