import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

// Actual reducer from library not working
// import { i18nReducer } from 'react-redux-i18n';
import i18nReducer from '../l10n/Reducers/i18reducer'
import { AuthReducer } from '../Modules/Auth/Reducers/auth.reducers'
import { LoaderReducer } from '../Modules/Loader/Reducers'
import { LocationReducer } from '../Modules/Common/Services/Location/location.reducer'
import { ProfileSettingsReducer } from '../Modules/ProfileSettings/Reducers'
import { DepartmentReducer } from 'Modules/Common/Services/Department/department.reducer'
import { BreadCrumbReducer } from 'Modules/Common/Services/BreadCrumb/BreadCrumb.reducer'
import { TenantReducer } from 'Modules/Tenant/Reducers/tenant.reducer'
import { RequestsReducer } from 'Modules/Requests/Reducers/requests.reducer'
import { SettingsReducer } from 'Modules/Settings/Reducer/settings.reducer'
import { UserReducer } from 'Modules/User/Reducers/user.reducer'
import { CookiesReducer } from 'Modules/Cookies/Reducers/cookies.reducer'
import { BannersReducer } from 'Modules/Banners/Reducers/banners.reducer'
import { ConsentLogsReducer } from 'Modules/ConsentLogs/Reducers/consentLogs.reducer'
import { DomainsReducer } from 'Modules/Domains/Reducers/domains.reducer'
import { DSARDashboardReducer } from 'Modules/Dashboard/Reducers/ConsentDashboard.reducer'
import { SubscribeScreenReducer } from 'Modules/Subscribe/Reducers'
import { SubscriptionScreenReducer } from 'Modules/Subscription/Reducers'

const appReducer = combineReducers({
  LoaderReducer,
  AuthReducer,
  LocationReducer,
  router: routerReducer,
  i18n: i18nReducer,
  ProfileSettingsReducer,
  DepartmentReducer,
  BreadCrumbReducer,
  TenantReducer,
  RequestsReducer,
  SettingsReducer,
  UserReducer,
  CookiesReducer,
  BannersReducer,
  ConsentLogsReducer,
  SubscribeScreenReducer,
  DomainsReducer,
  DSARDashboardReducer: DSARDashboardReducer,
  SubscriptionScreenReducer,
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined
  }

  return appReducer(state, action)
}

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>

export default rootReducer
