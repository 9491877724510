import { setLocale } from 'react-redux-i18n'
import { Action } from 'redux'
import { supportedLocales, fallbackLocale } from '../../Config/i18n'

export const ACTION_CHANGED_LANG = 'ACTION_CHANGED_LANG'
export const ACTION_SET_LOCALE = 'ACTION_SET_LOCALE'
export const ACTION_LOAD_TRANSLATIONS = 'ACTION_LOAD_TRANSLATIONS'

export interface ActionChangeLang extends Action {
  type: typeof ACTION_CHANGED_LANG
  payload: {
    data: any
  }
}

export interface ActionLoadTranslations extends Action {
  type: typeof ACTION_LOAD_TRANSLATIONS
  payload: {
    translations: any
  }
}

export interface ActionSetLocale extends Action {
  type: typeof ACTION_SET_LOCALE
  payload: {
    locale: string
  }
}

export type I18nActions = ActionChangeLang | ActionSetLocale | ActionLoadTranslations

const dispatchChangedLang = (data: any): ActionChangeLang => ({
  type: ACTION_CHANGED_LANG,
  payload: { data },
})

export const dispatchLoadTranslations = (translations: any): ActionLoadTranslations => ({
  type: ACTION_LOAD_TRANSLATIONS,
  payload: { translations },
})

export const dispatchSetLocale = (locale: string): ActionSetLocale => ({
  type: ACTION_SET_LOCALE,
  payload: { locale },
})

export function setLocaleWithFallback(desiredLocale: any) {
  const finalLocale = Object.keys(supportedLocales).includes(desiredLocale) ? desiredLocale : fallbackLocale
  return (dispatch: any) => dispatch(dispatchChangedLang(setLocale(finalLocale)))
}
