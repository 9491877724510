import { auth, tenantManagement } from '../../Common/Constants/ApiUrls'
import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { http } from 'Utils/AxiosWrapper'

export interface RegisterRequest {
  email: string
  password: string
  firstName: string
  lastName: string
  companyName: string
  phone: string
  phone_code?: string
  inviteCode: string
  country: string
  captchaCode?: string
}

export interface VisitorRegisterRequest {
  email: string
  password: string
  firstName: string
  lastName: string
  companyName: string
  companyurl: string
  country: string
  captchaCode?: string
  auth_code?: string
}

export interface RegisterResponse {
  success: boolean
  message: string
  code: number
}

export interface RegisterService {
  register(request: RegisterRequest): Promise<RegisterResponse>
}

class RegisterServiceDefault implements RegisterService {
  async register(request: RegisterRequest): Promise<RegisterResponse> {
    const phone_code = request?.phone_code?.split('+')?.[1]
    const bodyJSONData = {
      email: request.email,
      password: request.password,
      firstname: request.firstName,
      lastname: request.lastName,
      phone: request.phone,
      phone_code: phone_code,
      country_mobile_code: request.phone,
      invitation_id: request.inviteCode,
      country: request.country,
      captchaCode: request.captchaCode,
    }
    let result: RegisterResponse
    try {
      const { data } = await http.put(auth.register, bodyJSONData)
      result = RegisterServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }


  async visitorregistration(request: VisitorRegisterRequest): Promise<RegisterResponse> {
    const bodyJSONData = {
      email: request.email,
      password: request.password,
      firstname: request.firstName,
      lastname: request.lastName,
      country: request.country,
      captchaCode: request.captchaCode,
      companyName: request.companyName,
      companyurl: request.companyurl,
    }
    let result: RegisterResponse
    try {
      const { data } = await http.post(tenantManagement.visitorregistration, bodyJSONData)
      result = RegisterServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }


  async partnersignupprocess(request: VisitorRegisterRequest): Promise<RegisterResponse> {
    const bodyJSONData = {
      email: request.email,
      password: request.password,
      firstname: request.firstName,
      lastname: request.lastName,
      country: request.country,
      captchaCode: request.captchaCode,
      companyName: request.companyName,
      companyurl: request.companyurl,
      auth_code:request.auth_code,
    }
    let result: RegisterResponse
    try {
      const { data } = await http.post(tenantManagement.partnerregistration, bodyJSONData)
      result = RegisterServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  static buildResponse(data: any): RegisterResponse {
    if (!data || !data.jwt_token) {
      return {
        success: false,
        message: '',
        code: 404,
      }
    }

    SessionStorage.setKey(SessionStorageKeyNames.TempToken, data.jwt_token.token)
    SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, data.jwt_token.exp_time_in_minutes)

    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new RegisterServiceDefault()
