export enum BroadcastChannelNames {
  Logout = 'logout',
}
export enum BroadcastChannelMessages {
  Logout = 'logoutSystem',
}

export enum Pages {
  LoginPage = 'login',
  RegisterPage = 'register',
  VerifyCodePage = 'verifyCode',
  SendCodePage = 'sendCode',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  VisitorLogin = 'visitorLogin', 
  AuthenticateUser  = 'authenticateUser',
  Passwordpage = 'password',
  VerifyMFA = 'verifyVisitorCode',
  SignupPage = 'signup',
  SignupVerifyCodePage = 'verifyCode',
}

export enum SessionStorageKeyNames {
  AccessToken = 'access_token',
  TempToken = 'temp_token',
  TempTokenExpiry = 'temp_token_expiry',
  UserData = 'user_data',
}

export enum CommonColors {
  HeaderProfileIcon = '#FFFFFF',
  SideBarExpandIcon = '#f26a21',
}

export enum AppHealthDataScanChartColors {
  Active = '#009121',
  Connected = '#009120',
  PendingActivation = '#C69400',
  Paused = 'rgba(0, 0, 0, 0.5)',
  NotConfigured = 'rgba(0, 0, 0, 0.5)',
  Error = '#E90000',
}
export enum ResponseCodes {
  Unauthorized = 4001,
  AlreadyExist = 4001,
  CreatedSuccessfully = 2001,
  Success = 2000,
  InvalidParams = 4000,
  InvalidEmail = 4003,
  NotFound = 4004,
  ReportNotFound = 4041,
  Conflicts = 4009,
  InValidDomain = 4005,
  entityExistsInAnotherTenant = 4006,
  ExpiredCode = 4002,
  EmptyData = 2004,
  PermissionDenied = 4003,
  GatewayTimeout = 5004,
  DatasourceLimitExceeded = 4010,
  ScannerAlreadyActive = 2011,
  SubsctiptionExpired = 4012,
  PausedOrErrorState = 4007,
  ConfirmDeletePA = 4008,
  RequestExists = 4091,
  RequestUserExists = 4016,
  AddressNotFound = 3002,
  linkExpired = 4098,
}

export enum DataSourceResponseCodes {
  scannerAuthenticated = 6000,
  scannerOffline = 6001,
  blockedByDatasource = 6007,
  timeOutError = 6002,
  invalidRequest = 6003,
  unknownException = 6004,
  invalidCredentials = 6005,
  toManyRequest = 6006,
}
export enum ScannerStates {
  PendingActivation = 'Pending Activation',
  Active = 'Active',
  ScanInProgress = 'Scan In Progress',
  Paused = 'Paused',
  Error = 'Error',
  Connected = 'Connected',
}

export enum DatasourceEngine {
  Microsoft365 = 'microsoft365',
}

export enum DatasourceDeployType {
  OnPrem = 'On-premise',
  Cloud = 'Cloud',
  SaaS = 'SaaS',
}

export enum DatasourceType {
  Databases = 'Databases',
  SaaS = 'SaaS',
  DataWarehouse = 'DataWarehouse',
}

export enum ToggleSwitchTheme {
  Orange = 'orange',
}

export enum TableTrainingEnums {
  RootAttributeType = 'EMAIL_ADDRESS',
}

export enum LogoutStatus {
  status = 'sessionExpired',
}

export enum AddUserRoles {
  CytrioAdmin = 'cytrio_admin',
  Admin = 'tenant_admin',
  DataOwner = 'data_owner',
  DatasourceAdmin = 'data_owner_admin',
  DSARResponder = 'data_owner_dsar_responder',
  ReviewerApprover = 'reviewer',
  LegalReviewer = 'legal_reviewer',
  PrivacyManager = 'privacy_manager',
  DSARSubmitter = 'dsar_submitter',
  BusinessProcessOwner = 'process_owner',
  ReviewerDPO = 'dpia_reviewer',
  AssessmentOwner = 'assessment_owner',
  AssessmentReviewer = 'assessment_reviewer',
  ServiceProvider = 'service_provider',
}

export enum SubscriptionPlan {
  free_plan = 0,
  free_trial = 1,
  professional = 2,
  enterprise = 3,
}
export enum Permissions {
  ConfigureDatasources = 'datasources',
  DeleteUser = 'remove_user',
  DeleteTenantUser = 'delete_tenant_users',
  DeleteSPUser = 'delete_service_provider_users',
}

export enum ReuqestType {
  Access = 'rt_access',
  Delete = 'rt_delete',
}

export enum RegulationTypes {
  All = 'all',
  CCPA = 'CCPA',
  GDPR = 'GDPR',
}

export enum PrivacyCenterStatus {
  PendingActivation = 'pending_activation',
  Online = 'online',
  Error = 'error',
}

export enum TenantUserStatus {
  Invited = 'invited',
  Accepted = 'accepted',
}

export enum TenantDeployStatus {
  Active = 'active',
  Error = 'error',
  PendingDeployment = 'pending_deployment',
}

export enum SubjectTypes {
  Customer = 'Customer',
  Employee = 'Employee',
}

export enum OverdueTypes {
  Overdue = 'overdue',
  OverdueToday = 'overdue_today',
  OverdueInXXDays = 'on_track',
}
