import { Dispatch } from 'react'
import { Action } from 'redux'
import service, { ChangePasswordRequestModal } from '../Services/change-password.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_CHANGE_PASSWORD = 'ACTION_CHANGE_PASSWORD'
export const ACTION_CHANGE_PASSWORD_SUCCESS = 'ACTION_CHANGE_PASSWORD_SUCCESS'
export const ACTION_CHANGE_PASSWORD_FAILED = 'ACTION_CHANGE_PASSWORD_FAILED'

export interface ActionChangePassword extends Action {
  type: typeof ACTION_CHANGE_PASSWORD
}

export interface ActionChangePasswordSuccess extends Action {
  type: typeof ACTION_CHANGE_PASSWORD_SUCCESS
}

export interface ActionChangePasswordFailed extends Action {
  type: typeof ACTION_CHANGE_PASSWORD_FAILED
  payload: {
    errorMessage: string
  }
}

export type ChangePasswordActions = ActionChangePassword | ActionChangePasswordFailed | ActionChangePasswordSuccess

const dispatchChangePassword = (): ActionChangePassword => ({
  type: ACTION_CHANGE_PASSWORD,
})

const dispatchChangePasswordSuccess = (): ActionChangePasswordSuccess => ({
  type: ACTION_CHANGE_PASSWORD_SUCCESS,
})

const dispatchChangePasswordFailed = (error: Error): ActionChangePasswordFailed => ({
  type: ACTION_CHANGE_PASSWORD_FAILED,
  payload: { errorMessage: error.message },
})

const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.Success:
      toastService.success(I18n.t('profile_settings.toast.change_password_success'))
      break
    case ResponseCodes.InvalidParams:
      toastService.error(I18n.t('profile_settings.toast.wrong_current_password'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionChangePassword(
  request: ChangePasswordRequestModal
): (dispatch: Dispatch<ChangePasswordActions>) => Promise<void> {
  return async (dispatch: Dispatch<ChangePasswordActions>) => {
    dispatch(dispatchChangePassword())
    loaderStart(dispatch)
    try {
      const result = await service.changePassword(request)
      if (result && result.success) {
        toaster(result?.statusCode)
        dispatch(dispatchChangePasswordSuccess())
      } else {
        toaster(result?.statusCode)
        dispatch(dispatchChangePasswordFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchChangePasswordFailed(error))
      loaderStop(dispatch)
    }
  }
}
