import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { PCHTMLCodeModel } from '../Models/tenant.models'
import service from '../Services/pcHTMLCode.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'

export const ACTION_GET_PC_HTML_CODE = 'ACTION_GET_PC_HTML_CODE'
export const ACTION_GET_PC_HTML_CODE_SUCCESS = 'ACTION_GET_PC_HTML_CODE_SUCCESS'
export const ACTION_GET_PC_HTML_CODE_FAILURE = 'ACTION_GET_PC_HTML_CODE_FAILURE'

export const ACTION_UPDATE_PC_HTML_CODE = 'ACTION_UPDATE_PC_HTML_CODE'
export const ACTION_UPDATE_PC_HTML_CODE_SUCCESS = 'ACTION_UPDATE_PC_HTML_CODE_SUCCESS'
export const ACTION_UPDATE_PC_HTML_CODE_FAILURE = 'ACTION_UPDATE_PC_HTML_CODE_FAILURE'

export interface ActionGetPCHTMLCode extends Action {
  type: typeof ACTION_GET_PC_HTML_CODE
}

export interface ActionGetPCHTMLCodeSuccess extends Action {
  type: typeof ACTION_GET_PC_HTML_CODE_SUCCESS
  payload: { data: PCHTMLCodeModel }
}

export interface ActionGetPCHTMLCodeFailure extends Action {
  type: typeof ACTION_GET_PC_HTML_CODE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionUpdatePCHTMLCode extends Action {
  type: typeof ACTION_UPDATE_PC_HTML_CODE
}

export interface ActionUpdatePCHTMLCodeSuccess extends Action {
  type: typeof ACTION_UPDATE_PC_HTML_CODE_SUCCESS
}

export interface ActionUpdatePCHTMLCodeFailure extends Action {
  type: typeof ACTION_UPDATE_PC_HTML_CODE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export type PCHTMLCodeActions =
  | ActionGetPCHTMLCode
  | ActionGetPCHTMLCodeFailure
  | ActionGetPCHTMLCodeSuccess
  | ActionUpdatePCHTMLCode
  | ActionUpdatePCHTMLCodeSuccess
  | ActionUpdatePCHTMLCodeFailure

export const dispatchGetPCHTMLCode = () => ({
  type: ACTION_GET_PC_HTML_CODE,
})

const dispatchGetPCHTMLCodeSuccess = (data: PCHTMLCodeModel): ActionGetPCHTMLCodeSuccess => ({
  type: ACTION_GET_PC_HTML_CODE_SUCCESS,
  payload: { data },
})

const dispatchGetPCHTMLCodeFailure = (error: Error, success: boolean): ActionGetPCHTMLCodeFailure => ({
  type: ACTION_GET_PC_HTML_CODE_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionGetPCHTMLCode(tenantId: number, domainId: any): (dispatch: Dispatch<PCHTMLCodeActions>) => Promise<void> {
  return async (dispatch: Dispatch<PCHTMLCodeActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.getPCHTMLCode(tenantId, domainId)
      if (result && result.success) {
        dispatch(dispatchGetPCHTMLCodeSuccess(result?.data))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchGetPCHTMLCodeFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetPCHTMLCodeFailure(error, false))
      loaderStop(dispatch)
    }
  }
}

const dispatchUpdatePCHTMLCodeFailure = (err: Error): ActionUpdatePCHTMLCodeFailure => ({
  type: ACTION_UPDATE_PC_HTML_CODE_FAILURE,
  payload: { success: false, errorMessage: err.message },
})

export function actionUpdatePCHTMLCode(
  data: PCHTMLCodeModel,
  tenantId: string,
  domainId: string,
  isReset: boolean
): (dispatch: Dispatch<PCHTMLCodeActions>) => Promise<void> {
  return async (dispatch: Dispatch<PCHTMLCodeActions>) => {
    try {
      const result = await service.updatePCHTMLCode(data, tenantId, domainId, isReset)
      if (!result.success) {
        dispatch(dispatchUpdatePCHTMLCodeFailure(new Error(result.message)))
        toaster.error(I18n.t('common.toast.something_went_wrong'))
      }
    } catch (err: any) {
      dispatch(dispatchUpdatePCHTMLCodeFailure(err))
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}
