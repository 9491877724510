import {
  GetCookiesListActions,
  ACTION_GET_COOKIES_LIST,
  ACTION_GET_COOKIES_LIST_FAILURE,
  ACTION_GET_COOKIES_LIST_SUCCESS,
  ACTION_GET_COOKIES_TENANT_LIST,
  ACTION_GET_COOKIES_TENANT_LIST_FAILURE,
  ACTION_GET_COOKIES_TENANT_LIST_SUCCESS,
  ACTION_GET_COOKIES_LIST_RESET,
} from '../Actions/cookies-list.actions'

import {
  CookieActions,
  ACTION_EDIT_COOKIE_CLEAR,
  ACTION_EDIT_COOKIE_FAILURE,
  ACTION_EDIT_COOKIE_SUCCESS,
  ACTION_REFETCH_COOKIE_LIST,
} from '../Actions/cookie.actions'

import { BasicDropdownModel, PageParams } from 'Modules/Common/Models'

export interface CookiesReducerState {
  list: Array<any>
  totalRecords: number
  pageParams: PageParams
  activePage: number
  domainList: Array<BasicDropdownModel>
  subDomainList: Array<BasicDropdownModel>
  categoryList: Array<BasicDropdownModel>
  getTenantList: {
    success: boolean
    data: Array<BasicDropdownModel>
  }
  refetchCookieList: boolean
  editCookie: { success: boolean }
}

export const initialState: CookiesReducerState = {
  activePage: 1,
  list: [],
  pageParams: null,
  totalRecords: 0,
  domainList: [],
  subDomainList: [],
  categoryList: [],
  getTenantList: {
    success: false,
    data: [],
  },
  refetchCookieList: false,
  editCookie: { success: false },
}

type Actions = GetCookiesListActions | CookieActions

export const CookiesReducer = (
  state = initialState, // NOSONAR
  action: Actions
): CookiesReducerState => {
  switch (action.type) {
    case ACTION_EDIT_COOKIE_CLEAR:
    case ACTION_EDIT_COOKIE_FAILURE:
      return {
        ...state,
        editCookie: { success: false },
      }
    case ACTION_EDIT_COOKIE_SUCCESS:
      return {
        ...state,
        editCookie: { success: true },
      }
    case ACTION_GET_COOKIES_LIST_RESET:
      return {
        ...state,
        activePage: 1,
        list: [],
        pageParams: null,
        totalRecords: 0,
        domainList: [],
        subDomainList: [],
        categoryList: [],
        getTenantList: {
          success: false,
          data: [],
        },
        editCookie: { success: false },
      }
    case ACTION_GET_COOKIES_LIST:
      return {
        ...state,
        pageParams: action.payload,
      }
    case ACTION_GET_COOKIES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        totalRecords: action.payload.totalRecords,
        activePage: action.payload.activePage,
        domainList: action.payload.domainList,
        subDomainList: action.payload.subDomainList,
        categoryList: action.payload.categoryList,
        editCookie: { success: false },
        refetchCookieList: false,
      }
    case ACTION_GET_COOKIES_LIST_FAILURE:
      return {
        ...state,
        list: [],
        totalRecords: 0,
        activePage: 1,
      }

    case ACTION_GET_COOKIES_TENANT_LIST:
    case ACTION_GET_COOKIES_TENANT_LIST_FAILURE:
      return {
        ...state,
        getTenantList: { success: false, data: [] },
      }
    case ACTION_GET_COOKIES_TENANT_LIST_SUCCESS:
      return {
        ...state,
        getTenantList: { success: true, data: action.payload?.data },
      }
    case ACTION_REFETCH_COOKIE_LIST:
      return {
        ...state,
        refetchCookieList: true,
      }
    default:
      return state
  }
}
