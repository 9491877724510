import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { BrandingModel } from '../Models/tenant.models'
import { ApiResponse } from 'Modules/Common/Models'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/privacyCenter.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_UPDATE_PC_BRANDING_START = 'ACTION_UPDATE_PC_BRANDING_START'
export const ACTION_UPDATE_PC_BRANDING_SUCCESS = 'ACTION_UPDATE_PC_BRANDING_SUCCESS'
export const ACTION_UPDATE_PC_BRANDING_FAILURE = 'ACTION_UPDATE_PC_BRANDING_FAILURE'
export const ACTION_UPDATE_PC_BRANDING_CLEAR = 'ACTION_UPDATE_PC_BRANDING_CLEAR'

export interface ActionUpdatePCBrandingStart extends Action {
  type: typeof ACTION_UPDATE_PC_BRANDING_START
  payload: {}
}

export interface ActionUpdatePCBrandingSuccess extends Action {
  type: typeof ACTION_UPDATE_PC_BRANDING_SUCCESS
  payload: {
    data: ApiResponse<string>
  }
}

export interface ActionUpdatePCBrandingFailure extends Action {
  type: typeof ACTION_UPDATE_PC_BRANDING_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export interface ActionUpdatePCBrandingClear extends Action {
  type: typeof ACTION_UPDATE_PC_BRANDING_CLEAR
  payload: {}
}

export type UpdatePCBrandingActions =
  | ActionUpdatePCBrandingStart
  | ActionUpdatePCBrandingSuccess
  | ActionUpdatePCBrandingFailure
  | ActionUpdatePCBrandingClear

const dispatchUpdatePCBrandingSuccess = (data: ApiResponse<string>): ActionUpdatePCBrandingSuccess => ({
  type: ACTION_UPDATE_PC_BRANDING_SUCCESS,
  payload: { data },
})

const dispatchUpdatePCBrandingFailure = (err: Error, success: boolean): ActionUpdatePCBrandingFailure => ({
  type: ACTION_UPDATE_PC_BRANDING_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

export const dispatchUpdatePCBrandingClear = (): ActionUpdatePCBrandingClear => ({
  type: ACTION_UPDATE_PC_BRANDING_CLEAR,
  payload: {},
})

export function actionUpdatePCBranding(
  data: BrandingModel,
  tenantId: string,
  brandingDetailsUpdated: boolean,
  showToasterMessage = true,
  domainId?: string,
  subDomainId?: string
): (dispatch: Dispatch<UpdatePCBrandingActions>) => Promise<void> {
  return async (dispatch: Dispatch<UpdatePCBrandingActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.updatePCBranding(data, tenantId, domainId, subDomainId)
      if (result.statusCode === ResponseCodes.Success) {
        dispatch(dispatchUpdatePCBrandingSuccess(result))
        if (showToasterMessage) {
          if (brandingDetailsUpdated) {
            toaster.success(I18n.t('tenant.brandingupdateMsgNew'))
          } else {
            toaster.success(I18n.t('tenant.addTenant.detailsUpdated'))
          }
        }
      } else {
        if (data.logo) {
          toaster.error(I18n.t('tenant.failedLogo'))
        } else {
          toaster.error(I18n.t('tenant.generciError'))
        }
        dispatch(dispatchUpdatePCBrandingFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdatePCBrandingFailure(err, false))
    }
  }
}
