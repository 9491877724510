import { UserModel } from '../Models/user.model'
import { auth } from '../../Common/Constants/ApiUrls'
import { SessionStorage } from '../../../Utils/SessionStorageWrapper'
import { SessionStorageKeyNames } from 'Modules/Common/Enums/Common'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse } from 'Modules/Common/Models'
export interface VerifyCodeRequest {
  email: string
  code: string
}

export interface VerifyCodeResponse {
  success: boolean
  message: string
  code: number
  user?: UserModel
}

export interface VerifyCodeService {
  verifyCode(request: VerifyCodeRequest): Promise<VerifyCodeResponse>
  verifyImpersonateToken(jwtToken: string): Promise<VerifyCodeResponse>
  getSPToken(): Promise<VerifyCodeResponse>
}

class VerifyCodeServiceDefault implements VerifyCodeService {
  async verifyCode(request: VerifyCodeRequest): Promise<VerifyCodeResponse> {
    const bodyJSONData = {
      email: request.email,
      code: request.code,
    }
    let result: VerifyCodeResponse
    try {
      const { data } = await http.post(auth.verifyCode, bodyJSONData)
      result = VerifyCodeServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  async verifyImpersonateToken(jwtToken: string): Promise<VerifyCodeResponse> {
    let result: VerifyCodeResponse
    try {
      const { data } = await http.post(auth.verifyImpersonateToken, { jwt_token: jwtToken })
      result = VerifyCodeServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  async getRolesAndPermissions(
    email: string
  ): Promise<
    ApiResponse<{
      roles_and_permissions: any
      feature_permissions: any
    }>
  > {
    let result
    try {
      const { data }: any = await http.post(auth.getRolesAndPermission, {
        email,
      })
      result = {
        data: {
          roles_and_permissions: data?.data.roles_and_permissions,
          feature_permissions: data?.data.feature_permissions,
        },
        success: true,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  async getSPToken(): Promise<VerifyCodeResponse> {
    let result: VerifyCodeResponse
    try {
      const { data } = await http.get(auth.getSPToken)
      result = VerifyCodeServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  static buildResponse(data: any): VerifyCodeResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty user data in response',
      }
    }
    const userData = data.user_data
    const tenantData = data.tenant_data
    const licence = data.licence
    const tenantSource = data.tenant_source
    const firstTimeLogin = data.first_time_login
    const source_type = data?.source_type
    const latest_domain_id = data?.latest_domain_id

    if (!userData || !tenantData) {
      return {
        success: false,
        message: data.message,
        code: data.code,
      }
    }

    const roleDisplayName = data?.roles_and_permissions?.map(e => e.role_display_name)?.join(', ') || ''

    const user: UserModel = {
      firstName: userData.firstname,
      lastName: userData.lastname,
      phone: userData.phone,
      email: userData.email,
      role: userData.role,
      tenantId: tenantData.tenant_id,
      tenantName: tenantData.tenant_name,
      displayLanguage: tenantData.display_language,
      pcUrl: tenantData.privacy_center_url,
      roles_and_permissions: data.roles_and_permissions,
      feature_permissions: data.feature_permissions,
      days_left: licence ? licence.days_left : undefined,
      is_banner_allowed: licence ? licence.is_banner_allowed : undefined,
      mcUserId: userData.user_id,
      roleDisplayName,
      logo_url: tenantData.logo_url || '',
      tenantSource,
      firstTimeLogin,
      source_type,
      latest_domain_id,
    }

    if (data?.impersonate_from) {
      user.impersonate_from = data?.impersonate_from
    }

    if (data?.jwt_token) {
      SessionStorage.setKey(SessionStorageKeyNames.TempToken, data?.jwt_token.token)
      SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, data?.jwt_token.exp_time_in_minutes)
    }

    SessionStorage.setKey(SessionStorageKeyNames.UserData, JSON.stringify(user))

    return {
      success: true,
      message: data.message,
      code: data.code,
      user,
    }
  }
}

export default new VerifyCodeServiceDefault()
