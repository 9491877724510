import cx from 'classnames'
import React from 'react'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import { CheckCircle } from 'react-feather'
import styles from '../Auth.module.css'
import ButtonWithIcon from 'Modules/Common/Components/Button'
import { useHistory } from 'react-router'
import { I18n } from 'react-redux-i18n'

interface PasswordChangePopupProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
}

function PasswordChangePopup(props: PasswordChangePopupProps) {
  const { isShowPopup, setIsShowPopup } = props
  const history = useHistory()
  let bodyChild: JSX.Element = (
    <div className="row mr-2 ml-2">
      <div className="col-md-12 d-flex justify-content-center">
        <CheckCircle height={40} width={40} color="#009120" />
      </div>
      <div className="col-md-12 mt-2 text-center">
        <strong className={styles.sentPopupHeading}>{I18n.t('forgetPass.passwordChanged')}</strong>
      </div>
      <div className={cx('col-md-12 mt-2 text-center', styles.sentPopupDescription)}>
        {I18n.t('forgetPass.passwordChangedSuccess')}
      </div>
      <div className={cx('col-md-12 mt-4 d-flex justify-content-center')}>
        <ButtonWithIcon
          type="button"
          text={I18n.t('common.ok')}
          style={{ width: '50%' }}
          x-automation-id="ok-btn-changepass"
          id="next-btn"
          className={styles.customButton}
          onClick={() => {
            history.push('/login')
            setIsShowPopup(false)
          }}
        />
      </div>
    </div>
  )
  return (
    <>
      <VerticallyCenteredModal
        show={isShowPopup}
        dialogClassName="sentModal"
        x-automation-id="add-datasource-popup"
        bodyChild={bodyChild}
        onHide={() => setIsShowPopup(false)}
      />
    </>
  )
}

export default React.memo(PasswordChangePopup)
