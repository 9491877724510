import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { TenantModel, AddTenantResponse, FreeTrialResponse } from '../Models/tenant.models'
import service from '../Services/tenant.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import cookiescan from '../../CookieScanning/Services/index'

export const ACTION_ADD_TENANT = 'ACTION_ADD_TENANT'
export const ACTION_ADD_TENANT_SUCCESS = 'ACTION_ADD_TENANT_SUCCESS'
export const ACTION_ADD_TENANT_FAILURE = 'ACTION_ADD_TENANT_FAILURE'

export const ACTION_DELETE_TENANT = 'ACTION_DELETE_TENANT'
export const ACTION_DELETE_TENANT_SUCCESS = 'ACTION_DELETE_TENANT_SUCCESS'
export const ACTION_DELETE_TENANT_FAILURE = 'ACTION_DELETE_TENANT_FAILURE'
export const ACTION_FREE_TRIAL_DETAILS = 'ACTION_FREE_TRIAL_DETAILS'
export const ACTION_SEND_SUBSCRIPTION_TO_SALES_TEAM = 'ACTION_SEND_SUBSCRIPTION_TO_SALES_TEAM'
export const ACTION_GET_STRIPE_CHECKOUT_URL = 'ACTION_GET_STRIPE_CHECKOUT_URL'
export const ACTION_GET_STRIPE_PAYMENT_DETAILS = 'ACTION_GET_STRIPE_PAYMENT_DETAILS'
export const ACTION_SET_STRIPE_CHECKOUT_LOADING = 'ACTION_SET_STRIPE_CHECKOUT_LOADING'
export const RESET_SUBSCRIPTION_STATUS = 'RESET_SUBSCRIPTION_STATUS';

export interface ActionAddTenant extends Action {
  type: typeof ACTION_ADD_TENANT
  payload: {
    addTenant: TenantModel
  }
}

export interface ActionAddTenantSuccess extends Action {
  type: typeof ACTION_ADD_TENANT_SUCCESS
  payload: {
    data: AddTenantResponse
  }
}

export interface ActionAddTenantFailure extends Action {
  type: typeof ACTION_ADD_TENANT_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export interface ActionDeleteTenant extends Action {
  type: typeof ACTION_DELETE_TENANT
}

export interface ActionDeleteTenantSuccess extends Action {
  type: typeof ACTION_DELETE_TENANT_SUCCESS
}

export interface ActionFreeTrialDetails extends Action {
  type: typeof ACTION_FREE_TRIAL_DETAILS
  payload: { data: FreeTrialResponse }
}
export interface ActionDeleteTenantFailure extends Action {
  type: typeof ACTION_DELETE_TENANT_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionSendSubscriptionToSalesTeam extends Action {
  type: typeof ACTION_SEND_SUBSCRIPTION_TO_SALES_TEAM
  payload: { success: boolean; message: string }
}

export interface ActionGetStripeCheckoutUrl extends Action {
  type: typeof ACTION_GET_STRIPE_CHECKOUT_URL
  payload: { checkoutUrl: string; sessionId: string, subscription_status:string }
}

export interface ActionGetStripePaymentDetails extends Action {
  type: typeof ACTION_GET_STRIPE_PAYMENT_DETAILS
  payload: { data: any }
}

export interface ActionSetStripeCheckoutUrlLoading extends Action {
  type: typeof ACTION_SET_STRIPE_CHECKOUT_LOADING
  payload: { loading: boolean }
}

export const resetSubscriptionStatus = (): Action => ({
  type: RESET_SUBSCRIPTION_STATUS,
});

export type TenantActions =
  | ActionAddTenant
  | ActionAddTenantFailure
  | ActionAddTenantSuccess
  | ActionDeleteTenant
  | ActionDeleteTenantSuccess
  | ActionDeleteTenantFailure
  | ActionFreeTrialDetails
  | ActionSendSubscriptionToSalesTeam
  | ActionGetStripeCheckoutUrl
  | ActionGetStripePaymentDetails
  | ActionSetStripeCheckoutUrlLoading
  | { type: typeof RESET_SUBSCRIPTION_STATUS }

const dispatchAddTenantSuccess = (data: AddTenantResponse): ActionAddTenantSuccess => ({
  type: ACTION_ADD_TENANT_SUCCESS,
  payload: { data },
})

const dispatchAddTenantFailure = (error: Error, success: boolean): ActionAddTenantFailure => ({
  type: ACTION_ADD_TENANT_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionAddTenant(addTenant: TenantModel): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.addTenant(addTenant)
      if (result && result.success) {
        const data: AddTenantResponse = {
          success: result.success,
          message: result.message,
          statusCode: result.statusCode,
          id: result.id,
        }
        toaster.success(I18n.t('tenant.addTenant.addTenantSuccess'))
        dispatch(dispatchAddTenantSuccess(data))
        const scan_result = await cookiescan.addcookiescanning(result.id)
        if (scan_result.success) {
          // toaster.success(I18n.t('domains.cookiescanningsuccessful'))
        } else {
          // toaster.error(I18n.t('domains.cookiescanningfailed'))
        }
      } else {
        if (result.statusCode == ResponseCodes.Conflicts) {
          toaster.error(I18n.t('tenant.addTenant.companyURLEmailAlreadyExists'))
        } else {
          toaster.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchAddTenantFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchAddTenantFailure(error, false))
      loaderStop(dispatch)
    }
  }
}

const dispatchDeleteTenantSuccess = (): ActionDeleteTenantSuccess => ({
  type: ACTION_DELETE_TENANT_SUCCESS,
})

const dispatchFreeTrialSuccess = (data: FreeTrialResponse): ActionFreeTrialDetails => ({
  type: ACTION_FREE_TRIAL_DETAILS,
  payload: { data },
})

const dispatchSubscriptionToTeamsSuccess = (message): ActionSendSubscriptionToSalesTeam => ({
  type: ACTION_SEND_SUBSCRIPTION_TO_SALES_TEAM,
  payload: {
    success: true,
    message,
  },
})

const dispatchDeleteTenantFailure = (err: Error): ActionDeleteTenantFailure => ({
  type: ACTION_DELETE_TENANT_FAILURE,
  payload: { success: false, errorMessage: err.message },
})

const dispatchGetStripeCheckoutUrl = (checkoutUrl: string, sessionId: string, subscription_status:string): ActionGetStripeCheckoutUrl => ({
  type: ACTION_GET_STRIPE_CHECKOUT_URL,
  payload: { checkoutUrl, sessionId, subscription_status },
})

const dispatchGetStripePaymentDetails = (data: any): ActionGetStripePaymentDetails => ({
  type: ACTION_GET_STRIPE_PAYMENT_DETAILS,
  payload: { data },
})

const dispatchSetStripeCheckoutUrlLoading = (loading: boolean): ActionSetStripeCheckoutUrlLoading => ({
  type: ACTION_SET_STRIPE_CHECKOUT_LOADING,
  payload: { loading },
})

export function actionDeleteTenant(tenantId: string): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.deleteTenant(tenantId)
      if (result.success) {
        dispatch(dispatchDeleteTenantSuccess())
        toaster.success(I18n.t('tenant.tenantRemovedSuccess'))
      } else {
        dispatch(dispatchDeleteTenantFailure(new Error(result.message)))
        toaster.error(I18n.t('common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      dispatch(dispatchDeleteTenantFailure(err))
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionGetFreeTrialDetails(tenantId: string): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.freeTrialDetails(tenantId)
      if (result.success) {
        const data: FreeTrialResponse = {
          success: result.success,
          message: result.message,
          statusCode: result.statusCode,
          days_remaining: result.data.days_remaining,
          license_plan: result.data.license_plan,
          domain_limit: result.data.domain_limit,
          domains_used: result.data.domains_used,
          free_plan_edit_domain_limit:result.data.free_plan_edit_domain_limit,
          website_scan_visitor:result.data.website_scan_visitor,
          new_domain_id:result.data.new_domain_id,
          old_domain_id:result.data.old_domain_id,
          is_app_sumo_user:result.data.is_app_sumo_user
        }
        dispatch(dispatchFreeTrialSuccess(data))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionSendSubscriptionToSalesTeam(
  tenantId: string
): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.sendSubscriptionToSalesTeam(tenantId)
      if (result.success) {
        const data = {
          success: result.success,
          message: result.message,
          statusCode: result.statusCode,
        }
        toaster.success(result.message)
        dispatch(dispatchSubscriptionToTeamsSuccess(data))
      } else {
        toaster.error(result?.message)
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionGetStripeCheckoutUrl(
  tenantId: string,
  payload: {
    plan: string
    recurrence: string
  }
): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    dispatch(dispatchSetStripeCheckoutUrlLoading(true))

    try {
      const result = await service.getStripeCheckoutUrl(tenantId, payload)
      if (result.success) {
        if(result.data.subscription_status === 'upgrade'){
          toaster.success(I18n.t('Your Subcription Upgraded Successfully'))
          //dispatch(dispatchSetStripeCheckoutUrlLoading(false))
          dispatch(dispatchGetStripeCheckoutUrl(result.data.checkoutUrl, result.data.sessionId, result.data.subscription_status))
        }else if(result.data.subscription_status === 'downgrade'){
          toaster.success(I18n.t('Your Subcription Downgraded Successfully'))
          //dispatch(dispatchSetStripeCheckoutUrlLoading(false))
          dispatch(dispatchGetStripeCheckoutUrl(result.data.checkoutUrl, result.data.sessionId, result.data.subscription_status))
        }else {
          dispatch(dispatchGetStripeCheckoutUrl(result.data.checkoutUrl, result.data.sessionId, result.data.subscription_status))
        }
      } else {
        toaster.error(result?.message)
        dispatch(dispatchSetStripeCheckoutUrlLoading(false))
      }
    } catch (err: any) {
      dispatch(dispatchSetStripeCheckoutUrlLoading(false))
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionGetStripePaymentDetails(): (dispatch: Dispatch<TenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<TenantActions>) => {
    try {
      const result = await service.getStripePaymentDetails()
      if (result.success) {
        dispatch(dispatchGetStripePaymentDetails(result.data.data))
      } else {
        toaster.error(result?.message)
      }
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
    }
  }
}
