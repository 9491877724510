export const common = {
  filters: 'Filters',
  export: 'Export',
  clickHere: 'Click Here',
  close: 'Close',
  send: 'Send',
  clear: 'Clear',
  on: 'On',
  off: 'Off',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  cancel: 'Cancel',
  add: 'Add',
  update: 'Update',
  location: 'Location',
  locationRequired: 'Please select location',
  scannerListError: 'Error while loading scanner',
  invalidEmail: 'Invalid email address format',
  invalidUrl: 'Invalid URL address',
  search: 'Search',
  search_by_keyword: 'Search By Keyword',
  headquarter: 'Company Headquarter',
  toast: {
    something_went_wrong: 'Something went wrong',
    no_results_found: 'No results found',
    no_scanner_installed: 'No Scanners installed yet',
    jwtTokenExpired: 'Your session has expired',
    permissionDenied: 'Permission Denied',
    KPAListError: 'Error while getting KPA List',
    PIDataCategoryError: 'Error while getting PI Data Category List',
    addBusinessFunctionSuccess: 'Business function added',
    entityCategorySucess: 'Entity Category added',
  },
  prev: 'Prev',
  next: 'Next',
  back: 'Back',
  invite: 'Invite',
  action: 'Action',
  createdAt: 'Created on',
  resubmit: 'Re-submit',
  error_boundry_text: 'There was an error in loading this section.',
  error_boundry_reload: 'Reload this page',
  ok: 'OK',
  confirmation: 'Confirmation',
  proceed: 'Proceed',
  saveAndPublish: 'Save & Publish',
  saveAndClose: 'Save & Close',
  saveAndNext: 'Save & Next',
  confirmAndPublsih: 'Confirm Publish',
  confirm: 'Confirm',
  expiryPeriodText: 'Your free trial expires in ',
  gracePeriodText: 'Your free trial has expired',
  day: ' day',
  days: ' days',
  expired: 'Expired',
  remaining: 'Remaining',
  lastScanned: 'Last Scanned',
  lastUpdated: 'Last Updated',
  lastReviewed: 'Last Reviewed',
  upgradePlan: 'Upgrade Plan',
  skipForNow: 'Skip for now',
  confirmDelete: 'Confirm Delete',
  delete: 'Delete',
  pending_changes: 'You have changes pending to be saved',
  trialExpired: 'Your free trial has expired. Please upgrade your license subscription to continue',
  done: 'Done',
  attributes: 'Attributes',
  testConfiguration: 'Test Configuration',
  submit: 'Submit',
  date: 'Date',
  all: 'All',
  edit: 'Edit',
  remove: 'Remove',
  apply: 'Apply',
  reject: 'Reject',
  approve: 'Approve',
  rejected: 'Rejected',
  approved: 'Approved',
  discardChanges: 'Discard Changes',
  loading: 'Loading',
  noDataFound: 'No data found',
  clearAll: 'Clear All',
  selectDataRange: 'Select Date Range...',
  reset: 'Reset',
  copy: 'Copy',
  skip: 'Skip',
  upgrade: 'Upgrade',
  publish: 'Publish',
  willDoLater: "I'll do later",
  subscribe: 'Subscribe',
  optional: 'Optional',
  help: 'Help',
  welcomeMessage: 'Welcome to CYTRIO Privacy Manager',
  failedToLoadPreview: 'Failed to load preview.',
}
