import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { BulkUploadErrorResponse, BulkUploadModel, BulkUploadResponseCodes } from '../Models/tenant.models'
import { loaderStart } from 'Modules/Loader/Reducers'
import service from '../Services/tenant.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_BULK_UPLOAD_START = 'ACTION_BULK_UPLOAD_START'
export const ACTION_BULK_UPLOAD_SUCCESS = 'ACTION_BULK_UPLOAD_SUCCESS'
export const ACTION_BULK_UPLOAD_FAILURE = 'ACTION_BULK_UPLOAD_FAILURE'
export const ACTION_BULK_UPLOAD_CLEAR = 'ACTION_BULK_UPLOAD_CLEAR'

export interface ActionBulkUploadStart extends Action {
  type: typeof ACTION_BULK_UPLOAD_START
  payload: {}
}

export interface ActionBulkUploadSuccess extends Action {
  type: typeof ACTION_BULK_UPLOAD_SUCCESS
  payload: {
  }
}

export interface ActionBulkImportFailure extends Action {
  type: typeof ACTION_BULK_UPLOAD_FAILURE
  payload: {
    success: boolean
    error: boolean
    errorMessage: BulkUploadErrorResponse | string
  }
}

export interface ActionBulkImportClear extends Action {
  type: typeof ACTION_BULK_UPLOAD_CLEAR
  payload: {}
}

export type BulkUploadActions =
  | ActionBulkUploadStart
  | ActionBulkUploadSuccess
  | ActionBulkImportFailure
  | ActionBulkImportClear

const dispatchBulkUploadStart = (): ActionBulkUploadStart => ({
  type: ACTION_BULK_UPLOAD_START,
  payload: {},
})

const dispatchBulkUploadSuccess = (): ActionBulkUploadSuccess => ({
  type: ACTION_BULK_UPLOAD_SUCCESS,
  payload: {},
})

const dispatchBulkUploadFailure = (err: BulkUploadErrorResponse | string, error: boolean): ActionBulkImportFailure => ({
  type: ACTION_BULK_UPLOAD_FAILURE,
  payload: {
    errorMessage: err,
    error: true,
    success: false,
  },
})

export const dispatchBulkUploadClear = (): ActionBulkImportClear => ({
  type: ACTION_BULK_UPLOAD_CLEAR,
  payload: {},
})

const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.CreatedSuccessfully:
      toastService.success(I18n.t('tenant.addTenant.bulkUploadSuccessMSG'))
      break
    case BulkUploadResponseCodes.NoEntityDataFound:
      toastService.error(I18n.t('tenant.addTenant.noEntityDataFound'))
      break
    case BulkUploadResponseCodes.MaxRecordsExceeded:
      toastService.error(I18n.t('tenant.addTenant.maxRecordsExceeded'))
      break
    case BulkUploadResponseCodes.IncorrectExtension:
      toastService.error(I18n.t('tenant.addTenant.incorrectExtension'))
      break
    case BulkUploadResponseCodes.IncorrectFileStructure:
      toastService.error(I18n.t('tenant.addTenant.incorrectFileStructure'))
      break
    case BulkUploadResponseCodes.EntityAlreadyExits:
      toastService.error(I18n.t('tenant.addTenant.entityAlreadyExits'))
      break
    case BulkUploadResponseCodes.InvalidFileData:
      toastService.error(I18n.t('tenant.addTenant.invalidFileData'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

const responseCodes = [
  BulkUploadResponseCodes.NoEntityDataFound,
  BulkUploadResponseCodes.MaxRecordsExceeded,
  BulkUploadResponseCodes.IncorrectExtension,
  BulkUploadResponseCodes.IncorrectFileStructure,
  BulkUploadResponseCodes.EntityAlreadyExits,
  BulkUploadResponseCodes.InvalidFileData
]

export function actionBulkTenantUpload(
  data: BulkUploadModel,
  showLoader: boolean = true
): (dispatch: Dispatch<BulkUploadActions>) => Promise<void> {
  return async (dispatch: Dispatch<BulkUploadActions>) => {
    dispatch(dispatchBulkUploadStart())
    try {
      const result = await service.tenantBulkUpload(data)
      if (result.statusCode === ResponseCodes.CreatedSuccessfully) {
        dispatch(dispatchBulkUploadSuccess())
      } else {
        dispatch(dispatchBulkUploadFailure(result.message, responseCodes.includes(result?.statusCode)))
      }
      toaster(result?.statusCode)
    } catch (err: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchBulkUploadFailure(err, false))
    }
  }
}
