import { Dispatch } from 'react';
import { Action } from 'redux';
import { LocationDropdownModel } from '../../Models/index';
import service from './locations.service';
export const ACTION_GET_LOCATION = 'ACTION_GET_LOCATION';
export const ACTION_GET_LOCATION_SUCCESS = 'ACTION_GET_LOCATION_SUCCESS';
export const ACTION_GET_LOCATION_FAILURE = 'ACTION_GET_LOCATION_FAILURE';

export interface ActionGetLocation extends Action {
  type: typeof ACTION_GET_LOCATION;
  payload: {};
}

export interface ActionGetLocationSuccess extends Action {
  type: typeof ACTION_GET_LOCATION_SUCCESS;
  payload: {
    success: boolean;
    data: Array<LocationDropdownModel>;
  };
}

export interface ActionGetLocationFailure extends Action {
  type: typeof ACTION_GET_LOCATION_FAILURE;
  payload: {
    success: boolean;
    errorMessage: string;
  };
}

export type GetLocationActions =
  | ActionGetLocation
  | ActionGetLocationFailure
  | ActionGetLocationSuccess;

const dispatchGetLocationSuccess = (
  success: boolean,
  data: Array<LocationDropdownModel>
): ActionGetLocationSuccess => ({
  type: ACTION_GET_LOCATION_SUCCESS,
  payload: {
    success,
    data,
  },
});

const dispatchGetLocationFailure = (
  error: Error,
  success: boolean
): ActionGetLocationFailure => ({
  type: ACTION_GET_LOCATION_FAILURE,
  payload: { errorMessage: error.message, success },
});

export function getLocationDropdownAction(): (
  dispatch: Dispatch<GetLocationActions>
) => Promise<void> {
  return async (dispatch: Dispatch<GetLocationActions>) => {
    try {
      const result = await service.getlocationDropdown();
      if (result && result.success) {
        let data: Array<LocationDropdownModel> | undefined = result.data;
        if (data) {
          dispatch(dispatchGetLocationSuccess(true, data));
        } else {
          dispatch(
            dispatchGetLocationFailure(
              new Error('Error while getting Location'),
              false
            )
          );
        }
      } else {
        dispatch(dispatchGetLocationFailure(new Error(result.message), false));
      }
    } catch (error: any) {
      dispatch(dispatchGetLocationFailure(error, false));
    }
  };
}
