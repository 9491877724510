import { ACTION_ADD_SUBSCRIPTION, ActionAddSubscription, ACTION_LOADING, ActionLoading } from '../Actions/index'

export interface SubscriptionScreenReducerState {
  message: string
  loading: boolean
}

export const initialState: SubscriptionScreenReducerState = {
  message: '',
  loading: false,
}

export const SubscribeScreenReducer = (
  state = initialState,
  action: ActionAddSubscription | ActionLoading
): SubscriptionScreenReducerState => {
  switch (action.type) {
    case ACTION_ADD_SUBSCRIPTION:
      return {
        ...state,
        message: action.payload.message,
      }
    case ACTION_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}
