import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Dropdown } from 'react-bootstrap'
import * as Icons from 'react-feather'
import styles from '../Layout.module.css'
import { I18n } from 'react-redux-i18n'
import { CommonColors } from '../../Common/Enums/Common'
import { useDispatch, useSelector } from 'react-redux'
import { actionLogout } from '../../Auth/Actions/logout.actions'
import { AppStateType } from 'Store/reducers'
import cx from 'classnames'
import Breadcrumbs from 'Modules/Common/Components/Breadcrumbs'
import { BiUserCircle } from 'react-icons/bi'
import ImpersonateBackButton from 'Modules/Common/Components/ImpersonateBackButton'
import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import { actionGetFreeTrialDetails } from 'Modules/Tenant/Actions/tenant.actions'
import { isNull } from 'lodash'
import { useHistory } from 'react-router-dom'
import { HelpCircle, X } from 'react-feather'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import '../videoModal.css'

const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
  <a
    href=""
    ref={ref}
    role="headerProfielIcon"
    id="headerProfielIcon"
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <div className={styles.profileIcon}>
      <Icons.User color={CommonColors.HeaderProfileIcon} size={15} />
    </div>
  </a>
))

type Props = {
  isSidebarCollapsed: boolean
}

const Header = ({ isSidebarCollapsed }: Props) => {
  const dispatch = useDispatch()
  const userData = useSelector((state: AppStateType) => state.AuthReducer.userData)
  const freeTrialRemaningDays = useSelector((state: AppStateType) => state.TenantReducer.freeTrialRemaningDays)
  const licensePlan = useSelector((state: AppStateType) => state.TenantReducer.licensePlan)
  const breadcrumbsMenu = useSelector((state: AppStateType) => state.BreadCrumbReducer?.data)
  const history = useHistory()

  const [helpButtonToogle, setHelpButtonToogle] = useState(false)
  const [showInitialModal, setShowInitialModal] = useState(false)

  const [modal, setModal] = useState({
    show: false,
    link: '',
    text: '',
  })

  const divRef = useRef(null)

  const onLogoutClick = () => {
    dispatch(actionLogout())
  }

  const handleSubscriptionButton = () => {
    history.push('/subscription')
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target?.id &&
        !event.target.parentNode?.id &&
        (event.target.id !== 'videoItem' ||
          event.target.id !== 'productLinks' ||
          event.target.id !== 'contactsupport' ||
          event.target.parentNode.id !== 'videoItem' ||
          event.target.parentNode.id !== 'productLinks' ||
          event.target.parentNode.id !== 'contactsupport' ||
          event.targe.parentNode.id !== 'helpCenterMainDiv' ||
          event.targe.id !== 'helpCenterMainDiv')
      ) {
        setHelpButtonToogle(false)
      }
    }

    document.addEventListener('mouseup', handleClickOutside)

    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (userData?.tenantId) {
      dispatch(actionGetFreeTrialDetails(userData?.tenantId))
    }
  }, [dispatch, userData?.tenantId])

  useEffect(() => {
    if (
      userData?.firstTimeLogin &&
      (userData?.tenantSource === 'direct' || userData?.tenantSource === 'visitor') &&
      localStorage.getItem('initial_banner_viewed') !== 'true'
    ) {
      setTimeout(() => {
        setShowInitialModal(true)
      }, 5000)
    }
  }, [userData?.firstTimeLogin, userData?.tenantSource])

  const getExpiryMessage = (freeTrialRemaningDays: number | null) => {
    if (freeTrialRemaningDays === null) {
      return ''
    } else if (freeTrialRemaningDays <= 0) {
      return I18n.t('common.trialExpired')
    } else {
      return I18n.t('common.expiryPeriodText') + freeTrialRemaningDays + I18n.t('common.days')
    }
  }

  const showSubscriptionMenu = userData?.tenantSource === 'direct' || userData?.tenantSource === 'visitor'

  const helpVideos = [
    {
      text: 'Cookie Consent Setup',
      link: process.env.REACT_APP_CONSENT_SETUP_VIDEO,
    },
    {
      text: 'Privacy Center Setup',
      link: process.env.REACT_APP_PRIVACY_CENTER_SETUP_VIDEO,
    },
  ]

  const documentationLinks = [
    {
      text: 'Cookie Consent Setup',
      link: 'https://cytrio.com/documentation/cookie-banner-setup/',
    },
    {
      text: 'Privacy Center Setup',
      link: 'https://cytrio.com/documentation/privacy-center-setup/',
    },
    {
      text: 'Google Consent Mode v2',
      link: 'https://cytrio.com/documentation/google-consent-mode/',
    },
    {
      text: 'CMS Integrations',
      link: 'https://cytrio.com/documentation/integrations/',
    }
  ]

  const VideoItem = ({ index, text, link }) => {
    return (
      <div
        className={styles.videoItemContainer}
        onClick={() => {
          setModal({
            link,
            text,
            show: true,
          })
          setHelpButtonToogle(false)
        }}
        id="videoItem"
      >
        <div className={styles.videoCountNumber}>{index}</div>
        <div className={styles.videoText}>{text}</div>
      </div>
    )
  }

  const DocumentItem = ({ index, text, link }) => {
    return (
      <div className={styles.videoItemContainer}>
        <div className={styles.videoCountNumber}>{index}</div>
        <div
          className={styles.videoText}
          onClick={() => {
            setHelpButtonToogle(false)
          }}
          style={{ cursor: 'pointer' }}
          id="productLinks"
        >
          <a className={styles.documentItemContainer} href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        </div>
      </div>
    )
  }

  const SupportEmail = () => {
    return (
      <div className={styles.supportItemContainer}>
                
        <div
          className={styles.videoText}
          onClick={() => {
            setHelpButtonToogle(false)
          }}
          style={{ cursor: 'pointer' }}
          id="contactsupport"
        >
          <a className={styles.innersupportItemContainer}  rel="noopener noreferrer"  href="mailto:support@cytrio.com">
           support@cytrio.com
          </a>
        </div>
      </div>
    )
  }

  const HelpCenterDropdown = () => {
    return (
      <div className={cx(styles.helpCenterContainer, 'helpCenter')} ref={divRef} id="helpCenterMainDiv">
        <div className={cx(styles.helpCenterTitleContainer, 'helpCenter')}>
          <div className={cx('helpCenter')}>Help Center</div>
          <div className={cx('helpCenter')}>
            <X color="#fff" cursor="pointer" />
          </div>
        </div>

        <div>
          <div className={styles.productVideos}>
            <div className={styles.productVideosTitle}> Product Videos</div>
            {helpVideos.map((item, index) => (
              <VideoItem text={item.text} link={item.link} index={index + 1} />
            ))}
          </div>

          <div className={styles.productVideos}>
            <div className={styles.productVideosTitle}> Help Guides</div>
            {documentationLinks.map((item, index) => (
              <DocumentItem text={item.text} link={item.link} index={index + 1} />
            ))}
          </div>

          
          <div className={styles.productVideos} id="contactsupport">
            <div className={styles.productVideosTitle}> Contact Us</div>
              <SupportEmail />
          </div>
        </div>
      </div>
    )
  }

  const VideoModal = () => (
    <div>
      <VerticallyCenteredModal
        dialogClassName={'modalContainer'}
        show={modal.show}
        onHide={() =>
          setModal({
            show: false,
            link: '',
            text: '',
          })
        }
        heading={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className={cx(styles.modalHeader)}>{modal.text}</span>
            <button
              type="button"
              role="closeIcon"
              id="TableFiltersSidebar-close-button"
              className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
              onClick={() =>
                setModal({
                  show: false,
                  link: '',
                  text: '',
                })
              }
            >
              <X />
            </button>
          </div>
        }
        bodyChild={
          <div className={cx('d-flex justify-content-center', styles.videoContainer)}>
            <ReactPlayer
              url={modal.link}
              width={'100%'}
              height={'100%'}
              controls={true}
              playing={true}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    type: 'video/webm',
                  },
                },
              }}
            />
          </div>
        }
      />
    </div>
  )

  const InitialSetupPopupModal = () => (
    <div>
      <VerticallyCenteredModal
        dialogClassName={'initialModalContainer'}
        show={showInitialModal}
        onHide={() =>
          setModal({
            show: false,
            link: '',
            text: '',
          })
        }
        heading={
          <div className="d-flex justify-content-center align-items-center w-100">
            <span className={cx('initialModalHeader')}>{I18n.t('common.welcomeMessage')}</span>
          </div>
        }
        bodyChild={
          <div className={cx('d-flex justify-content-center flex-column')}>
            <div className="initialModalMessage d-flex justify-content-center">
              Setup your system in few clicks by following step-by-step
            </div>
            <div className="initialModalMessage d-flex justify-content-center">
              walkthrough videos in the Help Center.
            </div>
            <div className={cx('d-flex justify-content-center initialPopupButtonContainer')}>
              <CancelButtonWithIcon
                text={'Skip'}
                className="mr-2"
                onClick={() => {
                  setShowInitialModal(false)
                  localStorage.setItem('initial_banner_viewed', 'true')
                }}
              />
              <ButtonWithIcon
                text="View Setup Videos"
                onClick={() => {
                  setHelpButtonToogle(true)
                  setShowInitialModal(false)
                  localStorage.setItem('initial_banner_viewed', 'true')
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  )

  return (
    <>
      <div className={cx(styles.header, 'd-flex justify-content-between p-3')}>
        <ImpersonateBackButton />
        <div className="d-flex align-items-center">
          <Breadcrumbs menu={breadcrumbsMenu} />
        </div>
        <div className="d-flex align-items-center">
          {licensePlan === 'free_trial' && !isNull(freeTrialRemaningDays) ? (
            <div className={styles.freeTrialContainer}>
              <p className={styles.freeTrialText}>{getExpiryMessage(freeTrialRemaningDays)}</p>
              <div className="freeTrialButtonContainer">
                <div
                  onClick={() => {
                    history.push('/plans')
                  }}
                  className={cx('mr-2', styles.subscribeButtonContainer)}
                >
                  {I18n.t('common.subscribe')}
                </div>
              </div>
            </div>
          ) : null}
          {freeTrialRemaningDays && licensePlan === 'free_trial' ? <div className={styles.verticalLine}></div> : null}

          {userData?.tenantSource === 'direct' ||
          userData?.tenantSource === 'visitor' ||
          userData?.tenantSource === null ||
          userData?.tenantSource === 'service_provider' ? (
            <div
              className={styles.helpButtonContainer}
              onClick={() => {
                setHelpButtonToogle(prev => !prev)
              }}
            >
              <HelpCircle color="#fff" className={styles.helpIcon} />
              {I18n.t('common.help')}
            </div>
          ) : null}

          {helpButtonToogle ? <HelpCenterDropdown /> : null}
          {userData?.tenantSource === 'direct' ? <div className={styles.verticalLine}></div> : null}
          <Dropdown className="pr-3">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
            <Dropdown.Menu className="p-0">
              <Dropdown.Item
                role="userName"
                className={cx(styles.userInfoSection, 'd-flex align-items-center ')}
                disabled
              >
                <div className={styles.innerProfileIcon}>
                  <BiUserCircle size={36} />
                </div>
                <div className="px-2">
                  <div className={styles.userName}>{`${userData?.firstName} ${userData?.lastName}`}</div>
                  <div className={styles.userEmail}> {`${userData?.email}`} </div>
                </div>
              </Dropdown.Item>
              {showSubscriptionMenu ? (
                <Dropdown.Item
                  eventKey="1"
                  role="subscriptionButton"
                  id="subscriptionButton"
                  onClick={handleSubscriptionButton}
                  className={cx(styles.dropdownProfileMenuesItem)}
                >
                  <span className={cx(styles.logoutCircel, 'mr-2')}>
                    <Icons.DollarSign size={14} color={'#20C997'} />
                  </span>
                  {I18n.t('header.subscription')}
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item
                eventKey="1"
                role="logoutButton"
                id="logoutButton"
                onClick={onLogoutClick}
                className={cx(styles.dropdownProfileMenuesItem)}
              >
                <span className={cx(styles.logoutCircel, 'mr-2')}>
                  <Icons.LogOut size={14} color={'#20C997'} />
                </span>
                {I18n.t('header.logout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <VideoModal />
      <InitialSetupPopupModal />
    </>
  )
}

export default Header
