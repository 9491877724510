import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export function BootstrapTooltip({ children, title, tooltipClassName, ...props }: any) {
  if (!title) {
    return children
  }
  return (
    <OverlayTrigger
      {...props}
      overlay={
        <Tooltip className={`${tooltipClassName ? tooltipClassName : ''} bootstrap-tooltip`} id="tooltip-disabled">
          {title}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}
