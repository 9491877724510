import { subscriptionScreen } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse } from 'Modules/Common/Models'

class GetFeaturesListServiceDefault {
  async cancelFreeTrail(tenantId: string | number, reason: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.cancelFreeTrail(tenantId),
        data: {
          cancellation_reason: reason,
        },
      })
      result = {
        success: true,
        message: data.message,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async cancelProfessionalPlan(tenantId: string | number, reason: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.cancelProfessionalPlan(tenantId),
        data: {
          cancellation_reason: reason,
        },
      })
      result = {
        success: true,
        message: data.message,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }
  async cancelEnterprisePlan(tenantId: string | number, reason: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.cancelEnterprisePlan(tenantId),
        data: {
          cancellation_reason: reason,
        },
      })
      result = {
        success: true,
        message: data.message,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async changePlan(tenantId: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.changePlan(tenantId),
      })
      result = {
        success: true,
        message: data.message,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async getSubscription(tenantId: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.getSubscription(tenantId),
      })

      result = {
        success: true,
        data: data,
        message: data.message,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }
}

export default new GetFeaturesListServiceDefault()
