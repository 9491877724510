import { Action } from 'redux'
import { Dispatch } from 'react'
import service from '../Services/logout.service'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { BroadcastChannelMessages, SessionStorageKeyNames } from 'Modules/Common/Enums'
import LogOutAllTabs from 'Utils/LogOutAllTabs'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'

export const ACTION_LOGOUT = 'ACTION_LOGOUT'

export interface ActionLogout extends Action {
  type: typeof ACTION_LOGOUT
}

export type LogoutActions = ActionLogout

export const dispatchLogout = (): ActionLogout => ({
  type: ACTION_LOGOUT,
})

export function actionLogout(): (dispatch: Dispatch<LogoutActions>) => Promise<void> {
  return async (dispatch: Dispatch<LogoutActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.logout()
      if (result && result.success) {
        dispatch(dispatchLogout())
        LogOutAllTabs.postMessage(BroadcastChannelMessages.Logout)
        SessionStorage.removeKey(SessionStorageKeyNames.UserData)
        SessionStorage.removeKey(SessionStorageKeyNames.TempToken)
        SessionStorage.removeKey(SessionStorageKeyNames.TempTokenExpiry)
        localStorage.removeItem('initial_banner_viewed')
        window.location.href = window.location.origin + '/login'
      } else {
        toastService.error(I18n.t('common.toast.something_went_wrong'))
      }
      loaderStop(dispatch)
    } catch (error) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}
