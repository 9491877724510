import {
  ACTION_REGISTER,
  ACTION_REGISTER_FAILED,
  ACTION_REGISTER_SUCCESS,
  RegisterActions,
} from '../Actions/register.actions'

import {
  ACTION_GET_INVITE_INFO,
  ACTION_GET_INVITE_INFO_FAILED,
  ACTION_GET_INVITE_INFO_SUCCESS,
  ACTION_IS_LINK_EXPIRED,
  GetInviteInfoActions,
} from '../Actions/invite-info.actions'

import {
  ACTION_VERIFY_CODE,
  ACTION_VERIFY_CODE_FAILED,
  ACTION_VERIFY_CODE_SUCCESS,
  VerifyCodeActions,
} from '../Actions/verify-code.actions'

import {
  ACTION_RESEND_CODE,
  ACTION_RESEND_CODE_FAILED,
  ACTION_RESEND_CODE_SUCCESS,
  ResendCodeActions,
} from '../Actions/resend-code.actions'

import {
  ACTION_SET_ROLES_AND_PERMISSIONS,
  ACTION_SET_USER_DATA,
  SetUserDataActions,
} from '../Actions/user-data.actions'

import { ACTION_LOGOUT, LogoutActions } from '../Actions/logout.actions'

import {
  ACTION_LOGIN,
  ACTION_LOGIN_FAILED,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_CANCEL,
  LoginActions,
} from '../Actions/login.actions'

import {
  ACTION_VERIFY_EMAIL,
  ACTION_VERIFY_EMAIL_CANCEL,
  ACTION_VERIFY_EMAIL_FAILED,
  ACTION_VERIFY_EMAIL_SUCCESS,
  VerifyEmailActions
} from '../Actions/verifyemail.actions'

import {
  ACTION_VERIFY_APPSUMO_LICENSE,
  ACTION_VERIFY_APPSUMO_LICENSE_CANCEL,
  ACTION_VERIFY_APPSUMO_LICENSE_FAILED,
  ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS,
  VerifyASLicenseActions
} from '../Actions/verifyuserlicense.actions'


import {
  ACTION_FORGOT_PASSWORD,
  ACTION_FORGOT_PASSWORD_FAILED,
  ACTION_FORGOT_PASSWORD_SUCCESS,
  ForgotPasswordActions,
} from '../Actions/forget-password.actions'
import {
  ACTION_VERIFY_RESET_PASSWORD_CODE,
  ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED,
  ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  VerifyResetPasswordCodeActions,
} from '../Actions/verify-password-link.actions'
import {
  ACTION_RESET_PASSWORD,
  ACTION_RESET_PASSWORD_FAILED,
  ACTION_RESET_PASSWORD_SUCCESS,
  ResetPasswordActions,
} from '../Actions/reset-password.actions'

import { InviteInfoModel, UserModel } from '../Models/user.model'
import {
  ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED,
  ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS,
  VerifyImpersonateTokenActions,
} from '../Actions/verify-impersonate-token.actions'
import {
  ACTION_GET_SP_TOKEN_FAILED,
  ACTION_GET_SP_TOKEN_SUCCESS,
  GetSPTokenActions,
} from '../Actions/get-sp-token.actions'

export interface AuthReducerState {
  errorMessage: string
  userData: UserModel | null
  isAuthenticated: boolean
  isVerified: boolean
  inviteInfo: InviteInfoModel | null
  resendCodeSuccess: boolean
  isExistingUser?: boolean
  register_user?: boolean
  verifyEmail: string
  forgotPassword?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  }
  verifyResetPasswordLink?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  }
  resetPassword?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  }
  isRegisterLinkExpired?: boolean
  is_appsumo_license_user: boolean
}

export const initialState: AuthReducerState = {
  errorMessage: '',
  userData: null,
  inviteInfo: null,
  isAuthenticated: false,
  isVerified: false,
  resendCodeSuccess: false,
  verifyEmail: '',
  isRegisterLinkExpired: false,
  isExistingUser: false,
  register_user: false,
  is_appsumo_license_user: false,
}

type AuthActions =
  | RegisterActions
  | VerifyCodeActions
  | GetInviteInfoActions
  | ResendCodeActions
  | SetUserDataActions
  | LogoutActions
  | LoginActions
  | ForgotPasswordActions
  | VerifyResetPasswordCodeActions
  | ResetPasswordActions
  | VerifyImpersonateTokenActions
  | GetSPTokenActions
  | VerifyEmailActions
  | VerifyASLicenseActions

export const AuthReducer = (state = initialState, action: AuthActions): AuthReducerState => {
  // NOSONAR
  switch (action.type) {
    case ACTION_REGISTER:
      return {
        ...state,
        errorMessage: '',
        verifyEmail: '',
        inviteInfo: {
          ...state?.inviteInfo,
          inviteCode: action.payload?.inviteCode,
          phone: action.payload?.phone,
          password: action.payload?.password,
          confirmPassword: action.payload?.confirmPassword,
          agreeTermsAndConditions: action.payload?.agree,
          phone_code: action.payload?.phone_code,
          country: action.payload?.country,
        },
      }
    case ACTION_REGISTER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isAuthenticated: true,
        verifyEmail: action.payload.email,
      }
    case ACTION_REGISTER_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
      }
    case ACTION_VERIFY_CODE_FAILED:
      return {
        ...state,
        isVerified: false,
        errorMessage: action.payload.errorMessage,
      }
    case ACTION_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isVerified: true,
        errorMessage: '',
        userData: action.payload.user,
        inviteInfo: null,
        resendCodeSuccess: false,
      }
    case ACTION_VERIFY_IMPERSONATE_TOKEN_FAILED:
    case ACTION_GET_SP_TOKEN_FAILED:
      return {
        ...state,
        isVerified: false,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
      }
    case ACTION_VERIFY_IMPERSONATE_TOKEN_SUCCESS:
    case ACTION_GET_SP_TOKEN_SUCCESS:
      return {
        ...state,
        isVerified: true,
        isAuthenticated: true,
        errorMessage: '',
        userData: action.payload.user,
        inviteInfo: null,
        resendCodeSuccess: false,
      }
    case ACTION_GET_INVITE_INFO:
    case ACTION_VERIFY_CODE:
      return {
        ...state,
        errorMessage: '',
      }
    case ACTION_GET_INVITE_INFO_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        inviteInfo: null,
      }
    case ACTION_GET_INVITE_INFO_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        inviteInfo: {
          ...state.inviteInfo,
          ...action.payload.inviteInfo,
        },
        isRegisterLinkExpired: false,
      }
    case ACTION_RESEND_CODE:
      return {
        ...state,
        errorMessage: '',
        resendCodeSuccess: false,
      }
    case ACTION_RESEND_CODE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        inviteInfo: null,
        resendCodeSuccess: false,
      }
    case ACTION_RESEND_CODE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        resendCodeSuccess: true,
      }
    case ACTION_SET_USER_DATA:
      return {
        ...state,
        errorMessage: '',
        isAuthenticated: true,
        isVerified: true,
        userData: action.payload.userData,
      }
    case ACTION_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        userData: null,
        inviteInfo: null,
      }
    case ACTION_LOGIN:
      return {
        ...state,
        verifyEmail: '',
      }
    case ACTION_LOGIN_CANCEL:
      return {
        ...state,
        verifyEmail: '',
        isAuthenticated: false,
      }
    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        verifyEmail: action.payload.email,
      }
    case ACTION_LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
      }

    case ACTION_VERIFY_EMAIL:
      return {
        ...state,
        verifyEmail: '',
      }
    case ACTION_VERIFY_EMAIL_CANCEL:
      return {
        ...state,
        verifyEmail: '',
        isExistingUser: false,
      }
    case ACTION_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isExistingUser: true,
        verifyEmail: action.payload.email,
        register_user: false
      }
    case ACTION_VERIFY_EMAIL_FAILED:
      return {
        ...state,
        isExistingUser: false,
        verifyEmail: action.payload.email,
        register_user: true,
      }

    case ACTION_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPassword: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_VERIFY_RESET_PASSWORD_CODE:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_SET_ROLES_AND_PERMISSIONS:
      return {
        ...state,
        userData: {
          ...state.userData,
          roles_and_permissions: action.payload.roles_and_permissions,
          feature_permissions: action.payload.feature_permissions,
        },
      }

    case ACTION_IS_LINK_EXPIRED:
      return {
        ...state,
        isRegisterLinkExpired: action.payload.isLinkExpired,
      }


    case ACTION_VERIFY_APPSUMO_LICENSE:
      return {
        ...state
      }
    case ACTION_VERIFY_APPSUMO_LICENSE_CANCEL:
      return {
        ...state,
        is_appsumo_license_user: false,
      }
    case ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS:
      return {
        ...state,
        is_appsumo_license_user: true
      }
    case ACTION_VERIFY_APPSUMO_LICENSE_FAILED:
      return {
        ...state,
        is_appsumo_license_user: false,
      }

    default:
      return state
  }
}
