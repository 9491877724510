import { Dispatch } from 'react'
import { Action } from 'redux'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ReportUpload, BulkUploadResponseCodes, TenantDsarReports } from '../Models/request.model'
import service from '../Services/request.service'
// import { AddEditRecipientCategoriesRequest } from '../Models'

export const ACTION_GET_DSAR_REPORTS = 'ACTION_GET_DSAR_REPORTS'
export const ACTION_GET_DSAR_REPORTS_SUCCESS = 'ACTION_GET_DSAR_REPORTS_SUCCESS'
export const ACTION_GET_DSAR_REPORTS_FAILURE = 'ACTION_GET_DSAR_REPORTS_FAILURE'

export const ACTION_UPDATE_DSAR_REPORTS = 'ACTION_UPDATE_DSAR_REPORTS'
export const ACTION_UPDATE_DSAR_REPORTS_SUCCESS = 'ACTION_UPDATE_DSAR_REPORTS_SUCCESS'
export const ACTION_UPDATE_DSAR_REPORTS_FAILURE = 'ACTION_UPDATE_DSAR_REPORTS_FAILURE'

export interface ActionGetDsarReports extends Action {
  type: typeof ACTION_GET_DSAR_REPORTS
}

export interface ActionGetDsarReportsSuccess extends Action {
  type: typeof ACTION_GET_DSAR_REPORTS_SUCCESS
  payload: any
}

export interface ActionGetDsarReportsFailure extends Action {
  type: typeof ACTION_GET_DSAR_REPORTS_FAILURE
  payload: { errorMessage: string }
}

export interface ActionUpdateDsarReports extends Action {
  type: typeof ACTION_UPDATE_DSAR_REPORTS
}

export interface ActionUpdateDsarReportsSuccess extends Action {
  type: typeof ACTION_UPDATE_DSAR_REPORTS_SUCCESS
}

export interface ActionUpdateDsarReportsFailure extends Action {
  type: typeof ACTION_UPDATE_DSAR_REPORTS_FAILURE
  payload: { errorMessage: string }
}

export type DsarReportActions =
  | ActionGetDsarReports
  | ActionGetDsarReportsFailure
  | ActionGetDsarReportsSuccess
  | ActionUpdateDsarReports
  | ActionUpdateDsarReportsFailure
  | ActionUpdateDsarReportsSuccess

export const dispatchGetDsarReports = (): ActionGetDsarReports => ({
  type: ACTION_GET_DSAR_REPORTS,
})

const dispatchGetDsarReportsSuccess = (data: any): ActionGetDsarReportsSuccess => ({
  type: ACTION_GET_DSAR_REPORTS_SUCCESS,
  payload: data,
})

const dispatchGetDsarReportsFailure = (error: Error): ActionGetDsarReportsFailure => ({
  type: ACTION_GET_DSAR_REPORTS_FAILURE,
  payload: { errorMessage: error.message },
})

const dispatchUpdateDsarReportsSuccess = (): ActionUpdateDsarReportsSuccess => ({
  type: ACTION_UPDATE_DSAR_REPORTS_SUCCESS,
})

const dispatchUpdateDsarReportsFailure = (error: Error): ActionUpdateDsarReportsFailure => ({
  type: ACTION_UPDATE_DSAR_REPORTS_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetDSARReports(
  tenantId: string,
  dsar_id: number
): (dispatch: Dispatch<DsarReportActions>) => Promise<void> {
  return async (dispatch: Dispatch<DsarReportActions>) => {
    dispatch(dispatchGetDsarReports())
    loaderStart(dispatch)
    try {
      const result = await service.getDSARReports(tenantId, dsar_id)
      if (result && result.success) {
        dispatch(dispatchGetDsarReportsSuccess(result?.data))
      } else {
        toastService.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchGetDsarReportsFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetDsarReportsFailure(error))
      loaderStop(dispatch)
    }
  }
}

export function actionUpdateDSARReports(
  request: TenantDsarReports,
  ProcessingActivityId: number,
  tenantId: string,
  isDelete: boolean
): (dispatch: Dispatch<DsarReportActions>) => Promise<void> {
  return async (dispatch: Dispatch<DsarReportActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.updateDsarReports(request, ProcessingActivityId, tenantId, isDelete)
      if (result && result.success) {
        toastService.success(I18n.t('processingActivities.toast.updateRecipientCategoriesSuccess'))
        dispatch(dispatchUpdateDsarReportsSuccess())
      } else {
        toastService.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchUpdateDsarReportsFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdateDsarReportsFailure(error))
      loaderStop(dispatch)
    }
  }
}
