import CopyIcon from 'Modules/Common/Components/CopyIcon'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'

export default function CopyDoNotSellLink({ pcUrl }) {
  const [privacyCenterUrl, setPrivacyCenterUrl] = useState('')

  useEffect(() => {
    setPrivacyCenterUrl(
      `<a href="${pcUrl}" target="_blank" rel="noreferrer noopener">Do not sell or share my personal information</a>`
    )
  }, [pcUrl])

  return (
    <div className="row p-0 m-0">
      <div className="col-md-12 ">
      <br/><br/><br/>
        <div className="copyAndPasteThisCodeText mb-2 w-100">{I18n.t('tenant.addDoNotSellLinkToFooter')}</div>
        <div className="d-flex">
          <label className="w-100">
            <input id="scriptUrl" readOnly value={privacyCenterUrl} className="form-control mr-2" />
          </label>
          <div className="ml-2">
            <CopyIcon data={privacyCenterUrl} copyText={I18n.t('common.copy')} />
          </div>
        </div>
      </div>
    </div>
  )
}
