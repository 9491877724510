import {
  TenantActions,
  ACTION_ADD_TENANT,
  ACTION_ADD_TENANT_SUCCESS,
  ACTION_ADD_TENANT_FAILURE,
  ACTION_DELETE_TENANT_SUCCESS,
  ACTION_DELETE_TENANT,
  ACTION_DELETE_TENANT_FAILURE,
  ACTION_FREE_TRIAL_DETAILS,
  ACTION_GET_STRIPE_CHECKOUT_URL,
  ACTION_GET_STRIPE_PAYMENT_DETAILS,
  ACTION_SET_STRIPE_CHECKOUT_LOADING,
  RESET_SUBSCRIPTION_STATUS,
} from '../Actions/tenant.actions'

import {
  GetTenantsActions,
  ACTION_GET_TENANTS,
  ACTION_GET_TENANTS_FAILURE,
  ACTION_GET_TENANTS_SUCCESS,
  ACTION_TENANTS_CLEAR,
  ACTION_REFRESH_TENANT_LIST,
} from '../Actions/tenant-list.actions'

import { PageParams } from 'Modules/Common/Models'
import {
  GeneralSetting,
  AvailableLicenses,
  BulkUploadErrorResponse,
  LicenseTier,
  TenantModel,
  TenantSubscription,
  PCHTMLCodeModel,
} from '../Models/tenant.models'
import {
  ACTION_BULK_UPLOAD_CLEAR,
  ACTION_BULK_UPLOAD_FAILURE,
  ACTION_BULK_UPLOAD_START,
  ACTION_BULK_UPLOAD_SUCCESS,
  BulkUploadActions,
} from '../Actions/bulk-tenant-upload.actions'
import {
  ACTION_GET_TENANT,
  ACTION_GET_TENANT_FAILURE,
  ACTION_GET_TENANT_SUCCESS,
  GetTenantActions,
} from '../Actions/get-tenant.actions'
import {
  ACTION_UPDATE_TENANT,
  ACTION_UPDATE_TENANT_CLEAR,
  ACTION_UPDATE_TENANT_FAILURE,
  ACTION_UPDATE_TENANT_SUCCESS,
  UpdateTenantActions,
} from '../Actions/update-tenant.actions'
import {
  ACTION_GET_PC_BRANDING_FAILURE,
  ACTION_GET_PC_BRANDING_START,
  ACTION_GET_PC_BRANDING_SUCCESS,
  GetPCBrandingActions,
} from '../Actions/getPCBranding.actions'
import {
  ACTION_UPDATE_PC_BRANDING_CLEAR,
  ACTION_UPDATE_PC_BRANDING_FAILURE,
  ACTION_UPDATE_PC_BRANDING_START,
  ACTION_UPDATE_PC_BRANDING_SUCCESS,
  UpdatePCBrandingActions,
} from '../Actions/updatePCBranding.actions'

import {
  ACTION_GET_TENANT_AVAILABLE_LICENSE,
  ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE,
  ACTION_GET_TENANT_AVAILABLE_LICENSE_SUCCESS,
  GetTenantAvailableLicensesActions,
} from '../Actions/get-tenant-availableLicense.actions'

import {
  ACTION_GET_PC_HTML_CODE,
  ACTION_GET_PC_HTML_CODE_FAILURE,
  ACTION_GET_PC_HTML_CODE_SUCCESS,
  ACTION_UPDATE_PC_HTML_CODE,
  ACTION_UPDATE_PC_HTML_CODE_FAILURE,
  ACTION_UPDATE_PC_HTML_CODE_SUCCESS,
  PCHTMLCodeActions,
} from '../Actions/pcHTMLCode.actions'

import {
  ACTION_GET_PC_URL_DEPLOYMENT_STATUS,
  ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE,
  ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS,
  PCURLDeploymentActions,
} from '../Actions/PCURLDeployment.actions'

import {
  ACTION_TENANT_BUY_LICENSE,
  ACTION_TENANT_BUY_LICENSE_FAILURE,
  ACTION_TENANT_BUY_LICENSE_SUCCESS,
  GetTenantBuyLicensesActions,
} from '../Actions/tenant-buyLicense.actions'
import {
  ACTION_SEND_EMAIL,
  ACTION_SEND_EMAIL_FAILURE,
  ACTION_SEND_EMAIL_SUCCESS,
  SendEmailActions,
} from '../Actions/send-email.actions'

export type PCURLDeploymentStatus = 'loading' | 'success' | 'failed' | 'not_found'

export interface TenantReducerState {
  tenantList: Array<any>
  totalRecords: number
  pageParams: PageParams
  activePage: number
  extendedFreeTrialDays?: number
  addTenant: {
    success: boolean
    id: string
  }
  isExtendFreeTrialSuccess: boolean
  isBuyLicensesSucess: boolean
  licenseTiers?: Array<LicenseTier>
  tenantSubsciption?: TenantSubscription
  availableLicenses?: AvailableLicenses
  selectedTenantDetails: TenantModel
  updateTenantSubscription: {
    success: boolean
    message: string
  }
  bulkImport?: {
    success: boolean
    message?: BulkUploadErrorResponse | string
    inProgress?: boolean
    error: boolean
  }
  branding: {
    success: boolean
    errMgs?: string
    data?: GeneralSetting
  }
  updateBranding?: {
    success: boolean
    errMgs?: string
  }
  getPCHTMLCode: { success: boolean; data?: PCHTMLCodeModel }
  updatePCHTMLCode: { success: boolean }
  deleteTenant: { success: boolean }
  sendEmail: { success: boolean }
  refreshList: { success: boolean }
  getPCURLDeploymentStatus: { status: PCURLDeploymentStatus }
  freeTrialRemaningDays: number
  licensePlan: string | null
  domainLimit: number | null
  domainsUsed: number | null
  freePlanEditDomainLimit: string | null
  websiteVisitor: boolean
  old_domain_id: number
  new_domain_id: number
  is_app_sumo_user: boolean
  brandingUpdateStatus: {
    success: boolean
  }
  checkoutUrl: string
  sessionId: string
  subscription_status: string
  stripePaymentDetails: any
  checkoutUrlLoading: boolean
}

export const initialState: TenantReducerState = {
  activePage: 1,
  tenantList: [],
  pageParams: null,
  totalRecords: 0,
  extendedFreeTrialDays: 0,
  addTenant: {
    success: false,
    id: '',
  },
  licenseTiers: [],
  selectedTenantDetails: null,
  updateTenantSubscription: {
    success: false,
    message: '',
  },
  isExtendFreeTrialSuccess: false,
  isBuyLicensesSucess: false,
  bulkImport: {
    success: false,
    message: null,
    inProgress: false,
    error: false,
  },
  branding: {
    success: false,
    errMgs: '',
  },
  updateBranding: {
    success: false,
    errMgs: '',
  },
  deleteTenant: { success: false },
  sendEmail: { success: false },
  getPCHTMLCode: { success: false, data: null },
  updatePCHTMLCode: { success: false },
  refreshList: { success: false },
  getPCURLDeploymentStatus: { status: 'failed' },
  freeTrialRemaningDays: null,
  licensePlan: null,
  domainLimit: null,
  domainsUsed: null,
  freePlanEditDomainLimit: null,
  websiteVisitor: false,  
  old_domain_id: null,
  new_domain_id: null,
  is_app_sumo_user: false,
  brandingUpdateStatus: {
    success: false,
  },
  checkoutUrl: '',
  sessionId: '',
  subscription_status: '',
  stripePaymentDetails: [],
  checkoutUrlLoading: false,
}

type Actions =
  | GetTenantsActions
  | GetTenantActions
  | TenantActions
  | UpdateTenantActions
  | BulkUploadActions
  | GetPCBrandingActions
  | UpdatePCBrandingActions
  | GetTenantAvailableLicensesActions
  | GetTenantBuyLicensesActions
  | SendEmailActions
  | PCHTMLCodeActions
  | PCURLDeploymentActions

export const TenantReducer = (
  state = initialState, // NOSONAR
  action: Actions
): TenantReducerState => {
  switch (action.type) {

    case RESET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscription_status: null, // or any other default value
      };
      
    case ACTION_TENANTS_CLEAR:
      return {
        ...state,
        addTenant: { ...initialState?.addTenant },
        updateTenantSubscription: { ...initialState?.updateTenantSubscription },
        updateBranding: { ...initialState?.updateBranding },
        branding: { ...initialState?.branding },
        bulkImport: { ...initialState?.bulkImport },
        deleteTenant: { ...initialState?.deleteTenant },
        selectedTenantDetails: null,
        refreshList: { success: false },
        brandingUpdateStatus: { success: false },
      }
    case ACTION_REFRESH_TENANT_LIST:
      return {
        ...state,
        refreshList: { success: true },
      }
    case ACTION_ADD_TENANT:
      return {
        ...state,
        addTenant: {
          success: false,
          id: '',
        },
      }
    case ACTION_ADD_TENANT_SUCCESS:
      return {
        ...state,
        addTenant: {
          success: true,
          id: action.payload.data.id,
        },
      }
    case ACTION_ADD_TENANT_FAILURE:
      return {
        ...state,
        addTenant: { id: '', success: false },
      }
    case ACTION_GET_TENANTS:
      return {
        ...state,
        tenantList: null,
        pageParams: { ...state?.pageParams, ...action.payload },
        addTenant: { success: false, id: '' },
      }
    case ACTION_GET_TENANTS_SUCCESS:
      return {
        ...state,
        addTenant: {
          success: false,
          id: '',
        },
        tenantList: action.payload.data,
        totalRecords: action.payload.totalRecords,
        activePage: action.payload.activePage,
        extendedFreeTrialDays: action.payload.extendedFreeTrialDays,
      }
    case ACTION_GET_TENANTS_FAILURE:
      return {
        ...state,
        tenantList: [],
        totalRecords: 0,
        activePage: 1,
      }

    case ACTION_GET_TENANT:
    case ACTION_GET_TENANT_FAILURE:
      return {
        ...state,
        selectedTenantDetails: null,
      }
    case ACTION_GET_TENANT_SUCCESS:
      return {
        ...state,
        selectedTenantDetails: action.payload.data,
      }

    case ACTION_UPDATE_TENANT:
    case ACTION_UPDATE_TENANT_FAILURE:
    case ACTION_UPDATE_TENANT_CLEAR:
      return {
        ...state,
        updateTenantSubscription: {
          success: false,
          message: '',
        },
        selectedTenantDetails: null,
      }
    case ACTION_UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        updateTenantSubscription: {
          success: true,
          message: action.payload.data,
        },
      }

    case ACTION_BULK_UPLOAD_START:
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          success: false,
          message: null,
          inProgress: true,
          error: false,
        },
      }
    case ACTION_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          success: true,
          error: false,
          inProgress: false,
        },
      }
    case ACTION_BULK_UPLOAD_FAILURE:
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          success: false,
          message: action.payload.errorMessage,
          inProgress: false,
          error: action.payload.error,
        },
      }
    case ACTION_BULK_UPLOAD_CLEAR:
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          success: false,
          inProgress: false,
          error: false,
        },
      }

    case ACTION_GET_PC_BRANDING_START:
      return {
        ...state,
        branding: {
          success: false,
          errMgs: '',
        },
      }
    case ACTION_GET_PC_BRANDING_SUCCESS:
      return {
        ...state,
        branding: { ...action.payload.data },
      }
    case ACTION_GET_PC_BRANDING_FAILURE:
      return {
        ...state,
        branding: {
          success: false,
          errMgs: action.payload.errorMessage,
        },
      }

    case ACTION_UPDATE_PC_BRANDING_START:
      return {
        ...state,
        updateBranding: {
          success: false,
          errMgs: '',
        },
      }
    case ACTION_UPDATE_PC_BRANDING_SUCCESS:
      return {
        ...state,
        updateBranding: { ...action.payload.data },
        brandingUpdateStatus: { success: true },
      }
    case ACTION_UPDATE_PC_BRANDING_FAILURE:
      return {
        ...state,
        updateBranding: {
          success: false,
          errMgs: action.payload.errorMessage,
        },
      }
    case ACTION_UPDATE_PC_BRANDING_CLEAR:
      return {
        ...state,
        updateBranding: {
          success: false,
        },
      }
    case ACTION_TENANT_BUY_LICENSE:
    case ACTION_TENANT_BUY_LICENSE_FAILURE:
      return {
        ...state,
        isBuyLicensesSucess: false,
      }
    case ACTION_TENANT_BUY_LICENSE_SUCCESS:
      return {
        ...state,
        isBuyLicensesSucess: true,
      }

    case ACTION_GET_TENANT_AVAILABLE_LICENSE:
    case ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE:
      return {
        ...state,
        availableLicenses: null,
      }
    case ACTION_GET_TENANT_AVAILABLE_LICENSE_SUCCESS:
      return {
        ...state,
        availableLicenses: action.payload.data,
      }
    case ACTION_DELETE_TENANT:
    case ACTION_DELETE_TENANT_FAILURE:
      return {
        ...state,
        deleteTenant: { success: false },
      }
    case ACTION_DELETE_TENANT_SUCCESS:
      return {
        ...state,
        deleteTenant: { success: true },
      }

    case ACTION_SEND_EMAIL:
    case ACTION_SEND_EMAIL_FAILURE:
      return {
        ...state,
        sendEmail: { success: false },
      }
    case ACTION_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmail: { success: true },
      }

    case ACTION_GET_PC_HTML_CODE:
    case ACTION_GET_PC_HTML_CODE_FAILURE:
      return {
        ...state,
        getPCHTMLCode: { success: false },
        updatePCHTMLCode: { success: false },
      }
    case ACTION_GET_PC_HTML_CODE_SUCCESS:
      return {
        ...state,
        getPCHTMLCode: { success: true, data: action.payload.data },
        updatePCHTMLCode: { success: false },
        brandingUpdateStatus: { success: false },
      }

    case ACTION_UPDATE_PC_HTML_CODE:
    case ACTION_UPDATE_PC_HTML_CODE_FAILURE:
      return {
        ...state,
        updatePCHTMLCode: { success: false },
      }
    case ACTION_UPDATE_PC_HTML_CODE_SUCCESS:
      return {
        ...state,
        updatePCHTMLCode: { success: true },
      }
    case ACTION_GET_PC_URL_DEPLOYMENT_STATUS:
      return {
        ...state,
        getPCURLDeploymentStatus: { status: 'loading' },
      }
    case ACTION_GET_PC_URL_DEPLOYMENT_STATUS_FAILURE:
      return {
        ...state,
        getPCURLDeploymentStatus: { status: action.payload.status || 'failed' },
      }
    case ACTION_GET_PC_URL_DEPLOYMENT_STATUS_SUCCESS:
      return {
        ...state,
        getPCURLDeploymentStatus: { status: action.payload?.data ? 'success' : 'failed' },
      }
    case ACTION_FREE_TRIAL_DETAILS:
      return {
        ...state,
        freeTrialRemaningDays: action.payload?.data?.days_remaining,
        licensePlan: action.payload?.data?.license_plan,
        domainLimit: action.payload?.data?.domain_limit,
        domainsUsed: action.payload?.data?.domains_used,
        freePlanEditDomainLimit: action.payload?.data?.free_plan_edit_domain_limit,
        websiteVisitor: action.payload?.data?.website_scan_visitor,  
        old_domain_id: action.payload?.data?.old_domain_id,
        new_domain_id: action.payload?.data?.new_domain_id,
        is_app_sumo_user: action.payload?.data?.is_app_sumo_user,
      }

    case ACTION_GET_STRIPE_CHECKOUT_URL:
      return {
        ...state,
        checkoutUrl: action.payload.checkoutUrl,
        sessionId: action.payload.sessionId,
        checkoutUrlLoading: false,
        subscription_status: action.payload.subscription_status,
      }

    case ACTION_SET_STRIPE_CHECKOUT_LOADING:
      return {
        ...state,
        checkoutUrlLoading: action.payload.loading,
      }
    case ACTION_GET_STRIPE_PAYMENT_DETAILS:
      return {
        ...state,
        stripePaymentDetails: action.payload.data,
      }
    default:
      return state
  }
}
