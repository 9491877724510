import React from 'react'
import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import { I18n } from 'react-redux-i18n'
import Modal from 'Modules/Common/Components/VerticallyCenteredModal'

interface CommonConfirmModalProps {
  show: boolean
  setShow: (flag: boolean) => void
  onConfirm: () => void
  bodyChild?: JSX.Element
  bodyText?: string
  title?: string
}

const CommonConfirmModal = (props: CommonConfirmModalProps) => {
  const { show, setShow, onConfirm, bodyChild, bodyText, title } = props

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      heading={title || I18n.t('dsarWorkspace.confirmChanges')}
      dialogClassName="tableTrainingConfirmModal"
      bodyChild={
        bodyChild || (
          <div className="col-md-12 pl-0">
            <p className="ds-tt-modal-body pt-2 pb-2 mb-0">{bodyText}</p>
          </div>
        )
      }
      footerChild={
        <div className="row">
          <div className="d-flex w-100 align-items-center justify-content-end">
            <CancelButtonWithIcon
              onClick={() => setShow(false)}
              text={I18n.t('common.cancel')}
              x-automation-id="cancel"
              id="cancelBtn"
            />
            <ButtonWithIcon
              className="ml-2"
              text={I18n.t('common.confirm')}
              x-automation-id="confirm"
              id="confirmBtn"
              onClick={onConfirm}
            />
          </div>
        </div>
      }
    />
  )
}

export default CommonConfirmModal
