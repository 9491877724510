import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import { X } from 'react-feather'
import styles from './Domains.module.css'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { actionRemoveDomain, actionRemoveSubDomain, actionRemoveWebsiteDomain, actionScanWebsiteDomain } from './Actions/domainList.actions'
import cx from 'classnames'
import { addPrefixToDomain } from './DomainsList'

const WebsiteEditModel = ({ showwebsiteEditModel, setShowWebsiteEditModel, title, message, message_2, message_3 }) => {
  const dispatch = useDispatch()
  return (
    <div>
      <VerticallyCenteredModal
        dialogClassName={'websiteDeleteModelContainer'}
        show={showwebsiteEditModel.showBanner}
        onHide={() =>
          setShowWebsiteEditModel({
            showBanner: false,
          })
        }
        heading={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className={styles.cancelSubscriptionHeader}>{title}</span>
            <button
              type="button"
              role="closeIcon"
              id="TableFiltersSidebar-close-button"
              className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
              onClick={() => {
                setShowWebsiteEditModel({
                  showBanner: false,
                })
                dispatch(actionRemoveWebsiteDomain(showwebsiteEditModel.new_domainId, '1'))
              }}
            >
              <X />
            </button>
          </div>
        }
        bodyChild={
          <>
            <div className={cx('col-md-12 pl-0 w-100', 'deleteDomainMessage')}>
              {/* <b>{addPrefixToDomain(show.domainName)}&nbsp;</b> */}
              {message} <br />
              {message_2} <br />
              {message_3}
            </div>
          </>
        }
        footerChild={
          <div className="row w-100">
            <div className="d-flex w-100 align-items-center justify-content-end">
              <CancelButtonWithIcon
                onClick={() => {
                  setShowWebsiteEditModel({
                    domainName: '',
                    showBanner: false,
                  })
                  dispatch(actionRemoveWebsiteDomain(showwebsiteEditModel.new_domainId, '1'))
                }}
                text={I18n.t('common.cancel')}
                x-automation-id="cancel"
                id="cancelBtn"
              />
              <ButtonWithIcon
                className="ml-2"
                text={I18n.t('domains.editbuttonConfirm')}
                x-automation-id="donebutton"
                id="doneBtn"
                onClick={() => {
                  setShowWebsiteEditModel({
                    domainName: '',
                    showBanner: false,
                  })
                  dispatch(actionRemoveDomain(showwebsiteEditModel.old_domainId, '2'))
                  dispatch(actionScanWebsiteDomain(showwebsiteEditModel.new_domainId))
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default WebsiteEditModel
