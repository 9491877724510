export const consntDashboard = {
  requestTrend: 'Request Trend',
  weeks: 'Weeks',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
  last365Days: 'Last 365 days',
  viewBy: 'View by',
  dsarDashboardTitle: 'Consent Dashboard',
  view: 'View',
  days: 'Days',
  bannerViews: 'Unique Banner Views',
  bannerViewsToolTip:
    'Unique banner views is the no. of times consent banner is shown on a website to the visitor (A banner will be shown to the visitor with every new session of that visitor or consent expiration time -- whichever of the two occurs first)',
  tenant: 'Tenant',
  domains: 'Domain',
  cookies: 'Cookies',
  consent_ratio: 'Cookie Consent Ratio',
  consent_converastion: 'Consent Trend',
  consent_geolocation: 'Consent By Geolocation',
  consent_category: 'Consent By Category',
  noDataFound: 'No Data Found',
  first_party: '1st party',
  third_party: '3rd party',
  consent_accepted: 'Accepted',
  consent_rejected: 'Rejected',
  consent_partiallyaccepted: 'Partially Accepted',
  visitors: 'No of Consents',
}
