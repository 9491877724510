import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/tenant.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { TenantModel } from '../Models/tenant.models'

export const ACTION_GET_TENANT = 'ACTION_GET_TENANT'
export const ACTION_GET_TENANT_SUCCESS = 'ACTION_GET_TENANT_SUCCESS'
export const ACTION_GET_TENANT_FAILURE = 'ACTION_GET_TENANT_FAILURE'

export interface ActionGetTenant extends Action {
  type: typeof ACTION_GET_TENANT
}

export interface ActionGetTenantSuccess extends Action {
  type: typeof ACTION_GET_TENANT_SUCCESS
  payload: {
    data: TenantModel
  }
}

export interface ActionGetTenantFailure extends Action {
  type: typeof ACTION_GET_TENANT_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetTenantActions =
  | ActionGetTenant
  | ActionGetTenantFailure
  | ActionGetTenantSuccess

export const dispatchGetTenant = (): ActionGetTenant => ({
  type: ACTION_GET_TENANT,
})

const dispatchGetTenantSuccess = (data: TenantModel): ActionGetTenantSuccess => ({
  type: ACTION_GET_TENANT_SUCCESS,
  payload: {
    data,
  },
})

const dispatchGetTenantFailure = (error: Error): ActionGetTenantFailure => ({
  type: ACTION_GET_TENANT_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetTenant(
  tenantId: number
): (dispatch: Dispatch<GetTenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.getTenant(tenantId)
      if (result && result.success) {
        dispatch(dispatchGetTenantSuccess(result?.data))
      } else {
        if (result?.statusCode !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetTenantFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTenantFailure(error))
      loaderStop(dispatch)
    }
  }
}
