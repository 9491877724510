import React, { useEffect } from 'react'
import { useSelector, Provider } from 'react-redux'
import Home from './Modules/Home'
import { ThemeProvider } from 'styled-components'
import { AppStateType } from './Store/reducers'
import { BrowserRouter } from 'react-router-dom'
import LogOutAllTabs from './Utils/LogOutAllTabs'
import store from './Store/index'
import LoadingOverlay from 'react-loading-overlay-ts'
import { ToastContainer } from 'react-toastify'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  useEffect(() => {
    LogOutAllTabs.logOutAllTabs()
  })

  const { isLoading, loadingText } = useSelector((state: AppStateType) => state.LoaderReducer)

  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={false}
        theme="colored"
      />
      <BrowserRouter>
        <LoadingOverlay classNamePrefix="loading" active={isLoading} spinner text={loadingText}>
          <Home />
        </LoadingOverlay>
      </BrowserRouter>
    </Provider>
  )
}

export default App
