import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { BrandingModel } from '../Models'
import { ApiResponse } from 'Modules/Common/Models'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/tenantBranding.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_UPDATE_TENANT_BRANDING_START = 'ACTION_UPDATE_TENANT_BRANDING_START'
export const ACTION_UPDATE_TENANT_BRANDING_SUCCESS = 'ACTION_UPDATE_TENANT_BRANDING_SUCCESS'
export const ACTION_UPDATE_TENANT_BRANDING_FAILURE = 'ACTION_UPDATE_TENANT_BRANDING_FAILURE'
export const ACTION_UPDATE_TENANT_BRANDING_CLEAR = 'ACTION_UPDATE_TENANT_BRANDING_CLEAR'

export interface ActionUpdateTenantBrandingStart extends Action {
  type: typeof ACTION_UPDATE_TENANT_BRANDING_START
  payload: {}
}

export interface ActionUpdateTenantBrandingSuccess extends Action {
  type: typeof ACTION_UPDATE_TENANT_BRANDING_SUCCESS
  payload: {
    data: ApiResponse<string>
  }
}

export interface ActionUpdateTenantBrandingFailure extends Action {
  type: typeof ACTION_UPDATE_TENANT_BRANDING_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export interface ActionUpdateTenantBrandingClear extends Action {
  type: typeof ACTION_UPDATE_TENANT_BRANDING_CLEAR
  payload: {}
}

export type UpdateTenantBrandingActions =
  | ActionUpdateTenantBrandingStart
  | ActionUpdateTenantBrandingSuccess
  | ActionUpdateTenantBrandingFailure
  | ActionUpdateTenantBrandingClear

const dispatchUpdateTenantBrandingSuccess = (data: ApiResponse<string>): ActionUpdateTenantBrandingSuccess => ({
  type: ACTION_UPDATE_TENANT_BRANDING_SUCCESS,
  payload: { data },
})

const dispatchUpdateTenantBrandingFailure = (err: Error, success: boolean): ActionUpdateTenantBrandingFailure => ({
  type: ACTION_UPDATE_TENANT_BRANDING_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

export const dispatchUpdateTenantBrandingClear = (): ActionUpdateTenantBrandingClear => ({
  type: ACTION_UPDATE_TENANT_BRANDING_CLEAR,
  payload: {},
})

export function actionUpdateTenantBranding(
  data: BrandingModel
): (dispatch: Dispatch<UpdateTenantBrandingActions>) => Promise<void> {
  return async (dispatch: Dispatch<UpdateTenantBrandingActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.updateTenantBranding(data)
      if (result.statusCode === ResponseCodes.Success) {
        dispatch(dispatchUpdateTenantBrandingSuccess(result))
        toaster.success(I18n.t('tenant.brandingupdateMgs'))
      } else {
        if (data.logo) {
          toaster.error(I18n.t('tenant.failedLogo'))
        } else {
          toaster.error(I18n.t('tenant.generciError'))
        }
        dispatch(dispatchUpdateTenantBrandingFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdateTenantBrandingFailure(err, false))
    }
  }
}
