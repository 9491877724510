export const auth = {
  getInviteInfo: '/api/v1/tenants/users/register/details',
  verifyCode: '/api/v1/users/mfa/verify',
  resendCode: '/api/v1/users/mfa/resend',
  login: '/api/v1/users/login',
  verifyemail: '/api/v1/users/verify-email',
  logout: '/api/v1/users/logout',
  register: '/api/v1/users/signup',
  forgotPassword: '/api/v1/users/password/forgot',
  verifyForgotPasswordLink: '/api/v1/users/password/reset/verify',
  resetPassword: '/api/v1/users/password/reset',
  verifyImpersonateToken: '/api/v1/serviceprovider/impersonate_token/verify',
  getSPToken: '/api/v1/serviceprovider/sp_token',
  getRolesAndPermission: `/api/v1/users/get_roles_and_features_permission`,
}

export const consentManagement = {
  cookies: '/api/v1/cookies',
  cookiesGetTenants: '/api/v1/cookies/tenants',
  getDomains: '/api/v1/consent_template/get_domains',
  consentTemplate: '/api/v1/consent_template',
  getConsentLogs: '/api/v1/consent_logs',
  publishBanner: '/api/v1/consent_template/publish/banner',
  exportConsentLogs: '/api/v1/consent_logs/download_consent_logs',
  getWebsiteScreenShot: (domain_name: string) => `/api/v1/tenants/screenshot/preview/${domain_name}`,
}

export const requests = {
  requests: '/api/v1/dsar/requests',
  closeRequest: (requestId: number) => `/api/v1/dsar/requests/${requestId}`,
  exportRequests: '/api/v1/dsar/download_dsar_request',
  uploadReport: (tenantId: string, id: number) => `/api/v1/dsar/upload_report/${tenantId}/${id}`,
  getDSARReports: (tenantId: string, id: number) => `/api/v1/dsar/get_dsar_reports/${tenantId}/${id}`,
  updateDSARReport: (id: number, tenantId: string) => `/api/v1/dsar/${tenantId}/${id}/update`,
  downloadReport: (filename: string, id: number, tenantId: string) =>
    `/api/v1/dsar/download_report/${tenantId}/${id}/${filename}`,
  downloadDSARGuide: '/api/v1/dsar/download_dsarguide',
}

export const tenantManagement = {
  PCHTMLCode: (tenantId: string | number, domainId: string | number) =>
    `/api/v1/tenants/pc-url-code/${tenantId}/${domainId}`,
  pcBranding: (tenantId: string | number, domainId: string) =>
    `/api/v1/privacy-center/${tenantId}/${domainId}/branding`,
  addTenant: '/api/v1/tenants',
  deleteTenant: (tenantId: string) => `/api/v1/tenants/${tenantId}`,
  subscription: (tenantId: number) => `/api/v1/tenants/${tenantId}`,
  addnewTenantDomain: (tenantId: number) => `/api/v1/tenants/add_tenant_domains/${tenantId}`,
  subscriptionTypes: '/api/v1/tenants/subscription-types',
  extendFreeTrial: (tenantId: number) => `/api/v1/tenants/${tenantId}/extend_free_trial`,
  downloadSampleTemplateForBulkTenantCreation: '/api/v1/tenants/download_sample_template',
  tenantBulkUpload: '/api/v1/tenants/bulk_tenant_creation',
  availableLicenses: '/api/v1/tenants/available-license',
  buyLicenses: '/api/v1/tenants/buy_licenses',
  sendEmail: '/api/v1/serviceprovider/share_pc_url',
  tenantPCDeployment: (tenantId: number) => `/api/v1/tenants/${tenantId}/pcdeployment`,
  getImpersonateToken: (tenantId: number) => `/api/v1/serviceprovider/impersonate_token/${tenantId}`,
  PCURLDeployment: (tenantId: string, domainId: string) => `/api/v1/tenants/check-pc-url/${tenantId}/${domainId}`,
  freeTrialDetails: (tenantId: string) => `/api/v1/tenant_license_plan/tenant/${tenantId}`,
  sendSubscriptionToSalesTeam: (tenantId: string) => `/api/v1/tenants/request/subscription/${tenantId}`,
  getStripeCheckoutUrl: (tenantId: string) => `/api/v1/tenant_license_plan/tenant_subscription/${tenantId}`,
  getStripePricing: `/api/v1/tenant_license_plan/get_stripe_pricing`,
  visitorregistration: '/api/v1/tenants/visitor_registration',
  verifyuserlicense: '/api/v1/tenants/verify_appsumo_license',
  partnerregistration: '/api/v1/tenants/appsumo_signup_process',
}

export const common = {
  location: '/api/v1/locations',
  auditLogsCategory: '/api/v1/audit-logs/levels',
  auditLogsAction: '/api/v1/audit-logs/event_name',
  auditLogsEntity: '/api/v1/audit-logs/entity',
  KPAList: '/api/v1/classifications',
  department: '/api/v1/users/departments',
  businessFunction: '/api/v1/processing_activities/business_function',
  createentityCategory: '/api/v1/processing_activities/createentity_categories',
  getentityCategory: (entity_type: string) => `/api/v1/processing_activities/getentity_categories/${entity_type}`,
  processingActivitiesEntity: '/api/v1/processing_activities/entities',
  processingActivities: (tenantId: string) => `/api/v1/tenants/${tenantId}/processing_activities`,
  processingActivitiesDatasource: (processingactivityId: string) =>
    '/api/v1/processing_activities/data_sources/' + processingactivityId,
  processingActivitiesDatasourcePIData: (datasourceId: string, processingactivityId: string) =>
    '/api/v1/processing_activities/data_sources/' + datasourceId + '/' + processingactivityId,
}

export const userManagement = {
  addUserList: '/api/v1/users',
  checkEmail: '/api/v1/users/validation',
  getUserList: '/api/v1/users',
  validateDatasource: '/api/v1/datastores/search',
  permissions: '/api/v1/users/roles/permissions',
  deleteUser: (userId: number) => '/api/v1/users/' + userId,
}

export const datasourceManagement = {
  datasource: '/api/v1/datastores',
  datasourceConfig: '/api/v1/datastores/configs',
  testauth: '/api/v1/datastores/test-authentication',
  tableTraining: (guid: string) => `/api/v1/datasources/${guid}/table-trainings`,
  scanNow: (guid: string) => `/api/v1/datastores/${guid}/metadata-scan`,
  deleteDatasource: (guid: string) => `/api/v1/datastores/${guid}`,
  upgradePlan: '/api/v1/tenants/subscriptions/upgrade-request',
  verifySampleDSAR: (guid: string) => `/api/v1/datastores/${guid}/verify-sample-dsar`,
  viewSampleData: (guid: string) => `/api/v1/datastores/${guid}/sample-data`,
  getProcessingDetails: (guid: string) => `/api/v1/datastores/${guid}/processing-details`,
  updateProcessingDetails: (guid: string) => `/api/v1/datastores/${guid}/processing-details`,
}

export const scannerManagement = {
  scannerListDS: '/api/v1/scanners/ds_lists',
  scannerList: '/api/v1/scanners',
  updateScannerName: (scannerGuid: string) => `/api/v1/scanners/${scannerGuid}/details`,
  activateScanner: (scannerGuid: string) => `/api/v1/scanners/${scannerGuid}/activate`,
  pauseScanner: (scannerGuid: string) => `/api/v1/scanners/${scannerGuid}/pause`,
  resumeScanner: (scannerGuid: string) => `/api/v1/scanners/${scannerGuid}/resume`,
  getCSRFToken: '/api/v1/users/token/csrf',
}

export const dsarWorkspace = {
  ms365DownloadZip: (requestId: number) => `/api/v1/dsar/${requestId}/reports`,
  dsarWorkspaceList: '/api/v1/dsar/requests',
  assignReviewers: (requestId: number) => `/api/v1/dsar/${requestId}/users`,
  postReviewNote: (requestId: number) => `/api/v1/dsar/${requestId}/notes`,
  viewReport: (requestId: number) => `/api/v1/dsar/${requestId}/reports`,
  updateReport: (requestId: number, stageId: number) => `/api/v1/dsar/${requestId}/stages/${stageId}/review/attributes`,
  approveRequest: (requestId: number, stageId: number) => `/api/v1/dsar/${requestId}/stages/${stageId}/review`,
  autoDiscoveryDetailsById: (requestId: number, stageId: number) =>
    `/api/v1/dsar/requests/${requestId}/stages/${stageId}`,
  autoAssignDetailsById: (requestId: number) => `/api/v1/dsar/requests/${requestId}/user-tasks`,
  autoAssignAttributesDetails: (requestId: number, taskId: number) =>
    `/api/v1/dsar/requests/${requestId}/user-tasks/${taskId}/details`,
  notifyFailure: (requestId: number, stageId: number) =>
    `/api/v1/dsar/requests/${requestId}/stages/${stageId}/notify-failure`,
  retryDeleteValidation: (requestId: number, stageId: number) =>
    `/api/v1/dsar/${requestId}/stages/${stageId}/validate/retry`,
  confirmDeleteValidation: (requestId: number, stageId: number) =>
    `/api/v1/dsar/${requestId}/stages/${stageId}/validate/confirm`,
  resubmitDSAR: (requestId: number) => `/api/v1/dsar/${requestId}/re-submit`,
  submitDSARRequests: `/api/v1/dsar/submit-requests`,
  bulkImportDSARRequest: `/api/v1/dsar/file-upload`,
}

export const PCSettingsManagement = {
  pcBranding: '/api/v1/privacy-center/branding',
}
export const profileSettings = {
  changePassword: '/api/v1/users/password',
}

export const consentDashboard = {
  dsarDashboard: '/api/v1/dsar/dashboard',
  getUniqueBannerViews: '/api/v1/consent_dashboard/unique_banner_views',
  getCookieTypes: '/api/v1/consent_dashboard/cookie-types',
  getCookieByRatio: '/api/v1/consent_dashboard/cookie-consent-ratio',
  getConsentByGeoLocation: '/api/v1/consent_dashboard/consent/geo-location',
  getConsentByCookieCategories: '/api/v1/consent_dashboard/consent/category',

  getCostSavings: '/api/v1/dsar/dashboard/cost-savings',
  getTrendChatData: `/api/v1/consent_dashboard/consent_logs/count`,
  getOpenRequestData: '/api/v1/dsar/dashboard/open-requests',
  getPendingAction: '/api/v1/dsar/dashboard/pending-actions',
  dsarRequestbyChart: '/api/v2/dsar/dashboard/dsar-by-subject',
  dsarByLocation: '/api/v2/dsar/dashboard/dsar-by-location',
  getRegulationChartData: '/api/v1/dsar/dashboard/dsar-by-regulation',
  getRequestByType: '/api/v2/dsar/dashboard/dsar-by-type',
}

export const applicationHealth = {
  viewLicenseDataUsage: '/api/v1/subscriptions/usage',
  privacyCenterDetailsV1: (tenantId: string) => `/api/v1/tenants/${tenantId}/dashboards/health`,
  privacyCenterDetails: (tenantId: string) => `/api/v2/tenants/${tenantId}/dashboards/health`,
  dataScanSourcesV1: (tenantId: string) => `/api/v1/tenants/${tenantId}/dashboards/health/datasources`,
  dataScanSources: (tenantId: string) => `/api/v2/tenants/${tenantId}/dashboards/health/datasources`,
  auditLogs: (tenantId: string) => `/api/v1/tenants/${tenantId}/audit-logs/errors`,
  dataScanScannersV1: (tenantId: string) => `/api/v1/tenants/${tenantId}/dashboards/health/scanners`,
  dataScanScanners: (tenantId: string) => `/api/v2/tenants/${tenantId}/dashboards/health/scanners`,
}

export const settings = {
  emailTemplate: '/api/v1/settings/customise_email_template',
  emailTemplateImage: '/api/v1/settings/customise_email_template/image',
  tenantBranding: '/api/v1/tenants/sp_logo',
}

export const auditlog = {
  mainconsole: '/api/v1/audit-logs',
}

export const entity = {
  getEntityList: '/api/v1/entity/entities',
  createEntity: `/api/v1/entity/create_entities`,
  updateEntity: (entityId: string) => `/api/v1/entity/update_entity/${entityId}`,
  deleteEntity: (entityId: string) => `/api/v1/entity/delete_entity/${entityId}`,
  getEntity: (entityId: string) => `/api/v1/entity/get_entity/${entityId}`,
}

export const businessPurpose = {
  businessPurpose: '/api/v1/business_purpose',
  deleteBusinessPurpose: (id: string) => `/api/v1/business_purpose/${id}`,
  getBusinessPurpose: (id: string) => `/api/v1/business_purpose/${id}`,
  PIDataCategory: '/api/v1/business_purpose/get_pi_data_categories',
}

export const reports = {
  getRoPaList: (tenantId: string) => `/api/v1/tenants/${tenantId}/reports/ropa`,
  downloadRoPA: '/api/v1/tenants/donwload_ropa_report',
}

export const assessments = {
  assessments: '/api/v1/assessments',
  getAllEntities: '/api/v1/assessments/risk_assessment/get_all_entities',
  getAssessments: (id: string) => `/api/v1/assessments/${id}`,
  addEditRisk: (id: string) => `/api/v1/assessments/risk_assessment${id ? '/' + id : ''}`,
  getRiskById: (id: string) => `/api/v1/assessments/risk_assessment/${id}`,
  inherentRisk: (id: string) => `/api/v1/assessments/risk_assessment/${id}/inherent_risk`,
  residualRisk: (id: string) => `/api/v1/assessments/risk_assessment/${id}/residual_risk`,
  riskAction: (id: string) => `/api/v1/assessments/risk_assessment/${id}/risk_action`,
  riskTreatmentControls: (id: string) => `/api/v1/assessments/risk_assessment/${id}/risk_treatment_controls`,
  getRiskCategoryAndOwners: (id: string) => `/api/v1/assessments/dpia_category_pa_owners/${id}`,
  updateAssessments: (id: string) => `/api/v1/assessments/${id}`,
  getDPIAQuestionnaire: (id: string) => `/api/v1/assessments/${id}/dpia_assessment`,
  saveDPIAQuestionnaire: (id: string) => `/api/v1/assessments/${id}/dpia_assessment`,
  updateDPIAQuestionnaire: (id: string) => `/api/v1/assessments/${id}/dpia_assessment`,
  dpiaSteps: (id: string) => `/api/v1/assessments/${id}/dpia_steps`,
  risklisting: (id: string) => `/api/v1/assessments/${id}/get_risk_assessment`,
  getAssessmenetReviewDetails: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/review_assessment `,
  getAssessmentReviewComments: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/review_comments`,
  addAssessmentReviewComment: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/review_comment`,
  submitAssessmentForReview: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/submit`,
  assignedToOwner: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/assigned_to_owner`,
  markAsCompleted: (assessmentId: string) => `/api/v1/assessments/${assessmentId}/mark_as_completed`,
}

export const subscriptionScreen = {
  addSubscription: (id: string) => `/api/v1/users/features/subscription/${id}`,
  cancelFreeTrail: (id: string | number) => `/api/v1/tenants/cancel/subscription/${id}`,
  cancelProfessionalPlan: (id: string | number) => `/api/v1/tenant_license_plan/cancel_subscription/${id}`,
  cancelEnterprisePlan: (id: string | number) => `/api/v1/tenants/cancel/paid/subscription/${id}`,
  changePlan: (id: string | number) => `/api/v1/tenant_license_plan/change_plan/${id}`,
  getSubscription: (id: string | number) => `/api/v1/tenants/view/licence/${id}`,
}

export const cookiescanning = {
  addCookiScanning: (id: number) => `/api/v1/cookies/initial/scan/${id}`,
}

export const domains = {
  getDomainsList: `/api/v1/tenants/tenant_domain_list`,
  addDomain: '/api/v1/tenants/add_tenant_domains',
  addWebsiteDomain: '/api/v1/tenants/add_website_domain',
  removeWebsiteDomain: '/api/v1/tenants/remove_website_domain',
  editDomain: (id: number) => `/api/v1/tenants/update_tenant_domain/${id}`,
  editDomainServiceProvider: (id: number) => `/api/v1/tenants/update_sp_tenant_domain/${id}`,
  getDomainById: (id: number) => `/api/v1/tenants/get_tenant_domain/${id}`,
  removeSubdomain: (id: number) => `/api/v1/tenants/delete_tenant_sub_domain/${id}`,
  removeDomain: (id: number, flag: string) => `/api/v1/tenants/delete/domain/${id}/${flag}`,
  websiteScanning: (id: number) => `/api/v1/tenants/website_cookie_scanning/${id}`,
  checkValidDomain: `/api/v1/tenants/check_valid_domain`,
  getJsScript: `/api/v1/tenants/get/js_script`,
  verifyJsScript: `/api/v1/tenants/deploy/banner`,
  AppSumoTenantWebsiteScanning: '/api/v1/tenants/appsumo_website_cookie_scanning',
}
