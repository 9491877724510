import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { SendEmailPayload, TenantModel } from '../Models/tenant.models'
import service from '../Services/tenant.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'

export const ACTION_SEND_EMAIL = 'ACTION_SEND_EMAIL'
export const ACTION_SEND_EMAIL_SUCCESS = 'ACTION_SEND_EMAIL_SUCCESS'
export const ACTION_SEND_EMAIL_FAILURE = 'ACTION_SEND_EMAIL_FAILURE'
export const ACTION_SEND_EMAIL_CLEAR = 'ACTION_SEND_EMAIL_CLEAR'

export interface ActionSendEmail extends Action {
  type: typeof ACTION_SEND_EMAIL
}

export interface ActionSendEmailClear extends Action {
  type: typeof ACTION_SEND_EMAIL_CLEAR
}

export interface ActionSendEmailSuccess extends Action {
  type: typeof ACTION_SEND_EMAIL_SUCCESS
  payload: {
    data: string
  }
}

export interface ActionSendEmailFailure extends Action {
  type: typeof ACTION_SEND_EMAIL_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export type SendEmailActions =
  | ActionSendEmail
  | ActionSendEmailFailure
  | ActionSendEmailSuccess
  | ActionSendEmailClear

export const dispatchSendEmailClear = (): ActionSendEmailClear => ({
  type: ACTION_SEND_EMAIL_CLEAR,
})

const dispatchSendEmailSuccess = (data: string): ActionSendEmailSuccess => ({
  type: ACTION_SEND_EMAIL_SUCCESS,
  payload: {
    data,
  },
})

const dispatchSendEmailFailure = (
  error: Error,
  success: boolean
): ActionSendEmailFailure => ({
  type: ACTION_SEND_EMAIL_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionSendEmail(
  sendEmailPayload: SendEmailPayload
): (dispatch: Dispatch<SendEmailActions>) => Promise<void> {
  return async (dispatch: Dispatch<SendEmailActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.sendEmail(sendEmailPayload)
      if (result && result.success) {
        toaster.success(I18n.t('shareCodeModal.sendEmailSuccessMsg'))
        dispatch(dispatchSendEmailSuccess(result?.data))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchSendEmailFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchSendEmailFailure(error, false))
      loaderStop(dispatch)
    }
  }
}
