import { Action } from 'redux'
import { DomainsDropDownModel } from '../Models/ConsentDashboard.model'

export const ACTION_SET_SELECTED_REGULATION = 'ACTION_SET_SELECTED_REGULATION'
export const ACTION_RESET_SELECTED_REGULATION = 'ACTION_RESET_SELECTED_REGULATION'
export const ACTION_RESET_DASHBOARD_VALUES = 'ACTION_RESET_DASHBOARD_VALUES'

export interface ActionResetSelectedRegulation extends Action {
  type: typeof ACTION_RESET_SELECTED_REGULATION
}
export interface ActionResetDashboardValues extends Action {
  type: typeof ACTION_RESET_DASHBOARD_VALUES
}

export interface ActionSetSelectedRegulation extends Action {
  type: typeof ACTION_SET_SELECTED_REGULATION
  payload: DomainsDropDownModel
}

export type SetSelectedRegulationActions = ActionResetSelectedRegulation | ActionSetSelectedRegulation

export const dispatchSetSelectedRegulation = (DomainsDropDownModel): ActionSetSelectedRegulation => ({
  type: ACTION_SET_SELECTED_REGULATION,
  payload: DomainsDropDownModel,
})

export const dispatchResetSetSelectedRegulation = (): ActionResetSelectedRegulation => ({
  type: ACTION_RESET_SELECTED_REGULATION,
})

export const dispatchResetDashboardValues = (): ActionResetDashboardValues => ({
  type: ACTION_RESET_DASHBOARD_VALUES,
})
