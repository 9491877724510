import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { AppStateType } from 'Store/reducers'
import CircleItem from '../Common/Components/CircleItem'
import styles from './ConsentLogs.module.css'
import cx from 'classnames'
import { BasicDropdownModel } from 'Modules/Common/Models'
import ButtonWithIcon from 'Modules/Common/Components/Button'
import { ConsentLogsType, ConsentLogTab, ConsentStatus, ConsentLogsExportPayload } from './Models/consentLogs.model'
import SearchInput from 'Modules/Common/Components/SearchInput'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import { dispatchGetConsentLogs } from './Actions/getConsentLogs.actions'
import { actionExportRequest } from './Actions/export-ConsentLogs.actions'
import SelectDomains from 'Modules/Common/Components/SelectDomains'
import SelectDropdown from 'Modules/Common/Components/SelectDropdown/SelectDropdown'

interface ConsentLogsHeaderProps {
  activeFilters: ConsentLogsType
  setActiveFilters: React.Dispatch<React.SetStateAction<ConsentLogsType>>
}

const ConsentLogsHeader = ({ activeFilters, setActiveFilters }: ConsentLogsHeaderProps) => {
  const [search, setSearch] = useState('')
  const tabItems = [
    {
      title: I18n.t('consentLogs.website'),
      key: ConsentLogTab.Website,
      icon: null,
    },
  ]

  const consentStatusList: BasicDropdownModel[] = [
    { label: 'Consent Status - All', value: ConsentStatus.All },
    { label: 'Accepted', value: ConsentStatus.Accepted },
    { label: 'Rejected', value: ConsentStatus.Rejected },
    { label: 'Partially Accepted', value: ConsentStatus.PartiallyAccepted },
  ]
  const getDomainsList = useSelector((state: AppStateType) => state.BannersReducer?.getDomainsList)
  const { pageParams } = useSelector((state: AppStateType) => state.ConsentLogsReducer.privacy_center)
  const { totalRecords } = useSelector(
    (state: AppStateType) => state.ConsentLogsReducer[activeFilters.consent_category]
  )
  let { url } = useRouteMatch()
  let history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { domain_id, sub_domain_id, status: statusQueryParam, searchparam } = queryString.parse(location?.search)

  const getDefaultSelectedDomain = () => {
    if (parseInt(sub_domain_id)) {
      return getDomainsList?.data
        ?.find(item => {
          return parseInt(item.value) === parseInt(domain_id)
        })
        ?.subDomains?.find(item => parseInt(item.value) === parseInt(sub_domain_id))
    } else {
      return getDomainsList?.data?.find(item => {
        return parseInt(item.value) === parseInt(domain_id)
      })
    }
  }

  useEffect(() => {
    const defaultFilters = { ...activeFilters }
    if (!activeFilters?.selectedConsentStatus) {
      if (statusQueryParam) {
        defaultFilters['selectedConsentStatus'] = consentStatusList.find(
          status => status.value === statusQueryParam
        ) as {
          label: string
          value: ConsentStatus
        }
      } else {
        defaultFilters['selectedConsentStatus'] = consentStatusList[0] as {
          label: string
          value: ConsentStatus
        }
      }
    }

    if (getDomainsList?.success && getDomainsList?.data?.length > 0 && !activeFilters?.domain_id) {
      if (domain_id || sub_domain_id) {
        defaultFilters['domain_id'] = getDefaultSelectedDomain()
      } else {
        defaultFilters['domain_id'] = getDomainsList?.data[0] as {
          label: string
          value: ConsentStatus
        }
      }
    }

    setActiveFilters(defaultFilters)
    if (searchparam) {
      setSearch(searchparam)
      dispatch(dispatchGetConsentLogs({ ...pageParams, page_no: 1, search: search }, activeFilters.consent_category))
    }
  }, [getDomainsList])

  useEffect(() => {
    if (location?.search) {
      const { selectedTab: newSelectedTab } = queryString.parse(location?.search)
      if (newSelectedTab?.length) {
        if (Array.isArray(newSelectedTab) && newSelectedTab[0]) {
          onSelectionChange('consent_category', newSelectedTab[0])
        } else if (typeof newSelectedTab === 'string') {
          onSelectionChange('consent_category', newSelectedTab)
        }
      } else {
        history.push(`${url}?selectedTab=${ConsentLogTab.Website}`)
      }
    } else {
      history.push(`${url}?selectedTab=${ConsentLogTab.Website}`)
    }
  }, [location?.search])

  const getSelectedTab = () => {
    let activeTab = ''
    tabItems?.forEach(tab => {
      if (history?.location?.search?.includes(tab?.key) && activeTab === '') {
        activeTab = tab?.key
      }
    })
    if (activeTab) {
      return activeTab
    }
    return ''
  }

  const onTabClick = (tab: string) => {
    history.push(`${url}?selectedTab=${tab}`)
  }

  const onExportClick = () => {
    let consentStatus = activeFilters?.selectedConsentStatus?.value

    const payload: ConsentLogsExportPayload = {
      export: 'csv',
      export_type: 'All',
      consent_status: consentStatus,
      search: search,
      domain_id: activeFilters?.domain_id?.value,
    }
    dispatch(actionExportRequest(payload))
  }

  const onSelectionChange = (keyName: 'domain_id' | 'selectedConsentStatus' | 'consent_category', value) => {
    const filter = {
      ...activeFilters,
      [keyName]: value,
    }
    setActiveFilters(filter)
  }

  return (
    <>
      <div className="row w-100 p-0 m-0 pt-1">
        <div className="row w-100 p-0 m-0">
          <div className="col-xs-12 col-sm-6 col-md-4 col-ls-4 d-flex ">
            <div>
              <span className="page-heading-text">{I18n.t('consentLogs.consentLogs')}</span>
              <CircleItem text={totalRecords} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-8 col-ls-8 d-flex justify-content-end">
            <ButtonWithIcon
              type="button"
              onClick={() => {
                onExportClick()
              }}
              text={I18n.t('consentLogs.export')}
              iconToRight={true}
              x-automation-id="export-btn"
              id="export-btn"
              disabled={totalRecords === 0}
              className={'mx-2'}
            />
          </div>
        </div>
      </div>
      <div className="row w-100 p-0 m-0 pt-1">
        <div className={cx('row w-100 m-0 ml-3 mr-3', styles.boxshadow)}></div>
      </div>
      <div className="row w-100 p-0 m-0 mb-3 pt-3">
        <div className="ml-3 w-30">
          <SearchInput
            id="consent-log-search"
            automationEleId="consentLogSearchInput"
            placeholder={I18n.t('consentLogs.search')}
            value={search}
            onChange={({ target }) => {
              setSearch(target.value)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                dispatch(
                  dispatchGetConsentLogs({ ...pageParams, page_no: 1, search: search }, activeFilters.consent_category)
                )
              }
            }}
            handleClear={() => {
              setSearch('')
              dispatch(
                dispatchGetConsentLogs({ ...pageParams, page_no: 1, search: '' }, activeFilters.consent_category)
              )
            }}
          />
        </div>

        {activeFilters.consent_category == 'website' && (
          <div className={cx(styles.selectMinWidth, 'ml-3')}>
            <SelectDomains
              data={getDomainsList?.data}
              value={activeFilters?.domain_id}
              onChange={value => {
                onSelectionChange('domain_id', value)
              }}
            />
          </div>
        )}

        <div className={cx(styles.selectMinWidth, 'ml-3')}>
          <SelectDropdown
            id="content-status-dropdown"
            x-automation-id="content-status-dropdown"
            classNamePrefix="react-select"
            placeholder={I18n.t('consentLogs.selectConsentStatus')}
            className={`p-0`}
            isClearable={false}
            options={consentStatusList}
            value={activeFilters?.selectedConsentStatus as any}
            onChange={value => onSelectionChange('selectedConsentStatus', value)}
          />
        </div>
      </div>
    </>
  )
}
export default ConsentLogsHeader
