import React from 'react'
import { Modal } from 'react-bootstrap'

interface VerticallyCenteredModalProps {
  footerChild?: JSX.Element
  heading?: JSX.Element | string
  bodyChild: JSX.Element
  show: boolean
  onHide: () => void
  dialogClassName?: string
  closeButton?: boolean
  className?: string
}

function VerticallyCenteredModal(props: VerticallyCenteredModalProps) {
  const { show, onHide, footerChild, heading, bodyChild, dialogClassName, closeButton, className } = props
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
      backdrop="static"
      keyboard={false}
      className={className || ''}
    >
      {heading && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{bodyChild}</Modal.Body>
      {footerChild && <Modal.Footer>{footerChild}</Modal.Footer>}
    </Modal>
  )
}

export default VerticallyCenteredModal
