import React, { useEffect, useState } from 'react'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import { I18n } from 'react-redux-i18n'
import HTMLTemplateEditor from 'Modules/Common/Components/HTMLTemplateEditor'
import CopyIcon from 'Modules/Common/Components/CopyIcon'
import { Mail, X } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import { actionGetPCHTMLCode, actionUpdatePCHTMLCode, dispatchGetPCHTMLCode } from './Actions/pcHTMLCode.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { dispatchRefreshTenantList } from './Actions/tenant-list.actions'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import StepConnector from '@material-ui/core/StepConnector'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import VerifyDeployment from './VerifyDeployment'
import styled from 'styled-components'
import { checkCircleIcon } from 'Assets/Images'
import { actionGetPCBranding } from './Actions/getPCBranding.actions'
import VerifyScriptDeployment from './VerifyScriptDeployment'
import {
  actionGetJsScript,
  actionVerifyJsScript,
  dispatchRefetchDomainsList,
} from 'Modules/Domains/Actions/domainList.actions'
import CopyDoNotSellLink from './CopyDoNotSellLink'
import CustomizePrivacyCenter from './CustomizePrivacyCenter'
import { useForm } from 'react-hook-form'
import { BrandingModel } from './Models/tenant.models'
import { actionUpdatePCBranding } from './Actions/updatePCBranding.actions'
import cx from 'classnames'

export const Span = styled.span<{ active: boolean; completed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:hover {
    text-decoration: 'none';
  }
  background: ${props => {
    if (props.completed) {
      return '#FFF'
    }
    return props.active ? 'rgba(233, 105, 77, 0.2)' : 'rgba(0, 0, 0, 0.3)'
  }};
  border: ${props => {
    return props.active ? '1px solid #F26A21' : 'none'
  }};
  color: ${props => {
    return props.active ? '#F26A21' : '#ffffff'
  }};
`

const getNumber = (index: number, isCompleted: boolean) => {
  if (isCompleted) {
    return <img src={checkCircleIcon} width={21} />
  }
  return index + 1
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: '13px',
  },
  stepLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  stepContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minHeight: '300px',
  },
  optional: {
    top: '0px',
    position: 'relative',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
  },
}))

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#2a8a15',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#2a8a15',
    },
  },
  line: {
    borderColor: 'rgba(0, 0, 0, 0.2);',
    borderTopWidth: 1,
    borderRadius: 1,
  },
})(StepConnector)

interface CopyCodeModalProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
  pcURL?: string
  code?: string
  onSendEmailClick?: () => void
  setModifiedCode?: (modifiedCode: string) => void
  tenantId?: any
  rowData?: any
  isDeployScript?: boolean
  className?: string
  subDomainId?: number
}

function CopyCodeModal(props: CopyCodeModalProps) {
  const { isShowPopup, setIsShowPopup, tenantId, rowData, pcURL, isDeployScript, className, subDomainId } = props
  const [selectedImg, setSelectedImg] = useState<File>()

  const dispatch = useDispatch()
  const steps = isDeployScript
    ? [I18n.t('tenant.installScript'), I18n.t('tenant.verify')]
    : [
        I18n.t('tenant.customizePrivacyCenter'),
        I18n.t('tenant.updatePrivacyPolicyText'),
        I18n.t('tenant.verify'),
        I18n.t('tenant.addDoNotSellLink'),
      ]
  const classes = useStyles()

  const {
    register,
    getValues,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const [activeStep, setActiveStep] = useState<number>(0)
  const [PCHTMLCode, setPCHTMLCode] = useState<string>('')
  const [PCURLCOde, setPCURLCode] = useState<string>('')
  const [scriptURL, setScriptURL] = useState<string>(
    '<script src="' + window?.location?.origin + '/script.min.js' + '"></script>'
  )
  const [isDoneClick, setIsDoneClick] = useState<boolean>(false)
  const { getPCHTMLCode, updatePCHTMLCode, branding, brandingUpdateStatus } = useSelector(
    (state: AppStateType) => state?.TenantReducer
  )
  const getPCURLDeploymentStatus = useSelector((state: AppStateType) => state?.TenantReducer?.getPCURLDeploymentStatus)
  const scriptUrl = useSelector((state: AppStateType) => state.DomainsReducer.scriptUrl)
  const scriptDeployStatus = useSelector((state: AppStateType) => state.DomainsReducer.scriptDeployStatus)
  const userData = useSelector((state: AppStateType) => state.AuthReducer.userData)
  const [logoPath, setLogoPath] = useState<string>()

  const tenant_id = tenantId || parseInt(userData.tenantId)

  useEffect(() => {
    if (isDeployScript) {
      if (subDomainId) {
        dispatch(actionGetJsScript(tenant_id, rowData?.domain_id, subDomainId))
      } else {
        dispatch(actionGetJsScript(tenant_id, rowData?.domain_id))
      }
    } else {
      dispatch(actionGetPCHTMLCode(tenant_id, rowData?.domain_id))
      dispatch(actionGetPCBranding(tenant_id, rowData?.domain_id, false))
    }
  }, [])

  useEffect(() => {
    if (getPCHTMLCode?.success) {
      setPCHTMLCode(getPCHTMLCode?.data?.tenant_pc_html_code)
      setPCURLCode(getPCHTMLCode?.data?.pc_url)
    }
  }, [getPCHTMLCode])

  useEffect(() => {
    if (updatePCHTMLCode?.success) {
      if (isDoneClick) {
        dispatch(dispatchRefreshTenantList())
        setIsShowPopup(false)
      } else {
        dispatch(actionGetPCHTMLCode(tenant_id, rowData.domain_id))
      }
    }
  }, [updatePCHTMLCode])

  useEffect(() => {
    if (scriptUrl) {
      setScriptURL('<script class="cytrio-script" src="' + scriptUrl + '"></script>')
    } else {
      setScriptURL('<script class="cytrio-script" src="' + '"></script>')
    }
  }, [scriptUrl])

  const logoUrl = branding.data?.logo_url?.replace(/(^\w+:|^)\/\//, '')
  const termsOfUse = branding.data?.terms_of_use?.replace(/(^\w+:|^)\/\//, '')
  const privacyPolicy = branding.data?.privacy_policy?.replace(/(^\w+:|^)\/\//, '')
  const supportEmail = branding.data?.support_email

  useEffect(() => {
    reset({
      logo_url: logoUrl,
      terms_of_use: termsOfUse,
      privacy_policy: privacyPolicy,
      contact_email: branding.data?.contact_email,
      support_email: branding.data?.support_email,
    })
  }, [branding])

  useEffect(() => {
    if (logoUrl) {
      setLogoPath('https://' + logoUrl)
    }
  }, [logoUrl])

  const isPrivacyCenterCustomizationDone = !!logoUrl && !!termsOfUse && !!privacyPolicy && !!supportEmail

  useEffect(() => {
    if (isPrivacyCenterCustomizationDone && !isDeployScript) {
      setActiveStep(1)
    } else {
      setActiveStep(0)
    }
  }, [branding])

  useEffect(() => {
    if (brandingUpdateStatus?.success) {
      dispatch(actionGetPCHTMLCode(tenant_id, rowData?.domain_id))
      dispatch(actionGetPCBranding(tenant_id, rowData?.domain_id, false))
      setActiveStep(1)
    }
  }, [brandingUpdateStatus?.success])

  const doneButton = (
    <ButtonWithIcon
      type="button"
      text={'Finish'}
      onClick={() => {
        dispatch(dispatchRefreshTenantList())
        dispatch(dispatchRefetchDomainsList())
        setIsShowPopup(false)
      }}
    />
  )

  const deployScriptDoneButton =
    scriptDeployStatus === 'active' ? (
      <ButtonWithIcon
        type="button"
        text={'Finish'}
        onClick={() => {
          dispatch(dispatchRefreshTenantList())
          dispatch(dispatchRefetchDomainsList())
          setIsShowPopup(false)
        }}
      />
    ) : (
      <ButtonWithIcon
        type="button"
        text={'Retry'}
        disabled={scriptDeployStatus === 'in_progress'}
        onClick={() => {
          if (subDomainId) {
            dispatch(actionVerifyJsScript(tenant_id, rowData.domain_id, subDomainId))
          } else {
            dispatch(actionVerifyJsScript(tenant_id, rowData.domain_id))
          }
        }}
      />
    )

  const getPrivacyPolicyStepContent = activeStep => {
    if (activeStep === 0) {
      return (
        <CustomizePrivacyCenter
          register={register}
          errors={errors}
          logoPath={logoPath}
          setLogoPath={setLogoPath}
          setSelectedImg={setSelectedImg}
          setValue={setValue}
        />
      )
    } else if (activeStep === 1) {

      return (
        <>
          <div className="row p-0 m-0">
            <div className="col-md-12">
              <br/><br/><br/>
              <div className="copyAndPasteThisCodeText mb-2 w-100">{I18n.t('tenant.copyInPrivacyPolicyPageOfTheWebsitelink')}</div>
              <div className="d-flex">
                <label className="w-75">
                  <input id="scriptUrl" readOnly value={PCURLCOde} className="form-control mr-2" />
                </label>
                <div className="ml-2">
                  <CopyIcon data={PCURLCOde} copyText={I18n.t('common.copy')} />
                </div>
                <a
                  className="copyEmailCodeButton ml-2"
                  target="_blank"
                  rel="noreferrer"
                  href={
                    `mailto:?subject=Deploying Privacy Center URL for ${rowData?.domain_name}` +
                    `&body=Hello, %0D%0A %0D%0APlease copy the below link and add to the Privacy Policy page of your website ` +
                    `${rowData?.domain_name}. %0D%0A %0D%0A${PCURLCOde} %0D%0A %0D%0ARegards, %0D%0AThe Cytrio team
              `
                  }
                >
                  <Mail color="#2f00d9" size={18} className="mr-1" />
                  {I18n.t('tenant.emailCode')}
                </a>
              </div>
            </div>
          </div>
        </>
      )


    } else if (activeStep === 2) {
      return (
        <VerifyDeployment
          tenantId={tenant_id}
          domainId={rowData?.domain_id}
          pcUrl={pcURL}
          privacyPolicyURL={branding?.data?.privacy_policy}
        />
      )
    } else {
      return <CopyDoNotSellLink pcUrl={pcURL} />
    }
  }



  const getInstallScriptStepContent = stepIndex => {
    if (stepIndex === 0) {
      return (
        <>
          <div className="row p-0 m-0">
            <div className="col-md-12 ">
              <div className="copyAndPasteThisCodeText mb-2 w-100">{I18n.t('tenant.copyAndPasteThisCodeText')}</div>
              <div className="d-flex">
                <label className="w-75">
                  <input id="scriptUrl" readOnly value={scriptURL} className="form-control mr-2" />
                </label>
                <div className="ml-2">
                  <CopyIcon data={scriptURL} copyText={I18n.t('common.copy')} />
                </div>
                <a
                  className="copyEmailCodeButton ml-2"
                  target="_blank"
                  rel="noreferrer"
                  href={
                    `mailto:?subject=Consent Banner installation script for ${rowData?.domain_name}` +
                    `&body=Hello, %0D%0A %0D%0APlease copy and paste the below script tag after the <head> tag of the HTML code for the website ` +
                    `${rowData?.domain_name}. %0D%0A %0D%0A${scriptURL} %0D%0A %0D%0ARegards, %0D%0AThe Cytrio team
              `
                  }
                >
                  <Mail color="#2f00d9" size={18} className="mr-1" />
                  {I18n.t('tenant.emailCode')}
                </a>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return <VerifyScriptDeployment />
    }
  }

  const getStepBgColor = index => (activeStep === index ? ' #f97200' : activeStep < index ? 'rgba(0,0,0,0.3)' : '#fff')

  const content = (
    <div className={classes.root}>
      <div style={{ width: '70%', marginRight: 'auto', marginLeft: 'auto' }}>
        <Stepper connector={<QontoConnector />} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className={classes.label}>
                <div className={classes.stepLabel}>
                  <Span
                    style={{
                      marginTop: '-8px',
                      marginBottom: '6px',
                      color: '#fff',
                      backgroundColor: getStepBgColor(index),
                    }}
                    active={activeStep == index}
                    completed={activeStep > index}
                  >
                    {getNumber(index, activeStep > index)}
                  </Span>
                  <div className="d-flex flex-column justify-content-center">
                    {label}
                    {!isDeployScript && index === 3 ? (
                      <div className={classes.optional}>{`(${I18n.t('common.optional')})`}</div>
                    ) : null}
                  </div>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="mt-3">
        <Typography component={'span'} className={classes.stepContent}>
          {isDeployScript ? getInstallScriptStepContent(activeStep) : getPrivacyPolicyStepContent(activeStep)}
        </Typography>
      </div>
    </div>
  )

  const cancelButtonText = isDeployScript
    ? activeStep === 1
      ? I18n.t('common.willDoLater')
      : I18n.t('common.cancel')
    : activeStep === 3
    ? I18n.t('common.skip')
    : I18n.t('common.cancel')

  const isNextButtonDisabledForPrivacyCenter =
    (!isDeployScript && activeStep === 2 && getPCURLDeploymentStatus?.status !== 'success') || !isValid

  const handleCustomizePrivacyCenter = () => {
    const privacy_policy = getValues('privacy_policy')?.replace(/(^\w+:|^)\/\//, '')
    const terms_of_use = getValues('terms_of_use')?.replace(/(^\w+:|^)\/\//, '')
    let brandingData: BrandingModel = {
      logo_url: logoPath,
      logo: selectedImg,
      privacy_policy: 'https://' + privacy_policy,
      terms_of_use: 'https://' + terms_of_use,
      contact_email: getValues('contact_email'),
      support_email: getValues('support_email'),
    }

    if (isDirty) {
      dispatch(actionUpdatePCBranding(brandingData, tenant_id.toString(), true, false, rowData?.domain_id))
    } else {
      setActiveStep(prev => prev + 1)
    }
  }

  const handlePrivacyCenterNextButton = () => {
    if (activeStep === 0) {
      handleCustomizePrivacyCenter()
    } else {
      dispatch(actionUpdatePCHTMLCode({ tenant_pc_html_code: PCHTMLCode }, tenant_id, rowData?.domain_id, false))
      dispatch(dispatchGetPCHTMLCode())
      setActiveStep(prev => prev + 1)
    }
  }

  const handleScriptDeploymentNextButton = () => {
    setActiveStep(prev => prev + 1)
    if (subDomainId) {
      dispatch(actionVerifyJsScript(tenant_id, rowData.domain_id, subDomainId))
    } else {
      dispatch(actionVerifyJsScript(tenant_id, rowData.domain_id))
    }
  }
  return (
    <>
      <VerticallyCenteredModal
        className={className}
        heading={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>{isDeployScript ? I18n.t('tenant.deployConsentBanner') : I18n.t('tenant.deploy')}</span>
            <button
              type="button"
              role="closeIcon"
              id="TableFiltersSidebar-close-button"
              className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
              onClick={() => setIsShowPopup(false)}
            >
              <X />
            </button>
          </div>
        }
        show={isShowPopup}
        dialogClassName="PCURLModal"
        x-automation-id="send-model-popup"
        bodyChild={content}
        onHide={() => setIsShowPopup(false)}
        footerChild={
          <div className="col-md-12 d-flex justify-content-between pr-1 pb-1">
            <div className="d-flex">
              {activeStep !== 0 ? (
                <CancelButtonWithIcon
                  type="button"
                  text={I18n.t('common.back')}
                  onClick={() => setActiveStep(prev => prev - 1)}
                  className="mr-2"
                />
              ) : null}
            </div>
            <div className="d-flex">
              <CancelButtonWithIcon
                type="button"
                text={cancelButtonText}
                onClick={() => setIsShowPopup(false)}
                className="mr-2"
              />
              {activeStep === 0 || (!isDeployScript && activeStep === 1) || (!isDeployScript && activeStep === 2) ? (
                <ButtonWithIcon
                  type="button"
                  text={I18n.t('common.next')}
                  disabled={isNextButtonDisabledForPrivacyCenter}
                  onClick={() => {
                    if (isDeployScript) {
                      handleScriptDeploymentNextButton()
                    } else {
                      handlePrivacyCenterNextButton()
                    }
                  }}
                />
              ) : isDeployScript ? (
                deployScriptDoneButton
              ) : (
                doneButton
              )}
            </div>
          </div>
        }
      />
    </>
  )
}

export default React.memo(CopyCodeModal)
