import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { EmailTemplateModel } from '../Models/index'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/emailTemplate.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_UPDATE_EMAIL_TEMPLATE = 'ACTION_UPDATE_EMAIL_TEMPLATE'
export const ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS = 'ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS'
export const ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE = 'ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE'

export const ACTION_GET_EMAIL_TEMPLATE = 'ACTION_GET_EMAIL_TEMPLATE'
export const ACTION_GET_EMAIL_TEMPLATE_SUCCESS = 'ACTION_GET_EMAIL_TEMPLATE_SUCCESS'
export const ACTION_GET_EMAIL_TEMPLATE_FAILURE = 'ACTION_GET_EMAIL_TEMPLATE_FAILURE'

export interface ActionUpdateEmailTemplate extends Action {
  type: typeof ACTION_UPDATE_EMAIL_TEMPLATE
}

export interface ActionUpdateEmailTemplateSuccess extends Action {
  type: typeof ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS
}

export interface ActionUpdateEmailTemplateFailure extends Action {
  type: typeof ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionGetEmailTemplate extends Action {
  type: typeof ACTION_GET_EMAIL_TEMPLATE
}

export interface ActionGetEmailTemplateSuccess extends Action {
  type: typeof ACTION_GET_EMAIL_TEMPLATE_SUCCESS
  payload: EmailTemplateModel
}

export interface ActionGetEmailTemplateFailure extends Action {
  type: typeof ACTION_GET_EMAIL_TEMPLATE_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export type EmailTemplateActions =
  | ActionUpdateEmailTemplate
  | ActionUpdateEmailTemplateSuccess
  | ActionUpdateEmailTemplateFailure
  | ActionGetEmailTemplate
  | ActionGetEmailTemplateSuccess
  | ActionGetEmailTemplateFailure

const dispatchUpdateEmailTemplateSuccess = (): ActionUpdateEmailTemplateSuccess => ({
  type: ACTION_UPDATE_EMAIL_TEMPLATE_SUCCESS,
})

const dispatchUpdateEmailTemplateFailure = (err: Error, success: boolean): ActionUpdateEmailTemplateFailure => ({
  type: ACTION_UPDATE_EMAIL_TEMPLATE_FAILURE,
  payload: { errorMessage: err.message, success },
})

export function actionUpdateEmailTemplate(
  request: EmailTemplateModel
): (dispatch: Dispatch<EmailTemplateActions>) => Promise<void> {
  return async (dispatch: Dispatch<EmailTemplateActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.updateEmailTemplate(request)
      if (result.code === ResponseCodes.Success) {
        dispatch(dispatchUpdateEmailTemplateSuccess())
        toaster.success(I18n.t('settings.toast.inviteTenantEmailTemplateUpdated'))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchUpdateEmailTemplateFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdateEmailTemplateFailure(err, false))
    }
  }
}

export const dispatchGetEmailTemplate = (): ActionGetEmailTemplate => ({
  type: ACTION_GET_EMAIL_TEMPLATE,
})

const dispatchGetEmailTemplateSuccess = (data: EmailTemplateModel): ActionGetEmailTemplateSuccess => ({
  type: ACTION_GET_EMAIL_TEMPLATE_SUCCESS,
  payload: data,
})

const dispatchGetEmailTemplateFailure = (err: Error, success: boolean): ActionGetEmailTemplateFailure => ({
  type: ACTION_GET_EMAIL_TEMPLATE_FAILURE,
  payload: { errorMessage: err.message, success },
})

export function actionGetEmailTemplate(request: string): (dispatch: Dispatch<EmailTemplateActions>) => Promise<void> {
  return async (dispatch: Dispatch<EmailTemplateActions>) => {
    try {
      loaderStart(dispatch)
      dispatch(dispatchGetEmailTemplate())
      const result = await service.getEmailTemplate(request)
      if (result.code === ResponseCodes.Success) {
        dispatch(dispatchGetEmailTemplateSuccess(result.data))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchGetEmailTemplateFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetEmailTemplateFailure(err, false))
    }
  }
}
