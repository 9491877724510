import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/ConsentDashboard.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { DSARDashboardResponseModel } from '../Models/ConsentDashboard.model'

export const ACTION_GET_DSAR_DASHBOARD_START = 'ACTION_GET_DSAR_DASHBOARD_START'
export const ACTION_GET_DSAR_DASHBOARD_SUCCESS = 'ACTION_GET_DSAR_DASHBOARD_SUCCESS'
export const ACTION_GET_DSAR_DASHBOARD_FAILURE = 'ACTION_GET_DSAR_DASHBOARD_FAILURE'

export interface ActionGetDSARDashboard extends Action {
  type: typeof ACTION_GET_DSAR_DASHBOARD_START
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionGetDSARDashboardSuccess extends Action {
  type: typeof ACTION_GET_DSAR_DASHBOARD_SUCCESS
  payload: DSARDashboardResponseModel
}

export interface ActionGetDSARDashboardFailure extends Action {
  type: typeof ACTION_GET_DSAR_DASHBOARD_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetDSARDashboardActions =
  | ActionGetDSARDashboard
  | ActionGetDSARDashboardFailure
  | ActionGetDSARDashboardSuccess

export const dispatchGetDSARDashboard = (startDate: string, endDate: string): ActionGetDSARDashboard => ({
  type: ACTION_GET_DSAR_DASHBOARD_START,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetDSARDashboardSuccess = (data: DSARDashboardResponseModel): ActionGetDSARDashboardSuccess => ({
  type: ACTION_GET_DSAR_DASHBOARD_SUCCESS,
  payload: data,
})

const dispatchGetDSARDashboardFailure = (error: Error, code?: number): ActionGetDSARDashboardFailure => ({
  type: ACTION_GET_DSAR_DASHBOARD_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetDSARDashboard(
  startDate: number,
  endDate: number,
  isLoader: boolean = true
): (dispatch: Dispatch<GetDSARDashboardActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetDSARDashboardActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getDSARDashboard(startDate, endDate)
      if (result && result.success) {
        dispatch(dispatchGetDSARDashboardSuccess({ ...result.data, startDate, endDate }))
      } else {
        toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(
          dispatchGetDSARDashboardFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetDSARDashboardFailure(error))
      loaderStop(dispatch)
    }
  }
}
