import { http } from '../../../Utils/AxiosWrapper'
import { consentDashboard } from '../../Common/Constants/ApiUrls'
import { ApiResponse, CountryDataMapModel } from 'Modules/Common/Models'
import { RegulationTypes } from 'Modules/Common/Enums/Common'

interface ConsentByGeoLocationService {
  getConsentByGeoLocation(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string
  ): Promise<ApiResponse<CountryDataMapModel>>
}

class ConsentByGeoLocationServiceDefault implements ConsentByGeoLocationService {
  async getConsentByGeoLocation(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string
  ): Promise<ApiResponse<CountryDataMapModel>> {
    let response: ApiResponse<CountryDataMapModel>
    try {
      const params: any = {
        start_date: startDate,
        end_date: endDate,
      }
      if (selectedTenant && selectedTenant !== '') {
        params.tenant_id = selectedTenant
      }
      if (selectedDomain && selectedDomain !== '') {
        params.domain_id = selectedDomain
      }
      if (selectedSubDomain && selectedSubDomain !== '') {
        params.sub_domain_id = selectedSubDomain
      }
      const { data } = await http.get(consentDashboard.getConsentByGeoLocation, {
        params,
      })
      // const data   =  {"code":2000,"data":{"South Africa":{"no_consent":18}, "India":{"no_consent":8},"China":{"no_consent":45}},"message":"Cookies by Type Data Fetched Successfully."}

      response = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: data?.data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new ConsentByGeoLocationServiceDefault()
