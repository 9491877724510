import React, { useState } from 'react'
import styles from './TenantList.module.css'
import { Check } from 'react-feather'
import { cx } from '@emotion/css'
import { I18n } from 'react-redux-i18n'
import CopyCodeModal from '../CopyCodeModal'
import { BootstrapTooltip } from 'Modules/Common/Components/BootstrapTooltip'

export interface CookieBannerProps {
  status: string
  title?: string
  data: any
  subDomainId?: number
  tenantId: number
}

export default function CookieBanner({ status, title, data, subDomainId, tenantId }: CookieBannerProps) {
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  const styleStatusMap = {
    active: {
      title: I18n.t('tenant.active'),
      className: styles.activeStatus,
    },
    error: {
      title: I18n.t('tenant.error'),
      className: styles.errorStatus,
    },
    pending_deployment: {
      title: I18n.t('tenant.pendingDeployment'),
      className: styles.pendingStatus,
    },
  }

  const className = styleStatusMap[status]
    ? styleStatusMap[status].className
    : styleStatusMap['pending_deployment'].className

  const titleByStatus = styleStatusMap[status]
    ? styleStatusMap[status]?.title
    : styleStatusMap['pending_deployment']?.title

  const cookieBannerTitle = title ? title : titleByStatus

  return (
    <div className={cx('columnContainer', styles.mainContainer)}>
      <div>
        {status === 'error' ? (
          <BootstrapTooltip title={I18n.t('tenant.scriptNotInstalledTooltipMsg')}>
            <div className={className}>{cookieBannerTitle}</div>
          </BootstrapTooltip>
        ) : (
          <div className={className}>
            {status === 'active' ? <Check className={styles.checkIcon} /> : null}
            {cookieBannerTitle}
          </div>
        )}
      </div>
      {status !== 'active' && (
        <button className={cx(styles.deployButton, 'mt-2')} onClick={() => setIsShowPopup(true)}>
          {I18n.t('tenant.deploy')}
        </button>
      )}

      {isShowPopup ? (
        <CopyCodeModal
          rowData={data}
          tenantId={tenantId}
          subDomainId={subDomainId}
          isDeployScript={true}
          isShowPopup={isShowPopup}
          setIsShowPopup={setIsShowPopup}
          className={'copyModalContainer'}
        />
      ) : null}
    </div>
  )
}
