import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/tenant.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { AvailableLicenses } from '../Models/tenant.models'

export const ACTION_GET_TENANT_AVAILABLE_LICENSE = 'ACTION_GET_TENANT_AVAILABLE_LICENSE'
export const ACTION_GET_TENANT_AVAILABLE_LICENSE_SUCCESS = 'ACTION_GET_AVAILABLE_LICENSE_SUCCESS'
export const ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE = 'ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE'

export interface ActionGetTenantAvailableLicenses extends Action {
  type: typeof ACTION_GET_TENANT_AVAILABLE_LICENSE
}

export interface ActionGetTenantAvailableLicensesSuccess extends Action {
  type: typeof ACTION_GET_TENANT_AVAILABLE_LICENSE_SUCCESS
  payload: {
    data: AvailableLicenses
  }
}

export interface ActionGetTenantAvailableLicensesFailure extends Action {
  type: typeof ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetTenantAvailableLicensesActions =
  | ActionGetTenantAvailableLicenses
  | ActionGetTenantAvailableLicensesFailure
  | ActionGetTenantAvailableLicensesSuccess

export const dispatchGetTenantAvailableLicenses = (): ActionGetTenantAvailableLicenses => ({
  type: ACTION_GET_TENANT_AVAILABLE_LICENSE,
})

const dispatchGetTenantAvailableLicensesSuccess = (data: AvailableLicenses): ActionGetTenantAvailableLicensesSuccess => ({
  type: ACTION_GET_TENANT_AVAILABLE_LICENSE_SUCCESS,
  payload: {
    data,
  },
})

const dispatchGetTenantAvailableLicensesFailure = (error: Error): ActionGetTenantAvailableLicensesFailure => ({
  type: ACTION_GET_TENANT_AVAILABLE_LICENSE_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetTenantAvailableLicense(
): (dispatch: Dispatch<GetTenantAvailableLicensesActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTenantAvailableLicensesActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.getAvailableLicenses()
      if (result && result.success) {
        dispatch(dispatchGetTenantAvailableLicensesSuccess(result?.data))
      } else {
        if (result?.statusCode !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetTenantAvailableLicensesFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTenantAvailableLicensesFailure(error))
      loaderStop(dispatch)
    }
  }
}
