import { http } from '../../../Utils/AxiosWrapper'
import { ConsentTemplateModel } from '../Models/banner.models'
import { consentManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse, PublishConsentTemplate } from 'Modules/Common/Models'

interface ConsentTemplateServiceProps {
  getConsentTemplate(domain_id: string): Promise<ApiResponse<ConsentTemplateModel>>
}

class ConsentTemplateService implements ConsentTemplateServiceProps {
  public async getConsentTemplate(
    domain_id: string,
    sub_domain_id?: string
  ): Promise<ApiResponse<ConsentTemplateModel>> {
    let response: ApiResponse<ConsentTemplateModel>
    try {
      const { data }: any = await http.get(consentManagement.consentTemplate, { params: { domain_id, sub_domain_id } })
      response = {
        success: true,
        message: data?.message,
        code: data.code,
        data: data.data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async publishConsentTemplate(
    tenant_id: string,
    payload: any,
    domain_id?: string,
    sub_domain_id?: string
  ): Promise<ApiResponse<PublishConsentTemplate>> {
    let response: ApiResponse<PublishConsentTemplate>
    try {
      const { data }: any = await http.post(consentManagement.publishBanner, payload, {
        params: { domain_id, tenant_id, sub_domain_id },
      })
      response = {
        success: true,
        message: data?.message,
        code: data.code,
        data: data.data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async getWebsiteScreenShot(domain_name?: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.get(consentManagement.getWebsiteScreenShot(domain_name))
      response = {
        success: true,
        message: data?.message,
        data: data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new ConsentTemplateService()
