import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { http } from 'Utils/AxiosWrapper'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { auth } from '../../Common/Constants/ApiUrls'

export interface VerifyEmailRequest {
  email: string
}

export interface VerifyEmailResponse {
  success: boolean
  message: string
  code: number
}

export interface VerifyEmailService {
  verifyemail(request: VerifyEmailRequest): Promise<VerifyEmailResponse>
}

class VerifyEmailServiceDefault implements VerifyEmailService {
  async verifyemail(request: VerifyEmailRequest): Promise<VerifyEmailResponse> {
    const bodyJSONData = {
      email: request.email,
    }
    let result: VerifyEmailResponse
    try {
      const { data } = await http.post(auth.verifyemail, bodyJSONData)
      result = VerifyEmailServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): VerifyEmailResponse {
    if (!data) {
      return {
        success: false,
        message: 'Error no data in response',
        code: 4000,
      }
    }
    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new VerifyEmailServiceDefault()
