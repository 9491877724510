import React, { useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import styled from 'styled-components'

const Div = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.08);
  z-index: 1;
  display: flex;
  align-items: center;
`

type Props = {
  classname?: string
  children: any
}

const FixedBottomRow = ({ classname, children }: Props) => {
  const [sidebarWidth, setSidebarWidth] = useState<string>('')

  const updateSize = () => {
    setSidebarWidth(document?.getElementById('sidebarContainer')?.offsetWidth + 'px')
  }

  useLayoutEffect(() => {
    window?.addEventListener('resize', updateSize)
    if (document?.getElementById('sidebarContainer')) {
      document?.getElementById('sidebarContainer')?.addEventListener('resize', updateSize)
      new ResizeObserver(updateSize).observe(document?.getElementById('sidebarContainer'))
    }
    window?.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window?.removeEventListener('resize', updateSize)
      if (document?.getElementById('sidebarContainer')) {
        document?.getElementById('sidebarContainer')?.removeEventListener('resize', updateSize)
      }
    }
  }, [])

  return (
    <Div className={classnames('row pt-2', classname || '')} style={{ paddingLeft: sidebarWidth }}>
      {children}
    </Div>
  )
}

export default FixedBottomRow
