import React, { useCallback, useEffect, useState } from 'react'
import { alertTriangleIcon, checkCircleIcon, exlamationCircleIcon } from 'Assets/Images'
import parse from 'html-react-parser'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { AppStateType } from 'Store/reducers'
import styled from 'styled-components'
import { actionGetPCURLDeploymentStatus } from './Actions/PCURLDeployment.actions'

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  tenantId: any
  pcUrl: string
  privacyPolicyURL: string
  domainId: string
}

const VerifyDeployment = (props: Props) => {
  const { tenantId, pcUrl, domainId, privacyPolicyURL } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [state, setState] = useState<
    'success' | 'script_not_installed' | 'pc_url_not_detected' | 'loading' | 'not_found'
  >()

  const getPCURLDeploymentStatus = useSelector((state: AppStateType) => state?.TenantReducer?.getPCURLDeploymentStatus)
  const { branding } = useSelector((state: AppStateType) => state?.TenantReducer)

  useEffect(() => {
    if (privacyPolicyURL) {
      setState('loading')
      dispatch(actionGetPCURLDeploymentStatus(tenantId, domainId))
    }
  }, [])

  useEffect(() => {
    if (getPCURLDeploymentStatus?.status) {
      if (getPCURLDeploymentStatus?.status == 'loading') {
        setState('loading')
      } else if (getPCURLDeploymentStatus?.status == 'failed') {
        setState('pc_url_not_detected')
      } else if (getPCURLDeploymentStatus?.status == 'success') {
        setState('success')
      } else if (getPCURLDeploymentStatus?.status == 'not_found') {
        setState('not_found')
      }
    }
  }, [getPCURLDeploymentStatus])

  const sucessState = messageText => {
    return (
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={checkCircleIcon} className="mr-1 " width={50} />
          <div className="messageText textSuccess mt-2">{messageText} </div>
        </div>
      </div>
    )
  }

  const errorState = (messageText, subText) => {
    return (
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={exlamationCircleIcon} className="mr-1 " width={50} />
          <div className="messageText textError text-center mt-2">{messageText}</div>
          <div className="subText mt-1">{parse(subText)}</div>
        </div>
      </div>
    )
  }

  const errorNotFoundState = () => {
    return (
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={exlamationCircleIcon} className="mr-1 " width={50} />
          <div className="messageText textError text-center mt-2">{I18n.t('tenant.privacyPolicyPageNotReachable')}</div>
          <div className="subText mt-1">
            Please ensure the URL for Privacy Policy page of your website is correct and active&nbsp;
            <a className="errorBlock__link_black" href={branding?.data?.privacy_policy} target="_blank">
              {branding?.data?.privacy_policy}
            </a>{' '}
            <a className="errorBlock__link" onClick={() => history.push('/privacy-center/branding')}>
              Edit URL
            </a>
          </div>
        </div>
      </div>
    )
  }

  const renderMessageByState = useCallback(() => {
    switch (state) {
      case 'success':
        return sucessState(I18n.t('tenant.deploymentSuccesfullMsg'))
      case 'script_not_installed':
        return errorState(I18n.t('tenant.scriptNotInstalledMsg'), I18n.t('tenant.scriptNotInstalledSubMsg'))
      case 'pc_url_not_detected':
        const pcUrlLink = `<a href="${pcUrl}" target="_blank">${pcUrl}</a>`
        const subMessage = `Please ensure ${pcUrlLink} is added in the Privacy Policy page of the website`
        return errorState(I18n.t('tenant.pcUrlMsg'), subMessage)
      case 'not_found':
        return errorNotFoundState()
      case 'loading':
        return <LoaderContainer>Verifying deployment...</LoaderContainer>
      default:
        return <></>
    }
  }, [state])

  return (
    <>
      {branding?.success && branding?.data?.privacy_policy == '' ? (
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <div className="errorBlock">
            <div>
              <img src={alertTriangleIcon} width={20} />
            </div>
            <div className="errorBlock__text">{I18n.t('tenant.privacyPolicyLinkMissingError')}</div>
            <a className="errorBlock__link" onClick={() => history.push('/privacy-center/branding')}>
              {I18n.t('tenant.clickToAddPrivacyPolicyURL')}
            </a>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center verifyContainer">{renderMessageByState()}</div>
      )}
    </>
  )
}

export default VerifyDeployment
