import React from 'react'
import styles from '../Auth.module.css'
import { AlertTriangle, ArrowRight } from 'react-feather'
import redirectToUrl from 'Utils/redirectToUrl'
import { I18n } from 'react-redux-i18n'
export default function SignupLinkExpired() {
  const handleSignUp = () => {
    redirectToUrl('https://cytrio.com/free-trial/')
  }

  return (
    <div className={styles.linkExpiredContainer}>
      <div className={styles.linkExpiredDiv}>
        <div className="AlertIcon">
          <AlertTriangle size={40} color="#E90000" />
        </div>
        <div className={styles.linkExpiredText}>{I18n.t('register.linkExpiryMessage')}</div>
        <div className={styles.clickOnSignUpButton}>{I18n.t('register.clickOnSignUpButton')}</div>
        <div className={styles.resendLinkButton} onClick={handleSignUp}>
          {I18n.t('register.signUp')}
          <ArrowRight color="#fff" className={'ml-2'} />
        </div>
      </div>
    </div>
  )
}
