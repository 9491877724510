import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import service from '../Services/index'
import Toaster from '../../Common/Services/Toaster'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'

export const ACTION_GET_SUBSCRIPTION = 'ACTION_GET_SUBSCRIPTION'
export const ACTION_CANCEL_FREE_TRIAL_OR_PLAN = 'ACTION_CANCEL_FREE_TRIAL_OR_PLAN'
export const ACTION_CHANGE_PLAN = 'ACTION_CHANGE_PLAN'
export const ACTION_LOADING = 'ACTION_LOADING'
export const ACTION_CANCEL_PROFESSIONAL_PLAN = 'ACTION_CANCEL_PROFESSIONAL_PLAN'
export const ACTION_CANCEL_ENTERPRISE_PLAN = 'ACTION_CANCEL_ENTERPRISE_PLAN'

export interface ActionGetSubscription extends Action {
  type: typeof ACTION_GET_SUBSCRIPTION
  payload: {
    data: any
  }
}

export interface ActionCancelFreeTrailorPlan extends Action {
  type: typeof ACTION_CANCEL_FREE_TRIAL_OR_PLAN
  payload: {
    message: string
  }
}
export interface ActionCancelProfessionalPlan extends Action {
  type: typeof ACTION_CANCEL_PROFESSIONAL_PLAN
  payload: {
    message: string
  }
}
export interface ActionCancelEnterprisePlan extends Action {
  type: typeof ACTION_CANCEL_ENTERPRISE_PLAN
  payload: {
    message: string
  }
}

export interface ActionChangePlan extends Action {
  type: typeof ACTION_CHANGE_PLAN
  payload: {
    message: string
  }
}

export interface ActionLoading extends Action {
  type: typeof ACTION_LOADING
  payload: {
    loading: boolean
  }
}

export const dispatchActionGetSubscription = (data: string): ActionGetSubscription => ({
  type: ACTION_GET_SUBSCRIPTION,
  payload: {
    data: data,
  },
})

export const dispatchActionCancelFreeTrailorPlan = (message: string): ActionCancelFreeTrailorPlan => ({
  type: ACTION_CANCEL_FREE_TRIAL_OR_PLAN,
  payload: {
    message: message,
  },
})

export const dispatchActionCancelProfessionalPlan = (message: string): ActionCancelProfessionalPlan => ({
  type: ACTION_CANCEL_PROFESSIONAL_PLAN,
  payload: {
    message: message,
  },
})
export const dispatchActionEnterprisePlan = (message: string): ActionCancelEnterprisePlan => ({
  type: ACTION_CANCEL_ENTERPRISE_PLAN,
  payload: {
    message: message,
  },
})

export const dispatchActionChangePlan = (message: string): ActionChangePlan => ({
  type: ACTION_CHANGE_PLAN,
  payload: {
    message: message,
  },
})

export const dispatchActionLoading = (loading: boolean): ActionLoading => ({
  type: ACTION_LOADING,
  payload: {
    loading,
  },
})

export function actionGetSubscription(
  tenantId: string
): (dispatch: Dispatch<ActionGetSubscription | ActionLoading>) => Promise<void> {
  return async (dispatch: Dispatch<ActionGetSubscription | ActionLoading>) => {
    dispatch(dispatchActionLoading(true))
    try {
      const result = await service.getSubscription(tenantId)

      if (result?.success) {
        dispatch(dispatchActionGetSubscription(result.data))
      } else {
        Toaster.error(result?.message)
        dispatch(dispatchActionGetSubscription(result.data))
      }
      dispatch(dispatchActionLoading(false))
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchActionLoading(false))
    }
  }
}

export function actionCancelFreeTrailOrPlan(
  tenantId: string,
  reason: string
): (dispatch: Dispatch<ActionCancelFreeTrailorPlan | ActionLoading>) => Promise<void> {
  return async (dispatch: Dispatch<ActionCancelFreeTrailorPlan | ActionLoading>) => {
    loaderStart(dispatch)
    try {
      const result = await service.cancelFreeTrail(tenantId, reason)
      if (result.success) {
        dispatch(dispatchActionCancelFreeTrailorPlan(result.message))
      } else {
        toaster.error('Something went wrong')
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionCancelProfessionalPlan(
  tenantId: string,
  reason: string
): (dispatch: Dispatch<ActionCancelProfessionalPlan | ActionLoading>) => Promise<void> {
  return async (dispatch: Dispatch<ActionCancelProfessionalPlan | ActionLoading>) => {
    loaderStart(dispatch)
    try {
      const result = await service.cancelProfessionalPlan(tenantId, reason)
      if (result.success) {
        toaster.success(result.message)
        dispatch(dispatchActionCancelProfessionalPlan(result.message))
      } else {
        toaster.error('Something went wrong')
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionCancelEnterprisePlan(
  tenantId: string,
  reason: string
): (dispatch: Dispatch<ActionCancelEnterprisePlan | ActionLoading>) => Promise<void> {
  return async (dispatch: Dispatch<ActionCancelEnterprisePlan | ActionLoading>) => {
    loaderStart(dispatch)
    try {
      const result = await service.cancelEnterprisePlan(tenantId, reason)
      if (result.success) {
        toaster.success(result.message)
        dispatch(dispatchActionEnterprisePlan(result.message))
      } else {
        toaster.error('Something went wrong')
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      loaderStop(dispatch)
    }
  }
}

export function actionChangePlan(
  tenantId: string
): (dispatch: Dispatch<ActionChangePlan | ActionLoading | ActionChangePlan>) => Promise<void> {
  return async (dispatch: Dispatch<ActionChangePlan | ActionLoading | ActionChangePlan>) => {
    dispatch(dispatchActionLoading(true))
    loaderStart(dispatch)
    try {
      const result = await service.changePlan(tenantId)
      if (result.success) {
        toaster.success(result.message)
        dispatch(dispatchActionChangePlan(result.message))
      } else {
        toaster.error('Something went wrong')
      }
      dispatch(dispatchActionLoading(false))
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchActionLoading(false))
      loaderStop(dispatch)
    }
  }
}
