import { http } from '../../../Utils/AxiosWrapper'
import { BrandingModel } from '../Models'
import { settings } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

interface TenantBranding {
  getTenantBranding(): Promise<ApiResponse<BrandingModel>>
  updateTenantBranding(data: BrandingModel): Promise<ApiResponse<string>>
}
class TenantBrandingService implements TenantBranding {
  async getTenantBranding(): Promise<ApiResponse<BrandingModel>> {
    let result: ApiResponse<BrandingModel>
    try {
      const { data } = await http.get(settings.tenantBranding)
      result = TenantBrandingService.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }

  async updateTenantBranding(inputData: BrandingModel): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'put',
        url: settings.tenantBranding,
        data: TenantBrandingService.buildRequest(inputData),
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      result = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ApiResponse<BrandingModel> {
    if (!data) {
      return {
        success: false,
        statusCode: ResponseCodes.InvalidParams,
        message: 'Empty data in response',
      }
    }

    if (data.code === ResponseCodes.NotFound) {
      return {
        success: false,
        statusCode: data.code,
        message: data.message,
      }
    }

    return {
      success: true,
      statusCode: data.code,
      message: data.message || '',
      data: data.data,
    }
  }

  static buildRequest(request: BrandingModel): FormData {
    const { logo, support_email, logo_url } = request

    const formData = new FormData()
    if (logo) {
      formData.append('logo', logo, logo.name)
    }
    if (support_email) {
      formData.append('support_email', support_email)
    }
    if (logo_url) {
      formData.append('logo_url', logo_url)
    }
    return formData
  }
}

export default new TenantBrandingService()
