import { FileEditIcon, FileIcon, ShieldPlusIcon } from 'Assets/Images'
import * as Icon from 'react-feather'
import { Translate } from 'react-redux-i18n'

export interface MenuItem {
  icon: JSX.Element
  label: JSX.Element
  tooltipLabel: JSX.Element
  route: string
}

export const Admin: Array<MenuItem> = [
  {
    icon: <Icon.Settings size={18} />,
    label: <Translate value="sidebar.domains" />,
    tooltipLabel: <Translate value="sidebar.domains" />,
    route: '/admin/domains',
  },
  {
    icon: <Icon.User size={18} />,
    label: <Translate value="sidebar.users" />,
    tooltipLabel: <Translate value="sidebar.users" />,
    route: '/admin/usermanagement',
  },
  {
    icon: <Icon.User size={18} />,
    label: <Translate value="sidebar.users" />,
    tooltipLabel: <Translate value="sidebar.users" />,
    route: '/admin/sp/usermanagement',
  },
  {
    icon: <Icon.Settings size={18} />,
    label: <Translate value="sidebar.settings" />,
    tooltipLabel: <Translate value="sidebar.settings" />,
    route: '/admin/settings',
  },
]

export const UnParentedItems: Array<MenuItem> = [
  {
    icon: <Icon.PieChart size={18} />,
    label: <Translate value="sidebar.dashboard" />,
    tooltipLabel: <Translate value="sidebar.dashboard" />,
    route: '/dashboard',
  },
  {
    icon: <Icon.Users size={18} />,
    label: <Translate value="sidebar.tenants" />,
    tooltipLabel: <Translate value="sidebar.tenants" />,
    route: '/admin/tenant-management',
  },
  {
    icon: <img src={ShieldPlusIcon} alt="policies" width={16} height={18} />,
    label: <Translate value="sidebar.policies" />,
    tooltipLabel: <Translate value="sidebar.policies" />,
    route: '/admin/policies',
  },
]

export const Consents: Array<MenuItem> = [
  {
    icon: null,
    label: <Translate value="sidebar.cookies" />,
    tooltipLabel: <Translate value="sidebar.cookies" />,
    route: '/consents/cookies',
  },
  {
    icon: null,
    label: <Translate value="sidebar.banner" />,
    tooltipLabel: <Translate value="sidebar.banner" />,
    route: '/consents/banners',
  },
  {
    icon: null,
    label: <Translate value="sidebar.logs" />,
    tooltipLabel: <Translate value="sidebar.logs" />,
    route: '/consents/logs',
  },
]

export const PrivacyCenter: Array<MenuItem> = [
  {
    icon: null,
    label: <Translate value="sidebar.requests" />,
    tooltipLabel: <Translate value="sidebar.requests" />,
    route: '/privacy-center/requests',
  },
  {
    icon: null,
    label: <Translate value="sidebar.branding" />,
    tooltipLabel: <Translate value="sidebar.branding" />,
    route: '/privacy-center/branding',
  },
]
