export interface RequestExportPayload {
  export: 'csv' | 'excel'
  export_type: 'All' | 'Selected'
  dsar_list: number[]
  dsar_filter: any
  start_date: any
  end_date: any,
  search: any
}

export interface ReportUpload {
  Files?: Array<File>,
}

export interface TenantRemoveDsar {
  dsar_reports: Array<string>
}

export interface TenantDsarReports {
  removedsar: Array<string>
}

export enum BulkUploadResponseCodes {
  NoEntityDataFound = 4004,
  IncorrectExtension = 4013,
  MaxRecordsExceeded = 4014,
  IncorrectFileStructure = 4015,
  EntityAlreadyExits = 4009,
  InvalidFileData = 4022,
}


export interface ReportResponse {
  statusCode: number
  message: string
  success: boolean
  id: string
}