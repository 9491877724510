import React from 'react'
import { actionActionGetSPToken } from 'Modules/Auth/Actions/get-sp-token.actions'
import { ArrowLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { AppStateType } from 'Store/reducers'
import ButtonWithIcon from './Button'

function ImpersonateBackButton() {
  const { userData } = useSelector((state: AppStateType) => state.AuthReducer)
  const dispatch = useDispatch()

  const handleImpersonateBack = () => {
    dispatch(actionActionGetSPToken())
  }

  return (
    <>
      {userData && userData.impersonate_from && (
        <ButtonWithIcon
          icon={<ArrowLeft size={18} />}
          x-automation-id="add-user-id"
          id="add-user-id"
          text={I18n.t('common.back')}
          className="ml-3"
          onClick={handleImpersonateBack}
        />
      )}
    </>
  )
}

export default ImpersonateBackButton
