import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import service from '../Services/consentLogs.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ConsentLogsExportPayload } from '../Models/consentLogs.model'

export const ACTION_EXPORT_CONSENTLOGS = 'ACTION_EXPORT_CONSENTLOGS'
export const ACTION_EXPORT_CONSENTLOGS_SUCCESS = 'ACTION_EXPORT_REQUEST_SUCCESS'
export const ACTION_EXPORT_CONSENTLOGS_FAILURE = 'ACTION_EXPORT_REQUEST_FAILURE'
export const ACTION_EXPORT_CONSENT_CLEAR = 'ACTION_EXPORT_REQUEST_CLEAR'

export interface ActionExportConsentLogs extends Action {
  type: typeof ACTION_EXPORT_CONSENTLOGS
}

export interface ActionExportConsentLogsClear extends Action {
  type: typeof ACTION_EXPORT_CONSENT_CLEAR
}

export interface ActionExportConsentLogSuccess extends Action {
  type: typeof ACTION_EXPORT_CONSENTLOGS_SUCCESS
  payload: {
    data: string
  }
}

export interface ActionExportConsentLogsFailure extends Action {
  type: typeof ACTION_EXPORT_CONSENTLOGS_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export type ExportConsentLogsActions =
  | ActionExportConsentLogs
  | ActionExportConsentLogSuccess
  | ActionExportConsentLogsFailure
  | ActionExportConsentLogsClear

export const dispatchConsetLogsClear = (): ActionExportConsentLogsClear => ({
  type: ACTION_EXPORT_CONSENT_CLEAR,
})

const dispatchConsentLogsSuccess = (data: string): ActionExportConsentLogSuccess => ({
  type: ACTION_EXPORT_CONSENTLOGS_SUCCESS,
  payload: {
    data,
  },
})

const dispatchConsentLogsFailure = (error: Error, success: boolean): ActionExportConsentLogsFailure => ({
  type: ACTION_EXPORT_CONSENTLOGS_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionExportRequest(
  payload: ConsentLogsExportPayload
): (dispatch: Dispatch<ExportConsentLogsActions>) => Promise<void> {
  return async (dispatch: Dispatch<ExportConsentLogsActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.exportConsentLogs(payload)
      if (result && result.success) {
        // toaster.success(I18n.t('Success'))
        dispatch(dispatchConsentLogsSuccess(result?.message))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(dispatchConsentLogsFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchConsentLogsFailure(error, false))
      loaderStop(dispatch)
    }
  }
}
