import { cx } from '@emotion/css'
import { Link, useHistory } from 'react-router-dom'
import { AlertTriangle, Clock, Edit2, Trash } from 'react-feather'
import { I18n } from 'react-redux-i18n'
import { SubDomainListProps } from 'Modules/Tenant/Models/tenantList.model'
import CookieBanner from 'Modules/Tenant/TenantList/CookieBanner'
import styles from './Domains.module.css'
import { useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import { useState } from 'react'
import ActionDropdown from 'Modules/Common/Components/ActionDropdown'

const SubDomainList = ({ subDomainList, tenantName, data, tenantId }: SubDomainListProps) => {
  const licensePlan = useSelector((state: AppStateType) => state.TenantReducer.licensePlan)
  const isFreePlan = licensePlan === 'free_plan' || 'free_trial'
  const [show, setShow] = useState({
    domainName: '',
    showBanner: false,
    domainId: '',
  })

  const history = useHistory()

  return (
    <>
      {subDomainList.map(subDomain => (
        <tr>
          <td className={cx(styles.column1, styles.subdomainBg)}>
            <div className={cx(styles.subDomainName, styles.domain)}>{subDomain.subdomain_name}</div>
          </td>
          <td className={cx(styles.column2, styles.subdomainBg)}></td>
          <td className={cx(styles.column3, styles.subdomainBg)}></td>
          {!isFreePlan && <td className={cx(styles.column4, styles.subdomainBg)}></td>}
          <td className={cx(styles.column5, styles.subdomainBg)}>
            <CookieBanner
              status={subDomain.consent_status}
              data={data}
              subDomainId={subDomain.subdomain_id}
              tenantId={tenantId}
            />
          </td>

          <td className={cx(styles.column6, styles.subdomainBg)}>
            <div>
              {subDomain?.cookies_scan_status === 'failed' ? (
                <div>
                  <div className={styles.scanFailedIcon}>
                    <AlertTriangle />
                    <span className="ml-1">{I18n.t('tenant.scanFailed')}</span>
                  </div>
                </div>
              ) : subDomain?.no_of_cookies === null ? (
                <div className={styles.scanningIcon}>
                  <Clock></Clock>
                  <span className="ml-1">{I18n.t('tenant.scanInProgress')}</span>
                </div>
              ) : (
                <Link
                  className="columnLink"
                  to={`/consents/cookies?tenantName=${tenantName}&domain_id=${data?.domain_id}&sub_domain_id=${subDomain?.subdomain_id}`}
                >
                  {subDomain?.no_of_cookies || 0}
                </Link>
              )}
            </div>
          </td>
          <td className={cx(styles.column7, styles.subdomainBg)}>
            <div className="d-flex">
              <ActionDropdown
                menuItems={[
                  {
                    label: I18n.t('domains.editDomain'),
                    onClick: (event: any) => {
                      event.stopPropagation()
                      history.push(`/admin/domains/addDomains?domainId=${data.domain_id}`)
                    },
                    icon: <Edit2 size="18px" />,
                    id: 'edit-domain',
                  },
                  {
                    label: I18n.t('domains.deleteDomain'),
                    onClick: (event: any) => {
                      event.stopPropagation()
                      setShow({
                        domainName: subDomain?.subdomain_name,
                        showBanner: true,
                        domainId: subDomain?.subdomain_id + '',
                      })
                    },
                    icon: <Trash size="18px" />,
                    id: 'delete-domain',
                  },
                ]}
                indexKey={data?.tenant_id}
              />
            </div>
          </td>
        </tr>
      ))}

      <ConfirmDeleteModal
        show={show}
        setShow={setShow}
        title={I18n.t('domains.deleteDomainTitle')}
        message={I18n.t('domains.deleteSubDomainMessage')}
        isSubdomain={true}
      />
    </>
  )
}
export default SubDomainList
