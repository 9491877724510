import { LocationDropdownModel } from 'Modules/Common/Models';
import {
  GetLocationActions,
  ACTION_GET_LOCATION,
  ACTION_GET_LOCATION_SUCCESS,
  ACTION_GET_LOCATION_FAILURE,
} from './location.actions';

export interface LocationReducerState {
  locationDropdown?: {
    success?: boolean;
    message?: string;
    statusCode?: number;
    getlocationDropDownLoader?: boolean;
    errorMessage?: string;
    data?: Array<LocationDropdownModel>;
    apiCallFinished: boolean
  };
}

export const initialState: LocationReducerState = {
  locationDropdown: {
    success: false,
    apiCallFinished: false
  },
};

export const LocationReducer = (
  state = initialState, // NOSONAR
  action: GetLocationActions
): LocationReducerState => {
  switch (action.type) {
    case ACTION_GET_LOCATION:
      return {
        ...state,
        locationDropdown: {
          success: true,
          errorMessage: '',
          apiCallFinished: false
        },
      };
    case ACTION_GET_LOCATION_SUCCESS:
      return {
        ...state,
        locationDropdown: {
          data: action.payload.data,
          success: true,
          apiCallFinished: true
        },
      };
    case ACTION_GET_LOCATION_FAILURE:
      return {
        ...state,
        locationDropdown: {
          errorMessage: action.payload.errorMessage,
          getlocationDropDownLoader: false,
          success: false,
          apiCallFinished: true
        },
      };
    default:
      return state;
  }
};
