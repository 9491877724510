import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/getTrendChartData.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { TrendChartModel } from '../Models/ConsentDashboard.model'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_TREND_CHART_DATA = 'ACTION_GET_TREND_CHART_DATA'
export const ACTION_GET_TREND_CHART_DATA_SUCCESS = 'ACTION_GET_TREND_CHART_DATA_SUCCESS'
export const ACTION_GET_TREND_CHART_DATA_FAILURE = 'ACTION_GET_TREND_CHART_DATA_FAILURE'

export interface ActionGetTrendChartData extends Action {
  type: typeof ACTION_GET_TREND_CHART_DATA
  payload: {
    startDate: number
    endDate: number
    viewBy: string
  }
}

export interface ActionGetTrendChartDataSuccess extends Action {
  type: typeof ACTION_GET_TREND_CHART_DATA_SUCCESS
  payload: TrendChartModel
}

export interface ActionGetTrendChartDataFailure extends Action {
  type: typeof ACTION_GET_TREND_CHART_DATA_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetTrendChartDataActions =
  | ActionGetTrendChartData
  | ActionGetTrendChartDataFailure
  | ActionGetTrendChartDataSuccess

export const dispatchGetTrendChartData = (
  startDate: number,
  endDate: number,
  viewBy: string
): ActionGetTrendChartData => ({
  type: ACTION_GET_TREND_CHART_DATA,
  payload: { startDate: startDate, endDate: endDate, viewBy },
})

const dispatchGetTrendChartDataSuccess = (data: TrendChartModel): ActionGetTrendChartDataSuccess => ({
  type: ACTION_GET_TREND_CHART_DATA_SUCCESS,
  payload: data,
})

const dispatchGetTrendChartDataFailure = (error: Error, code?: number): ActionGetTrendChartDataFailure => ({
  type: ACTION_GET_TREND_CHART_DATA_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetTrendChartData(
  startDate: number,
  endDate: number,
  selectedTenant: string,
  selectedDomain: string,
  selectedSubDomain: string
): (dispatch: Dispatch<GetTrendChartDataActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTrendChartDataActions>) => {
    try {
      const result = await service.getTrendChartData(
        startDate,
        endDate,
        selectedTenant,
        selectedDomain,
        selectedSubDomain
      )

      if (result && result.success) {
        dispatch(dispatchGetTrendChartDataSuccess(result.data))
      } else {
        if (result?.code !== ResponseCodes.Unauthorized) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(
          dispatchGetTrendChartDataFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTrendChartDataFailure(error))
      loaderStop(dispatch)
    }
  }
}
