import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Admin, UnParentedItems, Consents, MenuItem, PrivacyCenter } from './NavItems'
import {
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  FileText,
  PieChart,
  Settings,
  Shield,
} from 'react-feather'
import { Scrollbars } from 'react-custom-scrollbars'
import cx from 'classnames'
import styles from '../Layout.module.css'
import { CommonColors } from '../../Common/Enums/Common'
import { getAllowedPages, getUserRoles } from 'Modules/Auth/Utils'
import { useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { I18n } from 'react-redux-i18n'
import { getCurrentYear } from 'Utils/dateAndTimeUtils'
import { CytrioLogo, CytrioLogoIcon } from 'Assets/Images'
import { BootstrapTooltip } from 'Modules/Common/Components/BootstrapTooltip'
import PoweredByCytrio from 'Modules/Common/Components/PoweredByCytrio'
import { cytrioPrivacyPolicyURL, cytrioTermsofServiceURL } from 'Modules/Common/Constants'
import MenuPopover from './MenuPopover'

type Props = {
  seIsSidebarCollapsed: (value: boolean) => void
}

type MenuProps = {
  collapsedSidebar: boolean
  activePath: string
  pages: Array<MenuItem>
  activeMenus: Array<string>
  setActiveMenus: (value: Array<string>) => void
  parentKey: string
  icon: any
  history: any
  isUnParented: boolean
  disabled?: boolean
  isMenuAccessable: boolean
}

const Menu = (menuProps: MenuProps) => {
  const labels = {
    admin: I18n.t('sidebar.administration'),
    privacy_management: I18n.t('sidebar.privacy_center'),
    consent_management: I18n.t('sidebar.consents'),
    dashboard: I18n.t('sidebar.dashboard'),
  }
  const {
    isUnParented,
    history,
    icon,
    collapsedSidebar,
    activePath,
    pages,
    activeMenus,
    setActiveMenus,
    parentKey,
    isMenuAccessable,
  } = menuProps

  return (
    <div className={cx(styles.navMenu)}>
      {!isUnParented ? (
        <MenuPopover isShown={collapsedSidebar} activePath={activePath} pages={pages} history={history}>
          <button
            className={cx(
              styles.sidebarDrodownBtn,
              'd-flex align-items-center mt-2',
              collapsedSidebar && 'justify-content-center'
            )}
            x-automation-id="admin-sidebar-expand"
            id="admin-sidebar-expand"
            onClick={() => {
              if (!isMenuAccessable) {
                history.push({
                  pathname: '/subscribe',
                  state: {
                    feature_name: parentKey,
                  },
                })
                return
              }
              if (activeMenus?.includes(parentKey)) {
                setActiveMenus(activeMenus?.filter(e => e !== parentKey))
              } else {
                setActiveMenus([...activeMenus, parentKey])
              }
            }}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center">
                <div
                  className={cx(collapsedSidebar && styles.collapsedMenuIcon, !isMenuAccessable && styles.disabled)}
                  style={{ minWidth: '25px', marginLeft: '3px' }}
                >
                  {icon}
                </div>
                {!collapsedSidebar && (
                  <span className={cx('ml-2', !isMenuAccessable && styles.disabled)}>{labels[parentKey]}</span>
                )}
              </div>
              {!collapsedSidebar && isMenuAccessable && (
                <div style={{ minWidth: '25px', marginLeft: '3px' }}>
                  <span>
                    {activeMenus?.includes(parentKey) ? (
                      <ChevronDown className={cx(!isMenuAccessable && styles.disabled)} />
                    ) : (
                      <ChevronRight className={cx(!isMenuAccessable && styles.disabled)} />
                    )}
                  </span>
                </div>
              )}
            </div>
          </button>
        </MenuPopover>
      ) : null}
      {((activeMenus?.includes(parentKey) && !collapsedSidebar) || isUnParented) && (
        <ul className="nav flex-column">
          {pages?.map(({ label, route, icon, tooltipLabel }, index) => (
            <li key={index} className={`admin-menu-li ${collapsedSidebar ? 'mt-2' : ''}`}>
              <BootstrapTooltip
                rootClose={true}
                trigger={['hover', 'focus']}
                placement="right"
                title={collapsedSidebar ? tooltipLabel : ''}
                tooltipClassName="sidebar-tooltip-bootstrap-tooltip"
              >
                <Link
                  id={'adminPages-navlink-' + index.toString()}
                  className={cx(
                    styles.navLink,
                    !isUnParented ? styles.navItem : styles.navItemParent,
                    activePath.includes(route) && styles.activePill,
                    !collapsedSidebar ? 'ml-2 mr-1' : styles.collapsedMenuIcon
                  )}
                  to={route}
                >
                  {activePath.includes(route) && !collapsedSidebar ? <div className={styles.activePillBox} /> : null}
                  <div style={{ minWidth: '25px' }}>{isUnParented ? icon : null}</div>
                  {!collapsedSidebar && <span className="ml-1">{label}</span>}
                </Link>
              </BootstrapTooltip>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const Sidebar = ({ seIsSidebarCollapsed }: Props) => {
  const isPathPresentInMenu = (key: 'admin' | 'consents' | 'dashboard' | 'privacy') => {
    switch (key) {
      case 'dashboard':
        return location?.pathname?.includes('consents/dashboard')
      case 'admin':
        return (
          location?.pathname?.includes('settings') ||
          location?.pathname?.includes('admin/usermanagement') ||
          location?.pathname?.includes('admin/sp/usermanagement') ||
          location?.pathname?.includes('admin/domains')
        )
      case 'consents':
        return (
          location?.pathname?.includes('consents/webistes') ||
          location?.pathname?.includes('consents/tracking-scripts') ||
          location?.pathname?.includes('consents/cookies') ||
          location?.pathname?.includes('consents/banners') ||
          location?.pathname?.includes('consents/logs')
        )

      case 'privacy':
        return (
          location?.pathname?.includes('privacy-center/requests') ||
          location?.pathname?.includes('privacy-center/branding')
        )

      default:
        return false
    }
  }

  const getActiveMenus = () => {
    return [
      isPathPresentInMenu('consents') ? 'consent_management' : '',
      isPathPresentInMenu('admin') ? 'admin' : '',
      isPathPresentInMenu('dashboard') ? 'dashboard' : '',
      isPathPresentInMenu('privacy') ? 'privacy_management' : '',
    ]
  }

  const location = useLocation()

  const [collapsedSidebar, setCollapsedSidebar] = useState<boolean>(false)
  const [activeMenus, setActiveMenus] = useState<Array<string>>()
  const [activePath, setActivePath] = useState<string>(location?.pathname)

  const userModel = useSelector((state: AppStateType) => state.AuthReducer.userData)
  const allowedPages = useMemo(() => getAllowedPages(userModel?.roles_and_permissions), [userModel])
  const role = useMemo(() => getUserRoles(userModel?.roles_and_permissions), [userModel])
  const license_plan = useSelector((state: AppStateType) => state.TenantReducer.licensePlan)
  let featurePermissions = userModel?.feature_permissions || []

  const history = useHistory()

  useEffect(() => {
    setActivePath(location?.pathname)
    setActiveMenus(getActiveMenus())
  }, [location, setActivePath])

  const collapseSidebar = () => {
    seIsSidebarCollapsed(!collapsedSidebar)
    setCollapsedSidebar(!collapsedSidebar)
    setActiveMenus(getActiveMenus())
  }

  if (!userModel) {
    return null
  }

  const adminPages = Admin.filter(page => allowedPages.includes(page.route))
  const consentPages = Consents.filter(page => allowedPages.includes(page.route))
  const privacyCenterPages = PrivacyCenter.filter(page => allowedPages.includes(page.route))
  const unParentedItems = UnParentedItems.filter(page => allowedPages.includes(page.route))

  const getLogo = () => {
    if (userModel?.logo_url) {
      return userModel?.logo_url
    }
    return collapsedSidebar ? CytrioLogoIcon : CytrioLogo
  }

  return (
    <div>
      <div className={cx(styles.appHeaderLogo, collapsedSidebar ? styles.collapsed : 'pl-3')}>
        <div className="d-flex justify-content-between w-100 mt-2 mb-2 positionRelative">
          <div
            className="d-flex align-items-center"
            style={{ height: collapsedSidebar ? 20 : 25, marginLeft: collapsedSidebar ? 5 : 0 }}
          >
            <img
              className={cx(styles.cytrioLogoIcon, collapsedSidebar ? styles.smallLogo : '')}
              src={getLogo()}
              alt="logo"
              id="cytrioLogoIcon"
              onClick={() => {
                history.push('/')
              }}
            />
          </div>
          <BootstrapTooltip
            rootClose={true}
            trigger={['hover', 'focus']}
            placement="right"
            title={collapsedSidebar ? I18n.t('sidebar.expand') : I18n.t('sidebar.collapse')}
          >
            <button
              onClick={collapseSidebar}
              x-automation-id="collapse-sidebar"
              id="collapse-sidebar"
              className={styles.sidebarExpandIcon}
            >
              {collapsedSidebar ? (
                <ChevronRight size={24} color={CommonColors.SideBarExpandIcon} style={{ strokeWidth: 3 }} />
              ) : (
                <ChevronLeft size={24} color={CommonColors.SideBarExpandIcon} style={{ strokeWidth: 3 }} />
              )}
            </button>
          </BootstrapTooltip>
        </div>
      </div>
      <div id="sidebarContainer" className={cx(styles.sidebar, collapsedSidebar ? styles.collapsed : '')}>
        <Scrollbars
          autoHeightMax=""
          autoHide
          renderTrackVertical={() => <div className="track-vertical" />}
          renderThumbVertical={() => <div className="thumb-vertical" />}
        >
          <nav id="nav-sidebar-container" className={styles.sidebarNav}>
            <div>
              {unParentedItems?.length > 0 && (
                <Menu
                  isUnParented={true}
                  icon={null}
                  parentKey={'unparented'}
                  collapsedSidebar={collapsedSidebar}
                  pages={unParentedItems}
                  activeMenus={activeMenus}
                  setActiveMenus={setActiveMenus}
                  activePath={activePath}
                  history={history}
                  isMenuAccessable={true}
                />
              )}
              <Menu
                isUnParented={false}
                history={history}
                icon={
                  <Shield size={18} color={collapsedSidebar && isPathPresentInMenu('consents') ? '#f97200' : '#FFF'} />
                }
                parentKey={'privacy_management'}
                collapsedSidebar={collapsedSidebar}
                pages={privacyCenterPages}
                activeMenus={activeMenus}
                setActiveMenus={setActiveMenus}
                activePath={activePath}
                disabled={true}
                isMenuAccessable = {license_plan === 'free_plan'  ? false : true}
                // isMenuAccessable={
                //   featurePermissions.includes('privacy_management') || role.includes('service_provider')
                // }
              />
              <Menu
                isUnParented={false}
                history={history}
                icon={
                  <FileText
                    size={18}
                    color={collapsedSidebar && isPathPresentInMenu('consents') ? '#f97200' : '#FFF'}
                  />
                }
                parentKey={'consent_management'}
                collapsedSidebar={collapsedSidebar}
                pages={consentPages}
                activeMenus={activeMenus}
                setActiveMenus={setActiveMenus}
                activePath={activePath}
                isMenuAccessable={
                  featurePermissions.includes('consent_management') || role.includes('service_provider')
                }
              />
              {adminPages?.length > 0 && (
                <Menu
                  isUnParented={false}
                  icon={
                    <Settings color={collapsedSidebar && isPathPresentInMenu('admin') ? '#f97200' : '#FFF'} size={18} />
                  }
                  parentKey={'admin'}
                  collapsedSidebar={collapsedSidebar}
                  pages={adminPages}
                  activeMenus={activeMenus}
                  setActiveMenus={setActiveMenus}
                  activePath={activePath}
                  history={history}
                  isMenuAccessable={true}
                />
              )}
            </div>
            {!collapsedSidebar && (
              <div className="d-flex flex-column align-items-start justify-content-center pl-3">
                <PoweredByCytrio />
                <div className={styles.footerLinksDiv}>
                  <a href={cytrioPrivacyPolicyURL} target="_blank" rel="noreferrer">
                    {I18n.t('sidebar.privacy_policy')}
                  </a>
                  <div className={styles.verticle}></div>
                  <a href={cytrioTermsofServiceURL} target="_blank" className={styles.footerTC} rel="noreferrer">
                    {I18n.t('sidebar.terms_of_service')}
                  </a>
                </div>
                <div className={cx(styles.copyrightDiv, 'mt-1')}>
                  © {getCurrentYear()} {I18n.t('sidebar.cytrioInc')}
                </div>
              </div>
            )}
          </nav>
        </Scrollbars>
      </div>
    </div>
  )
}

export default Sidebar
