export const banners = {
  banner: 'Banner',
  general: 'General',
  layout: 'Layout',
  content: 'Content',
  colours: 'Colours',
  customCss: 'Custom CSS',
  consentTemplate: 'Consent Template',
  showAdvancedSettings: 'Show advanced settings',
  hideAdvancedSettings: 'Hide advanced settings',
  consentExpirationDays: 'Consent expiration (days)',
  consentUpdatedSuccessfully: 'Consent template updated successfully',
  publishChanges: 'Publish Changes',
  selectTenant: 'Tenant',
  selectDomain: 'Domain',
}
