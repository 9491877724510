import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/resend-code.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { StatusCodes } from 'Modules/Common/Enums'

export const ACTION_RESEND_CODE = 'ACTION_RESEND_CODE'
export const ACTION_RESEND_CODE_SUCCESS = 'ACTION_RESEND_CODE_SUCCESS'
export const ACTION_RESEND_CODE_FAILED = 'ACTION_RESEND_CODE_FAILED'

export interface ActionResendCode extends Action {
  type: typeof ACTION_RESEND_CODE
}

export interface ActionResendCodeSuccess extends Action {
  type: typeof ACTION_RESEND_CODE_SUCCESS
  payload: {
    success: boolean
  }
}

export interface ActionResendCodeFailed extends Action {
  type: typeof ACTION_RESEND_CODE_FAILED
  payload: {
    errorMessage: string
  }
}

export type ResendCodeActions = ActionResendCode | ActionResendCodeFailed | ActionResendCodeSuccess

const dispatchResendCode = (): ActionResendCode => ({
  type: ACTION_RESEND_CODE,
})

const dispatchResendCodeSuccess = (success: boolean): ActionResendCodeSuccess => ({
  type: ACTION_RESEND_CODE_SUCCESS,
  payload: {
    success,
  },
})

const dispatchResendCodeFailed = (error: Error): ActionResendCodeFailed => ({
  type: ACTION_RESEND_CODE_FAILED,
  payload: { errorMessage: error.message },
})

const toaster = (code: number) => {
  switch (code) {
    case 2000:
    case 2001:
      toastService.success(I18n.t('auth_toast.code_resent_success'))
      break
    case 4001:
    case 4004:
      toastService.error(I18n.t('auth_toast.user_not_exists'))
      break
    case StatusCodes.TooManyRequests:
      toastService.error(I18n.t('auth_toast.toomanycall'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionResendCode(request: string): (dispatch: Dispatch<ResendCodeActions>) => Promise<void> {
  return async (dispatch: Dispatch<ResendCodeActions>) => {
    dispatch(dispatchResendCode())
    loaderStart(dispatch)
    try {
      const result = await service.resendCode(request)
      if (result && result.success) {
        toaster(result?.code)
        dispatch(dispatchResendCodeSuccess(result.success))
      } else {
        toaster(result?.code)
        dispatch(dispatchResendCodeFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchResendCodeFailed(error))
      loaderStop(dispatch)
    }
  }
}
