import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/verify-code.service'
export const ACTION_SET_USER_DATA = 'ACTION_SET_USER_DATA'
export const ACTION_SET_USER_DATA_SUCCESS = 'ACTION_SET_USER_DATA_SUCCESS'
export const ACTION_SET_USER_DATA_FAILED = 'ACTION_SET_USER_DATA_FAILED'
export const ACTION_SET_ROLES_AND_PERMISSIONS = 'ACTION_SET_ROLES_AND_PERMISSIONS'

export interface ActionSetUserData extends Action {
  type: typeof ACTION_SET_USER_DATA
  payload: {
    userData: any
  }
}

export interface ActionSetUserData extends Action {
  type: typeof ACTION_SET_USER_DATA
  payload: {
    userData: any
  }
}

export interface ActionSetRolesAndPermissions extends Action {
  type: typeof ACTION_SET_ROLES_AND_PERMISSIONS
  payload: {
    roles_and_permissions: any
    feature_permissions: any
  }
}

export type SetUserDataActions = ActionSetUserData | ActionSetRolesAndPermissions

const dispatchSetUserData = (userData: any): ActionSetUserData => ({
  type: ACTION_SET_USER_DATA,
  payload: {
    userData,
  },
})

export const dispatchSetRolesAndPermission = (
  roles_and_permissions: any,
  feature_permissions: any
): ActionSetRolesAndPermissions => ({
  type: ACTION_SET_ROLES_AND_PERMISSIONS,
  payload: {
    roles_and_permissions,
    feature_permissions,
  },
})

export function actionSetUserData(data: any): (dispatch: Dispatch<SetUserDataActions>) => Promise<void> {
  return async (dispatch: Dispatch<SetUserDataActions>) => {
    dispatch(dispatchSetUserData(data))
  }
}

export function actionSetRolesAndPermissions(email: string): (dispatch: Dispatch<{}>) => Promise<void> {
  return async (dispatch: Dispatch<{}>) => {
    loaderStart(dispatch)
    try {
      const result = await service.getRolesAndPermissions(email)
      if (result && result.success) {
        dispatch(dispatchSetRolesAndPermission(result.data.roles_and_permissions, result.data.feature_permissions))
      }
      loaderStop(dispatch)
    } catch (error) {
      loaderStop(dispatch)
    }
  }
}
