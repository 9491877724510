import React from 'react'
import Select from 'react-select'
import './SelectDropdown.css'
import { SelectComponentsProps } from 'react-select/src/Select'
import cx from 'classnames'

export default function SelectDropdown({ value, className, ...props }: SelectComponentsProps) {
  return (
    <Select
      className={cx(className)}
      value={value}
      theme={theme => ({
        ...theme,
        boxShadow: 'none',
        colors: {
          ...theme.colors,
          primary25: 'hotpink',
          primary: 'rgb(255, 105, 0)',
        },
      })}
      {...props}
    />
  )
}
