import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import toastService from 'Modules/Common/Services/Toaster'

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  height: 150px;
`
interface ErrorState {
  error: string
  errorInfo: string
  hasError: boolean
}

interface ErrorProps {}

export default class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props) {
    super(props)
  }
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo })
    this.setState({ errorInfo })
  }
  render() {
    // next code block goes here
    // return this.props.children;
    const { hasError } = this.state
    if (hasError) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      return (
        <StyledDiv>
          {I18n.t('common.error_boundry_text')}
          <span
            style={{ cursor: 'pointer', color: '#0077FF', marginLeft: '5px' }}
            onClick={() => {
              window.location.reload()
            }}
          >
            {I18n.t('common.error_boundry_reload')}
          </span>{' '}
        </StyledDiv>
      )
    }
    return this.props.children
  }
}
