import { UserModel } from '../Models/user.model'
import { auth } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'

export interface GetInviteInfoResponse {
  success: boolean
  code: number
  msg: string
  inviteInfo?: UserModel
}

export interface InviteInfoService {
  getInviteInfo(request: string): Promise<GetInviteInfoResponse>
}

class InviteInfoServiceDefault implements InviteInfoService {
  async getInviteInfo(request: string): Promise<GetInviteInfoResponse> {
    let result: GetInviteInfoResponse
    try {
      const { data } = await http.get(auth.getInviteInfo, {
        params: {
          register_id: request,
        },
      })
      result = InviteInfoServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        msg: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): GetInviteInfoResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        msg: 'Empty data in response',
      }
    }

    const userData = data.result
    if (!userData) {
      return {
        success: false,
        code: data.code,
        msg: data.msg,
      }
    }

    return {
      success: true,
      code: data.code,
      msg: data.msg,
      inviteInfo: {
        email: userData.email?.trim(),
        firstName: userData.firstname?.trim(),
        lastName: userData.lastname?.trim(),
        phone: userData.phone?.trim(),
        tenantName: userData.tenant_name?.trim(),
        createdBy: userData.created_by,
        displayLanguage: userData.display_language,
        invitedBy: userData.invited_by,
        mcUserId: userData.mc_user_id,
        modifiedBy: userData.modified_by,
        pcUrl: userData.privacy_center_url,
        status: userData.status,
        tenantId: userData.tenant_id_fk,
        phone_code: userData.phone_code ? '+' + userData.phone_code : undefined,
        country: userData.country,
      },
    }
  }
}

export default new InviteInfoServiceDefault()
