import { emailRegex } from 'Modules/Common/Constants'

export function numFormatter(num, fixed = 0) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(fixed) + 'K'
  } else if (num > 999999 && num < 100000000) {
    return (num / 1000000).toFixed(fixed) + 'M'
  } else if (num > 99999999) {
    return (num / 100000000).toFixed(fixed) + 'B'
  } else {
    return num
  }
}

export const splitAndGetLast = (splitBy: string, str: string): string => {
  if (str && splitBy) {
    const splitdsProp = str.split(splitBy)
    return splitdsProp[splitdsProp.length - 1]
  }
  return str
}

export const groupBy = (list: any, key: string): any => {
  if (list) {
    return list.reduce((acc, currentVal) => {
      acc[currentVal[key]] = acc[currentVal[key]] || []
      acc[currentVal[key]].push(currentVal)
      return acc
    }, {})
  }
  return []
}

export const splitString = (str: string, splitBy: string): Array<string> => {
  return str && splitBy ? str.split(splitBy) : []
}

export const roundNumber = (value): string => {
  if (!value) return ''
  return Math.round(value).toString()
}

export const percentageCalculate = (partialValue, totalValue): number => {
  return (100 * partialValue) / totalValue
}

export const bytesToSize = (bytes: number, decimals: number = 2, isSuffix: boolean = true): string | number => {
  if (bytes === 0) {
    return isSuffix ? '0 B' : 0
  }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  if (isSuffix) {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  } else {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  }
}

export const bytesToSizeType = (bytes: number): string | number => {
  if (bytes === 0) {
    return 'B'
  }
  const k = 1024
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  return sizes[i]
}

export const getStringNumber = (numberValue: number): string => {
  if (numberValue < 10) {
    return '0' + numberValue
  } else {
    return numberValue?.toString()
  }
}

export const validateEmailForSpecificDomain = (email: string, domain: string): boolean => {
  let isValid = false
  if (email && domain) {
    if (emailRegex.test(email)) {
      return email.split("@")[1] === domain
    }
  }
  return isValid
}

export const generateUUID = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export const deepCopy = (data: any) => {
  return JSON.parse(JSON.stringify(data || {}))
}

export const getDomainNameFromURL = (url: string): string => {
  if (!url || url === '') {
    return ''
  }
  let domain = ''
  let newUrl
  if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1) {
    newUrl = url
  } else {
    newUrl = 'http://' + url
  }
  try {
    const urlObject = new URL(newUrl)
    domain = urlObject.hostname.replace('www.', '')
  } catch (error) { }

  return domain
}

export const getDomainNameFromEmail = (email: string): string => {
  if (!email || email === '') {
    return ''
  }
  let domain = ''
  try {
    domain = email.substring(email.indexOf('@') + 1, email.indexOf('.com'))
  } catch (error) { }

  return domain
}