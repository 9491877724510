import React, { useEffect, useState } from 'react'
import styles from '../Auth.module.css'
import cx from 'classnames'
import logoC from '../../../Assets/Images/CytrioLogo/c.png'
import redCircle from '../../../Assets/Images/CytrioLogo/red_circle.png'
import RegisterForm from './RegisterForm'
import VerifyCode from './VerifyCode'
import { SessionStorageKeyNames, Pages } from '../../Common/Enums/Common'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../Store/reducers'
import { Lock, User } from 'react-feather'
import { I18n } from 'react-redux-i18n'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import Logo from 'Modules/Common/Components/Logo'
import PoweredByCytrio from 'Modules/Common/Components/PoweredByCytrio'
import {
  cytrioAboutUsURL,
  cytrioHelpURL,
  cytrioPrivacyPolicyURL,
  cytrioTermsofServiceURL,
} from 'Modules/Common/Constants'
import { actionGetInviteInfo } from '../Actions/invite-info.actions'
import RegisterLinkExpired from './RegisterLinkExpired'

const leftDiv = (page: Pages) => (
  <div className="row d-flex h-100 p-2 m-0">
    <div className="col-md-12">
      <div className="mt-4">
        <Logo />
      </div>
      <div className={cx(styles.loginText, 'mt-5')}>
        <p className={page == Pages.RegisterPage ? styles.activeLoginText : styles.inActiveLoginText}>
          <span>
            <User color="#FFFFFF" />
          </span>
          {I18n.t('register.information')}
        </p>
        <p className={page == Pages.VerifyCodePage ? styles.activeLoginText : styles.inActiveLoginText}>
          <span>
            <Lock color="#FFFFFF" />
          </span>
          {I18n.t('register.verification_code')}
        </p>
      </div>
    </div>
    <div className="row d-flex align-items-end p-0 m-0 mb-3">
      <div className="col-md-12 d-flex align-items-end">
        <div>
          <p className={cx(styles.desc, 'pb-3')}>{I18n.t('register.data_captured')}</p>
          <PoweredByCytrio />
          <div className={cx(styles.footerDiv, 'col-md-12 p-0 d-flex justify-content-between pb-0')}>
            <a href={cytrioAboutUsURL} target="_blank" className="pr-2">
              {I18n.t('register.about')}
            </a>
            <div className={styles.verticle}></div>
            <a href={cytrioHelpURL} target="_blank" className="pr-2">
              {I18n.t('register.help')}
            </a>
            <div className={styles.verticle}></div>
            <a href={cytrioPrivacyPolicyURL} target="_blank" className="pr-2">
              {I18n.t('register.privacy_policy')}
            </a>
            <div className={styles.verticle}></div>
            <a href={cytrioTermsofServiceURL} target="_blank">
              {I18n.t('sidebar.terms_of_service')}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

interface RegisterProps {
  page: Pages
}

const Register = ({ page }: RegisterProps) => {
  const { isAuthenticated, isVerified, isRegisterLinkExpired } = useSelector((state: AppStateType) => state.AuthReducer)
  const { isLoading } = useSelector((state: AppStateType) => state.LoaderReducer)

  const [hashCode, setHashCode] = useState<string>('')

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (!hashCode || page !== Pages.RegisterPage) {
      return
    }
    dispatch(actionGetInviteInfo(hashCode, history))
  }, [hashCode, dispatch, history])

  if (isAuthenticated && isVerified) {
    return <Redirect to="/" />
  }

  const userDataSessionStorage = SessionStorage.get(SessionStorageKeyNames.UserData)
  const userDataParsed = userDataSessionStorage && JSON.parse(userDataSessionStorage)

  if (userDataParsed) {
    return <Redirect to="/" />
  }

  let form
  if (page == Pages.VerifyCodePage) {
    form = <VerifyCode page={Pages.RegisterPage} />
  }
  if (page === Pages.RegisterPage) {
    form = <RegisterForm hashCode={hashCode} setHashCode={setHashCode} />
  }

  if (isRegisterLinkExpired) {
    return <RegisterLinkExpired />
  }

  if (isLoading) {
    return <></>
  }

  return (
    <div className={styles.outerDiv}>
      <div className={styles.mainDiv}>
        <div className={styles.innerDiv}>
          <div className={styles.leftDiv}>
            <img className={styles.image} src={logoC} />
            <img className={styles.image2} src={redCircle} />
            {leftDiv(page)}
          </div>
          <div className={styles.rightDiv}>{form}</div>
        </div>
      </div>
    </div>
  )
}

export default Register
