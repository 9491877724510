import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useCountdownTimer } from 'use-countdown-timer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actionResendCode } from '../Actions/resend-code.actions'
import { actionVerifyCode } from '../Actions/verify-code.actions'
import { AppStateType } from '../../../Store/reducers'
import { useForm } from 'react-hook-form'
import { millisToMinutesAndSeconds } from '../../../Utils/dateAndTimeUtils'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Pages } from 'Modules/Common/Enums/Common'
import { dispatchLoginCancel } from '../Actions/login.actions'
import Toaster from 'Modules/Common/Services/Toaster'
import { getLocationDropdownAction } from 'Modules/Common/Services/Location/location.actions'
import { actionAddDomainWebsite } from 'Modules/Domains/Actions/domainList.actions'
import { actionGetFreeTrialDetails } from 'Modules/Tenant/Actions/tenant.actions'

interface Props {
  page: Pages
}

const VisitorVerifyCode = ({ page }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const location = useLocation()
  let search = useLocation().search

  const dispatch = useDispatch()
  const history = useHistory()
  const { verifyEmail, resendCodeSuccess, isAuthenticated, isVerified, inviteInfo } = useSelector(
    (state: AppStateType) => state.AuthReducer
  )
  const { userData } = useSelector((state: AppStateType) => state.AuthReducer)
  const getLocations = useSelector(
    (state: AppStateType) => ({ ...state.LocationReducer.locationDropdown }),
    shallowEqual
  )
  const [email, setEmail] = useState<string>('')
  const [codeExpired, setCodeExpired] = useState<boolean>(false)

  // MFA Code Time
  const { countdown, start, reset } = useCountdownTimer({
    // ---------10 minutes ---------
    timer: 10000 * 60,
    autostart: false,
    resetOnExpire: false,
    onExpire: () => {
      // When timer expires
      setCodeExpired(true)
    },
  })

  useEffect(() => {
    if (!verifyEmail) {
      return
    }
    setEmail(verifyEmail)
  }, [verifyEmail])

  useEffect(() => {
    if (!(getLocations && getLocations.data) && isVerified) {
      dispatch(getLocationDropdownAction())
    }
  }, [isVerified, getLocations])

  useEffect(() => {
    start()
  }, [])

  useEffect(() => {
    if (resendCodeSuccess) {
      reset()
      start()
      setCodeExpired(false)
    }
  }, [resendCodeSuccess])

  const onResendClick = () => {
    dispatch(actionResendCode(email))
  }
  const weburl = new URLSearchParams(search).get('weburi')
  const user_type = new URLSearchParams(search).get('usertype')

  const DomainPayload = () => {
    const formValues = {
      domain_name: atob(weburl),
      user_type: atob(user_type),
    }
    return formValues
  }
  const onSubmit = (data: any) => {
    if (codeExpired) {
      Toaster.error(I18n.t('auth_toast.code_expired'))
    } else {
      dispatch(actionVerifyCode(email, data.code))
    }
  }
 
  useEffect(() => {
    if (userData && weburl) {
      dispatch(actionAddDomainWebsite(DomainPayload()))
    }
  }, [userData])

  const onCancelClick = () => {
    dispatch(dispatchLoginCancel())
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={cx('row p-0', page === Pages.RegisterPage ? styles.registerVerifyCodeContainer : ' m-5')}>
        <div className="row p-0 m-0 w-100">
          <p className={styles.createAccountText}>{I18n.t('verify_code.title')}</p>
        </div>
        <div className="row p-0 m-0 w-100">
          <p className={styles.verificationCodeSentText}>{I18n.t('verify_code.sent_to_email')}</p>
        </div>
        <div className="row p-0 m-0 w-100">
          <div className="col-md-12 p-0">
            <Form.Group>
              <Form.Label className={cx(styles.verificationCodeSentText, styles.required)}>
                {I18n.t('verify_code.enter_code')}
              </Form.Label>
              <Form.Control
                type="text"
                maxLength={6}
                x-automation-id="code"
                id="code"
                className={cx(styles.mfaInput)}
                placeholder="00 00 00"
                {...register('code', {
                  required: I18n.t('verify_code.code_required'),
                  pattern: {
                    value: /^[0-9]*$/,
                    message: I18n.t('verify_code.valid_code'),
                  },
                })}
                isInvalid={errors?.code}
              />
              <Form.Control.Feedback type="invalid" className="m-0">
                {errors?.code?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="row p-0 m-0 mt-3 w-100 d-flex justify-content-center">
          <div className="col-md-6 d-flex justify-content-end p-0 m-0 pr-1">
            <CancelButtonWithIcon
              onClick={onCancelClick}
              type="button"
              text={I18n.t('common.cancel')}
              style={{ width: '100%' }}
              x-automation-id="cancel-btn"
              id="cancel-btn"
              className={styles.customButton}
            />
          </div>
          <div className="col-md-6 p-0 m-0 pl-1">
            <ButtonWithIcon
              type="submit"
              x-automation-id="submit-btn"
              id="submit-btn"
              style={{ width: '100%' }}
              text={I18n.t('verify_code.submit_btn')}
              icon={<ArrowRight color="#FFFFFF" />}
              iconToRight={true}
              disabled={!email}
              className={styles.customButton}
            />
          </div>
        </div>
        <div className="row p-0 m-0 mt-4 w-100 d-flex justify-content-center">
          <p className={styles.alreadyRegisteredText}>
            {I18n.t('verify_code.valid_for')}{' '}
            <span className={styles.mfaTimer}>{millisToMinutesAndSeconds(countdown)}</span> {I18n.t('verify_code.mins')}
          </p>
        </div>
        <div className="row p-0 m-0 mt-3 w-100 d-flex justify-content-center">
          <p className={styles.alreadyRegisteredText}>
            {I18n.t('verify_code.didnt_receive')}{' '}
            <a x-automation-id="resend-btn" id="resend-btn" className={styles.linkText} onClick={onResendClick}>
              {I18n.t('verify_code.resend')}
            </a>
          </p>
        </div>
        {page === Pages.RegisterPage ? (
          <div className="row w-100 mt-5 d-flex justify-content-center">
            <p className={styles.alreadyRegisteredText}>
              {I18n.t('register.trouble_sign_up')}{' '}
              <a
                href={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
                x-automation-id="contact-admin"
                id="contact-admin"
                className={styles.linkText}
              >
                {I18n.t('register.contact_admin')}
              </a>
            </p>
          </div>
        ) : null}
      </div>
    </Form>
  )
}

export default VisitorVerifyCode
