import { DomainType } from 'Modules/Tenant/Models/tenantList.model'
import { http } from '../../../Utils/AxiosWrapper'
import { domains } from '../../Common/Constants/ApiUrls'
import { ApiResponse, ApiResponseDomain } from 'Modules/Common/Models'
import { AddDomainPayload, WebsiteAddDomainPayload } from '../Models/domains.model'
import { domain } from 'process'

interface DomainsServiceProps {
  getDomainsList(params: any): Promise<ApiResponse<DomainType[]>>
}

class DomainsService implements DomainsServiceProps {
  public async getDomainsList(params: any): Promise<ApiResponse<DomainType[]>> {
    let response: ApiResponse<DomainType[]>
    try {
      const { data }: any = await http.request({
        method: 'get',
        url: domains.getDomainsList,
        params,
      })

      response = {
        success: true,
        data: data?.data,
        code: data?.code,
        message: data?.message,
        totalRecords: data?.total_records,
        activePage: data?.page,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async getDomainById(id: number): Promise<ApiResponse<DomainType>> {
    let response: ApiResponse<DomainType>
    try {
      const { data }: any = await http.get(domains.getDomainById(id))
      response = {
        success: true,
        data: data?.data,
        code: data?.code,
        message: data?.message,
        totalRecords: data?.total_records,
        activePage: data?.page,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async addDomain(payload: AddDomainPayload): Promise<ApiResponseDomain<string>> {
    let response: ApiResponseDomain<string>
    try {
      const { data }: any = await http.post(domains.addDomain, payload)
      response = {
        success: true,
        code: data.code,
        message: data?.message,
        tenant_id: data?.tenant_id,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
        tenant_id: error.response?.data?.tenant_id,
      }
    }
    return response
  }
  
  public async editDomain(payload: AddDomainPayload, id: number, is_sp:boolean): Promise<ApiResponseDomain<string>> {
    let response: ApiResponseDomain<string>
    try {
      if (is_sp) {
        const { data }: any = await http.put(domains.editDomainServiceProvider(id), payload)
        response = {
          success: true,
          code: data.code,
          message: data?.message,
          tenant_id: data?.tenant_id,
        }
      } else {
        const { data }: any = await http.put(domains.editDomain(id), payload)
        response = {
          success: true,
          code: data.code,
          message: data?.message,
          tenant_id: data?.tenant_id,
        }
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
        tenant_id: error?.response?.data?.tenant_id,
      }
    }
    return response
  }

  public async removeSubDomain(id: number): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.delete(domains.removeSubdomain(id))
      response = {
        success: true,
        code: data.code,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async removeDomain(id: number, flag: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.delete(domains.removeDomain(id, flag))
      response = {
        success: true,
        code: data.code,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async getJsScript(tenant_id: number, domain_id: number, sub_domain_id: number): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.post(domains.getJsScript, {
        tenant_id,
        domain_id,
        sub_domain_id,
      })
      response = {
        success: true,
        data: data.script,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

  public async verifyJsScript(
    tenant_id: number,
    domain_id: number,
    sub_domain_id: number
  ): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.post(domains.verifyJsScript, {
        tenant_id,
        domain_id,
        sub_domain_id,
      })
      response = {
        success: true,
        data: data.banner_deployment_status,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
  
  public async addWebsiteDomain(payload: WebsiteAddDomainPayload): Promise<ApiResponseDomain<string>> {
    let response: ApiResponseDomain<string>
    try {
      const { data }: any = await http.post(domains.addWebsiteDomain, payload)
      response = {
        success: true,
        code: data.code,
        message: data?.message,
        tenant_id: data?.tenant_id,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
        tenant_id: error.response?.data?.tenant_id,
      }
    }
    return response
  }

  public async scanwebsitedomain(domain_id: number): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.post(domains.websiteScanning(domain_id))
      response = {
        success: true,
        code: data.code,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }


  public async scanAppSumodomain(tenant_email: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data }: any = await http.post(domains.AppSumoTenantWebsiteScanning, {
        tenant_email,
      })
      response = {
        success: true,
        code: data.code,
        message: data?.message,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }

}

export default new DomainsService()
