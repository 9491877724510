import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { BreadCrumbMenuItem } from '../Models'

const HeadingSpan = styled.a`
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  color: #0057d9;
  cursor: pointer;
  text-decoration-line: none;
  padding: 2px;
  &:hover {
    text-decoration-line: underline;
  }
`

const DotSpan = styled.span`
  &:before {
    content: ' • ';
    color: rgba(0, 0, 0, 0.5);
    padding: 0px 5px;
    font-size: 20px;
  }
`

const SubHeading = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #787878;
  &:hover {
    text-decoration: none;
    color: #787878;
  }
`

interface Props {
  menu: Array<BreadCrumbMenuItem>
}

const Breadcrumbs = ({ menu }: Props) => {
  const history = useHistory()

  if (!menu || menu?.length === 0) {
    return null
  }

  return (
    <div className="d-flex align-items-center">
      {menu?.map((m, i) => (
        <React.Fragment key={i}>
          {i != 0 ? <DotSpan key={i + 'dot'} /> : null}
          {m.link ? (
            <HeadingSpan
              id={'breadcrumb-link-' + i.toString()}
              onClick={() => {
                history.push(m.link)
              }}
              key={i + 'heading'}
              role="link"
            >
              {m.text}
            </HeadingSpan>
          ) : (
            <SubHeading className="m-1" key={i + 'subheading'}>
              {m.text}
            </SubHeading>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default React.memo(Breadcrumbs)
