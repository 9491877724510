import { subscriptionScreen } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { AddSubscriptionPayload } from '../Model'

export interface GetFeaturesListService {
  addSubsription(id: string, payload: AddSubscriptionPayload): Promise<ApiResponse<any>>
}

class GetFeaturesListServiceDefault implements GetFeaturesListService {
  async addSubsription(id: string, payload: AddSubscriptionPayload): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'post',
        url: subscriptionScreen.addSubscription(id),
        data: payload,
      })
      result = GetFeaturesListServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ApiResponse<any> {
    if (!data || data.code !== ResponseCodes.Success) {
      return {
        success: false,
        code: ResponseCodes.NotFound,
        message: 'Empty data in response',
      }
    }

    return {
      success: true,
      code: data.code,
      message: data.message || '',
      data: data.data || [],
      totalRecords: data.total_records,
      activePage: parseInt(data.page),
    }
  }
}

export default new GetFeaturesListServiceDefault()
