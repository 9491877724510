import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './Domains.module.css'
import { cx } from '@emotion/css'
import Table from '../Common/Components/TableComponent'
import { I18n } from 'react-redux-i18n'
import { Column } from 'react-table'
import ActionDropdown from 'Modules/Common/Components/ActionDropdown'
import CopyPrivacyCenter from 'Modules/Tenant/CopyPrivacyCenter'
import CookieBanner from 'Modules/Tenant/TenantList/CookieBanner'
import { OverlayTrigger, Popover, Spinner, Tooltip } from 'react-bootstrap'
import { MinusSquare, PlusSquare, Clock, Edit2, Trash, Lock, AlertTriangle, X, MoreVertical } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import '../Tenant/Tenant.css'
import SubDomainList from './SubDomainList'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { actionGetAllDomainsList } from './Actions/domainList.actions'
import DomainsHeader from './DomainsHeader'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import { actionGetFreeTrialDetails } from 'Modules/Tenant/Actions/tenant.actions'
import WebsiteEditModel from './WebsiteEditModel'
import WebsiteEditModelSubscribe from './WebsiteEditModelSubscribe'

export const addPrefixToDomain = (domainName: string) => {
  if (domainName.includes('www.')) {
    return domainName
  } else {
    return 'www.' + domainName
  }
}

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    {I18n.t('domains.freePlanMenuText')}
  </Tooltip>
)

export default function DomainsList() {
  const { domainsList, pageParams, refreshDomainsList, scanStatus } = useSelector(
    (state: AppStateType) => state.DomainsReducer
  )
  const {
    licensePlan,
    freePlanEditDomainLimit,
    websiteVisitor,
    old_domain_id,
    new_domain_id,
    domainsUsed,
  } = useSelector((state: AppStateType) => state.TenantReducer)
  const { userData } = useSelector((state: AppStateType) => state.AuthReducer)
  const { register_user, isExistingUser } = useSelector((state: AppStateType) => state.AuthReducer)
  const [firstDomain, setFirstDomain] = useState<boolean>(true)
  const [showWebsiteScanStatus, setShowWebsiteScanStatus] = useState<boolean>(false)
  const [showProfessionalBox, sethowProfessionalBox] = useState<boolean>(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const [show, setShow] = useState({
    domainName: '',
    showBanner: false,
    domainId: '',
  })
  const [showwebsiteEditModel, setShowWebsiteEditModel] = useState({
    showBanner: false,
    old_domainId: null,
    new_domainId: null,
  })

  const [showwebsiteSubsribeModel, setShowwebsiteSubsribeModel] = useState({
    showBanner: false,
  })

  const isFreePlan = licensePlan === 'free_plan'
  useEffect(() => {
    if (userData?.tenantSource === 'visitor') {
      setShowWebsiteScanStatus(true)
    } else {
      setShowWebsiteScanStatus(false)
    }
    if (scanStatus?.success === true) {
      setShowWebsiteScanStatus(false)
      dispatch(actionGetAllDomainsList({}))
    }
  }, [dispatch, scanStatus, scanStatus?.success, userData])

  useEffect(() => {
    if (refreshDomainsList?.refetch) {
      dispatch(actionGetFreeTrialDetails(userData?.tenantId))
      dispatch(actionGetAllDomainsList({}))
    }
  }, [dispatch, refreshDomainsList?.refetch, userData?.tenantId])

  useEffect(() => {
    if (isFreePlan) {
      if (freePlanEditDomainLimit === 'True' && domainsUsed > 1) {
        setShowWebsiteEditModel({
          showBanner: true,
          old_domainId: old_domain_id,
          new_domainId: new_domain_id,
        })
        setFirstDomain(false)
      } else if (freePlanEditDomainLimit === 'False' && firstDomain) {
        setShowwebsiteSubsribeModel({
          showBanner: true,
        })
      }
    }
  }, [
    websiteVisitor,
    freePlanEditDomainLimit,
    old_domain_id,
    new_domain_id,
    isFreePlan,
    domainsUsed,
    isExistingUser,
    register_user,
    firstDomain,
  ])

  const onSubscriptionclick = () => {
    history.push('/plans')
  }

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const data = row.original.sub_domains

    return (
      <div className={styles.domainListTableContainer}>
        <div className="row p-0 m-0">
          <table className={styles.domainListTable}>
            <tbody>
              <SubDomainList
                tenantId={row.original.tenant_id}
                data={row.original}
                subDomainList={data}
                tenantName={''}
              />
            </tbody>
          </table>
        </div>
      </div>
    )
  }, [])

  const showRequestDetails = (openRequests: number, closedRequests: number) => {
    return (
      <Popover
        id="request-details-popover"
        className="reuestDetailsPopover p-2 popoverShadow"
        title="Open/Closed requests"
      >
        <div className="ml-2">
          <div className="row p-2">
            <div className="col greenText d-flex align-items-center"> {I18n.t('tenant.open')} </div>
            <div className="col">
              <div className="countRectBG">{openRequests}</div>
            </div>
          </div>
          <div className="row p-2">
            <div className="col greyText d-flex align-items-center"> {I18n.t('tenant.close')} </div>
            <div className="col">
              <div className="countRectBG">{closedRequests}</div>
            </div>
          </div>
        </div>
      </Popover>
    )
  }

  const setPageParams = useCallback(({ pageIndex, pageSize, sortBy }) => {
    let sortColumn = ''
    let sortDirection = ''
    if (sortBy && sortBy.length > 0) {
      sortColumn = sortBy[0].id || 'created_at'
      sortDirection = sortBy[0].desc ? 'desc' : 'asc'
    }
    dispatch(
      actionGetAllDomainsList({
        ...pageParams,
        page_no: pageIndex + 1,
        page_size: pageSize,
        sort_column: sortColumn,
        sort_direction: sortDirection,
      })
    )
  }, [])

  const columns: Array<Column> = useMemo((): Array<Column> => {
    const restCols = !isFreePlan
      ? [
          {
            width: '10%',
            Header: I18n.t('tenant.requests'),
            accessor: 'total_dsar_count',
            Cell: ({ cell }) => {
              const rowData: any = cell.row.original
              return (
                <div className="columnContainer">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={showRequestDetails(rowData?.total_open_request, rowData?.total_closed_request)}
                  >
                    <a className="columnLink">{rowData?.total_dsar_count}</a>
                  </OverlayTrigger>
                </div>
              )
            },
          },

          {
            width: '18%',
            Header: I18n.t('tenant.privacyCenter'),
            accessor: 'privacy_center_url',
            Cell: ({ cell }) => {
              const rowData: any = cell.row.original
              return (
                <div key={rowData?.tenant_id}>
                  {isFreePlan ? (
                    <div className={styles.upgradePlanIcon}>
                      <Lock></Lock>
                      <span className="ml-1">
                        <a className="columnLink" target="_blank" href="https://cytrio.com" rel="noopener noreferrer">
                          {I18n.t('common.upgradePlan')}
                        </a>
                      </span>
                    </div>
                  ) : (
                    <CopyPrivacyCenter
                      data={rowData}
                      tenantId={rowData.tenant_id}
                      value={rowData?.privacy_center_url}
                    />
                  )}
                </div>
              )
            },
          },
        ]
      : []

    const columns = [
      {
        width: '18%',
        Header: 'Domain',
        accessor: 'domain',
        id: 'expander',
        disableSortBy: true,
        Cell: ({ cell, row }) => {
          const rowData: any = cell.row.original
          const showExpandIcon = rowData?.sub_domains?.length > 0

          return (
            <>
              <div className={cx(styles.domain)}>
                {rowData?.domain_name}
                {row.isExpanded
                  ? showExpandIcon && (
                      <MinusSquare className={styles.expandButton} {...row.getToggleRowExpandedProps()} />
                    )
                  : showExpandIcon && (
                      <PlusSquare className={styles.expandButton} {...row.getToggleRowExpandedProps()} />
                    )}
              </div>
            </>
          )
        },
      },
      ...restCols,
      {
        width: '18%',
        Header: 'Cookie Banner',
        accessor: 'consent_status',
        disableSortBy: true,
        Cell: ({ value, cell }) => {
          const rowData: any = cell.row.original
          return rowData?.banner_status === 'inactive' ? (
            <div className={styles.scanningIcon}>
              <span className="ml-1">{I18n.t('Inactive')}</span>
            </div>
          ) : (
            <CookieBanner data={rowData} status={value} tenantId={rowData.tenant_id} />
          )
        },
      },
      {
        width: '18%',
        Header: 'Cookies',
        accessor: 'no_of_cookies',
        disableSortBy: true,
        Cell: ({ cell }) => {
          const rowData: any = cell.row.original

          if (showWebsiteScanStatus && rowData?.scan_status === null) {
            return (
              <div className={styles.scanningIcon}>
                <Clock></Clock>
                <span className="ml-1">{I18n.t('tenant.scanInProgress')}</span>
              </div>
            )
          }
          if (rowData?.cookies_scan_status === 'failed') {
            return (
              <div>
                <div className={styles.scanFailedIcon}>
                  <AlertTriangle />
                  <span className="ml-1">{I18n.t('tenant.scanFailed')}</span>
                </div>
              </div>
            )
          }
          return rowData?.no_of_cookies === null ? (
            <div className={styles.scanningIcon}>
              <Clock></Clock>
              <span className="ml-1">{I18n.t('tenant.scanInProgress')}</span>
            </div>
          ) : (
            <Link className="columnLink" to={`/consents/cookies?domain_id=${rowData?.domain_id}`}>
              {rowData?.no_of_cookies || 0}
            </Link>
          )
        },
      },
      {
        width: '6%',
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ cell }) => {
          const rowData: any = cell.row.original
          if (isFreePlan) {
            return (
              <div className="d-flex">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                  <span className="cursorPointer actionItem actionItemMedium">
                    <ActionDropdown
                      menuItems={[
                        {
                          isDisabled: isFreePlan,
                          label: I18n.t('domains.editDomain'),
                          onClick: (event: any) => {
                            event.stopPropagation()
                            history.push(`/admin/domains/addDomains?domainId=${rowData.domain_id}`)
                          },
                          icon: <Edit2 size="18px" />,
                          id: 'edit-domain',
                        },
                        {
                          isDisabled: isFreePlan,
                          label: I18n.t('domains.deleteDomain'),
                          onClick: (event: any) => {
                            event.stopPropagation()
                            setShow({
                              domainName: rowData?.domain_name,
                              showBanner: true,
                              domainId: rowData?.domain_id,
                            })
                          },
                          icon: <Trash size="18px" />,
                          id: 'delete-domain',
                        },
                      ]}
                      indexKey={rowData?.tenant_id}
                    />
                  </span>
                </OverlayTrigger>
              </div>
            )
          } else {
            return (
              <div className="d-flex">
                <ActionDropdown
                  menuItems={[
                    {
                      label: I18n.t('domains.editDomain'),
                      onClick: (event: any) => {
                        event.stopPropagation()
                        history.push(`/admin/domains/addDomains?domainId=${rowData.domain_id}`)
                      },
                      icon: <Edit2 size="18px" />,
                      id: 'edit-domain',
                    },
                    {
                      label: I18n.t('domains.deleteDomain'),
                      onClick: (event: any) => {
                        event.stopPropagation()
                        setShow({
                          domainName: rowData?.domain_name,
                          showBanner: true,
                          domainId: rowData?.domain_id,
                        })
                      },
                      icon: <Trash size="18px" />,
                      id: 'delete-domain',
                    },
                  ]}
                  indexKey={rowData?.tenant_id}
                />
              </div>
            )
          }
        },
      },
    ]

    return columns
  }, [licensePlan])

  return (
    <>
      <DomainsHeader />
      <div className="row m-0 p-0 w-100 mt-2 h-100">
        <div className={cx('col-md-12 h-100', styles.mainContainer)}>
          <Table
            activePage={pageParams.activePage}
            setPageParams={setPageParams}
            totalRecords={pageParams.totalRecords}
            data={domainsList || []}
            columns={columns}
            noRecordsMsg={domainsList ? I18n.t('domains.noDomainsMsg') : ''}
            noRecordsSubMsg={domainsList ? I18n.t('domains.noDomainsSubMsg') : ''}
            renderRowSubComponent={renderRowSubComponent}
            defaultSort={[
              {
                id: 'updated_at',
                desc: true,
              },
            ]}
          />
        </div>
        <ConfirmDeleteModal
          show={show}
          setShow={setShow}
          title={I18n.t('domains.deleteDomainTitle')}
          message={I18n.t('domains.deleteDomainMessage')}
          isSubdomain={false}
        />
        <WebsiteEditModel
          showwebsiteEditModel={showwebsiteEditModel}
          setShowWebsiteEditModel={setShowWebsiteEditModel}
          title={I18n.t('domains.editWebsiteDomainTitle')}
          message={I18n.t('domains.deleteDomainVisitorMessage')}
          message_2={I18n.t('domains.deleteDomainVisitorMessage1')}
          message_3={I18n.t('domains.deleteDomainVisitorMessage2')}
        />
        <WebsiteEditModelSubscribe
          showwebsiteSubsribeModel={showwebsiteSubsribeModel}
          setShowWebsiteSubscribeModel={setShowwebsiteSubsribeModel}
          title={I18n.t('domains.domaiLimitReached')}
          message={I18n.t('domains.deleteDomainVisitorPaidMessage')}
          message_2={I18n.t('domains.deleteDomainVisitorPaidMessage1')}
          onSubscriptionclick={onSubscriptionclick}
        />
      </div>
    </>
  )
}
