import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { PageParams } from 'Modules/Common/Models'

const convertToString = (value: string | string[]): string => {
  if (value instanceof Array) {
    return value.join('')
  }
  return value
}

export default convertToString

export const buildParams = (p: PageParams) => {
  const params: any = {}
  if (p) {
    params.page_no = p.page_no
    params.page_size = p.page_size
    if (p.search && p.search != '') {
      params.search = p.search
    }
    if (p.sort_column && p.sort_column != '') {
      params.sort_column = p.sort_column
    }
    if (p.sort_direction && p.sort_direction != '') {
      params.sort_direction = p.sort_direction
    }
  }
  return params
}

export const buildResponse = (data: any) => {
  if (!data) {
    return {
      success: false,
      code: ResponseCodes.InvalidParams,
      message: 'Empty data in response',
    }
  }

  if (data.code === ResponseCodes.NotFound || data.code === ResponseCodes.PermissionDenied) {
    return {
      success: false,
      code: data.code,
      message: data.message,
    }
  }

  if (data.code === ResponseCodes.EmptyData) {
    return {
      success: true,
      code: data.code,
      message: data.message,
    }
  }
  return {
    success: true,
    code: data.code,
    message: data.message || '',
    data: data?.data,
  }
}
