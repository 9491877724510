import { alertTriangleIcon, checkCircleIcon } from 'Assets/Images'
import React from 'react'
import styles from './VerifyScriptDeployment.module.css'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { Spinner } from 'react-bootstrap'

export default function VerifyScriptDeployment() {
  const scriptDeployStatus = useSelector((state: AppStateType) => state.DomainsReducer.scriptDeployStatus)

  if (scriptDeployStatus === 'in_progress') {
    return (
      <div className={styles.mainContainer}>
        <Spinner
          animation={'border'}
          size="sm"
          className={cx('mr-2', styles.spinner)}
          style={{ color: '#c69400' }}
        ></Spinner>
        <div className={styles.scanInProgessText}>{I18n.t('tenant.verifying')}</div>
      </div>
    )
  }
  return (
    <div className={styles.mainContainer}>
      {scriptDeployStatus === 'active' ? (
        <>
          <img src={checkCircleIcon} className={cx('mr-2', styles.icon)} />
          <p className={styles.successText}>{I18n.t('tenant.deploymentSuccesfullMsg')}</p>
        </>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className={'d-flex align-items-center'}>
            <img src={alertTriangleIcon} className={cx('mr-2 mt-1', styles.icon)} />
            <p className={styles.errorText}>{I18n.t('tenant.scriptNotInstalledMsg')}</p>
          </div>
          <p className={styles.errorSubText}>{I18n.t('tenant.scriptNotInstalledSubMsg')}</p>
        </div>
      )}
    </div>
  )
}
