function redirectToUrl(url: string) {
  let link = document.createElement('a')
  link.href = url

  document.body.appendChild(link)
  link.click()

  link.addEventListener('click', function () {
    link.parentNode.removeChild(link)
  })
}

export default redirectToUrl
