import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import service from '../Services/request.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { RequestExportPayload } from '../Models/request.model'

export const ACTION_EXPORT_REQUEST = 'ACTION_EXPORT_REQUEST'
export const ACTION_EXPORT_REQUEST_SUCCESS = 'ACTION_EXPORT_REQUEST_SUCCESS'
export const ACTION_EXPORT_REQUEST_FAILURE = 'ACTION_EXPORT_REQUEST_FAILURE'
export const ACTION_EXPORT_REQUEST_CLEAR = 'ACTION_EXPORT_REQUEST_CLEAR'

export interface ActionExportRequest extends Action {
    type: typeof ACTION_EXPORT_REQUEST
}

export interface ActionExportRequestClear extends Action {
    type: typeof ACTION_EXPORT_REQUEST_CLEAR
}

export interface ActionExportRequestSuccess extends Action {
    type: typeof ACTION_EXPORT_REQUEST_SUCCESS
    payload: {
        data: string
    }
}

export interface ActionExportRequestFailure extends Action {
    type: typeof ACTION_EXPORT_REQUEST_FAILURE
    payload: {
        success: boolean
        errorMessage: string
    }
}

export type ExportRequestActions =
    | ActionExportRequest
    | ActionExportRequestFailure
    | ActionExportRequestSuccess
    | ActionExportRequestClear

export const dispatchExportRequestClear = (): ActionExportRequestClear => ({
    type: ACTION_EXPORT_REQUEST_CLEAR,
})

const dispatchExportRequestSuccess = (data: string): ActionExportRequestSuccess => ({
    type: ACTION_EXPORT_REQUEST_SUCCESS,
    payload: {
        data,
    },
})

const dispatchExportRequestFailure = (
    error: Error,
    success: boolean
): ActionExportRequestFailure => ({
    type: ACTION_EXPORT_REQUEST_FAILURE,
    payload: { errorMessage: error.message, success },
})

export function actionExportRequest(
    payload: RequestExportPayload,
): (dispatch: Dispatch<ExportRequestActions>) => Promise<void> {
    return async (dispatch: Dispatch<ExportRequestActions>) => {
        loaderStart(dispatch)
        try {
            const result = await service.exportRequest(payload)
            if (result && result.success) {
                // toaster.success(I18n.t('requests.exportdsar'))
                dispatch(dispatchExportRequestSuccess(result?.message))
            } else {
                toaster.error(I18n.t('common.toast.something_went_wrong'))
                dispatch(dispatchExportRequestFailure(new Error(result?.message || 'Something went wrong.'), false))
            }
            loaderStop(dispatch)
        } catch (error: any) {
            toaster.error(I18n.t('common.toast.something_went_wrong'))
            dispatch(dispatchExportRequestFailure(error, false))
            loaderStop(dispatch)
        }
    }
}
