export const domains = {
  pageTitle: 'Domains',
  noDomainsMsg: 'No domain exist',
  noDomainsSubMsg: 'Please add domain',
  editDomain: 'Edit',
  deleteDomain: 'Delete',
  addDomain: 'Add Domain',
  successMessage: 'Domain added successfully',
  updateDomain: 'Edit Domain',
  updateMessage: 'Domain updated successfully',
  confirmRemoveText:
    'Deleting the domain shall delete all scanned cookie data associated with it.  Are you sure you want to proceed ?',
  upgradeAdditionalDomainText: 'Upgrade your plan to configure additional domains',
  removedSubdomain: 'Subdomain removed successfully',
  removedDomain: 'Domain removed successfully',
  cookiescanningsuccessful: 'Cookie scanning completed successfully',
  cookiescanningfailed: 'Cookie scanning failed, provide a valid domain/sub-domain',
  deleteDomainMessage:
    'will be permanently deleted from this account and all associated cookie/consent/DSAR data will be removed.  Any data related to linked sub-domains will also be removed.  This action is irreversible.',
  deleteSubDomainMessage:
    'will be permanently deleted from this account and all associated cookie/consent/DSAR data will be removed. This action is irreversible.',
  deleteDomainTitle: 'Delete this website?',
  yesDeleteIt: 'Yes, Delete it',
  deleteDomainVisitorMessage: "As part of your current free plan, you can configure one domain.",
  deleteDomainVisitorMessage1: "Editing with a new domain shall remove the existing domain and all the cookies and consent data associated to it.",
  deleteDomainVisitorMessage2: "Are you sure you want to proceed?",
  deleteDomainVisitorPaidMessage: "As part of your current free plan, you have reached the limit of configuring one domain.",
  deleteDomainVisitorPaidMessage1: "For configuring additional domains, please upgrade your plan.",
  yesSubscribe: 'Upgrade',
  editWebsiteDomainTitle: 'Confirm Edit Domain',
  domaiLimitReached: 'Domain Limit Reached',
  editbuttonConfirm: 'Confirm',
  freePlanMenuText: 'Available in Professional version',
}
