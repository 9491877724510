import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { TenantModel } from '../Models/tenant.models'
import service from '../Services/tenant.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'

export const ACTION_UPDATE_TENANT = 'ACTION_UPDATE_TENANT'
export const ACTION_UPDATE_TENANT_SUCCESS = 'ACTION_UPDATE_TENANT_SUCCESS'
export const ACTION_UPDATE_TENANT_FAILURE = 'ACTION_UPDATE_TENANT_FAILURE'
export const ACTION_UPDATE_TENANT_CLEAR = 'ACTION_UPDATE_TENANT_CLEAR'

export interface ActionUpdateTenant extends Action {
  type: typeof ACTION_UPDATE_TENANT
}

export interface ActionUpdateTenantClear extends Action {
  type: typeof ACTION_UPDATE_TENANT_CLEAR
}

export interface ActionUpdateTenantSuccess extends Action {
  type: typeof ACTION_UPDATE_TENANT_SUCCESS
  payload: {
    data: string
  }
}

export interface ActionUpdateTenantFailure extends Action {
  type: typeof ACTION_UPDATE_TENANT_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export type UpdateTenantActions =
  | ActionUpdateTenant
  | ActionUpdateTenantFailure
  | ActionUpdateTenantSuccess
  | ActionUpdateTenantClear

export const dispatchUpdateTenantClear = (): ActionUpdateTenantClear => ({
  type: ACTION_UPDATE_TENANT_CLEAR,
})

const dispatchUpdateTenantSuccess = (data: string): ActionUpdateTenantSuccess => ({
  type: ACTION_UPDATE_TENANT_SUCCESS,
  payload: {
    data,
  },
})

const dispatchUpdateTenantFailure = (error: Error, success: boolean): ActionUpdateTenantFailure => ({
  type: ACTION_UPDATE_TENANT_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function actionUpdateTenant(
  tenantId: number,
  updatedData: TenantModel
): (dispatch: Dispatch<UpdateTenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<UpdateTenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.updateTenant(tenantId, updatedData)
      if (result && result.success) {
        toaster.success(I18n.t('tenant.addTenant.detailsUpdated'))
        dispatch(dispatchUpdateTenantSuccess(result?.data))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchUpdateTenantFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdateTenantFailure(error, false))
      loaderStop(dispatch)
    }
  }
}

export function actionUpdateTenantDomain (
  tenantId: number,
  updatedData: TenantModel,
): (dispatch: Dispatch<UpdateTenantActions>) => Promise<void> {
  return async (dispatch: Dispatch<UpdateTenantActions>) => {
    loaderStart(dispatch)
    try {
      const result = await service.updateTenantDomains(tenantId, updatedData)
      if (result && result.success) {
        toaster.success(I18n.t('tenant.addTenant.detailsUpdated'))
        dispatch(dispatchUpdateTenantSuccess(result?.data))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchUpdateTenantFailure(new Error(result?.message || 'Something went wrong.'), false))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchUpdateTenantFailure(error, false))
      loaderStop(dispatch)
    }
  }
}