import {
  GetRequestsListActions,
  ACTION_GET_REQUESTS_LIST,
  ACTION_GET_REQUESTS_LIST_FAILURE,
  ACTION_GET_REQUESTS_LIST_SUCCESS,
} from '../Actions/requests-list.actions'

import { PageParams } from 'Modules/Common/Models'
import {
  ACTION_CLOSE_REQUEST,
  ACTION_CLOSE_REQUEST_CLEAR,
  ACTION_CLOSE_REQUEST_FAILURE,
  ACTION_CLOSE_REQUEST_SUCCESS,
  CloseRequestActions,
} from '../Actions/close-request.actions'
import {
  ACTION_EXPORT_REQUEST,
  ACTION_EXPORT_REQUEST_CLEAR,
  ACTION_EXPORT_REQUEST_FAILURE,
  ACTION_EXPORT_REQUEST_SUCCESS,
  ExportRequestActions,
} from '../Actions/export-requests.actions'
import {
  ActionToggleSelectAll,
  ACTION_TOGGLE_ROW,
  ACTION_TOGGLE_SELECT_ALL,
  SelectReuqestActions,
} from '../Actions/select-request.actions'

import {
  ACTION_REPORT_UPLOAD_CLEAR,
  ACTION_REPORT_UPLOAD_FAILURE,
  ACTION_REPORT_UPLOAD_START,
  ACTION_REPORT_UPLOAD_SUCCESS,
  ReportUploadActions,
} from '../Actions/upload-dsar-file.actions'

import {
  DsarReportActions,
  ACTION_GET_DSAR_REPORTS,
  ACTION_GET_DSAR_REPORTS_FAILURE,
  ACTION_GET_DSAR_REPORTS_SUCCESS,
  ACTION_UPDATE_DSAR_REPORTS,
  ACTION_UPDATE_DSAR_REPORTS_FAILURE,
  ACTION_UPDATE_DSAR_REPORTS_SUCCESS,
} from '../Actions/dsar-report-actions'
import { actionUpdateTenant } from 'Modules/Tenant/Actions/update-tenant.actions'

export interface RequestsReducerState {
  requestList: Array<any>
  totalRecords: number
  pageParams: PageParams
  activePage: number
  updateRequest: {
    success: boolean
    message: string
  }
  exportRequest: {
    success: boolean
    message: string
  }
  selectedRecords: {}
  isSelectAll: {}
  uploadReport?: {
    success: boolean
    message?: string
    inProgress?: boolean
    error: boolean
  }
  getTenantDsarReports: { success: boolean; data: Array<string> }
  updateTenantDsarReports : { success: boolean }
}

export const initialState: RequestsReducerState = {
  activePage: 1,
  requestList: [],
  pageParams: null,
  totalRecords: 0,
  updateRequest: {
    success: false,
    message: '',
  },
  exportRequest: {
    success: false,
    message: '',
  },
  selectedRecords: {},
  isSelectAll: {},
  uploadReport: {
    success: false,
    message: null,
    inProgress: false,
    error: false,
  },
  getTenantDsarReports: {success:false, data:[]},
  updateTenantDsarReports : {success:false}
}

type Actions =
  | GetRequestsListActions
  | CloseRequestActions
  | ExportRequestActions
  | SelectReuqestActions
  | ReportUploadActions
  | DsarReportActions

export const RequestsReducer = (
  state = initialState, // NOSONAR
  action: Actions
): RequestsReducerState => {
  switch (action.type) {
    case ACTION_GET_REQUESTS_LIST:
      return {
        ...state,
        pageParams: action.payload,
      }
    case ACTION_GET_REQUESTS_LIST_SUCCESS:
      return {
        ...state,
        requestList: action.payload.data,
        totalRecords: action.payload.totalRecords,
        activePage: action.payload.activePage,
      }
    case ACTION_GET_REQUESTS_LIST_FAILURE:
      return {
        ...state,
        requestList: [],
        totalRecords: 0,
        activePage: 1,
        selectedRecords: {},
        isSelectAll: {},
      }

    case ACTION_CLOSE_REQUEST:
    case ACTION_CLOSE_REQUEST_FAILURE:
      return {
        ...state,
        updateRequest: {
          success: false,
          message: '',
        },
      }

    case ACTION_CLOSE_REQUEST_SUCCESS:
      return {
        ...state,
        updateRequest: {
          success: true,
          message: action.payload.data,
        },
        selectedRecords: {},
        isSelectAll: {},
      }

    case ACTION_EXPORT_REQUEST:
    case ACTION_EXPORT_REQUEST_FAILURE:
    case ACTION_EXPORT_REQUEST_CLEAR:
      return {
        ...state,
        exportRequest: {
          success: false,
          message: '',
        },
        selectedRecords: {},
        isSelectAll: {},
      }

    case ACTION_EXPORT_REQUEST_SUCCESS:
      return {
        ...state,
        exportRequest: {
          success: true,
          message: action.payload.data,
        },
        selectedRecords: {},
        isSelectAll: {},
      }

    case ACTION_REPORT_UPLOAD_START:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          success: false,
          message: null,
          error: false,
        },
      }
    case ACTION_REPORT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          success: true,
          error: false,
          inProgress: true,
        },
      }
    case ACTION_REPORT_UPLOAD_FAILURE:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          success: false,
          message: action.payload.errorMessage,
          error: action.payload.error,
        },
      }
    case ACTION_REPORT_UPLOAD_CLEAR:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          success: false,
          error: false,
        },
      }

    case ACTION_UPDATE_DSAR_REPORTS:
    case ACTION_UPDATE_DSAR_REPORTS_FAILURE:
      return { ...state, updateTenantDsarReports: { success: false } }
    case ACTION_UPDATE_DSAR_REPORTS_SUCCESS:
      return { ...state, updateTenantDsarReports: { success: true } }

    case ACTION_GET_DSAR_REPORTS:
    case ACTION_GET_DSAR_REPORTS_FAILURE:
      return {
        ...state,
        getTenantDsarReports: { success: false, data: [] },
        updateTenantDsarReports: { success: false },
      }
    case ACTION_GET_DSAR_REPORTS_SUCCESS:
      return { ...state, getTenantDsarReports: { success: true, data: action.payload } }

      
    case ACTION_TOGGLE_ROW: {
      const recordId = action.recordId
      const pageAndSelectedMap = Object.assign({}, state.selectedRecords)
      const existingSelectRecordsForCurrentPage = pageAndSelectedMap[state.activePage] || {}
      existingSelectRecordsForCurrentPage[recordId] = !existingSelectRecordsForCurrentPage[recordId]
      pageAndSelectedMap[state.activePage] = existingSelectRecordsForCurrentPage

      let selectedCheckboxCount = 0

      Object.keys(pageAndSelectedMap).forEach(pageNo => {
        const selectedRecordOnCurrentPage = pageAndSelectedMap[pageNo]
        Object.keys(selectedRecordOnCurrentPage).forEach(key => {
          if (existingSelectRecordsForCurrentPage[key]) {
            selectedCheckboxCount++
          }
        })
      })

      const newSelectAll = Object.assign({}, state.isSelectAll)
      newSelectAll[state.activePage] = selectedCheckboxCount === state.requestList.length ? 1 : 2

      return {
        ...state,
        selectedRecords: pageAndSelectedMap,
        isSelectAll: newSelectAll,
      }
    }

    case ACTION_TOGGLE_SELECT_ALL: {
      const newSelectAll = Object.assign({}, state.isSelectAll)
      const newSelected = Object.assign({}, state.selectedRecords)
      newSelected[state.activePage] = {}

      const selectRecordsForCurrentPage = newSelected[state.activePage]
      if (!newSelectAll[state.activePage] || newSelectAll[state.activePage] == 0) {
        state.requestList.forEach(items => {
          selectRecordsForCurrentPage[items['request_id']] = true
        })
      }

      newSelectAll[state.activePage] = !newSelectAll[state.activePage] || newSelectAll[state.activePage] == 0 ? 1 : 0

      return {
        ...state,
        selectedRecords: newSelected,
        isSelectAll: newSelectAll,
      }
    }

    default:
      return state
  }
}
