import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { BrandingModel } from '../Models'
import { ApiResponse } from 'Modules/Common/Models'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/tenantBranding.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_TENANT_BRANDING_START = 'ACTION_GET_TENANT_BRANDING_START'
export const ACTION_GET_TENANT_BRANDING_SUCCESS = 'ACTION_GET_TENANT_BRANDING_SUCCESS'
export const ACTION_GET_TENANT_BRANDING_FAILURE = 'ACTION_GET_TENANT_BRANDING_FAILURE'
export const ACTION_GET_TENANT_BRANDING_CLEAR = 'ACTION_GET_TENANT_BRANDING_CLEAR'

export interface ActionGetTenantBrandingStart extends Action {
  type: typeof ACTION_GET_TENANT_BRANDING_START
  payload: {}
}

export interface ActionGetTenantBrandingSuccess extends Action {
  type: typeof ACTION_GET_TENANT_BRANDING_SUCCESS
  payload: { data: ApiResponse<BrandingModel> }
}

export interface ActionGetTenantBrandingFailure extends Action {
  type: typeof ACTION_GET_TENANT_BRANDING_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionGetTenantBrandingClear extends Action {
  type: typeof ACTION_GET_TENANT_BRANDING_CLEAR
  payload: {}
}

export type GetTenantBrandingActions =
  | ActionGetTenantBrandingStart
  | ActionGetTenantBrandingSuccess
  | ActionGetTenantBrandingFailure
  | ActionGetTenantBrandingClear

const dispatchGetTenantBrandingSuccess = (data: ApiResponse<BrandingModel>): ActionGetTenantBrandingSuccess => ({
  type: ACTION_GET_TENANT_BRANDING_SUCCESS,
  payload: { data },
})

const dispatchGetTenantBrandingFailure = (err: Error, success: boolean): ActionGetTenantBrandingFailure => ({
  type: ACTION_GET_TENANT_BRANDING_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

export const dispatchGetTenantBrandingClear = (): ActionGetTenantBrandingClear => ({
  type: ACTION_GET_TENANT_BRANDING_CLEAR,
  payload: {},
})

export function actionGetTenantBranding(): (dispatch: Dispatch<GetTenantBrandingActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTenantBrandingActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.getTenantBranding()
      if (result.statusCode === ResponseCodes.Success) {
        dispatch(dispatchGetTenantBrandingSuccess(result))
      } else {
        toaster.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(dispatchGetTenantBrandingFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTenantBrandingFailure(err, false))
    }
  }
}
