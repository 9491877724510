import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/tenant-list.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { PageParams } from 'Modules/Common/Models'

export const ACTION_TENANTS_CLEAR = 'ACTION_TENANTS_CLEAR'
export const ACTION_GET_TENANTS = 'ACTION_GET_TENANTS'
export const ACTION_GET_TENANTS_SUCCESS = 'ACTION_GET_TENANTS_SUCCESS'
export const ACTION_GET_TENANTS_FAILURE = 'ACTION_GET_TENANTS_FAILURE'
export const ACTION_REFRESH_TENANT_LIST = 'ACTION_REFRESH_TENANT_LIST'

export interface ActionGetTenants extends Action {
  type: typeof ACTION_GET_TENANTS
  payload: PageParams
}

export interface ActionRefreshTenantList extends Action {
  type: typeof ACTION_REFRESH_TENANT_LIST
}

export interface ActionTenantsClear extends Action {
  type: typeof ACTION_TENANTS_CLEAR
}

export interface ActionGetTenantsSuccess extends Action {
  type: typeof ACTION_GET_TENANTS_SUCCESS
  payload: {
    data: Array<any>
    totalRecords: number
    activePage: number
    extendedFreeTrialDays?: number
  }
}

export interface ActionGetTenantsFailure extends Action {
  type: typeof ACTION_GET_TENANTS_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetTenantsActions =
  | ActionGetTenants
  | ActionGetTenantsFailure
  | ActionGetTenantsSuccess
  | ActionTenantsClear
  | ActionRefreshTenantList

export const dispatchGetTenants = (pageParams: PageParams): ActionGetTenants => ({
  type: ACTION_GET_TENANTS,
  payload: pageParams,
})

export const dispatchRefreshTenantList = (): ActionRefreshTenantList => ({
  type: ACTION_REFRESH_TENANT_LIST,
})

const dispatchTenantsClear = (): ActionTenantsClear => ({
  type: ACTION_TENANTS_CLEAR,
})

const dispatchGetTenantsSuccess = (
  data: Array<any>,
  totalRecords: number,
  activePage: number,
  extendedFreeTrialDays: number
): ActionGetTenantsSuccess => ({
  type: ACTION_GET_TENANTS_SUCCESS,
  payload: {
    data,
    totalRecords,
    activePage,
    extendedFreeTrialDays,
  },
})

const dispatchGetTenantsFailure = (error: Error): ActionGetTenantsFailure => ({
  type: ACTION_GET_TENANTS_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetTenants(
  req: PageParams,
  additionalParams: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetTenantsActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTenantsActions>) => {
    loaderStart(dispatch, isLoader)
    dispatch(dispatchTenantsClear())
    try {
      const result = await service.getTenants(req, additionalParams)
      if (result && result.success) {
        dispatch(
          dispatchGetTenantsSuccess(result.data, result.totalRecords, result.activePage, result.extendedFreeTrialDays)
        )
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetTenantsFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTenantsFailure(error))
      loaderStop(dispatch)
    }
  }
}
