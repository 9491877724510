import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/getUniqueBannerViews.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { BannerResponseModel } from '../Models/ConsentDashboard.model'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_UNIQUE_BANNERVIEWS_DATA = 'ACTION_GET_UNIQUE_BANNERVIEWS_DATA'
export const ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS = 'ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS'
export const ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE = 'ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE'

export interface ActionGetBannerViewsData extends Action {
  type: typeof ACTION_GET_UNIQUE_BANNERVIEWS_DATA
  payload: {
    startDate: number
    endDate: number
    viewBy: string
  }
}

export interface ActionGetBannerViewsDataSuccess extends Action {
  type: typeof ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS
  payload: BannerResponseModel
}

export interface ActionGetBannerViewsDataFailure extends Action {
  type: typeof ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetUniqueBannerViewsActions =
  | ActionGetBannerViewsData
  | ActionGetBannerViewsDataSuccess
  | ActionGetBannerViewsDataFailure

export const dispatchGetOpenRequestsData = (
  startDate: number,
  endDate: number,
  viewBy: string
): ActionGetBannerViewsData => ({
  type: ACTION_GET_UNIQUE_BANNERVIEWS_DATA,
  payload: { startDate: startDate, endDate: endDate, viewBy },
})

const dispatchGetOpenRequestsDataSuccess = (data: BannerResponseModel): ActionGetBannerViewsDataSuccess => ({
  type: ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS,
  payload: data,
})

const dispatchGetOpenRequestsDataFailure = (error: Error, code?: number): ActionGetBannerViewsDataFailure => ({
  type: ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetOpenRequestsData(
  startDate: number,
  endDate: number,
  selectTenant: any,
  selectDomain: any,
  selectSubDomain: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetUniqueBannerViewsActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetUniqueBannerViewsActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getOpenRequestData(startDate, endDate, selectTenant, selectDomain, selectSubDomain)
      if (result && result.success) {
        dispatch(dispatchGetOpenRequestsDataSuccess(result.data))
      } else {
        if (result?.code !== ResponseCodes.Unauthorized) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(
          dispatchGetOpenRequestsDataFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetOpenRequestsDataFailure(error))
      loaderStop(dispatch)
    }
  }
}
