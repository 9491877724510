import { consentManagement } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse, BasicDropdownModel, PageParams } from 'Modules/Common/Models'

export interface GetCookiesListResponse {
  success: boolean
  code: number
  message: string
  data?: any
  totalRecords?: number
  activePage?: number
  domainList?: Array<BasicDropdownModel>
  subDomainList?: Array<BasicDropdownModel>
  categoryList?: Array<BasicDropdownModel>
}

const mapFn = e => ({
  label: e,
  value: e,
})

export interface GetCookiesListService {
  getCookiesList(request: PageParams, additionalParams: any): Promise<GetCookiesListResponse>
  getCookiesTenantsList(): Promise<ApiResponse<Array<BasicDropdownModel>>>
}

const buildParams = (p: PageParams, additionalParams: any) => {
  const params: any = {
    ...additionalParams,
  }

  if (!p) {
    return params
  }

  params['page_no'] = p?.page_no
  params['page_size'] = p?.page_size
  if (p.search && p.search != '') {
    params['search'] = p.search
  }
  if (p.sort_column && p.sort_column != '') {
    params['sort_column'] = p.sort_column
  }
  if (p.sort_direction && p.sort_direction != '') {
    params['sort_direction'] = p.sort_direction
  }
  return params
}

class GetCookiesListServiceDefault implements GetCookiesListService {
  async getCookiesList(request: PageParams, additionalParams: any): Promise<GetCookiesListResponse> {
    let result: GetCookiesListResponse

    try {
      const { data } = await http.request({
        method: 'get',
        url: consentManagement.cookies,
        params: buildParams(request, additionalParams),
      })

      result = GetCookiesListServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async getCookiesTenantsList(): Promise<ApiResponse<Array<BasicDropdownModel>>> {
    let result: ApiResponse<Array<BasicDropdownModel>>
    try {
      const { data } = await http.get(consentManagement.cookiesGetTenants)
      let tenants = []
      if (data?.data?.length > 0) {
        tenants = data?.data?.map(e => ({
          label: e?.tenant_name,
          value: e?.tenant_id,
        }))
      }
      result = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: tenants,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): GetCookiesListResponse {
    if (data?.code === 4004) {
      return {
        success: false,
        code: data.code,
        message: data.message,
      }
    }

    return {
      success: true,
      code: data?.code,
      message: data?.message || '',
      data: data?.data,
      totalRecords: data?.total_records,
      activePage: parseInt(data?.page),
      domainList: data?.domain_list?.map(mapFn),
      subDomainList: data?.sub_domain_list?.map(mapFn),
      categoryList: data?.category_list?.map(mapFn),
    }
  }
}

export default new GetCookiesListServiceDefault()
