import React, { useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'querystring'
import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { AppStateType } from 'Store/reducers'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { actionVerifyImpersonateToken } from '../Actions/verify-impersonate-token.actions'

const Impersonate = () => {
  const { isAuthenticated, isVerified } = useSelector((state: AppStateType) => state.AuthReducer)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const { token, exp } = queryString.parse(location?.search?.substr(1))
    if (token) {
      SessionStorage.setKey(SessionStorageKeyNames.TempToken, token as string)
    }
    if (exp) {
      SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, exp as string)
    }

    dispatch(actionVerifyImpersonateToken(token as string))
  }, [location?.search, history])

  if (isAuthenticated && isVerified) {
    return <Redirect to="/" />
  }

  return <div></div>
}

export default Impersonate
