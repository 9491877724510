import React from 'react'
import styles from './SelectDomains.module.css'
import { cx } from '@emotion/css'
import SelectDropdown from './SelectDropdown/SelectDropdown'

const MyOption = (props: any) => {
  return (
    <div className="custom-option">
      <div
        ref={props.innerRef}
        {...props.innerProps}
        className={cx(styles.option, props.isSelected ? styles.selected : null)}
        disabled={props.data.bannerStatus === 'inactive'}
      >
        <span className={cx(props.data.isSubdomain ? 'ml-2' : null)}> {props.data.label}</span>
      </div>
    </div>
  )
}

interface SubDomains {
  label: string
  value: string
}

interface Domain {
  label: string
  value: string
  subDomains?: SubDomains[]
  bannerStatus?: string
  privacyCenterUrl?: string
}

interface SelectedDomainsProp {
  data: Domain[]
  value: any
  onChange: any
}
export default function SelectDomains({ data, value, onChange }: SelectedDomainsProp) {
  const getOptions = () => {
    let options = []
    data?.map((domain: Domain) => {
      options?.push({
        label: domain.label,
        value: domain.value,
        privacyCenterUrl: domain?.privacyCenterUrl,
      })
      domain?.subDomains?.map(subDomain => {
        options?.push({
          label: `- ${subDomain.label}`,
          value: subDomain.value,
          isSubdomain: true,
          domain: domain.value,
          privacyCenterUrl: domain?.privacyCenterUrl,
        })
        return null
      })
      return null
    })
    return options
  }

  return (
    <SelectDropdown
      id="domain-dropdown"
      x-automation-id="domain-dropdown"
      components={{ Option: MyOption }}
      classNamePrefix="react-select"
      className={`p-0`}
      isClearable={true}
      options={getOptions()}
      onChange={onChange}
      value={value}
      isDisabled={data[0]?.bannerStatus === 'inactive'}
    />
  )
}
