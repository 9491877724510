export const settings = {
  publishChanges: 'Are you sure to publish your changes for Tenant Admins on Main Console?',
  branding: 'Branding',
  emailTemplates: 'Email Templates',
  settings: 'Settings',
  inviteTenant: 'Invite Tenant',
  customizeEmailTemplate: 'Customize Email Template',
  from: 'From',
  fromRequired: 'Please enter from email',
  subject: 'Subject',
  subjectRequired: 'Please enter email subject',
  bodyRequired: 'Please enter email body',
  toast: {
    inviteTenantEmailTemplateUpdated: 'Invite Tenant email template updated',
  },
}
