import {
  ACTION_GET_DSAR_DASHBOARD_FAILURE,
  ACTION_GET_DSAR_DASHBOARD_START,
  ACTION_GET_DSAR_DASHBOARD_SUCCESS,
  GetDSARDashboardActions,
} from '../Actions/ConsentDashboard.actions'

import {
  ACTION_GET_TREND_CHART_DATA,
  ACTION_GET_TREND_CHART_DATA_FAILURE,
  ACTION_GET_TREND_CHART_DATA_SUCCESS,
  GetTrendChartDataActions,
} from '../Actions/getTrendChartData.actions'

import {
  ACTION_SET_SELECTED_DATE_RANGE,
  ACTION_RESET_SELECTED_DATE_RANGE,
  DateRangeActions,
} from '../Actions/setSelectedDateRange.actions'

import {
  ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE,
  ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START,
  ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS,
  GetDSARByRequestSubjectActions,
} from '../Actions/getDSARByRequestSubject.actions'

import {
  ACTION_GET_REQUEST_BY_TYPE,
  ACTION_GET_REQUEST_BY_TYPE_FAILURE,
  ACTION_GET_REQUEST_BY_TYPE_SUCCESS,
  GetRequestByTypeActions,
} from '../Actions/getRequestByType.actions'

import {
  ACTION_GET_COOKIE_CONSENT_TYPE,
  ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE,
  ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS,
  GetCookieConsentActions,
} from '../Actions/getCookieConsentbyRatio.actions'

import {
  ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE,
  ACTION_GET_CONSENT_BY_GEOLOCATION_START,
  ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS,
  GetConsentByGeoLocation,
} from '../Actions/getConsentByGeoLocation.actions'

import {
  CountryMapModel,
  // OpenRequestModel,
  TrendChartModel,
  ViewByDropdownModel,
  RegulationDropdownModel,
  CookieByTypeModel,
  DomainsDropDownModel,
  BannerResponseModel,
  DSARSubjectModel,
  CookieConsentRationModel,
  CookieConsentByCategoryModel,
} from '../Models/ConsentDashboard.model'

import {
  ACTION_GET_UNIQUE_BANNERVIEWS_DATA,
  ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE,
  ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS,
  GetUniqueBannerViewsActions,
} from '../Actions/getUniqueBannerViews.actions'

import {
  ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START,
  ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS,
  ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE,
  GetConsentByCookieCategories,
} from '../Actions/getConsentByCookieCategories.actions'

import {
  ACTION_RESET_DASHBOARD_VALUES,
  ACTION_RESET_SELECTED_REGULATION,
  ACTION_SET_SELECTED_REGULATION,
  ActionResetDashboardValues,
  SetSelectedRegulationActions,
} from '../Actions/setSelectedDomains.actions'

import { CountryDataMapModel } from 'Modules/Common/Models'

export interface DSARDashboardReducerState {
  trendChartData: TrendChartModel
  selectedDateRange: ViewByDropdownModel
  selectedRegulation: RegulationDropdownModel
  selectUniquebanner: DomainsDropDownModel
  costSavingsData: {
    total: number
  }
  DSARbyLocation: CountryMapModel
  mapChart: CountryDataMapModel
  mapChartUSA: CountryMapModel
  cookieByType: CookieByTypeModel
  openRequestData: BannerResponseModel
  requestBySubject: DSARSubjectModel
  cookieConsentData: CookieConsentRationModel
  consentByCookieCategories: CookieConsentByCategoryModel
}

export const initialState: DSARDashboardReducerState = {
  trendChartData: null,
  selectedDateRange: null,
  selectedRegulation: null,
  costSavingsData: null,
  selectUniquebanner: null,
  mapChart: null,
  mapChartUSA: null,
  DSARbyLocation: null,
  openRequestData: null,
  cookieByType: null,
  requestBySubject: null,
  cookieConsentData: null,
  consentByCookieCategories: null,
}

type Actions =
  | GetDSARDashboardActions
  | GetTrendChartDataActions
  | DateRangeActions
  | GetUniqueBannerViewsActions
  | SetSelectedRegulationActions
  | GetDSARByRequestSubjectActions
  | GetRequestByTypeActions
  | GetCookieConsentActions
  | GetConsentByGeoLocation
  | GetConsentByCookieCategories
  | ActionResetDashboardValues

export const DSARDashboardReducer = (
  state = initialState, // NOSONAR
  action: Actions
): DSARDashboardReducerState => {
  switch (action.type) {
    case ACTION_GET_TREND_CHART_DATA:
    case ACTION_GET_UNIQUE_BANNERVIEWS_DATA:
    case ACTION_GET_UNIQUE_BANNERVIEWS_DATA_FAILURE:
    case ACTION_GET_TREND_CHART_DATA_FAILURE:
    case ACTION_GET_DSAR_DASHBOARD_START:
      return {
        ...state,
      }

    case ACTION_GET_TREND_CHART_DATA_SUCCESS:
      return {
        ...state,
        trendChartData: action.payload,
      }
    case ACTION_GET_UNIQUE_BANNERVIEWS_DATA_SUCCESS:
      return {
        ...state,
        openRequestData: action.payload,
      }
    case ACTION_SET_SELECTED_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.payload,
      }
    case ACTION_RESET_SELECTED_DATE_RANGE:
      return { ...state, selectedDateRange: null }

    case ACTION_SET_SELECTED_REGULATION:
      return {
        ...state,
        selectUniquebanner: action.payload,
      }
    case ACTION_RESET_SELECTED_REGULATION:
      return {
        ...state,
        selectUniquebanner: null,
      }

    case ACTION_GET_DSAR_BY_REQUEST_SUBJECT_FAILURE:
    case ACTION_GET_DSAR_BY_REQUEST_SUBJECT_START:
      return {
        ...state,
        requestBySubject: null,
      }
    case ACTION_GET_DSAR_BY_REQUEST_SUBJECT_SUCCESS:
      return {
        ...state,
        requestBySubject: action.payload,
      }
    case ACTION_GET_REQUEST_BY_TYPE_FAILURE:
    case ACTION_GET_REQUEST_BY_TYPE:
      return {
        ...state,
        cookieByType: null,
      }
    case ACTION_GET_REQUEST_BY_TYPE_SUCCESS:
      return {
        ...state,
        cookieByType: action.payload,
      }
    case ACTION_GET_COOKIE_CONSENT_TYPE:
    case ACTION_GET_COOKIE_CONSENT_TYPE_FAILURE:
      return {
        ...state,
        cookieConsentData: null,
      }
    case ACTION_GET_COOKIE_CONSENT_TYPE_SUCCESS:
      return {
        ...state,
        cookieConsentData: action.payload,
      }
    case ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE:
    case ACTION_GET_CONSENT_BY_GEOLOCATION_START:
      return {
        ...state,
        mapChart: null,
      }
    case ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS:
      return {
        ...state,
        mapChart: action.payload,
      }
    case ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE:
    case ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START:
      return {
        ...state,
        consentByCookieCategories: null,
      }
    case ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS:
      return {
        ...state,
        consentByCookieCategories: action.payload,
      }

    case ACTION_RESET_DASHBOARD_VALUES:
      return {
        ...state,
        cookieConsentData: null,
        trendChartData: null,
        consentByCookieCategories: null,
        mapChart: null,
        cookieByType: null,
        selectUniquebanner: null,
      }

    default:
      return state
  }
}
