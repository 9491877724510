import React, { useEffect, useState } from 'react'
import ConsentLogsHeader from './ConsentLogsHeader'
import { ConsentLogsType, ConsentLogTab, ConsentStatus } from './Models/consentLogs.model'
import WebsiteList from './WebsiteList'
import styles from './ConsentLogs.module.css'
import { actionGetCookiesTenantList } from 'Modules/Cookies/Actions/cookies-list.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'
import { actionGetDomains } from 'Modules/Banners/Actions/domains.actions'
import PrivacyCenterConsentList from './PrivacyCenterConsentList'

const ConsentLogs = () => {
  const dispatch = useDispatch()
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [activeFilters, setActiveFilters] = useState<ConsentLogsType>({
    consent_category: ConsentLogTab.Website,
    selectedConsentStatus: null,
    domain_id: null,
  })

  const userData = useSelector((state: AppStateType) => state?.AuthReducer?.userData)

  useEffect(() => {
    if (userData) {
      const { roles_and_permissions, tenantId, tenantName } = userData
      if (roles_and_permissions?.length > 0 && roles_and_permissions[0]?.role_name === 'tenant_admin') {
        setSelectedTenant({
          label: tenantName,
          value: tenantId,
        })
      }
    }
  }, [userData])

  useEffect(() => {
    if (selectedTenant) {
      dispatch(actionGetDomains(selectedTenant?.value))
    }
  }, [selectedTenant])

  return (
    <div className={styles.conentLogsContainer}>
      <div className="row m-0 p-0 w-100 pt-2">
        <ConsentLogsHeader activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
      </div>
      <div className="row m-0 p-0 w-100" style={{ height: 'calc(100% - 155px)' }}>
        {activeFilters?.consent_category === ConsentLogTab.PrivacyCenter ? (
          <PrivacyCenterConsentList activeFilters={activeFilters} />
        ) : (
          <WebsiteList activeFilters={activeFilters} />
        )}
      </div>
    </div>
  )
}

export default ConsentLogs
