import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/domains.service'
import { BasicDropdownModel } from 'Modules/Common/Models'

export const ACTION_GET_DOMAINS_SUCCESS = 'ACTION_GET_DOMAINS_SUCCESS'
export const ACTION_GET_DOMAINS_FAILURE = 'ACTION_GET_DOMAINS_FAILURE'
export const ACTION_GET_DOMAINS_CLEAR = 'ACTION_GET_DOMAINS_CLEAR'

export interface ActionGetDomainsSuccess extends Action {
  type: typeof ACTION_GET_DOMAINS_SUCCESS
  payload: { data: Array<BasicDropdownModel> }
}

export interface ActionGetDomainsFailure extends Action {
  type: typeof ACTION_GET_DOMAINS_FAILURE
  payload: { success: boolean; errorMessage: string }
}

export interface ActionGetDomainsClear extends Action {
  type: typeof ACTION_GET_DOMAINS_CLEAR
  payload: {}
}

export type DomainsActions = ActionGetDomainsSuccess | ActionGetDomainsFailure | ActionGetDomainsClear

const dispatchEditUsersSuccess = (data: Array<BasicDropdownModel>): ActionGetDomainsSuccess => ({
  type: ACTION_GET_DOMAINS_SUCCESS,
  payload: { data },
})

const dispatchEditUsersFailure = (err: Error, success: boolean): ActionGetDomainsFailure => ({
  type: ACTION_GET_DOMAINS_FAILURE,
  payload: { errorMessage: err.message, success },
})

export const dispatchEditDomainsClear = (): ActionGetDomainsClear => ({
  type: ACTION_GET_DOMAINS_CLEAR,
  payload: {},
})

export function actionGetDomains(tenant_id: string): (dispatch: Dispatch<DomainsActions>) => Promise<void> {
  return async (dispatch: Dispatch<DomainsActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.getDomains(tenant_id)
      if (result.success) {
        dispatch(dispatchEditUsersSuccess(result.data))
      } else {
        toaster.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(dispatchEditUsersFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err: any) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchEditUsersFailure(err, false))
    }
  }
}
