import { Dispatch } from 'react'
import { Action } from 'redux'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import service from '../Services/forget.password'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { StatusCodes } from 'Modules/Common/Enums'
import { ApiResponse } from 'Modules/Common/Models'

export const ACTION_FORGOT_PASSWORD = 'ACTION_FORGOT_PASSWORD'
export const ACTION_FORGOT_PASSWORD_SUCCESS = 'ACTION_FORGOT_PASSWORD_SUCCESS'
export const ACTION_FORGOT_PASSWORD_FAILED = 'ACTION_FORGOT_PASSWORD_FAILED'

export interface ActionForgotPassword extends Action {
  type: typeof ACTION_FORGOT_PASSWORD
}

export interface ActionForgotPasswordSuccess extends Action {
  type: typeof ACTION_FORGOT_PASSWORD_SUCCESS
  payload: {
    data: ApiResponse<string>
  }
}

export interface ActionForgotPasswordFailed extends Action {
  type: typeof ACTION_FORGOT_PASSWORD_FAILED
  payload: {
    data: ApiResponse<string>
  }
}

export type ForgotPasswordActions = ActionForgotPassword | ActionForgotPasswordFailed | ActionForgotPasswordSuccess

export const dispatchForgotPassword = (): ActionForgotPassword => ({
  type: ACTION_FORGOT_PASSWORD,
})

const dispatchForgotPasswordSuccess = (data: ApiResponse<string>): ActionForgotPasswordSuccess => ({
  type: ACTION_FORGOT_PASSWORD_SUCCESS,
  payload: { data },
})

const dispatchForgotPasswordFailed = (data: ApiResponse<string>): ActionForgotPasswordFailed => ({
  type: ACTION_FORGOT_PASSWORD_FAILED,
  payload: { data },
})

const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.NotFound:
      toastService.error(I18n.t('auth_toast.link_expired'))
      break
    case ResponseCodes.AlreadyExist:
      toastService.error(I18n.t('forgetPass.emaildidNotFound'))
      break
    case ResponseCodes.AddressNotFound:
      toastService.error(I18n.t('forgetPass.addressnotfound'))
      break
    case StatusCodes.TooManyRequests:
      toastService.error(I18n.t('auth_toast.toomanycall'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionForgotPasswordInfo(email: string): (dispatch: Dispatch<ForgotPasswordActions>) => Promise<void> {
  return async (dispatch: Dispatch<ForgotPasswordActions>) => {
    dispatch(dispatchForgotPassword())
    loaderStart(dispatch)
    try {
      const result = await service.sendForgotPasswordLink(email)
      if (result && result.success) {
        dispatch(dispatchForgotPasswordSuccess(result))
      } else {
        toaster(result?.statusCode)
        dispatch(dispatchForgotPasswordFailed(result))
      }
      loaderStop(dispatch)
    } catch (error) {
      let result: ApiResponse<string> = {
        message: error,
        success: false,
      }
      dispatch(dispatchForgotPasswordFailed(result))
      loaderStop(dispatch)
    }
  }
}
