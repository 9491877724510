import { http } from '../../../Utils/AxiosWrapper'
import { consentDashboard } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { DSARSubjectModel } from '../Models/ConsentDashboard.model'
import { RegulationTypes } from 'Modules/Common/Enums/Common'

interface DSARRequestBySubjectService {
  getRequestByChart(
    startDate: number,
    endDate: number,
    selectedRegulation: string
  ): Promise<ApiResponse<DSARSubjectModel>>
}

class DSARRequestBySubjectServiceDefault implements DSARRequestBySubjectService {
  async getRequestByChart(
    startDate: number,
    endDate: number,
    selectedRegulation: string
  ): Promise<ApiResponse<DSARSubjectModel>> {
    let response: ApiResponse<DSARSubjectModel>
    try {
      const params: any = {
        start_date: startDate,
        end_date: endDate,
      }
      if (selectedRegulation && selectedRegulation !== RegulationTypes.All) {
        params.regulation = selectedRegulation
      }
      const { data } = await http.get(consentDashboard.dsarRequestbyChart, {
        params,
      })
      let resData: DSARSubjectModel = {
        customer: data?.data?.dsar_by_subject?.customer,
        employee: data?.data?.dsar_by_subject?.employee,
        total: data?.data?.dsar_by_subject?.customer + data?.data?.dsar_by_subject?.employee,
        percentage: data?.data?.percentage_count,
      }
      response = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: resData,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new DSARRequestBySubjectServiceDefault()
