import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { LogoutStatus } from 'Modules/Common/Enums/Common'
import LogOutAllTabs from 'Utils/LogOutAllTabs'
import { BroadcastChannelMessages, SessionStorageKeyNames, StatusCodes } from '../../Modules/Common/Enums'
import { SessionStorage } from '../SessionStorageWrapper'

class Http {
  private instance: AxiosInstance | null = null

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp()
  }

  // We can use the following function to inject the JWT token through an interceptor
  // We get the `accessToken` from the SessionStorage that we set when we authenticate
  private injectToken(config: AxiosRequestConfig): AxiosRequestConfig {
    try {
      const token = SessionStorage.get(SessionStorageKeyNames.AccessToken)
      const tempToken = SessionStorage.get(SessionStorageKeyNames.TempToken)
      if (
        tempToken &&
        config &&
        config.url &&
        (config.url.includes('/users/mfa/verify') || config.url.includes('/serviceprovider/impersonate_token'))
      ) {
        config.headers.Authorization = `Bearer ${tempToken}`
      }

      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    } catch (error: any) {
      throw new Error(error)
    }
  }
  initHttp() {
    const httpAxios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
    })

    httpAxios.interceptors.request.use(this.injectToken, error => Promise.reject(error))

    httpAxios.interceptors.response.use(
      response => response,
      (error: any) => {
        if (error?.response?.status === StatusCodes.Unauthorized) {
          return this.handleUnAuthorizesError(error)
        } else {
          return this.handleError(error)
        }
      }
    )

    this.instance = httpAxios
    return httpAxios
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config)
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config)
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    const { status } = error.response

    switch (status) {
      case StatusCodes.InternalServerError: {
        // Handle InternalServerError
        break
      }
      case StatusCodes.Forbidden: {
        // Handle Forbidden
        break
      }
      case StatusCodes.TooManyRequests: {
        // Handle TooManyRequests
        break
      }
    }

    return Promise.reject(error)
  }

  private handleUnAuthorizesError(err: any) {
    SessionStorage.removeKey(SessionStorageKeyNames.UserData)
    SessionStorage.removeKey(SessionStorageKeyNames.TempToken)
    SessionStorage.removeKey(SessionStorageKeyNames.TempTokenExpiry)
    LogOutAllTabs.postMessage(BroadcastChannelMessages.Logout)
    window.location.href = window.location.origin + `/login?status=${LogoutStatus.status}`
    return new Promise((resolve, reject) => {})
  }
}

export const http = new Http()
