import { copyIcon } from 'Assets/Images'
import { useState } from 'react'
import { CheckCircle } from 'react-feather'
import { CancelButtonWithIcon } from './Button'

const handleCopy = (text: string) => {
  document.execCommand('copy')
  let textAreaRef = document.createElement('textarea')
  document.body.appendChild(textAreaRef)
  textAreaRef.value = text
  textAreaRef.select()
  document.execCommand('copy')
  document.body.removeChild(textAreaRef)
}

const CopyIcon = ({ data, copyText }) => {
  const [text, setText] = useState(copyText)

  const copied = () => {
    setText('Copied!')
    setTimeout(() => {
      setText(copyText)
    }, 1500)
  }

  return (
    <CancelButtonWithIcon
      type="button"
      text={text}
      style={{ minWidth: '80px', color: '#2f00d9' }}
      icon={
        text === 'Copied!' ? (
          <CheckCircle color="#00b050" size={18} />
        ) : (
          <img src={copyIcon} width={16} className="ml-1" />
        )
      }
      onClick={e => {
        e.stopPropagation()
        copied()
        handleCopy(data)
      }}
    />
  )
}

export default CopyIcon
