import React, { useEffect, useRef } from 'react'
import { Table } from 'react-bootstrap'
import Scrollbars from 'react-custom-scrollbars'
import './bootstraptable.css'

interface BootstrapTableProps {
  trChild?: JSX.Element
  theadChild?: JSX.Element
  className?: string
  scrollToBottomTrChild?: boolean
  isScrollable?: boolean
  striped: boolean
  bordered: boolean
  hover: boolean
  responsive: boolean
}

function BootstrapTable(props: BootstrapTableProps) {
  const {
    isScrollable,
    scrollToBottomTrChild,
    theadChild,
    trChild,
    striped,
    bordered,
    hover,
    responsive,
    className,
  } = props

  const ref = useRef(null)

  useEffect(() => {
    if (scrollToBottomTrChild) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [trChild])

  if (isScrollable) {
    return (
      <>
        <Table
          className={`${className ? className : ''} bootstarpTable mb-0`}
          striped={striped}
          bordered={bordered}
          hover={hover}
          responsive={responsive}
        >
          <thead>{theadChild}</thead>
        </Table>
        <Scrollbars
          autoHide={false}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={250}
        >
          <Table
            className={`${className ? className : ''} bootstarpTable mb-0`}
            striped={striped}
            bordered={bordered}
            hover={hover}
            responsive={responsive}
          >
            <tbody>
              {trChild}
              <tr ref={ref}></tr>
            </tbody>
          </Table>
        </Scrollbars>
      </>
    )
  }

  return (
    <Table
      className={`${className ? className : ''} bootstarpTable`}
      striped={striped}
      bordered={bordered}
      hover={hover}
      responsive={responsive}
    >
      <thead>{theadChild}</thead>
      <tbody>
        {trChild}
        <tr ref={ref}></tr>
      </tbody>
    </Table>
  )
}

export default BootstrapTable
