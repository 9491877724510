import { ErrorMessage } from '@hookform/error-message'
import { emailRegex } from 'Modules/Common/Constants'
import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import styles from './CustomizePrivacyCenter.module.css'
import cx from 'classnames'
import * as Icon from 'react-feather'

export default function CustomizePrivacyCenter({ register, errors, logoPath, setLogoPath, setSelectedImg, setValue }) {
  const [errMsg, setErrMsg] = useState<string>()

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const extention: string = event?.target?.files[0]?.type
    if (event?.target?.files?.length > 0) {
      if (extention === 'image/png' || extention === 'image/jpeg') {
        setLogoPath(URL.createObjectURL(event.target.files[0]))
        if (event.target.files[0].size > 4194304) {
          setErrMsg(I18n.t('tenant.logoSizeErr'))
        } else {
          setSelectedImg(event.target.files[0])
          setValue('logo_path', event.target.files[0], { shouldDirty: true })
          setErrMsg('')
        }
      } else {
        setErrMsg(I18n.t('tenant.incorrectFile'))
      }
    }
  }

  let companyLogoElem = (
    <>
      <div className="col-md-12 mt-2">
        <input
          type="file"
          name="file"
          id="file"
          className={styles.inputfile}
          onChange={onFileUpload}
          accept=".png, .jpg, .jpeg"
          x-automation-id="upload-logo"
        />
        <label className={cx('d-flex align-items-center flex-column', styles.lblUploadindx)} htmlFor="file">
          <Icon.Upload color="#787878" width="30" height="30" />
          <span>{I18n.t('tenant.uploadLogo')}</span>
        </label>
      </div>
    </>
  )

  if (logoPath) {
    companyLogoElem = <img src={logoPath} className={styles.companyLogoImg} alt="Logo" width="contain" />
  }

  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')

  return (
    <div className={cx('d-flex', styles.mainContainer)}>
      <div className={cx(styles.logoBox)}>
        <div className="d-flex flex-column justify-content-center">
          <div className={cx(styles.customizeTitleSmalllogo, 'd-flex align-items-center mb-1')}>
            {I18n.t('tenant.logo')}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className={cx(styles.boxMargin)}>
            <div className={cx(styles.customizeUploadLogo, styles.customizeTitleSmall, styles.imgBox)}>
              <div className={cx('d-flex align-items-center justify-content-center', styles.customizeLogoLabel)}>
                {companyLogoElem}
              </div>
            </div>
            {errMsg && <span className={styles.extError}>{errMsg}</span>}
            {logoPath && (
              <>
                <div className="mt-2">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className={styles.inputfile}
                    onChange={onFileUpload}
                    accept=".png, .jpg, .jpeg"
                    x-automation-id="upload-logo-update"
                  />
                  <label
                    className={cx(
                      'brandColor cursor-pointer d-flex align-items-center flex-column',
                      styles.lblUploadindx
                    )}
                    htmlFor="file"
                  >
                    <span>{I18n.t('tenant.changeLogo')}</span>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className={cx(styles.customizeTitleSmalllogo, 'd-flex align-items-center mb-1 mt-3')}>
            {I18n.t('tenant.notes')}
          </div>
        </div>
        <ul>
          <li className={cx(styles.list)}>
            <div className={cx('d-flex justify-content-start', styles.recomendText)}>
              <span className={styles.bcLogo}> {I18n.t('tenant.bcLogo')}</span>
            </div>
          </li>
          <li className={cx(styles.list)}>
            <div className={cx('d-flex justify-content-start', styles.fileTypeText)}>
              <span>{I18n.t('tenant.allowedFileType')}</span>
              <span className={styles.fileExtension}> {I18n.t('tenant.fileTypes')}</span>
            </div>
          </li>
          <li className={cx(styles.list)}>
            <div className={cx('d-flex justify-content-start', styles.fileTypeText)}>
              <span> {I18n.t('tenant.maximumSize')}</span>
              <span className={styles.fileExtension}>{I18n.t('tenant.fileSize')}</span>
            </div>
          </li>
        </ul>
      </div>
      <div className={styles.verticalLine}></div>
      <Form id="pcBranding" key={1} noValidate className="w-100">
      <div className={cx(styles.normaltextinformation, 'mb-2 w-100')}>{I18n.t('tenant.privacyCenterDeploymentText')}
      </div>
        <Form.Group className="required-astric mt-2">
          <Form.Label className={cx(styles.customizeTitleSmalllogos, 'd-flex align-items-center mb-1')}>
            {I18n.t('tenant.pvcPolicy')}
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3" className="form-control-InputGroup-text">
              https://
            </InputGroup.Text>
            <Form.Control
              id="pvc-policy"
              x-automation-id="pvc-policy"
              placeholder={I18n.t('tenant.pvcPolicyExample')}
              {...register('privacy_policy', {
                required: I18n.t('tenant.pvcPolicyRequired'),
                pattern: {
                  value: regex,
                  message: I18n.t('tenant.pvcPolicyRequired'),
                },
              })}
              isInvalid={errors?.privacy_policy}
              className={`custom-form-control ${errors?.privacy_policy ? 'is-invalid' : ''}`}
            />
            <ErrorMessage className="invalid-feedback" name="privacy_policy" as="div" errors={errors} />
          </InputGroup>
        </Form.Group>
        <Form.Group className="required-astric">
          <Form.Label className={cx(styles.customizeTitleSmalllogos, 'd-flex align-items-center mb-1')}>
            {I18n.t('tenant.tmsUse')}
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3" className="form-control-InputGroup-text">
              https://
            </InputGroup.Text>
            <Form.Control
              id="tms-use"
              x-automation-id="tms-use"
              placeholder={I18n.t('tenant.tmsUseExample')}
              {...register('terms_of_use', {
                required: I18n.t('tenant.tmsUseRequired'),
                pattern: {
                  value: regex,
                  message: I18n.t('tenant.tmsUseRequired'),
                },
              })}
              isInvalid={errors?.terms_of_use}
              className={`custom-form-control ${errors?.terms_of_use ? 'is-invalid' : ''}`}
            />
            <ErrorMessage className="invalid-feedback" name="terms_of_use" as="div" errors={errors} />
          </InputGroup>
        </Form.Group>
        
        <Form.Group className="required-astric">
          <Form.Label className={cx(styles.customizeTitleSmalllogos, 'd-flex align-items-center mb-1')}>
            {I18n.t('tenant.supportContact')}
          </Form.Label>
          <Form.Control
            id="cnt-email"
            maxLength={45}
            placeholder={I18n.t('tenant.supportContactExample')}
            x-automation-id="cnt-email"
            type="email"
            {...register('support_email', {
              required: I18n.t('tenant.supportEmailRequired'),
              pattern: {
                value: emailRegex,
                message: I18n.t('tenant.supportEmailRequired'),
              },
            })}
            isInvalid={errors?.contact_email}
            className={`custom-form-control ${errors?.support_email ? 'is-invalid' : ''}`}
          />
          <ErrorMessage className="invalid-feedback" name="contact_email" as="div" errors={errors} />
        </Form.Group>
      </Form>
    </div>
  )
}
