import React from 'react'
import styled from 'styled-components'

const Div = styled.div<{ color: string; bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${(props: any) => props.color};
  background-color: ${(props: any) => props.bgColor};
  border-radius: 4px;
  padding: 1px 8px;
`

const Span = styled.span<{ color?: string }>`
  color: ${(props: any) => props.color || 'rgba(0, 0, 0, 0.7)'};
`

type Props = {
  theme: 'success' | 'error' | 'in_progress' | 'draft_purple' | 'orange' | 'grey' | ''
  text: string
  textColor?: string
  bgColor?: string
  value?: number
}

const StatusBox = (props: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const { theme, text, bgColor, textColor, value, ...rest } = props
  if (!text) {
    return null
  }

  // by default theme in_progress
  let newTextColor = textColor || '#C69400'
  let newBGColor = bgColor || 'rgba(214, 160, 0, 0.15)'

  if (!textColor && !bgColor) {
    if (theme === 'success') {
      newTextColor = '#28A745'
      newBGColor = 'rgba(40, 167, 69, 0.1)'
    }

    if (theme === 'error') {
      newTextColor = '#E9694D'
      newBGColor = 'rgba(220, 53, 69, 0.1)'
    }

    if (theme === 'draft_purple') {
      newTextColor = '#5043E2'
      newBGColor = 'rgba(111, 66, 193, 0.1)'
    }

    if (theme === 'orange') {
      newTextColor = '#f97200'
      newBGColor = 'rgba(249, 114, 0, 0.1)'
    }

    if (theme === 'grey') {
      newTextColor = '#797979'
      newBGColor = 'rgba(0, 0, 0, 0.1)'
    }
  }

  return (
    <Div color={newTextColor} bgColor={newBGColor} {...rest}>
      {text} {value && <span className='ml-1'>{value}</span>}
    </Div>
  )
}

export default React.memo(StatusBox)
