import { http } from '../../../Utils/AxiosWrapper'
import { ApiResponse } from 'Modules/Common/Models'
import { userManagement } from '../../Common/Constants/ApiUrls'
import { UserRole } from '../Models'

interface GetUserPermissions {
  getUserPermissions(): Promise<ApiResponse<Array<UserRole>>>
}

class GetUserPermissionsService implements GetUserPermissions {
  async getUserPermissions(): Promise<ApiResponse<Array<UserRole>>> {
    let response: ApiResponse<Array<UserRole>>
    try {
      const { data }: any = await http.get(userManagement.permissions)
      response = {
        success: true,
        message: data.message,
        statusCode: data.code,
        data: data.data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new GetUserPermissionsService()
