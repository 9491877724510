import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ReportUpload, BulkUploadResponseCodes } from '../Models/request.model'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import service from '../Services/request.service'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { request } from 'http'

export const ACTION_REPORT_UPLOAD_START = 'ACTION_REPORT_UPLOAD_START'
export const ACTION_REPORT_UPLOAD_SUCCESS = 'ACTION_REPORT_UPLOAD_SUCCESS'
export const ACTION_REPORT_UPLOAD_FAILURE = 'ACTION_REPORT_UPLOAD_FAILURE'
export const ACTION_REPORT_UPLOAD_CLEAR = 'ACTION_REPORT_UPLOAD_CLEAR'

export interface ActionReportUploadStart extends Action {
  type: typeof ACTION_REPORT_UPLOAD_START
  payload: {}
}

export interface ActionReportUploadSuccess extends Action {
  type: typeof ACTION_REPORT_UPLOAD_SUCCESS
  payload: {}
}

export interface ActionReportImportFailure extends Action {
  type: typeof ACTION_REPORT_UPLOAD_FAILURE
  payload: {
    success: boolean
    error: boolean
    errorMessage: string
  }
}

export interface ActionReportImportClear extends Action {
  type: typeof ACTION_REPORT_UPLOAD_CLEAR
  payload: {}
}

export type ReportUploadActions =
  | ActionReportUploadStart
  | ActionReportUploadSuccess
  | ActionReportImportFailure
  | ActionReportImportClear

const dispatchReportUploadStart = (): ActionReportUploadStart => ({
  type: ACTION_REPORT_UPLOAD_START,
  payload: {},
})

const dispatchReportUploadSuccess = (): ActionReportUploadSuccess => ({
  type: ACTION_REPORT_UPLOAD_SUCCESS,
  payload: {},
})

const dispatchReportUploadFailure = (err: string, error: boolean): ActionReportImportFailure => ({
  type: ACTION_REPORT_UPLOAD_FAILURE,
  payload: {
    errorMessage: err,
    error: true,
    success: false,
  },
})

export const dispatchBulkUploadClear = (): ActionReportImportClear => ({
  type: ACTION_REPORT_UPLOAD_CLEAR,
  payload: {},
})
const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.Success:
      toastService.success(I18n.t('requests.fileUploadSuccessMessage'))
      break
    case ResponseCodes.EmptyData:
      toastService.success(I18n.t('requests.fileremoveSuccessMessage'))
      break
    case BulkUploadResponseCodes.IncorrectExtension:
      toastService.error(I18n.t('requests.incorrectExtension'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

const responseCodes = [BulkUploadResponseCodes.IncorrectExtension]

export function actionReportUpload(
  data: any,
  tenantId: string,
  dsar_id: number,
  showLoader: boolean = true
): (dispatch: Dispatch<ReportUploadActions>) => Promise<void> {
  return async (dispatch: Dispatch<ReportUploadActions>) => {
    loaderStart(dispatch)
    dispatch(dispatchReportUploadStart())
    try {
      const result = await service.uploadReport(data, tenantId, dsar_id)
      if (result.statusCode === ResponseCodes.Success || result.statusCode === ResponseCodes.EmptyData) {
        dispatch(dispatchReportUploadSuccess())
      } else {
        dispatch(dispatchReportUploadFailure(result.message, responseCodes.includes(result?.statusCode)))
      }
      loaderStop(dispatch)
      toaster(result?.statusCode)
    } catch (err: any) {
      loaderStop(dispatch)
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchReportUploadFailure(err, false))
    }
  }
}
