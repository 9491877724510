import { consentManagement } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { ApiResponse, PageParams } from 'Modules/Common/Models'
import { handleDownloadFile } from 'Utils/downloadFileUtils'
import { AxiosResponse } from 'axios'
import { ConsentLogsExportPayload } from '../Models/consentLogs.model'

export interface ConsentLogsResponse {
  success: boolean
  code: number
  message: string
  data?: any
  totalRecords?: number
  activePage?: number
}

export interface IConsentLogs {
  getConsentLogs(request: PageParams, additionalParams: any): Promise<ConsentLogsResponse>
}

const buildParams = (p: PageParams, additionalParams: any) => {
  const params: any = {
    ...additionalParams,
  }
  params.page_no = p.page_no
  params.page_size = p.page_size
  if (p.search && p.search != '') {
    params.search = p.search
  }
  if (p.sort_column && p.sort_column != '') {
    params.sort_column = p.sort_column
  }
  if (p.sort_direction && p.sort_direction != '') {
    params.sort_direction = p.sort_direction
  }
  return params
}

class ConsentLogsService implements IConsentLogs {
  async getConsentLogs(request: PageParams, additionalParams: any): Promise<ConsentLogsResponse> {
    let result: ConsentLogsResponse
    try {
      const { data } = await http.request({
        method: 'get',
        url: consentManagement.getConsentLogs,
        params: buildParams(request, additionalParams),
      })
      result = ConsentLogsService.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ConsentLogsResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty data in response',
      }
    }

    if (data.code === 4004) {
      return {
        success: false,
        code: data.code,
        message: data.message,
      }
    }

    return {
      success: true,
      code: data.code,
      message: data.message || '',
      data: data.data,
      totalRecords: data.total_records,
      activePage: parseInt(data.page),
    }
  }

  async exportConsentLogs(payload: ConsentLogsExportPayload): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const response: AxiosResponse = await http.post(consentManagement.exportConsentLogs, payload, {
        responseType: 'blob',
      })
      result = handleDownloadFile(response)
    } catch (error: any) {
      const data = await error?.response?.data?.text()
      result = {
        success: false,
        message: JSON.parse(data)?.message,
        statusCode: JSON.parse(data)?.code,
      }
    }
    return result
  }

  
}

export default new ConsentLogsService()
