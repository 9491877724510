import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { loaderStart, loaderStop } from 'Modules/Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import service from '../Service/user.service'

export const ACTION_DELETE_USER = 'ACTION_DELETE_USER'
export const ACTION_DELETE_USER_SUCCESS = 'ACTION_DELETE_USER_SUCCESS'
export const ACTION_DELETE_USER_FAILURE = 'ACTION_DELETE_USER_FAILURE'
export const ACTION_DELETE_USER_SHOW_CONFIRM_MODEL = 'ACTION_DELETE_USER_SHOW_CONFIRM_MODEL'

export interface ActionDeleteUserShowConfirmModal extends Action {
  type: typeof ACTION_DELETE_USER_SHOW_CONFIRM_MODEL
}

export interface ActionDeleteUser extends Action {
  type: typeof ACTION_DELETE_USER
}

export interface ActionDeleteUserSuccess extends Action {
  type: typeof ACTION_DELETE_USER_SUCCESS
  payload: {}
}

export interface ActionDeleteUserFailure extends Action {
  type: typeof ACTION_DELETE_USER_FAILURE
  payload: { errorMessage: string }
}

export type DeleteUserActions =
  | ActionDeleteUser
  | ActionDeleteUserSuccess
  | ActionDeleteUserFailure
  | ActionDeleteUserShowConfirmModal

export const dispatchDeleteUser = (): ActionDeleteUser => ({
  type: ACTION_DELETE_USER,
})

const dispatchDeleteUserShowConfirmModal = (): ActionDeleteUserShowConfirmModal => ({
  type: ACTION_DELETE_USER_SHOW_CONFIRM_MODEL,
})

const dispatchDeleteUserSuccess = (): ActionDeleteUserSuccess => ({
  type: ACTION_DELETE_USER_SUCCESS,
  payload: {},
})

const dispatchDeleteUserFailure = (err: Error): ActionDeleteUserFailure => ({
  type: ACTION_DELETE_USER_FAILURE,
  payload: { errorMessage: err.message },
})

const toasterHandler = (code: number) => {
  switch (code) {
    case ResponseCodes.Success:
      toaster.success(I18n.t('user.addUser.userDeleted'))
      break
    case ResponseCodes.Conflicts:
      toaster.error(I18n.t('user.addUser.cannotDeleteUserDSAR'), true)
      break
    default:
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionDeleteUser(id: number, forced: boolean): (dispatch: Dispatch<DeleteUserActions>) => Promise<void> {
  return async (dispatch: Dispatch<DeleteUserActions>) => {
    loaderStart(dispatch)
    dispatch(dispatchDeleteUser())
    try {
      const result = await service.deleteUser(id, forced)
      if (result.success) {
        dispatch(dispatchDeleteUserSuccess())
        toasterHandler(result.statusCode)
      } else if (result.statusCode === ResponseCodes.PausedOrErrorState) {
        dispatch(dispatchDeleteUserShowConfirmModal())
      } else {
        dispatch(dispatchDeleteUserFailure(new Error(result.message)))
        toasterHandler(result.statusCode)
      }
      loaderStop(dispatch)
    } catch (err: any) {
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchDeleteUserFailure(err))
      loaderStop(dispatch)
    }
  }
}
