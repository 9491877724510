import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/tenant.service'
import toaster from '../../Common/Services/Toaster'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { BuyLicense } from '../Models/tenant.models'

export const ACTION_TENANT_BUY_LICENSE = 'ACTION_TENANT_BUY_LICENSE '
export const ACTION_TENANT_BUY_LICENSE_SUCCESS = 'ACTION_TENANT_BUY_LICENSE_SUCCESS'
export const ACTION_TENANT_BUY_LICENSE_FAILURE = 'ACTION_TENANT_BUY_LICENSE_FAILURE'

export interface ActionGetTenantBuyLicenses extends Action {
  type: typeof ACTION_TENANT_BUY_LICENSE
}

export interface ActionGetTenantBuyLicensesSuccess extends Action {
  type: typeof ACTION_TENANT_BUY_LICENSE_SUCCESS
  payload: {
    data: BuyLicense
  }
}

export interface ActionGetTenantBuyLicensesFailure extends Action {
  type: typeof ACTION_TENANT_BUY_LICENSE_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetTenantBuyLicensesActions =
  | ActionGetTenantBuyLicenses
  | ActionGetTenantBuyLicensesFailure
  | ActionGetTenantBuyLicensesSuccess

export const dispatchGetTenantBuyLicenses = (): ActionGetTenantBuyLicenses => ({
  type: ACTION_TENANT_BUY_LICENSE,
})

const dispatchGetTenantBuyLicensesSuccess = (data: BuyLicense): ActionGetTenantBuyLicensesSuccess => ({
  type: ACTION_TENANT_BUY_LICENSE_SUCCESS,
  payload: {
    data,
  },
})

const dispatchGetTenantBuyLicensesFailure = (error: Error): ActionGetTenantBuyLicensesFailure => ({
  type: ACTION_TENANT_BUY_LICENSE_FAILURE,
  payload: { errorMessage: error.message },
})

const toasterHandler = (code: number, name: string = '') => {
  switch (code) {
    case ResponseCodes.Success:
      toaster.success(I18n.t('tenant.addTenant.licenserequestsucess'))
      break
    default:
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionTenantBuyLicense(
  requestedNumberOfLicenses: number
): (dispatch: Dispatch<GetTenantBuyLicensesActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetTenantBuyLicensesActions>) => {
    loaderStart(dispatch)
    dispatch(dispatchGetTenantBuyLicenses())
    try {
      const result = await service.buyLicenses(requestedNumberOfLicenses)
      if (result && result.success) {
        dispatch(dispatchGetTenantBuyLicensesSuccess(result))
        toasterHandler(result.statusCode)
      } else {
        if (result?.statusCode !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetTenantBuyLicensesFailure(new Error(result?.message)))
        toasterHandler(result.statusCode)
      }
      loaderStop(dispatch)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetTenantBuyLicensesFailure(error))
      loaderStop(dispatch)
    }
  }
}
