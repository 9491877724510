import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/consentByGeoLocation.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { CountryDataMapModel } from 'Modules/Common/Models'

export const ACTION_GET_CONSENT_BY_GEOLOCATION_START = 'ACTION_GET_CONSENT_BY_GEOLOCATION_START'
export const ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS = 'ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS'
export const ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE = 'ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE'

export interface ActionGetConsentByGeoLocation extends Action {
  type: typeof ACTION_GET_CONSENT_BY_GEOLOCATION_START
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionGetConsentByGeoLocationSuccess extends Action {
  type: typeof ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS
  payload: CountryDataMapModel
}

export interface ActionGetConsentByGeoLocationFailure extends Action {
  type: typeof ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetConsentByGeoLocation =
  | ActionGetConsentByGeoLocation
  | ActionGetConsentByGeoLocationFailure
  | ActionGetConsentByGeoLocationSuccess

export const dispatchGetConsentByGeoLocation = (startDate: string, endDate: string): ActionGetConsentByGeoLocation => ({
  type: ACTION_GET_CONSENT_BY_GEOLOCATION_START,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetConsentByGeoLocationSuccess = (data: CountryDataMapModel): ActionGetConsentByGeoLocationSuccess => ({
  type: ACTION_GET_CONSENT_BY_GEOLOCATION_SUCCESS,
  payload: data,
})

const dispatchGetConsentByGeoLocationFailure = (error: Error): ActionGetConsentByGeoLocationFailure => ({
  type: ACTION_GET_CONSENT_BY_GEOLOCATION_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetConsentByGeoLocation(
  startDate: number,
  endDate: number,
  selectTenant: any,
  selectDomain: any,
  selectSubDomain: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetConsentByGeoLocation>) => Promise<void> {
  return async (dispatch: Dispatch<GetConsentByGeoLocation>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getConsentByGeoLocation(
        startDate,
        endDate,
        selectTenant,
        selectDomain,
        selectSubDomain
      )
      if (result && result.success) {
        dispatch(dispatchGetConsentByGeoLocationSuccess({ ...result.data }))
      } else {
        toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(
          dispatchGetConsentByGeoLocationFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong'))
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetConsentByGeoLocationFailure(error))
      loaderStop(dispatch)
    }
  }
}
