import { Dispatch } from 'react'
import { Action } from 'redux'
import { UserModel } from '../Models/user.model'
import service from '../Services/verify-code.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_VERIFY_CODE = 'ACTION_VERIFY_CODE'
export const ACTION_VERIFY_CODE_SUCCESS = 'ACTION_VERIFY_CODE_SUCCESS'
export const ACTION_VERIFY_CODE_FAILED = 'ACTION_VERIFY_CODE_FAILED'

export interface ActionVerifyCode extends Action {
  type: typeof ACTION_VERIFY_CODE
}

export interface ActionVerifyCodeSuccess extends Action {
  type: typeof ACTION_VERIFY_CODE_SUCCESS
  payload: {
    user?: UserModel
  }
}

export interface ActionVerifyCodeFailed extends Action {
  type: typeof ACTION_VERIFY_CODE_FAILED
  payload: {
    errorMessage: string
  }
}

export type VerifyCodeActions = ActionVerifyCode | ActionVerifyCodeFailed | ActionVerifyCodeSuccess

const dispatchVerifyCode = (): ActionVerifyCode => ({
  type: ACTION_VERIFY_CODE,
})

const dispatchVerifyCodeSuccess = (user?: UserModel): ActionVerifyCodeSuccess => ({
  type: ACTION_VERIFY_CODE_SUCCESS,
  payload: {
    user,
  },
})

const dispatchVerifyCodeFailed = (error: Error): ActionVerifyCodeFailed => ({
  type: ACTION_VERIFY_CODE_FAILED,
  payload: { errorMessage: error.message },
})
const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.InvalidParams:
      toastService.error(I18n.t('auth_toast.invalid_code'))
      break
    case ResponseCodes.ExpiredCode:
      toastService.error(I18n.t('auth_toast.code_expired'))
      break
    case ResponseCodes.NotFound:
      toastService.error(I18n.t('auth_toast.user_not_exists'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionVerifyCode(
  email: string,
  code: string
): (dispatch: Dispatch<VerifyCodeActions>) => Promise<void> {
  return async (dispatch: Dispatch<VerifyCodeActions>) => {
    dispatch(dispatchVerifyCode())
    loaderStart(dispatch)
    try {
      const result = await service.verifyCode({
        email,
        code,
      })
      if (result && result.success) {
        dispatch(dispatchVerifyCodeSuccess(result.user))
      } else {
        toaster(result?.code)
        dispatch(dispatchVerifyCodeFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchVerifyCodeFailed(error))
      loaderStop(dispatch)
    }
  }
}
