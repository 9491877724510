import ButtonWithIcon, { CancelButtonWithIcon } from 'Modules/Common/Components/Button'
import VerticallyCenteredModal from 'Modules/Common/Components/VerticallyCenteredModal'
import { X } from 'react-feather'
import styles from './Domains.module.css'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { actionRemoveDomain, actionRemoveSubDomain } from './Actions/domainList.actions'
import cx from 'classnames'
import { addPrefixToDomain } from './DomainsList'

const ConfirmDeleteModal = ({ show, setShow, title, message, isSubdomain }) => {
  const dispatch = useDispatch()

  return (
    <div>
      <VerticallyCenteredModal
        dialogClassName={'deleteModalContainer'}
        show={show.showBanner}
        onHide={() =>
          setShow({
            domainName: '',
            showBanner: false,
          })
        }
        heading={
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className={styles.cancelSubscriptionHeader}>{title}</span>
            <button
              type="button"
              role="closeIcon"
              id="TableFiltersSidebar-close-button"
              className={'PCURLModalcloseBtn actionItem actionItemMedium ml-2'}
              onClick={() =>
                setShow({
                  domainName: '',
                  showBanner: false,
                })
              }
            >
              <X />
            </button>
          </div>
        }
        bodyChild={
          <>
            <div className={cx('col-md-12 pl-0 w-100', 'deleteDomainMessage')}>
              <b>{addPrefixToDomain(show.domainName)}&nbsp;</b>
              {message}
            </div>
          </>
        }
        footerChild={
          <div className="row w-100">
            <div className="d-flex w-100 align-items-center justify-content-end">
              <CancelButtonWithIcon
                onClick={() => {
                  setShow({
                    domainName: '',
                    showBanner: false,
                  })
                }}
                text={I18n.t('common.cancel')}
                x-automation-id="cancel"
                id="cancelBtn"
              />
              <ButtonWithIcon
                className="ml-2"
                text={I18n.t('domains.yesDeleteIt')}
                x-automation-id="donebutton"
                id="doneBtn"
                onClick={() => {
                  setShow({
                    domainName: '',
                    showBanner: false,
                  })
                  if (isSubdomain) {
                    dispatch(actionRemoveSubDomain(show.domainId))
                  } else {
                    dispatch(actionRemoveDomain(show.domainId, '0'))
                  }
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default ConfirmDeleteModal
