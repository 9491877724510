import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/requests-list.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { PageParams } from 'Modules/Common/Models'

export const ACTION_GET_REQUESTS_LIST = 'ACTION_GET_REQUESTS_LIST'
export const ACTION_GET_REQUESTS_LIST_SUCCESS = 'ACTION_GET_REQUESTS_LIST_SUCCESS'
export const ACTION_GET_REQUESTS_LIST_FAILURE = 'ACTION_GET_REQUESTS_LIST_FAILURE'

export interface ActionGetRequestsList extends Action {
  type: typeof ACTION_GET_REQUESTS_LIST
  payload: PageParams
}

export interface ActionGetRequestsListSuccess extends Action {
  type: typeof ACTION_GET_REQUESTS_LIST_SUCCESS
  payload: { data: Array<any>; totalRecords: number; activePage: number; extendedFreeTrialDays?: number }
}

export interface ActionGetRequestsListFailure extends Action {
  type: typeof ACTION_GET_REQUESTS_LIST_FAILURE
  payload: { errorMessage: string }
}

export type GetRequestsListActions = ActionGetRequestsList | ActionGetRequestsListFailure | ActionGetRequestsListSuccess

export const dispatchGetRequestsList = (pageParams: PageParams): ActionGetRequestsList => ({
  type: ACTION_GET_REQUESTS_LIST,
  payload: pageParams,
})

const dispatchGetRequestsListSuccess = (
  data: Array<any>,
  totalRecords: number,
  activePage: number
): ActionGetRequestsListSuccess => ({
  type: ACTION_GET_REQUESTS_LIST_SUCCESS,
  payload: { data, totalRecords, activePage },
})

const dispatchGetRequestsListFailure = (error: Error): ActionGetRequestsListFailure => ({
  type: ACTION_GET_REQUESTS_LIST_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetRequestsList(
  req: PageParams,
  additionalParams: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetRequestsListActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetRequestsListActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getRequestsList(req, additionalParams)
      if (result && result.success) {
        dispatch(dispatchGetRequestsListSuccess(result.data, result.totalRecords, result.activePage))
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetRequestsListFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetRequestsListFailure(error))
      loaderStop(dispatch)
    }
  }
}
