import { http } from '../../../Utils/AxiosWrapper'
import { consentDashboard } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { OpenRequestModel, BannerResponseModel } from '../Models/ConsentDashboard.model'
import { RegulationTypes } from 'Modules/Common/Enums/Common'

interface GetOpenRequestData {
  getOpenRequestData(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string,
  ): Promise<ApiResponse<BannerResponseModel>>
}

class GetOpenRequestDataDefault implements GetOpenRequestData {
  async getOpenRequestData(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string,
  ): Promise<ApiResponse<BannerResponseModel>> {
    let response: ApiResponse<BannerResponseModel>
    try {
      const params: any = {
        start_date: startDate,
        end_date: endDate,
      }
      if (selectedTenant && selectedTenant !== '') {
        params.tenant_id = selectedTenant
      }
      if (selectedDomain && selectedDomain !== '') {
        params.domain_id = selectedDomain
      }
      if (selectedSubDomain && selectedSubDomain !== '') {
        params.sub_domain_id = selectedSubDomain
      }
      const { data } = await http.get(consentDashboard.getUniqueBannerViews, {
        params,
      })
      response = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: {
          banner_total: data?.total_banner,
          percentage: data?.percentage
        },
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new GetOpenRequestDataDefault()
