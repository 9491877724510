import { Dispatch } from 'react'
import { Action } from 'redux'
import { UserModel } from '../Models/user.model'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import service from '../Services/invite-info.service'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_INVITE_INFO = 'ACTION_GET_INVITE_INFO'
export const ACTION_GET_INVITE_INFO_SUCCESS = 'ACTION_GET_INVITE_INFO_SUCCESS'
export const ACTION_GET_INVITE_INFO_FAILED = 'ACTION_GET_INVITE_INFO_FAILED'
export const ACTION_IS_LINK_EXPIRED = 'ACTION_IS_LINK_EXPIRED'

export interface ActionGetInviteInfo extends Action {
  type: typeof ACTION_GET_INVITE_INFO
}

export interface ActionGetInviteInfoSuccess extends Action {
  type: typeof ACTION_GET_INVITE_INFO_SUCCESS
  payload: {
    inviteInfo: UserModel
  }
}

export interface ActionGetInviteInfoFailed extends Action {
  type: typeof ACTION_GET_INVITE_INFO_FAILED
  payload: {
    errorMessage: string
  }
}
export interface ActionIsLinkExpired extends Action {
  type: typeof ACTION_IS_LINK_EXPIRED
  payload: {
    isLinkExpired: boolean
  }
}

export type GetInviteInfoActions =
  | ActionGetInviteInfo
  | ActionGetInviteInfoFailed
  | ActionGetInviteInfoSuccess
  | ActionIsLinkExpired

const dispatchGetInviteInfo = (): ActionGetInviteInfo => ({
  type: ACTION_GET_INVITE_INFO,
})

const dispatchGetInviteInfoSuccess = (inviteInfo: any): ActionGetInviteInfoSuccess => ({
  type: ACTION_GET_INVITE_INFO_SUCCESS,
  payload: {
    inviteInfo,
  },
})

const dispatchGetInviteInfoFailed = (error: Error): ActionGetInviteInfoFailed => ({
  type: ACTION_GET_INVITE_INFO_FAILED,
  payload: { errorMessage: error.message },
})

const dispatchLinkExpired = (isLinkExpired: boolean): ActionIsLinkExpired => ({
  type: ACTION_IS_LINK_EXPIRED,
  payload: { isLinkExpired },
})

export function actionGetInviteInfo(
  data: any,
  history: any
): (dispatch: Dispatch<GetInviteInfoActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetInviteInfoActions>) => {
    dispatch(dispatchGetInviteInfo())
    loaderStart(dispatch)
    try {
      const result = await service.getInviteInfo(data)
      if (result && result.success) {
        dispatch(dispatchGetInviteInfoSuccess(result.inviteInfo))
      } else if (result?.code === ResponseCodes.linkExpired) {
        dispatch(dispatchLinkExpired(true))
      } else {
        if (result?.code === ResponseCodes.NotFound) {
          toastService.error(I18n.t('auth_toast.link_expired'))
          history.push('/login')
        } else {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetInviteInfoFailed(new Error(result?.msg || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchGetInviteInfoFailed(error))
      loaderStop(dispatch)
    }
  }
}
