class SessionStorageWrapper {
    /**
     * @description Add key and value in SessionStorage brower api
     * @param key Name of the key
     * @param value set the value of key
     */
    public setKey(key: string, value: string): void {
      sessionStorage.setItem(key, value)
    }
    /***
     * @description Remove key and value in SessionStorage brower api
     * @param key Name of the key which want to remove
     */
    public removeKey(key: string): void {
      sessionStorage.removeItem(key)
    }
    /***
     * @description Get the value in SessionStorage brower api
     * @param key Name of the key which want to get
     */
    public get(key: string): string {
      return sessionStorage.getItem(key)
    }
    /**
     * @description Clear SessionStorage
     */
     public clear(): void {
      sessionStorage.clear();
    }
  }
  
  export const SessionStorage = new SessionStorageWrapper()
  