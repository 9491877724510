import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/cookies-list.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { BasicDropdownModel, PageParams } from 'Modules/Common/Models'

export const ACTION_GET_COOKIES_LIST = 'ACTION_GET_COOKIES_LIST'
export const ACTION_GET_COOKIES_LIST_SUCCESS = 'ACTION_GET_COOKIES_LIST_SUCCESS'
export const ACTION_GET_COOKIES_LIST_FAILURE = 'ACTION_GET_COOKIES_LIST_FAILURE'
export const ACTION_GET_COOKIES_LIST_RESET = 'ACTION_GET_COOKIES_LIST_RESET'

export const ACTION_GET_COOKIES_TENANT_LIST = 'ACTION_GET_COOKIES_TENANT_LIST'
export const ACTION_GET_COOKIES_TENANT_LIST_SUCCESS = 'ACTION_GET_COOKIES_TENANT_LIST_SUCCESS'
export const ACTION_GET_COOKIES_TENANT_LIST_FAILURE = 'ACTION_GET_COOKIES_TENANT_LIST_FAILURE'

export interface ActionGetCookiesListReset extends Action {
  type: typeof ACTION_GET_COOKIES_LIST_RESET
}

export interface ActionGetCookiesTenantList extends Action {
  type: typeof ACTION_GET_COOKIES_TENANT_LIST
}

export interface ActionGetCookiesTenantListSuccess extends Action {
  type: typeof ACTION_GET_COOKIES_TENANT_LIST_SUCCESS
  payload: { data: Array<BasicDropdownModel> }
}

export interface ActionGetCookiesTenantListFailure extends Action {
  type: typeof ACTION_GET_COOKIES_TENANT_LIST_FAILURE
  payload: { errorMessage: string }
}

export interface ActionGetCookiesList extends Action {
  type: typeof ACTION_GET_COOKIES_LIST
  payload: PageParams
}

export interface ActionGetCookiesListSuccess extends Action {
  type: typeof ACTION_GET_COOKIES_LIST_SUCCESS
  payload: {
    data: Array<any>
    totalRecords: number
    activePage: number
    domainList: Array<BasicDropdownModel>
    subDomainList: Array<BasicDropdownModel>
    categoryList: Array<BasicDropdownModel>
  }
}

export interface ActionGetCookiesListFailure extends Action {
  type: typeof ACTION_GET_COOKIES_LIST_FAILURE
  payload: { errorMessage: string }
}

export type GetCookiesListActions =
  | ActionGetCookiesList
  | ActionGetCookiesListFailure
  | ActionGetCookiesListSuccess
  | ActionGetCookiesTenantList
  | ActionGetCookiesTenantListFailure
  | ActionGetCookiesTenantListSuccess
  | ActionGetCookiesListReset

export const dispatchGetCookiesListReset = (): ActionGetCookiesListReset => ({
  type: ACTION_GET_COOKIES_LIST_RESET,
})

export const dispatchGetCookiesList = (pageParams: PageParams): ActionGetCookiesList => ({
  type: ACTION_GET_COOKIES_LIST,
  payload: pageParams,
})

const dispatchGetCookiesListSuccess = (
  data: Array<any>,
  totalRecords: number,
  activePage: number,
  domainList: Array<BasicDropdownModel>,
  subDomainList: Array<BasicDropdownModel>,
  categoryList: Array<BasicDropdownModel>
): ActionGetCookiesListSuccess => ({
  type: ACTION_GET_COOKIES_LIST_SUCCESS,
  payload: { data, totalRecords, activePage, domainList, subDomainList, categoryList },
})

const dispatchGetCookiesListFailure = (error: Error): ActionGetCookiesListFailure => ({
  type: ACTION_GET_COOKIES_LIST_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetCookiesList(
  req: PageParams,
  additionalParams: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetCookiesListActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetCookiesListActions>) => {
    loaderStart(dispatch, isLoader)

    try {
      const result = await service.getCookiesList(req, additionalParams)

      if (result && result.success) {
        dispatch(
          dispatchGetCookiesListSuccess(
            result.data,
            result.totalRecords,
            result.activePage,
            result.domainList,
            result.subDomainList,
            result.categoryList
          )
        )
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetCookiesListFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetCookiesListFailure(error))
      loaderStop(dispatch)
    }
  }
}

const dispatchGetCookiesTenantListSuccess = (data: Array<BasicDropdownModel>): ActionGetCookiesTenantListSuccess => ({
  type: ACTION_GET_COOKIES_TENANT_LIST_SUCCESS,
  payload: { data },
})

const dispatchGetCookiesTenantListFailure = (error: Error): ActionGetCookiesTenantListFailure => ({
  type: ACTION_GET_COOKIES_TENANT_LIST_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetCookiesTenantList(
  isLoader: boolean = true
): (dispatch: Dispatch<GetCookiesListActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetCookiesListActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getCookiesTenantsList()
      if (result && result.success) {
        dispatch(dispatchGetCookiesTenantListSuccess(result.data))
      } else {
        if (result?.code !== ResponseCodes.NotFound) {
          toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetCookiesTenantListFailure(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetCookiesTenantListFailure(error))
      loaderStop(dispatch)
    }
  }
}
