import { http } from 'Utils/AxiosWrapper'
import { auth } from '../../Common/Constants/ApiUrls'

export interface LogoutUserResponse {
  success: boolean
  message: string
  code: number
}

export interface LogoutUserService {
  logout(): Promise<LogoutUserResponse>
}

class LogoutUserServiceDefault implements LogoutUserService {
  async logout(): Promise<LogoutUserResponse> {
    
    let result: any
    try {
      const { data } = await http.get(auth.logout)
      result =  {
        success: true,
        message: data?.message,
        code: data?.code,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

}

export default new LogoutUserServiceDefault()
