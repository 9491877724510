import { Action } from 'redux'
import { ViewByDropdownModel } from '../Models/ConsentDashboard.model'

export const ACTION_SET_SELECTED_DATE_RANGE = 'ACTION_SET_SELECTED_DATE_RANGE'
export const ACTION_RESET_SELECTED_DATE_RANGE = 'ACTION_RESET_SELECTED_DATE_RANGE'

export interface ActionResetSelectedDateRange extends Action {
  type: typeof ACTION_RESET_SELECTED_DATE_RANGE
}

export interface ActionSetSelectedDateRange extends Action {
  type: typeof ACTION_SET_SELECTED_DATE_RANGE
  payload: ViewByDropdownModel
}

export type DateRangeActions = ActionResetSelectedDateRange | ActionSetSelectedDateRange

export const dispatchSetSelectedDateRange = (value: ViewByDropdownModel): ActionSetSelectedDateRange => ({
  type: ACTION_SET_SELECTED_DATE_RANGE,
  payload: value,
})

export const dispatchResetSetSelectedDateRange = (): ActionResetSelectedDateRange => ({
  type: ACTION_RESET_SELECTED_DATE_RANGE,
})
