import React from 'react'
import DomainsList from './DomainsList'

export default function Index() {
  return (
    <div className="h-100">
      <DomainsList />
    </div>
  )
}
