import { ResponseCodes } from 'Modules/Common/Enums/Common'

export function downloadFile(data: any, filename: string) {
  const blobData = new Blob([data], { type: 'application/zip' })
  let a = document.createElement('a')
  a.href = URL.createObjectURL(blobData)
  a?.setAttribute('download', filename)
  a?.click()
  a?.remove()
}

export function handleDownloadFile(response: any) {
  if (response) {
    const [, filename] = response.headers['content-disposition'].split('filename=')
    downloadFile(response.data, filename)
    return {
      success: true,
      message: '',
      statusCode: ResponseCodes.Success,
    }
  } else {
    return {
      success: false,
      message: response?.data?.message,
      statusCode: response?.data?.code,
    }
  }
}

export default downloadFile
