import React, { Suspense } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import styled from 'styled-components'

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface PrivateRouteProps {
  component: any
  isAccessible: boolean
}

const PrivateRoute = ({ component: Component, isAccessible, ...rest }: PrivateRouteProps & RouteProps) => (
  <Route
    {...rest}
    render={props => {
      // check if route is restricted by permission
      if (!isAccessible) {
        // not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }
      // authorised so return component
      return (
        <Suspense fallback={<LoaderContainer>Loading...</LoaderContainer>}>
          <Component {...props} />
        </Suspense>
      )
    }}
  />
)

export default PrivateRoute
