import React, { useState } from 'react'
import { X } from 'react-feather'
import { cx } from '@emotion/css'
import { ErrorMessage } from '@hookform/error-message'
import { useFieldArray } from 'react-hook-form'
import { I18n } from 'react-redux-i18n'
import styles from './Domains.module.css'
import { parseDomain, fromUrl, ParseResultType } from 'parse-domain'
import { isEqual } from 'lodash'
import CommonConfirmModal from 'Modules/Common/Components/CommonConfirmModal'
import { useDispatch, useSelector } from 'react-redux'
import { actionRemoveSubDomain, dispatchAddDomainValidated } from 'Modules/Domains/Actions/domainList.actions'
import { InputGroup, Spinner } from 'react-bootstrap'
import checkValidDomain from './checkValidDomain'
import { useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { AppStateType } from 'Store/reducers'

export default function NestedDomains({
  nestIndex,
  control,
  register,
  formState,
  getValues,
  isEditable,
  showLoader,
  setShowLoader,
  trigger,
}) {
  const [show, setShow] = useState(false)
  const domainsValidated = useSelector((state: AppStateType) => state.DomainsReducer.domainsValidated)

  const [indexAndIdToBeRemoved, setIndexAndIdToBeRemoved] = useState({
    index: 0,
    id: null,
  })

  const domain = getValues().domains?.[nestIndex]?.domain_name

  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const { domainId } = queryString.parse(location?.search)

  const { fields, append, remove } = useFieldArray({
    control,
    name: `domains[${nestIndex}].sub_domains`,
  })

  const subdomainHasError = (index: number) => {
    return formState?.errors?.domains?.[nestIndex]?.sub_domains?.[index]?.sub_domain_name
  }

  const isValidSubdomain = (value: string) => {
    const parsedDomain = parseDomain(fromUrl(domain))
    const parsedSubDomain = parseDomain(fromUrl(value))

    if (fromUrl(domain) === fromUrl(value)) {
      // checking if enterted value is equal
      return false
    }
    if (
      parsedSubDomain.type === ParseResultType.Invalid ||
      parsedSubDomain.type === ParseResultType.NotListed ||
      parsedSubDomain.type === ParseResultType.Reserved
    ) {
      // checking if enterted value is not valid value
      return false
    }
    if (parsedDomain.type === ParseResultType.Listed && parsedSubDomain.type === ParseResultType.Listed) {
      if (
        !isEqual(parsedDomain.domain, parsedSubDomain.domain) ||
        !isEqual(parsedDomain.topLevelDomains, parsedSubDomain.topLevelDomains)
      ) {
        return false
      }
    }

    return true
  }

  const isDuplicateSubDomain = (value: string, index: number): boolean => {
    const sub_domains = getValues().domains?.[nestIndex]?.sub_domains

    for (let i = 0; i < sub_domains.length; i++) {
      if (fromUrl(sub_domains[i].sub_domain_name) === fromUrl(value) && i !== index) {
        return true
      }
    }

    return false
  }

  const validate = async (value: string, index: number, name: string) => {
    const parsedDomain = parseDomain(fromUrl(value))

    if (!isValidSubdomain(value)) {
      return I18n.t('tenant.addTenant.invalidSubDomain')
    }

    if (isDuplicateSubDomain(value, index)) {
      return I18n.t('tenant.addTenant.duplicateSubDomain')
    }

    let validateSubdomain
    if (!domainsValidated.includes(value)) {
      validateSubdomain = await checkValidDomain(parsedDomain.hostname as string, index, setShowLoader, true)
      if (validateSubdomain === 'error') {
        return I18n.t('Subdomain does not exist')
      } else {
        dispatch(dispatchAddDomainValidated(value))
      }
    }

    return undefined
  }

  const handleOnConfirm = () => {
    setShow(false)
    remove(indexAndIdToBeRemoved.index)
    if (indexAndIdToBeRemoved.id) {
      dispatch(actionRemoveSubDomain(indexAndIdToBeRemoved.id))
    }
  }

  return (
    <>
      <div className={styles.nestedDomainsContainer}>
        {fields.map((item: Record<string, string>, k: number) => {
          return (
            <div key={item.id} className={cx('row p-0 m-0', `${styles.subDomainContainer}`)}>
              <div className={cx(styles.nestedDomainInputContainer, styles.minHeightBox, 'd-flex')}>
                <div className={cx(`${styles.horizontalLine}`)}></div>
                <InputGroup.Text
                  id="basic-addon3"
                  className={cx('form-control-InputGroup-text', styles.subdomain, styles.inputGroup)}
                >
                  https://
                </InputGroup.Text>
                <div className="d-flex flex-column w-100">
                  <input
                    x-automation-id="sub_domain_name"
                    defaultValue={item.sub_domain_name}
                    key={item.id}
                    placeholder="e.g. subdomain.cytrio.com "
                    className={`${styles.input} form-control ${subdomainHasError(k) ? 'is-invalid' : ''}`}
                    {...register(`domains.${nestIndex}.sub_domains.${k}.sub_domain_name`, {
                      required: I18n.t('tenant.addTenant.companySubDomainRequired'),
                      validate: (value: string) => {
                        return validate(value, k, `domains.${nestIndex}.sub_domains.${k}.sub_domain_name`)
                      },
                      setValueAs: v => v?.replace(/(^\w+:|^)\/\//, '')?.replace(/^www\./, ''),
                    })}
                  />
                  <input
                    x-automation-id="sub_domain_id"
                    defaultValue={item.sub_domain_name}
                    key={item.id}
                    className={styles.subdomainId}
                    {...register(`domains.${nestIndex}.sub_domains.${k}.sub_domain_id`)}
                  />

                  <ErrorMessage
                    className={cx('invalid-feedback', `${styles.subdomainError}`)}
                    name={`domains.${nestIndex}.sub_domains.${k}.sub_domain_name`}
                    as="div"
                    errors={formState?.errors}
                  />
                </div>
              </div>

              {/* <div className={cx('minHeightBox', styles.featureContainer)}>
              <label className={cx(`${styles.checkboxContainer}`, 'd-flex')}>
                <input
                  x-automation-id="is_consent_management_subcriptions_enable"
                  type="checkbox"
                  className={`checkbox`}
                  key={item.id}
                  defaultChecked={item.is_consent_management_subcriptions_enable}
                  {...register(`domains.${nestIndex}.sub_domains.${k}.is_consent_management_subcriptions_enable`)}
                />
                <div className={`${styles.label}`}>{I18n.t('tenant.addTenant.consentManagement')}</div>
              </label>
            </div>
            <div className={cx('minHeightBox', styles.featureContainer)} /> */}
              <div className={cx(styles.minHeightBox, styles.removeIconSubdomain)}>
                <div
                  onClick={() => {
                    if ((domainId || id) && isEditable && item.sub_domain_name !== '') {
                      setIndexAndIdToBeRemoved({
                        index: k,
                        id: item.sub_domain_id,
                      })
                      setShow(true)
                    } else {
                      remove(k)
                    }
                  }}
                >
                  <X className={cx(styles.removeIcon)}></X>
                </div>
              </div>
              <div className={styles.verifyingDiv}>
                <div>
                  {showLoader?.isSubdomain && showLoader?.loading && showLoader?.index === k ? (
                    <div className={cx('d-flex', 'align-item-center', styles.verifyingDivLoader)}>
                      <Spinner
                        animation={'border'}
                        role="status"
                        size="sm"
                        className="mr-2"
                        style={{ color: '#c69400' }}
                      />
                      <div className={styles.verifyingText}>{I18n.t('tenant.verifyingDomain')}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
        })}

        <CommonConfirmModal
          show={show}
          setShow={setShow}
          title={I18n.t('common.confirmDelete')}
          bodyText={I18n.t('domains.confirmRemoveText')}
          onConfirm={handleOnConfirm}
        />
      </div>

      <button
        type="button"
        onClick={() => append({ sub_domain_name: '', sub_domain_id: '' })}
        className={cx(styles.addSubdomainButton)}
        disabled={showLoader?.loading}
      >
        {I18n.t('tenant.addTenant.addSubDomain')}
      </button>
    </>
  )
}
