import { Dispatch } from 'react'
import { Action } from 'redux'
import { UserModel } from '../Models/user.model'
import service from '../Services/verify-code.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_SP_TOKEN = 'ACTION_GET_SP_TOKEN'
export const ACTION_GET_SP_TOKEN_SUCCESS = 'ACTION_GET_SP_TOKEN_SUCCESS'
export const ACTION_GET_SP_TOKEN_FAILED = 'ACTION_GET_SP_TOKEN_FAILED'

export interface ActionGetSPToken extends Action {
  type: typeof ACTION_GET_SP_TOKEN
}

export interface ActionGetSPTokenSuccess extends Action {
  type: typeof ACTION_GET_SP_TOKEN_SUCCESS
  payload: {
    user?: UserModel
  }
}

export interface ActionGetSPTokenFailed extends Action {
  type: typeof ACTION_GET_SP_TOKEN_FAILED
  payload: {
    errorMessage: string
  }
}

export type GetSPTokenActions = ActionGetSPToken | ActionGetSPTokenFailed | ActionGetSPTokenSuccess

const dispatchActionGetSPToken = (): ActionGetSPToken => ({
  type: ACTION_GET_SP_TOKEN,
})

const dispatchActionGetSPTokenSuccess = (user?: UserModel): ActionGetSPTokenSuccess => ({
  type: ACTION_GET_SP_TOKEN_SUCCESS,
  payload: {
    user,
  },
})

const dispatchActionGetSPTokenFailed = (error: Error): ActionGetSPTokenFailed => ({
  type: ACTION_GET_SP_TOKEN_FAILED,
  payload: { errorMessage: error.message },
})
const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.InvalidParams:
      toastService.error(I18n.t('auth_toast.invalid_code'))
      break
    case ResponseCodes.ExpiredCode:
      toastService.error(I18n.t('auth_toast.code_expired'))
      break
    case ResponseCodes.NotFound:
      toastService.error(I18n.t('auth_toast.user_not_exists'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionActionGetSPToken(): (dispatch: Dispatch<GetSPTokenActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetSPTokenActions>) => {
    dispatch(dispatchActionGetSPToken())
    loaderStart(dispatch)
    try {
      const result = await service.getSPToken()
      if (result && result.success) {
        dispatch(dispatchActionGetSPTokenSuccess(result.user))
        window.location.assign('/admin/tenant-management')
      } else {
        toaster(result?.code)
        dispatch(dispatchActionGetSPTokenFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      dispatch(dispatchActionGetSPTokenFailed(error))
      loaderStop(dispatch)
    }
  }
}
