import { Form, OverlayTrigger } from 'react-bootstrap'
import React, { useState } from 'react'
import PasswordCheckContent from './PasswordCheckContent'
import PasswordCheckPopover from './PasswordCheckPopover'

interface Validations {
  setContainsUL: boolean
  setContainsLL: boolean
  setContainsN: boolean
  setContains8: boolean
}

export const validatePasswordInput = (value: string): Validations => {
  let v = value || ''
  const validations: Validations = {
    setContainsUL: false,
    setContainsLL: false,
    setContainsN: false,
    setContains8: false,
  }
  // has uppercase letter
  if (v.toLowerCase() != v) {
    validations.setContainsUL = true
  } else {
    validations.setContainsUL = false
  }
  // has lowercase letter
  if (v.toUpperCase() != v) {
    validations.setContainsLL = true
  } else {
    validations.setContainsLL = false
  }
  // has number
  if (/\d/.test(v)) {
    validations.setContainsN = true
  } else {
    validations.setContainsN = false
  }
  // has 8 characters
  if (v.length >= 8) {
    validations.setContains8 = true
  } else {
    validations.setContains8 = false
  }

  return validations
}

const PasswordInput = React.forwardRef(({ placement, getValues, ...rest }: any, ref) => {
  const [show, setShow] = useState<boolean>(false)
  const [containsUL, setContainsUL] = useState<boolean>(false)
  const [containsN, setContainsN] = useState<boolean>(false)
  const [containsLL, setContainsLL] = useState<boolean>(false)
  const [contains8, setContains8] = useState<boolean>(false)

  const validatePassword = () => {
    const v = getValues()
    const validations: Validations = validatePasswordInput(v)
    setContainsUL(validations.setContainsUL)
    setContainsLL(validations.setContainsLL)
    setContainsN(validations.setContainsN)
    setContains8(validations.setContains8)
  }

  return (
    <OverlayTrigger
      show={show}
      placement={placement || 'left-start'}
      overlay={
        <PasswordCheckPopover id="popover-contained">
          <PasswordCheckContent
            containsLL={containsLL}
            containsUL={containsUL}
            contains8={contains8}
            containsN={containsN}
          />
        </PasswordCheckPopover>
      }
    >
      <Form.Control
        {...rest}
        type="password"
        onBlur={() => setShow(!show)}
        onFocus={() => setShow(!show)}
        onKeyUp={validatePassword}
      />
    </OverlayTrigger>
  )
})

export default PasswordInput
