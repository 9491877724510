import { http } from '../../../Utils/AxiosWrapper'
import { EmailTemplateModel } from '../Models/index'
import { settings } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'

interface EmailTemplate {
  getEmailTemplate(type: string): Promise<ApiResponse<EmailTemplateModel>>
  updateEmailTemplate(data: EmailTemplateModel): Promise<ApiResponse<string>>
}

class EmailTemplateService implements EmailTemplate {
  async getEmailTemplate(type: string): Promise<ApiResponse<EmailTemplateModel>> {
    let result: ApiResponse<EmailTemplateModel>
    try {
      const params = { type }
      const { data }: any = await http.get(settings.emailTemplate, { params })
      result = {
        success: true,
        code: data?.code,
        message: data?.message || '',
        data: data?.data,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  async updateEmailTemplate(updateEmailTemplateData: EmailTemplateModel): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data }: any = await http.put(
        settings.emailTemplate,
        EmailTemplateService.buildRequest(updateEmailTemplateData),
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      result = {
        success: true,
        code: data?.code,
        message: data?.message || '',
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildRequest(request: EmailTemplateModel): FormData {
    const { email_template_id, email_body, email_from, email_subject, email_type } = request

    const formData = new FormData()
    formData.append('email_template_id', email_template_id)
    formData.append('email_body', email_body)
    formData.append('email_from', email_from)
    formData.append('email_subject', email_subject)
    formData.append('email_type', email_type)
    return formData
  }
}

export default new EmailTemplateService()
