import React, { useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { X } from 'react-feather'

import PageTitle from 'Modules/Common/Components/PageTitle'
import styles from './Domains.module.css'
import TableFilters from 'Modules/Common/Components/TableFilters'
import SearchInput from 'Modules/Common/Components/SearchInput'
import ButtonWithIcon from 'Modules/Common/Components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetAllDomainsList } from './Actions/domainList.actions'
import { TenantDeployStatus } from 'Modules/Common/Enums/Common'
import { AppStateType } from 'Store/reducers'
import { useHistory } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    {I18n.t('domains.upgradeAdditionalDomainText')}
  </Tooltip>
)

export default function DomainsHeader() {
  const [search, setSearch] = useState('')
  const [activeFilters, setActiveFilters] = useState({
    privacyCenterStatus: null,
    consentBannerStatus: null,
  })

  const dispatch = useDispatch()
  const { pageParams } = useSelector((state: AppStateType) => state.DomainsReducer)
  const history = useHistory()

  const domainLimit = useSelector((state: AppStateType) => state.TenantReducer.domainLimit)
  const domainsUsed = useSelector((state: AppStateType) => state.TenantReducer.domainsUsed)
  const role = useSelector((state: AppStateType) => state.AuthReducer.userData?.roleDisplayName)

  const isAddDomainDisabled = role !== 'Service Provider' && domainLimit !== 0 && domainsUsed >= domainLimit

  useEffect(() => {
    let params = {}

    if (activeFilters?.privacyCenterStatus?.value) {
      params['privacy_center_status'] = activeFilters?.privacyCenterStatus?.value
    }

    if (activeFilters?.consentBannerStatus?.value) {
      params['consent_banner_status'] = activeFilters?.consentBannerStatus?.value
    }

    if (Object.keys(params).length > 0) {
      dispatch(
        actionGetAllDomainsList({
          ...params,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, dispatch])

  return (
    <div className={styles.domainsHeaderContainer}>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle title={I18n.t('domains.pageTitle')} />
        {isAddDomainDisabled ? (
          <OverlayTrigger placement="top" overlay={renderTooltip}>
            <div>
              <ButtonWithIcon
                text={I18n.t('domains.addDomain')}
                onClick={() => {
                  history.push('/admin/domains/addDomains')
                }}
                disabled={isAddDomainDisabled}
                style={{ pointerEvents: isAddDomainDisabled ? 'none' : 'all' }}
              />
            </div>
          </OverlayTrigger>
        ) : (
          <ButtonWithIcon
            text={I18n.t('domains.addDomain')}
            onClick={() => {
              history.push('/admin/domains/addDomains')
            }}
            disabled={isAddDomainDisabled}
          />
        )}
      </div>
      <div className="row w-100 p-0 m-0 mt-1 mb-3">
        <div className="col-xs-12 col-sm-12 col-md-4 col-ls-4 pl-0">
          <SearchInput
            automationEleId="requests-list-search-input"
            id="requests-list-search-input"
            placeholder={I18n.t('search_by.requests')}
            value={search}
            onChange={({ target }) => {
              setSearch(target.value)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                dispatch(actionGetAllDomainsList({ ...pageParams, page_no: 1, search: search }))
              }
            }}
            handleClear={() => {
              setSearch('')
              dispatch(actionGetAllDomainsList({ ...pageParams, page_no: 1, search: '' }))
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-ls-6 pl-0">
          <TableFilters
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            defaultValues={{}}
            filters={[
              {
                name: I18n.t('tenant.privacyCenterStatus'),
                key: 'privacyCenterStatus',
                type: 'dropdown',
                isMulti: false,
                options: [
                  {
                    label: I18n.t('tenant.active'),
                    value: TenantDeployStatus.Active,
                  },
                  {
                    label: I18n.t('tenant.error'),
                    value: TenantDeployStatus.Error,
                  },
                  {
                    label: I18n.t('tenant.pendingDeployment'),
                    value: TenantDeployStatus.PendingDeployment,
                  },
                ],
              },
              {
                name: I18n.t('tenant.consentBannerStatus'),
                key: 'consentBannerStatus',
                type: 'dropdown',
                isMulti: false,
                options: [
                  {
                    label: I18n.t('tenant.active'),
                    value: TenantDeployStatus.Active,
                  },
                  {
                    label: I18n.t('tenant.error'),
                    value: TenantDeployStatus.Error,
                  },
                  {
                    label: I18n.t('tenant.pendingDeployment'),
                    value: TenantDeployStatus.PendingDeployment,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}
