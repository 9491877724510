import ErrorBoundary from 'Modules/Common/Components/ErrorBoundary'
import React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Column } from 'react-table'
import { AppStateType } from 'Store/reducers'
import Table, { commonLastUpdated } from '../Common/Components/TableComponent'
import { actionGetConsentLogs, dispatchGetConsentLogs } from './Actions/getConsentLogs.actions'
import { ConsentLogsType } from './Models/consentLogs.model'
import { renderConsentStatus } from './Utils/common'
import { useLocation } from 'react-router'
import queryString from 'query-string'

interface Props {
  activeFilters: ConsentLogsType
}

const PrivacyCenterConsentList = ({ activeFilters }: Props) => {
  const dispatch = useDispatch()
  const { dataList: data, totalRecords, pageParams, activePage } = useSelector(
    (state: AppStateType) => state.ConsentLogsReducer.privacy_center
  )

  const location = useLocation()
  const { userData } = useSelector((state: AppStateType) => state.AuthReducer)
  const { startDate, endDate } = queryString.parse(location?.search)

  const setPageParams = useCallback(({ pageIndex, pageSize, sortBy }) => {
    let sortColumn = ''
    let sortDirection = ''
    if (sortBy && sortBy.length > 0) {
      sortColumn = sortBy[0].id || 'created_at'
      sortDirection = sortBy[0].desc ? 'desc' : 'asc'
    }
    dispatch(
      dispatchGetConsentLogs(
        {
          ...pageParams,
          page_no: pageIndex + 1,
          page_size: pageSize,
          sort_column: sortColumn,
          sort_direction: sortDirection,
        },
        activeFilters.consent_category
      )
    )
  }, [])

  useEffect(() => {
    if (!pageParams) {
      return
    }
    if (activeFilters.domain_id && activeFilters.consent_category && activeFilters.selectedConsentStatus) {
      loadData()
    }
  }, [pageParams, activeFilters])

  const loadData = (showLoader = true) => {
    let additionalParams: any = {}
    if (activeFilters?.domain_id) {
      additionalParams.domain_id = activeFilters?.domain_id.value
    }

    if (activeFilters?.selectedConsentStatus) {
      additionalParams.status = activeFilters?.selectedConsentStatus.value
    }

    if (activeFilters?.consent_category) {
      additionalParams.consent_category = activeFilters?.consent_category
    }
    if (userData?.tenantId) {
      additionalParams.tenant_id = userData?.tenantId
    }
    if (startDate !== 'null') {
      additionalParams.start_date = startDate
    }

    if (endDate !== 'null') {
      additionalParams.end_date = endDate
    }
    dispatch(actionGetConsentLogs(pageParams, additionalParams, showLoader))
  }

  const columns: Array<Column> = useMemo(
    (): Array<Column> => [
      //NOSONAR
      {
        width: '15%',
        Header: I18n.t('consentLogs.userName'),
        accessor: 'first_name',
        Cell: ({ value, cell }) => {
          //const rowData: any = cell.row.original
          return (
            <div className="columnContainer">
              <div className="coloumnTitle" title={value}>
                {value}
              </div>
            </div>
          )
        },
      },
      {
        width: '15%',
        Header: I18n.t('consentLogs.email'),
        accessor: 'email',
        Cell: ({ value, cell }) => {
          return (
            <div className="columnContainer">
              <div className="coloumnTitle" title={value}>
                {value}
              </div>
            </div>
          )
        },
      },
      {
        width: '10%',
        Header: I18n.t('consentLogs.country'),
        accessor: 'country',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <div className="columnContainer">
              <div className="coloumnTitle" title={value}>
                {value}
              </div>
            </div>
          )
        },
      },
      {
        width: '20%',
        Header: I18n.t('consentLogs.consentStatus'),
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value, cell }) => {
          const rowData: any = cell.row.original
          return (
            <div className="columnContainer">
              {value ? (
                <div className="coloumnTitle">
                  {renderConsentStatus(value, rowData?.accepted_cookies_category, rowData?.rejected_cookies_category)}
                </div>
              ) : null}
            </div>
          )
        },
      },
      {
        width: '25%',
        Header: I18n.t('consentLogs.dateTime'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: ({ value, cell }) => {
          const rowData: any = cell.row.original
          const text = rowData.modified_by || ''
          return commonLastUpdated(value, text, rowData)
        },
      },
    ],
    []
  )

  return (
    <ErrorBoundary>
      <div className="col-md-12 h-100 privacyCenterList">
        <Table
          activePage={activePage}
          setPageParams={setPageParams}
          totalRecords={totalRecords}
          data={data}
          columns={columns}
          noRecordsMsg={I18n.t('consentLogs.noDataMsg')}
          defaultSort={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(PrivacyCenterConsentList)
