import { http } from '../../../Utils/AxiosWrapper'
import { consentDashboard } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { CookieByTypeModel } from '../Models/ConsentDashboard.model'

interface GetRequestByType {
  getRequestByType(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string,
  ): Promise<ApiResponse<CookieByTypeModel>>
}

class GetRequestByTypeDefault implements GetRequestByType {
  async getRequestByType(
    startDate: number,
    endDate: number,
    selectedTenant: string,
    selectedDomain: string,
    selectedSubDomain: string,
  ): Promise<ApiResponse<CookieByTypeModel>> {
    let response: ApiResponse<CookieByTypeModel>
    const params: any = {
      start_date: startDate,
      end_date: endDate,
    }
    if (selectedTenant && selectedTenant !== '') {
      params.tenant_id = selectedTenant
    }
    if (selectedDomain && selectedDomain !== '') {
      params.domain_id = selectedDomain
    }
    if (selectedSubDomain && selectedSubDomain !== '') {
      params.sub_domain_id = selectedSubDomain
    }
    try {
      const { data } = await http.get(consentDashboard.getCookieTypes, {
        params,
      })
      response = {
        success: true,
        message: data?.message,
        code: data?.code,
        data: data?.data,
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return response
  }
}

export default new GetRequestByTypeDefault()
