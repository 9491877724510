import { http } from '../../../Utils/AxiosWrapper'
import { AddUserList } from '../Models/index'
import { userManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { AddUserRoles, ResponseCodes } from 'Modules/Common/Enums/Common'
import Toaster from 'Modules/Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'

interface UserManagement {
  addUsers(userList: AddUserList): Promise<ApiResponse<string[]>>
  checkEmail(email: string, role: string): Promise<ApiResponse<string>>
  deleteUser(userId: number, forced: boolean): Promise<ApiResponse<string>>
}

class UserManagementService implements UserManagement {
  public async addUsers(userList: AddUserList): Promise<ApiResponse<string[]>> {
    let response: ApiResponse<string[]>
    try {
      const { data }: any = await http.post(userManagement.addUserList, userList)
      if (data && data.code == ResponseCodes.CreatedSuccessfully) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
        data: error?.response?.data?.emails,
      }
    }
    return response
  }

  /**
   * checkEmail
   */
  public async checkEmail(email: string, role: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const apiData = await http.post(userManagement.checkEmail, { email, role })
      if (apiData && apiData.status == 200 && apiData.data) {
        response = {
          success: true,
          message: '',
          statusCode: apiData.status,
          tenant_email_domain: apiData?.data['tenant_email_domain'],
        }
      } else {
        response = {
          success: false,
          message: 'Empty response.',
          statusCode: apiData.status,
        }
      }
    } catch (error: any) {
      if (ResponseCodes.Conflicts == error?.response?.data?.code) {
        Toaster.error(I18n.t('user.addUser.emailAlready'))
        response = {
          success: false,
          message: error?.response?.data?.message,
          statusCode: error?.response?.data?.code,
        }
      } else if (ResponseCodes.InValidDomain == error?.response?.data?.code) {
        if (role == AddUserRoles.ServiceProvider) {
          Toaster.error(I18n.t('user.addUser.inValidSPDomain'))
        } else {
          Toaster.error(I18n.t('user.addUser.inValidDomain'))
        }

        response = {
          success: false,
          message: error?.response?.data?.message,
          statusCode: error?.response?.data?.code,
        }
      } else if (ResponseCodes.entityExistsInAnotherTenant == error?.response?.data?.code) {
        Toaster.error(I18n.t('user.addUser.entityExistsInAnotherTenant'))
        response = {
          success: false,
          message: error?.response?.data?.message,
          statusCode: error?.response?.data?.code,
        }
      } else {
        response = {
          success: false,
          message: error?.response?.data?.message,
          statusCode: error?.response?.data?.code,
        }
      }
    }
    return response
  }

  /**
   * deleteUser
   */
  public async deleteUser(userId: number, forced: boolean): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      let params: any = {}
      if (forced) {
        params.forced = forced ? '1' : '0'
      }
      const { data }: any = await http.delete(userManagement.deleteUser(userId), { params })
      if (data && data.code == ResponseCodes.Success) {
        response = {
          success: true,
          message: data.message,
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: data.message,
          statusCode: data.code,
        }
      }
    } catch (error: any) {
      response = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
        data: error?.response?.data?.emails,
      }
    }
    return response
  }
}

export default new UserManagementService()
