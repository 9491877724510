import { DropdownModel } from 'Modules/Common/Models';
import {
  GetDeparmentActions,
  ACTION_GET_DEPARTMENT,
  ACTION_GET_DEPARTMENT_SUCCESS,
  ACTION_GET_DEPARTMENT_FAILURE,
} from './department.actions';

export interface DepartmentReducerState {
  departmentDropdown?: {
    success?: boolean;
    message?: string;
    statusCode?: number;
    getdepartmentDropdownLoader?: boolean;
    errorMessage?: string;
    data?: Array<DropdownModel>;
  };
}

export const initialState: DepartmentReducerState = {
  departmentDropdown: {
    success: false,
  },
};

export const DepartmentReducer = (
  state = initialState, // NOSONAR
  action: GetDeparmentActions
): DepartmentReducerState => {
  switch (action.type) {
    case ACTION_GET_DEPARTMENT:
      return {
        ...state,
        departmentDropdown: {
          success: true,
          errorMessage: '',
        },
      };
    case ACTION_GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentDropdown: {
          data: action.payload.data,
          success: true,
        },
      };
    case ACTION_GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        departmentDropdown: {
          errorMessage: action.payload.errorMessage,
          getdepartmentDropdownLoader: false,
          success: false,
        },
      };
    default:
      return state;
  }
};
