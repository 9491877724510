import { auth } from '../../Common/Constants/ApiUrls'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { SessionStorageKeyNames } from 'Modules/Common/Enums'
import { http } from 'Utils/AxiosWrapper'

export interface ResendCodeResponse {
  success: boolean
  message: string
  code: number
}

export interface ResendCodeService {
  resendCode(request: string): Promise<ResendCodeResponse>
}

class ResendCodeServiceDefault implements ResendCodeService {
  async resendCode(email: string): Promise<ResendCodeResponse> {
    const bodyJSONData = {
      email,
    }
    let result: ResendCodeResponse
    try {
      const { data } = await http.post(auth.resendCode, bodyJSONData)
      result = ResendCodeServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }

    return result
  }

  static buildResponse(data: any): ResendCodeResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty data in response',
      }
    }

    SessionStorage.setKey(SessionStorageKeyNames.TempToken, data.jwt_token?.token)
    SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, data.jwt_token?.exp_time_in_minutes)

    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new ResendCodeServiceDefault()
