import styled from 'styled-components'
import { Calendar, Filter, X } from 'react-feather'
import { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { BasicDropdownModel } from '../Models'
import Sidebar from 'react-sidebar'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import cx from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import styles from './TableFilters.module.css'
import ButtonWithIcon, { CancelButtonWithIcon } from './Button'
import DatePicker from 'react-datepicker'
import { deepCopy } from 'Utils/stringUtils'
import 'react-datepicker/dist/react-datepicker.css'
import SelectDropdown from './SelectDropdown/SelectDropdown'

export type TableFilterType = {
  key: string
  name: string
  type: 'dropdown' | 'text' | 'daterange'
  options?: Array<BasicDropdownModel>
  isMulti?: boolean
  onChange?: (value: any) => void
  isDisabled?: boolean
}
interface TableFiltersProps {
  activeFilters: { [key: string]: any }
  defaultValues: { [key: string]: any }
  setActiveFilters: (value) => void
  filters: Array<TableFilterType>
}

const handleCalendarIconClick = (datepickerId: string) => {
  if (document.getElementById(datepickerId)) {
    document.getElementById(datepickerId).dispatchEvent(new Event('click', { cancelable: true, bubbles: true }))
  }
}

const StyledCancelButton = styled.button`
  height: 30px;
  min-width: 60px;
  padding: 7px 7px;
  font-size: 13px;
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  color: #1d252d;
  background: rgba(0, 0, 0, 0.05);
  border-color: rgba(61, 100, 239, 0.1);
  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.2);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &:disabled:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const Span = styled.span`
  display: flex;
  padding: 0px 3px;
`
const CountSpan = styled.span`
  background: #e90000;
  border-radius: 4px;
  padding: 1px 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  margin-left: 5px;
  min-width: 20px;
`

const TextSpan = styled.span`
  padding: 0px 3px;
`

const TableFilters = (props: TableFiltersProps) => {
  const { filters, setActiveFilters, activeFilters, defaultValues } = props

  const [activeFiltersCount, setActiveFiltersCount] = useState(0)

  const { register, handleSubmit, control, watch, reset, setValue } = useForm({
    defaultValues: activeFilters || defaultValues,
  })

  const allValues = watch()

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    const activeFilterValues = Object.values(activeFilters || {})?.filter(e => {
      if (Array.isArray(e) && e?.length > 0) {
        return true
      }
      if (Array.isArray(e) && e?.length === 0) {
        return false
      }
      return !!e
    })
    setActiveFiltersCount(activeFilterValues?.length)
  }, [activeFilters])

  const onSubmit = data => {
    setActiveFilters(data)
    setIsSidebarOpen(false)
  }

  const onCancelButtonClick = () => {
    setIsSidebarOpen(false)
  }

  const clearAllFilters = () => {
    setActiveFilters(null)
    reset()
  }

  const sideBarContent = (
    <div id="sidebarContent" className={styles.TableFiltersSidebarContainer}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} className="w-100">
        <div className="row m-0 pt-3 pb-2">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <div className={styles.sidebarTitle}>{I18n.t('common.filters')}</div>
            <div className="d-flex align-items-center">
              <span
                id="TableFiltersSidebar-clearAllButton"
                onClick={() => reset(defaultValues)}
                className={cx(styles.filterClearText, 'ml-2')}
              >
                {I18n.t('common.clearAll')}
              </span>
              <button
                type="button"
                role="closeIcon"
                id="TableFiltersSidebar-close-button"
                className={cx(styles.closeBtn, 'actionItem actionItemMedium ml-2')}
                onClick={() => setIsSidebarOpen(false)}
              >
                <X />
              </button>
            </div>
          </div>
        </div>
        {filters?.map((filter, index) => (
          <div className="row p-0 m-0" key={'tableFilter-' + index}>
            <Form.Group className="col-md-12">
              <Form.Label className="form-input-label">{filter?.name}</Form.Label>
              {filter.type === 'dropdown' ? (
                <Controller
                  name={filter?.key}
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectDropdown
                        {...field}
                        value={field.value || (filter?.isMulti ? [] : null)}
                        id={filter?.key}
                        x-automation-id={filter?.key}
                        classNamePrefix="react-select"
                        className={`p-0`}
                        isClearable
                        options={filter?.options}
                        isMulti={!!filter?.isMulti}
                        isDisabled={!!filter?.isDisabled}
                        onChange={value => {
                          if (filter.onChange) {
                            filter.onChange(value)
                          }
                          field.onChange(value)
                        }}
                      />
                    )
                  }}
                />
              ) : null}
              {filter.type === 'text' ? <Form.Control {...register(filter?.key)} /> : null}
              {filter.type === 'daterange' ? (
                <Controller
                  name={filter?.key}
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className={styles.calendarIconContainer}>
                        <DatePicker
                          className="dateSelect form-control formControlDateRange dsarFilterDateselect"
                          selected={field?.value?.start || null}
                          id={filter?.key}
                          onChange={dates => {
                            const [start, end] = dates
                            const newDates = deepCopy(allValues?.[filter?.key]) || {}
                            if (start || end) {
                              newDates.start = start
                              newDates.end = end
                            }
                            field.onChange(newDates)
                          }}
                          startDate={field?.value?.start || null}
                          onCalendarClose={() => {
                            if (!field?.value?.start || !field?.value?.end) {
                              field.onChange({ start: null, end: null })
                            }
                          }}
                          placeholderText={I18n.t('common.selectDataRange')}
                          endDate={field?.value?.end || null}
                          selectsRange={true}
                          autoComplete="off"
                          maxDate={new Date()}
                          dropdownMode="select"
                          shouldCloseOnSelect={true}
                        />
                        <Calendar
                          onClick={() => {
                            handleCalendarIconClick(filter?.key)
                          }}
                          size="18px"
                          className={styles.calendarIcon}
                          color="#787878"
                        />
                      </div>
                    )
                  }}
                />
              ) : null}
            </Form.Group>
          </div>
        ))}
      </Form>
      <div className={cx('row p-0 m-0 pt-2 pb-2')}>
        <div className="col-md-12 d-flex align-items-center justify-content-end">
          <CancelButtonWithIcon
            type="button"
            text={I18n.t('common.cancel')}
            onClick={onCancelButtonClick}
            id="TableFiltersSidebar-cancel-btn"
          />
          <ButtonWithIcon
            type="button"
            className={cx('ml-2', styles.applyButton)}
            onClick={handleSubmit(onSubmit)}
            text={I18n.t('common.apply')}
            id="TableFiltersSidebar-apply-btn"
          />
        </div>
      </div>
    </div>
  )

  return (
    <div className="d-flex align-items-center">
      <StyledCancelButton
        type="button"
        id="TableFiltersSidebar-filter-btn"
        onClick={() => {
          reset(activeFilters)
          setIsSidebarOpen(true)
        }}
      >
        <Span>
          <Filter size={16} />
        </Span>
        <TextSpan>{I18n.t('common.filters')}</TextSpan>
        {activeFiltersCount > 0 ? <CountSpan>{activeFiltersCount}</CountSpan> : null}
      </StyledCancelButton>
      {activeFiltersCount > 0 ? (
        <span
          id="TableFiltersSidebar-clearAllButton"
          onClick={clearAllFilters}
          className={cx(styles.filterClearText, 'ml-2')}
        >
          {I18n.t('common.clearAll')}
        </span>
      ) : null}
      <Sidebar
        sidebarClassName="TableFiltersSidebar-sidebar"
        overlayClassName="TableFiltersSidebar-overlay"
        sidebar={sideBarContent}
        open={isSidebarOpen}
        pullRight={true}
        onSetOpen={open => setIsSidebarOpen(open)}
        styles={{
          root: { position: undefined },
          content: { position: undefined },
          sidebar: { background: 'white', overflowY: 'unset', top: '70px', position: 'Fixed', marginTop: '-30px' },
        }}
      >
        <span></span>
      </Sidebar>
    </div>
  )
}

export default TableFilters
