import React from 'react'
import styles from './css/PageTitle.module.css'

export interface PageTitleProps {
  title: string
  children?: React.ReactNode
}
export default function PageTitle({ title, children }: PageTitleProps) {
  return (
    <div className={styles.pageTitle}>
      {title}
      {children}
    </div>
  )
}
