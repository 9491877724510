import { toast } from 'react-toastify'

class Toaster {
  /***
   * @description show success message. It will automatically convert message into internationalization.
   * @param message It must be I18n key. Other wise message text would be different.
   */
  public success(message: string) {
    toast.success(message)
  }
  /***
   * @description show info message.
   * @param message Actual message to display.
   */
  public info(message: string) {
    toast.info(message)
  }
  /***
   * @description show warning message.
   * @param message Actual message to display..
   */
  public warn(message: string) {
    toast.warn(message)
  }
  /***
   * @description show error message.
   * @param message Actual message to display..
   */
  public error(message: string, isDisplayIcon: boolean = false) {
    toast.error(message,{
      icon: isDisplayIcon
    })
  }
}
export default new Toaster()
