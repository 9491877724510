import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/getRequestByType.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { CookieByTypeModel } from '../Models/ConsentDashboard.model'

export const ACTION_GET_REQUEST_BY_TYPE = 'ACTION_GET_REQUEST_BY_TYPE'
export const ACTION_GET_REQUEST_BY_TYPE_SUCCESS = 'ACTION_GET_REQUEST_BY_TYPE_SUCCESS'
export const ACTION_GET_REQUEST_BY_TYPE_FAILURE = 'ACTION_GET_REQUEST_BY_TYPE_FAILURE'

export interface ActionGetRequestByType extends Action {
  type: typeof ACTION_GET_REQUEST_BY_TYPE
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionGetRequestByTypeSuccess extends Action {
  type: typeof ACTION_GET_REQUEST_BY_TYPE_SUCCESS
  payload: CookieByTypeModel
}

export interface ActionGetRequestByTypeFailure extends Action {
  type: typeof ACTION_GET_REQUEST_BY_TYPE_FAILURE
  payload: {
    errorMessage: string
    code?: number
  }
}

export type GetRequestByTypeActions =
  | ActionGetRequestByType
  | ActionGetRequestByTypeFailure
  | ActionGetRequestByTypeSuccess

export const dispatchGetRequestByType = (startDate: string, endDate: string): ActionGetRequestByType => ({
  type: ACTION_GET_REQUEST_BY_TYPE,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetRequestByTypeSuccess = (data: CookieByTypeModel): ActionGetRequestByTypeSuccess => ({
  type: ACTION_GET_REQUEST_BY_TYPE_SUCCESS,
  payload: data,
})

const dispatchGetRequestByTypeFailure = (error: Error, code?: number): ActionGetRequestByTypeFailure => ({
  type: ACTION_GET_REQUEST_BY_TYPE_FAILURE,
  payload: { errorMessage: error.message, code },
})

export function actionGetRequestByType(
  startDate: number,
  endDate: number,
  selectTenant: any,
  selectDomain: any,
  selectSubDomain: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetRequestByTypeActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetRequestByTypeActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getRequestByType(startDate, endDate, selectTenant, selectDomain, selectSubDomain)
      if (result && result.success) {
        dispatch(dispatchGetRequestByTypeSuccess(result.data))
      } else {
        toastService.error(I18n.t('common.toast.something_went_wrong'))
        dispatch(
          dispatchGetRequestByTypeFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong')),
            result.code
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetRequestByTypeFailure(error))
      loaderStop(dispatch)
    }
  }
}
