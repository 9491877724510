import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/consentByCookieCategories.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { CountryDataMapModel } from 'Modules/Common/Models'
import { CookieConsentByCategoryModel } from '../Models/ConsentDashboard.model'

export const ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START = 'ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START'
export const ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS = 'ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS'
export const ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE = 'ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE'

export interface ActionGetConsentByCookieCategories extends Action {
  type: typeof ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START
  payload: {
    startDate: string
    endDate: string
  }
}

export interface ActionConsentByCookieCategoriesSuccess extends Action {
  type: typeof ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS
  payload: CookieConsentByCategoryModel
}

export interface ActionConsentByCookieCategoriesFailure extends Action {
  type: typeof ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE
  payload: {
    errorMessage: string
  }
}

export type GetConsentByCookieCategories =
  | ActionGetConsentByCookieCategories
  | ActionConsentByCookieCategoriesFailure
  | ActionConsentByCookieCategoriesSuccess

export const dispatchGetConsentByCookieCategories = (
  startDate: string,
  endDate: string
): ActionGetConsentByCookieCategories => ({
  type: ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_START,
  payload: { startDate: startDate, endDate: endDate },
})

const dispatchGetConsentByCookieCategoriesSuccess = (
  data: CookieConsentByCategoryModel
): ActionConsentByCookieCategoriesSuccess => ({
  type: ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_SUCCESS,
  payload: data,
})

const dispatchGetConsentByCookieCategoriesFailure = (error: Error): ActionConsentByCookieCategoriesFailure => ({
  type: ACTION_GET_CONSENT_BY_COOKIE_CATEGORIES_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionGetConsentByCookieCategories(
  startDate: number,
  endDate: number,
  selectTenant: any,
  selectDomain: any,
  selectSubDomain: any,
  isLoader: boolean = true
): (dispatch: Dispatch<GetConsentByCookieCategories>) => Promise<void> {
  return async (dispatch: Dispatch<GetConsentByCookieCategories>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getConsentByCookieCategories(
        startDate,
        endDate,
        selectTenant,
        selectDomain,
        selectSubDomain
      )
      if (result && result.success) {
        dispatch(dispatchGetConsentByCookieCategoriesSuccess({ ...result.data }))
      } else {
        toastService.error(I18n.t(result?.message || 'common.toast.something_went_wrong'))
        dispatch(
          dispatchGetConsentByCookieCategoriesFailure(
            new Error(result?.message || I18n.t('common.toast.something_went_wrong'))
          )
        )
      }
      loaderStop(dispatch, isLoader)
    } catch (error: any) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetConsentByCookieCategoriesFailure(error))
      loaderStop(dispatch)
    }
  }
}
