import { http } from '../../../Utils/AxiosWrapper'
import { BrandingModel, GeneralSetting } from '../Models/tenant.models'
import { tenantManagement } from '../../Common/Constants/ApiUrls'
import { ApiResponse } from 'Modules/Common/Models'
import { ResponseCodes } from 'Modules/Common/Enums/Common'
import { isBoolean } from 'lodash'

interface PCBranding {
  getPCBranding(tenantId: string | number, domainId: string): Promise<ApiResponse<GeneralSetting>>
  updatePCBranding(data: BrandingModel, tenantId: string | number, domainId: string): Promise<ApiResponse<string>>
}
class PCBrandingService implements PCBranding {
  async getPCBranding(
    tenantId: string | number,
    domainId: string,
    subDomainId?: string
  ): Promise<ApiResponse<GeneralSetting>> {
    let result: ApiResponse<GeneralSetting>
    try {
      const { data } = await http.get(tenantManagement.pcBranding(tenantId, domainId), {
        params: {
          sub_domain_id: subDomainId,
        },
      })
      result = PCBrandingService.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }

  async updatePCBranding(
    inputData: BrandingModel,
    tenantId: string,
    domainId?: string,
    subDomainId?: string
  ): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'put',
        url: tenantManagement.pcBranding(tenantId, domainId),
        data: PCBrandingService.buildRequest(inputData),
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          sub_domain_id: subDomainId,
        },
      })
      result = {
        success: true,
        message: data.message,
        statusCode: data.code,
      }
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        statusCode: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ApiResponse<GeneralSetting> {
    if (!data) {
      return {
        success: false,
        statusCode: ResponseCodes.InvalidParams,
        message: 'Empty data in response',
      }
    }

    if (data.code === ResponseCodes.NotFound) {
      return {
        success: false,
        statusCode: data.code,
        message: data.message,
      }
    }

    return {
      success: true,
      statusCode: data.code,
      message: data.message || '',
      data: data.data,
    }
  }

  static buildRequest(request: BrandingModel): FormData {
    const {
      tenant_name,
      logo,
      privacy_policy,
      terms_of_use,
      contact_email,
      menu_style_color,
      banner_text_color,
      profile_icon_color,
      logo_url,
      support_email,
      do_not_sell_request,
    } = request

    const formData = new FormData()
    if (tenant_name) {
      formData.append('tenant_name', tenant_name)
    }
    if (logo) {
      formData.append('logo', logo, logo.name)
    }
    if (privacy_policy) {
      formData.append('privacy_policy', privacy_policy)
    }
    if (terms_of_use) {
      formData.append('terms_of_use', terms_of_use)
    }
    if (contact_email) {
      formData.append('contact_email', contact_email)
    }
    if (menu_style_color) {
      formData.append('menu_style_color', menu_style_color)
    }
    if (banner_text_color) {
      formData.append('banner_text_color', banner_text_color)
    }
    if (profile_icon_color) {
      formData.append('profile_icon_color', profile_icon_color)
    }
    if (logo_url) {
      formData.append('logo_url', logo_url)
    }
    if (support_email) {
      formData.append('support_email', support_email)
    }

    formData.append('do_not_sell_request', isBoolean(do_not_sell_request) ? do_not_sell_request.toString() : 'false')

    return formData
  }
}

export default new PCBrandingService()
