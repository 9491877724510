import { Dispatch } from 'react'
import { Action } from 'redux'
import service, { VerifyCodeRequest } from '../Services/verifyuserlicense.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_VERIFY_APPSUMO_LICENSE = 'ACTION_VERIFY_APPSUMO_LICENSE'
export const ACTION_VERIFY_APPSUMO_LICENSE_CANCEL = 'ACTION_VERIFY_APPSUMO_LICENSE_CANCEL'
export const ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS = 'ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS'
export const ACTION_VERIFY_APPSUMO_LICENSE_FAILED = 'ACTION_VERIFY_APPSUMO_LICENSE_FAILED'

export interface ActionVerifyASLicense extends Action {
  type: typeof ACTION_VERIFY_APPSUMO_LICENSE
}

export interface ActionVerifyASLicenseCancel extends Action {
  type: typeof ACTION_VERIFY_APPSUMO_LICENSE_CANCEL
}

export interface ActionVerifyASLicenseSuccess extends Action {
  type: typeof ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS
  payload: {
    code: string,
    is_appsumo_license_user: boolean
  }
}
export interface ActionVerifyASLicenseFailed extends Action {
  type: typeof ACTION_VERIFY_APPSUMO_LICENSE_FAILED
  payload: {
    code: string,
    is_appsumo_license_user: boolean
  }
}

export type VerifyASLicenseActions =
  | ActionVerifyASLicense
  | ActionVerifyASLicenseFailed
  | ActionVerifyASLicenseCancel
  | ActionVerifyASLicenseSuccess

const dispatchASLicneseVerify = (): ActionVerifyASLicense => ({
  type: ACTION_VERIFY_APPSUMO_LICENSE,
})

const dispatchASLicenseVerifySuccess = (code: string, is_appsumo_license_user: boolean): ActionVerifyASLicenseSuccess => ({
  type: ACTION_VERIFY_APPSUMO_LICENSE_SUCCESS,
  payload: {
    code,
    is_appsumo_license_user
  },
})

const dispatchASLicenseVeifyFailed = (code: string, is_appsumo_license_user: boolean): ActionVerifyASLicenseFailed => ({
  type: ACTION_VERIFY_APPSUMO_LICENSE_FAILED,
  payload: { code, is_appsumo_license_user },
})

export const dispatchEmailVerifyCancel = (): ActionVerifyASLicenseCancel => ({
  type: ACTION_VERIFY_APPSUMO_LICENSE_CANCEL,
})

export function actionCodeCheck(req: VerifyCodeRequest): (dispatch: Dispatch<VerifyASLicenseActions>) => Promise<void> {
  return async (dispatch: Dispatch<VerifyASLicenseActions>) => {
    dispatch(dispatchASLicneseVerify())
    loaderStart(dispatch)
    try {
      const result = await service.verifyUserLicense(req)
      if (result && result.success) {
        dispatch(dispatchASLicenseVerifySuccess(req.code, true))
      } else {  
        dispatch(dispatchASLicenseVeifyFailed(req.code, false))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchASLicenseVeifyFailed(req.code, false))
      loaderStop(dispatch)
    }
  }
}
