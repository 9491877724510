import { Dispatch } from 'react'
import { Action } from 'redux'
import service, { LoginUserRequest } from '../Services/login.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_LOGIN = 'ACTION_LOGIN'
export const ACTION_LOGIN_CANCEL = 'ACTION_LOGIN_CANCEL'
export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS'
export const ACTION_LOGIN_FAILED = 'ACTION_LOGIN_FAILED'

export interface ActionLogin extends Action {
  type: typeof ACTION_LOGIN
}

export interface ActionLoginCancel extends Action {
  type: typeof ACTION_LOGIN_CANCEL
}

export interface ActionLoginSuccess extends Action {
  type: typeof ACTION_LOGIN_SUCCESS
  payload: {
    email: string
  }
}

export interface ActionLoginFailed extends Action {
  type: typeof ACTION_LOGIN_FAILED
  payload: {
    errorMessage: string
  }
}

export type LoginActions = ActionLogin | ActionLoginFailed | ActionLoginSuccess | ActionLoginCancel

const dispatchLogin = (): ActionLogin => ({
  type: ACTION_LOGIN,
})

const dispatchLoginSuccess = (email: string): ActionLoginSuccess => ({
  type: ACTION_LOGIN_SUCCESS,
  payload: {
    email,
  },
})

const dispatchLoginFailed = (error: Error): ActionLoginFailed => ({
  type: ACTION_LOGIN_FAILED,
  payload: { errorMessage: error.message },
})

export const dispatchLoginCancel = (): ActionLoginCancel => ({
  type: ACTION_LOGIN_CANCEL,
})

export function actionLogin(req: LoginUserRequest): (dispatch: Dispatch<LoginActions>) => Promise<void> {
  return async (dispatch: Dispatch<LoginActions>) => {
    dispatch(dispatchLogin())
    loaderStart(dispatch)
    try {
      const result = await service.login(req)
      if (result && result.success) {
        dispatch(dispatchLoginSuccess(req.email))
      } else {
        if (result?.code === ResponseCodes.Unauthorized) {
          toastService.error(I18n.t('auth_toast.invalid_login'))
        } else {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchLoginFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchLoginFailed(error))
      loaderStop(dispatch)
    }
  }
}
