import React from 'react'
import styled from 'styled-components'

type EmailTooltipProps = {
  text: string
  email: string
  lightFont?: boolean
}

const Link = styled.a<{ lightFont: boolean }>`
  color: rgba(0, 0, 0, 0.8);
  font-style: normal;
  font-weight: ${props => (props.lightFont ? 400 : 500)};
  font-size: 13px;
  &:hover {
    color: #2f00d9;
  }
`

const EmailTooltip = (props: EmailTooltipProps) => {
  const { text, email, lightFont } = props
  return (
    <div className={`d-flex align-items-start`}>
      <Link lightFont={lightFont} title={text} href={'mailto:' + email}>
        {text}
      </Link>
    </div>
  )
}

export default React.memo(EmailTooltip)
