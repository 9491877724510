export const cookies = {
  name: 'Name',
  trackingScripts: 'Tracking Scripts',
  trackingScript: 'Tracking Script',
  thirdPartyScript: 'Third Party Script',
  allCategories: 'All Categories',
  cookie: 'Cookie',
  cookies: 'Cookies',
  category: 'Category',
  description: 'Description',
  duration: 'Duration',
  detectedOn: 'Detected On',
  noDataMsg: 'No Cookies Exist',
  noDataMsgScripts: 'No Tracking Scripts Exist',
  selectDomain: 'Domain',
  selectSubDomain: 'Select Sub Domain',
  selectCategory: 'Select Category',
  sessionExpiry: 'Session expiry',
  expiresOn: 'Expires on',
  editCookie: 'Edit Cookie',
  cookieUpdateSuccessMsg: 'Cookie details updated',
  firstPartyCookie: 'First Party Cookie',
  thirdPartyCookie: 'Third Party Cookie',
  selectCookieType: 'Select Cookie Type',
  selectCookieTypeError: 'Please select cookie type',
  selectCategoryError: 'Please select category',
  scriptError: 'Please enter tracking script URL',
  cookieType: 'Cookie Type',
  firstParty: '1st party',
  thirdParty: '3rd party',
  marketing: 'Marketing',
  functional: 'Functional',
  unclassified: 'Unclassified',
  targeting: 'Targeting',
  performance: 'Performance',
  analytics: 'Analytics',
}
