import { consentDashboard } from '../../Common/Constants/ApiUrls'
import { http } from 'Utils/AxiosWrapper'
import { DSARDashboardResponseModel } from '../Models/ConsentDashboard.model'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export interface GetDSARDashboardResponse {
  success: boolean
  code: number
  message: string
  data?: DSARDashboardResponseModel
}

export interface GetDSARDashboardService {
  getDSARDashboard(startDate: number, endDate: number): Promise<GetDSARDashboardResponse>
}

class GetDSARDashboardServiceDefault implements GetDSARDashboardService {
  async getDSARDashboard(startDate: number, endDate: number): Promise<GetDSARDashboardResponse> {
    let result: any
    try {
      const { data } = await http.request({
        method: 'get',
        url: consentDashboard.dsarDashboard + `?start_date=${startDate}&end_date=${endDate}`,
      })
      result = GetDSARDashboardServiceDefault.buildResponse(data)
    } catch (error: any) {
      result = {
        success: false,
        message: error?.response?.data?.message,
        code: error?.response?.data?.code,
      }
    }
    return result
  }

  static buildResponse(data: any): GetDSARDashboardResponse {
    if (!data) {
      return {
        success: false,
        code: ResponseCodes.EmptyData,
        message: 'Empty data in response',
      }
    }

    return {
      success: true,
      code: data.code,
      message: data.message || '',
      data: data.data,
    }
  }
}

export default new GetDSARDashboardServiceDefault()
